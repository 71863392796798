import { useState } from 'react'
import styled from 'styled-components'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import EarningsSection from './components/EarningsSection'
import GameChart from './components/GameChart'
import TeamPerformance from './components/TeamPerformance'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'
import { useUser } from '../../contexts/AuthContext'

const AnalyticsPage = () => {
  const [selectedUser, setSelectedUser] = useState<number>(0)

  const { isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer>
        <AlphaBanner />
        <Wrapper>
          <TeamPerformance selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
          <EarningsSection selectedUser={selectedUser} />
          <GameChart selectedUser={selectedUser} />
        </Wrapper>
      </PageContainer>
    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 22px;
`

export default AnalyticsPage
