import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SecondaryButton, SubmitButton } from '../../../../../../components/Button'
import Modal from '../../../../../../components/Modal'
import colors from '../../../../../../styles/colors'
import fonts from '../../../../../../styles/fonts'
import useManagersQuery from '../../queries/useManagersQuery'
import useSendRequestMutation from '../../queries/useSendRequestMutation'

interface ChooseManagerModalProps {
  isOpen: boolean
  setIsOpen: (o: boolean) => void
}

const ChooseManagerModal = ({ isOpen, setIsOpen }: ChooseManagerModalProps) => {
  const [selectedTeams, setSelectedTeams] = useState<number[]>([])
  const { data: managers } = useManagersQuery()
  const { mutateAsync: sendRequests } = useSendRequestMutation()
  const [successModal, setSuccessModal] = useState<boolean>(false)

  const sendRequest = async () => {
    await sendRequests({
      teamIds: selectedTeams,
    })

    setSuccessModal(true)
  }

  const selectTeam = (id: number) => {
    if (selectedTeams.includes(id)) {
      const newSelectedTeams = selectedTeams.filter(i => i !== id)

      setSelectedTeams([...newSelectedTeams])
    } else {
      const newSelectedTeams = [...selectedTeams]
      newSelectedTeams.push(id)
      setSelectedTeams(newSelectedTeams)
    }
  }

  useEffect(() => {}, [successModal])

  return (
    <Modal
      bigSize
      title={!successModal ? 'Choose Manager' : undefined}
      open={isOpen}
      setOpen={value => {
        setSuccessModal(false)
        setIsOpen(value)
      }}
    >
      <ModalBody>
        {successModal ? (
          <SuccessWrapper>
            <GreenTick src="/images/green-tick.svg" alt="green tick" />
            <SuccessMsg>
              A request has been sent to
              {selectedTeams.map((t, i) => {
                const manager = managers?.filter(m => m.teamId === t)?.[0]
                if (manager) {
                  return (
                    <span key={t}>
                      {' '}
                      {manager.firstName} {manager.lastName}
                      {i === selectedTeams.length - 1 ? '' : ', '}
                    </span>
                  )
                }
                return <span key={t} />
              })}
              . You will be notified of the reponse.....
            </SuccessMsg>
          </SuccessWrapper>
        ) : (
          <>
            <div>
              {managers?.map(manager => {
                return (
                  <Cell key={manager.id}>
                    <InnerWrapper
                      $disabled={manager.hasRequest}
                      onClick={() => {
                        if (!manager.hasRequest) {
                          selectTeam(manager.teamId)
                        }
                      }}
                      $selected={!manager.hasRequest && selectedTeams.includes(manager.teamId)}
                    >
                      <div>
                        <Image
                          src={manager.profilePictureUrl || '/images/manager.png'}
                          alt={`${manager.firstName} ${manager.lastName} profile picture`}
                        />
                      </div>
                      <Name>
                        {manager.firstName} {manager.lastName}
                      </Name>
                      <TeamName>
                        {manager.teamName} ({manager.teamPlayers || 0})
                      </TeamName>
                    </InnerWrapper>
                  </Cell>
                )
              })}
            </div>
            <Buttons>
              <SecondaryButton
                onClick={() => {
                  setSelectedTeams([])
                  document.getElementById('close-modal')?.click()
                }}
              >
                Cancel
              </SecondaryButton>
              <SubmitRequestButton
                onClick={sendRequest}
                disabled={selectedTeams.length === 0 || selectedTeams.length > 5}
              >
                Send Request
              </SubmitRequestButton>
            </Buttons>
          </>
        )}
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div``

const Cell = styled.div`
  display: inline-block;
  width: 33.333%;
  padding: 5px;
`

const InnerWrapper = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
  background: rgba(82, 130, 236, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 10px 10px 20px;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    `
    border: 1px solid #5282EC;
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.5;
    mix-blend-mode: luminosity;
    cursor: not-allowed;
  `}
`

const Buttons = styled.div`
  margin-top: 40px;
  text-align: center;
  margin-bottom: 20px;
`

const Image = styled.img`
  width: 36px;
  height: 36px;
  margin: 10px 0 5px;
`

const SubmitRequestButton = styled(SubmitButton)`
  margin-left: 10px;
`

const Name = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  font-family: ${fonts.fontFamilyBold};
  color: ${colors.blue900};
`

const TeamName = styled.span`
  color: #575f72;
  font-size: 12px;
  line-height: 12px;
`

const SuccessWrapper = styled.div`
  text-align: center;
  padding: 30px 60px;
`

const GreenTick = styled.img`
  width: 24px;
`

const SuccessMsg = styled.p`
  line-height: 20px;
  font-size: 14px;
  color: ${colors.blue900};

  & > span {
    font-family: ${fonts.fontFamilyBold};
  }
`

export default ChooseManagerModal
