import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'
import { ManagerRequest } from '../../../../../types/manager'

const getManagerRequests = async (): Promise<ManagerRequest[]> => {
  const data = await API.authGet(`team/requests`)

  return data
}

const useManagerRequestsQuery = () => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.MANAGER_REQUESTS, user.id], () => getManagerRequests(), {
    staleTime: 60 * 1000,
    enabled: !!user.id,
  })
}

export default useManagerRequestsQuery
