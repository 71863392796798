import colors from '../../../../../styles/colors'

interface FacebookIconProps {
  color: string
}

const FacebookIcon = ({ color }: FacebookIconProps) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0714 0H1.92857C1.41708 0 0.926543 0.207421 0.564865 0.576634C0.203188 0.945846 0 1.44661 0 1.96875L0 16.4062C0 16.9284 0.203188 17.4292 0.564865 17.7984C0.926543 18.1676 1.41708 18.375 1.92857 18.375H7.44308V12.1279H4.91183V9.1875H7.44308V6.94641C7.44308 4.39728 8.92969 2.98922 11.2066 2.98922C12.2971 2.98922 13.4373 3.18773 13.4373 3.18773V5.68969H12.1809C10.943 5.68969 10.5569 6.47391 10.5569 7.27822V9.1875H13.3204L12.8784 12.1279H10.5569V18.375H16.0714C16.5829 18.375 17.0735 18.1676 17.4351 17.7984C17.7968 17.4292 18 16.9284 18 16.4062V1.96875C18 1.44661 17.7968 0.945846 17.4351 0.576634C17.0735 0.207421 16.5829 0 16.0714 0Z"
        fill={color || colors.grey350}
      />
    </svg>
  )
}

export default FacebookIcon
