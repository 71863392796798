export enum NOTIFICATION_TYPES {
  GAME = 'Game',
  TOURNAMENT = 'Tournament',
  TEAM_REQUEST = 'TeamRequest',
  TEAM_REQUEST_ACCEPT = 'TeamRequestAccept',
  TEAM_REQUEST_REJECT = 'TeamRequestReject',
  NFT_REQUEST = 'NftRequest',
  NFT_REQUEST_ACCEPT = 'NftRequestAccept',
  NFT_REQUEST_REJECT = 'NftRequestReject',
  PAYMENT_REQUEST = 'PaymentRequest',
  SYSTEM = 'System',
}
