import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const rejectPlayer = async (id: number) => {
  const data = await API.authPost(`team/decline`, {
    id,
  })

  return data
}

interface RejectPlayerParams {
  id: number
}

const useRejectPlayerMutation = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  return useMutation(({ id }: RejectPlayerParams) => rejectPlayer(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGER_REQUESTS, user.id])
    },
  })
}

export default useRejectPlayerMutation
