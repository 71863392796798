import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import NFTCard from '../../../../components/NFTCard'
import ProfileModal from '../../../../components/ProfileModal'
import colors from '../../../../styles/colors'
import SearchSection from '../../../Marketplace/components/SearchSection'
import useSearchNFTQuery from '../../queries/useSearchNFTQuery'
import { useInView } from 'react-intersection-observer'

const ManagerNftsSection = () => {
  const { ref, inView } = useInView()
  const [searchParams] = useSearchParams()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number | null>(null)
  const navigate = useNavigate()
  const gameQuery = searchParams.get('game') || '1'
  const { status, data, fetchNextPage, hasNextPage } = useSearchNFTQuery()

  useEffect(() => {
    const page = searchParams.get('page')

    if (page) {
      const search = searchParams.get('search')
      const searchParam = encodeURIComponent(search || '')

      navigate(`/games?game=${gameQuery}${searchParam ? `&search=${searchParam}` : ''}`)
    }
  }, [])

  useEffect(() => {
    if (inView) {
      if (hasNextPage) {
        ;(async () => {
          const search = searchParams.get('search')
          const searchParam = encodeURIComponent(search || '')
          const page = searchParams.get('page')
          const newPage = parseInt(page || '1') + 1

          await fetchNextPage()

          navigate(
            `/games?game=${gameQuery}${searchParam ? `&search=${searchParam}` : ''}&page=${newPage}`
          )
        })()
      }
    }
  }, [inView])

  const handleClick = (userId?: number) => {
    if (userId) {
      setSelectedUser(userId)
      setOpenModal(true)
    }
  }

  return (
    <Container>
      <Title>Team NFTs</Title>
      <SearchSection noPadding />
      <NFTContainer>
        {data?.pages?.map?.((page, index) => (
          <Fragment key={index}>
            {page.items?.length > 0 ? (
              <>
                {page.items.map((nft: any) => (
                  <Cell key={`${nft.id}-${nft.address}`}>
                    <NFTCard
                      name={nft.name}
                      imageUrl={nft.imageUrl}
                      assignedToUserId={nft.assignedToUserId}
                      assignedToUserName={nft.assignedToUserName}
                      handleClick={handleClick}
                      availableForRequest={true}
                      metadata={nft.metadata}
                      id={nft.id}
                      isAxie={gameQuery === '1'}
                      selectedGameId={gameQuery ? Number(gameQuery) : 0}
                    />
                  </Cell>
                ))}
              </>
            ) : (
              <Wrapper>
                <NoNftsImage src="/images/no-nfts.svg" alt="no nfts" />
                <Description>Your team doesn't own any nfts.</Description>
              </Wrapper>
            )}
          </Fragment>
        ))}
      </NFTContainer>
      {hasNextPage ? (
        <LoadingContainer ref={ref}>
          <br />
        </LoadingContainer>
      ) : null}
      {hasNextPage || status === 'loading' ? (
        <LoadingContainer>
          <ClipLoader
            color={colors.blue900}
            loading={true}
            cssOverride={{
              verticalAlign: 'middle',
            }}
            size={80}
            aria-label="Loading Spinner"
          />
        </LoadingContainer>
      ) : null}
      <ProfileModal
        userId={selectedUser}
        openModal={openModal}
        setOpenModal={() => {
          setOpenModal(false)
          setSelectedUser(null)
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  padding: 0 5px 13px;
  margin-top: -20px;
`

const Title = styled.p`
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`
const NoNftsImage = styled.img`
  width: 80px;
  margin-bottom: 22px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 32px;
  color: ${colors.grey500};
`

const Wrapper = styled.div`
  text-align: center;
  padding: 50px 0;
  width: 100%;
`

const NFTContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  padding-top: 12px;
`

const Cell = styled.div`
  width: calc(33.333% - 12px);
`

const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 24px;
`

export default ManagerNftsSection
