import { useState } from 'react'
import styled from 'styled-components'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import { useUser } from '../../contexts/AuthContext'
import { ROLES } from '../../types/user'
import CardDetailsSection from './components/CardDetailsSection'
import CurrencySection from './components/CurrencySection'
import EarningsSection from './components/EarningsSection'
import ManagerReferralsSection from './components/ManagerReferralsSection'
import ManagerRequestsSection from './components/ManagerRequestsSection'
import MyManagerSection from './components/MyManagerSection'
import MyTeamSection from './components/MyTeamSection'
import PayoutSection from './components/PayoutSection'
import ProfileDetails from './components/ProfileDetails'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'

const ProfilePage = () => {
  const [editableSection, setEditableSection] = useState<number>(0)
  const { user, isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer>
        <AlphaBanner />
        <ProfileDetails
          editable={editableSection === 1}
          mask={editableSection !== 0 && editableSection !== 1}
          setEditable={() => {
            if (editableSection === 1) {
              setEditableSection(0)
            } else {
              setEditableSection(1)
            }
          }}
        />
        <SectionsContainer>
          <CurrencySection mask={editableSection !== 0 && editableSection !== 2} />
          {user.userRoleName === ROLES.MANAGER ? (
            <MyTeamSection
              editable={editableSection === 3}
              mask={editableSection !== 0 && editableSection !== 3}
            />
          ) : (
            <MyManagerSection
              editable={editableSection === 3}
              mask={editableSection !== 0 && editableSection !== 3}
            />
          )}
          <CardDetailsSection
            editable={editableSection === 4}
            mask={editableSection !== 0 && editableSection !== 4}
          />
          <EarningsSection
            editable={editableSection === 5}
            mask={editableSection !== 0 && editableSection !== 5}
          />
          <PayoutSection
            editable={editableSection === 6}
            mask={editableSection !== 0 && editableSection !== 6}
          />
          {user.userRoleName === ROLES.MANAGER ? (
            <ManagerReferralsSection
              editable={editableSection === 8}
              mask={editableSection !== 0 && editableSection !== 8}
            />
          ) : null}
          {user.userRoleName === ROLES.MANAGER ? (
            <ManagerRequestsSection
              editable={editableSection === 7}
              mask={editableSection !== 0 && editableSection !== 7}
            />
          ) : null}
        </SectionsContainer>
      </PageContainer>
    </>
  )
}

const SectionsContainer = styled.div`
  padding: 20px 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export default ProfilePage
