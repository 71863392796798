import { QueryClient } from '@tanstack/react-query'
import { redirectIfNotAuth } from '../utils/auth'
import API_INSTANCE from './config'

export const VA_TOKEN = 'va_t'

const API = {
  authPost: async (url: string, body: any) => {
    const token = window.localStorage.getItem(VA_TOKEN)
    if (!token) {
      redirectIfNotAuth()
      return
    }

    // validate if token will expire soon,
    // if true refresh the token and rerun the function
    // if (isJWTDueToExpireBeforeRequest(token)) {
    //   const refreshToken = await AsyncStorage.getItem(PAYDAYS_REFRESH_TOKEN);

    //   try {
    //     const { data } = await API_INSTANCE.post('/auth/refresh', {}, {
    //       headers: {
    //         'Authorization': `Bearer ${refreshToken}`
    //       }
    //     });

    //     if (data.access_token) {
    //       await AsyncStorage.setItem(PAYDAYS_TOKEN, data.access_token);
    //       if (API.refreshes > 5) {
    //         throw Error('Too many token refreshes')
    //       } else {
    //         API.authPost(url, body)
    //         API.refreshes += 1;
    //       }
    //     }
    //   } catch (e) {
    //     throw Error('Refresh failed')
    //   }
    //   return
    // }
    // API.refreshes = 0;

    const { data } = await API_INSTANCE.post(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return data
  },
  authPut: async (url: string, body: any) => {
    const token = window.localStorage.getItem(VA_TOKEN)
    if (!token) {
      redirectIfNotAuth()
      return
    }

    // validate if token will expire soon,
    // if true refresh the token and rerun the function
    // if (isJWTDueToExpireBeforeRequest(token)) {
    //   const refreshToken = await AsyncStorage.getItem(PAYDAYS_REFRESH_TOKEN);

    //   try {
    //     const { data } = await API_INSTANCE.post('/auth/refresh', {}, {
    //       headers: {
    //         'Authorization': `Bearer ${refreshToken}`
    //       }
    //     });

    //     if (data.access_token) {
    //       await AsyncStorage.setItem(PAYDAYS_TOKEN, data.access_token);
    //       if (API.refreshes > 5) {
    //         throw Error('Too many token refreshes')
    //       } else {
    //         API.authPost(url, body)
    //         API.refreshes += 1;
    //       }
    //     }
    //   } catch (e) {
    //     throw Error('Refresh failed')
    //   }
    //   return
    // }
    // API.refreshes = 0;

    try {
      const { data } = await API_INSTANCE.put(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      return data
    } catch (e) {
      throw Error(`${url} request failed`)
    }
  },
  authDelete: async (url: string) => {
    const token = window.localStorage.getItem(VA_TOKEN)
    if (!token) {
      redirectIfNotAuth()
      return
    }

    // validate if token will expire soon,
    // if true refresh the token and rerun the function
    // if (isJWTDueToExpireBeforeRequest(token)) {
    //   const refreshToken = await AsyncStorage.getItem(PAYDAYS_REFRESH_TOKEN);

    //   try {
    //     const { data } = await API_INSTANCE.post('/auth/refresh', {}, {
    //       headers: {
    //         'Authorization': `Bearer ${refreshToken}`
    //       }
    //     });

    //     if (data.access_token) {
    //       await AsyncStorage.setItem(PAYDAYS_TOKEN, data.access_token);
    //       if (API.refreshes > 5) {
    //         throw Error('Too many token refreshes')
    //       } else {
    //         API.authPost(url, body)
    //         API.refreshes += 1;
    //       }
    //     }
    //   } catch (e) {
    //     throw Error('Refresh failed')
    //   }
    //   return
    // }
    // API.refreshes = 0;

    try {
      const { data } = await API_INSTANCE.delete(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      return data
    } catch (e) {
      throw Error(`${url} request failed`)
    }
  },
  authGet: async (url: string) => {
    const token = window.localStorage.getItem(VA_TOKEN)
    if (!token) {
      redirectIfNotAuth()
      return
    }

    // validate if token will expire soon,
    // if true refresh the token and rerun the function
    // if (isJWTDueToExpireBeforeRequest(token)) {
    //   const refreshToken = await AsyncStorage.getItem(PAYDAYS_REFRESH_TOKEN);

    //   try {
    //     const { data } = await API_INSTANCE.post('/auth/refresh', {}, {
    //       headers: {
    //         'Authorization': `Bearer ${refreshToken}`
    //       }
    //     });

    //     if (data.access_token) {
    //       await AsyncStorage.setItem(PAYDAYS_TOKEN, data.access_token);
    //       if (API.refreshes > 5) {
    //         throw Error('Too many token refreshes')
    //       } else {
    //         API.authGet(url)
    //         API.refreshes += 1;
    //       }
    //     }
    //   } catch (e) {
    //     throw Error('Refresh failed')
    //   }
    //   return
    // }
    // API.refreshes = 0;

    try {
      const { data } = await API_INSTANCE.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })

      return data
    } catch (e) {
      throw Error(`${url} request failed`)
    }
  },
  get: async (url: string) => {
    try {
      const { data } = await API_INSTANCE.get(url, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return data
    } catch (e) {
      throw Error(`${url} request failed`)
    }
  },
  post: async (url: string, body: any) => {
    const { data } = await API_INSTANCE.post(url, body)

    return data
  },
}

export const queryClient = new QueryClient()

export default API
