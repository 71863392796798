import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { RejectButton, SecondaryButton } from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import colors from '../../../../../styles/colors'
import fonts from '../../../../../styles/fonts'
import useRejectRequestNFTMutation from '../queries/useRejectRequestNFTMutation'

interface RejectModalProps {
  openRejectModal: boolean
  setOpenRejectModal: (o: boolean) => void
  selectedRequest: any
}

const RejectModal = ({
  openRejectModal,
  setOpenRejectModal,
  selectedRequest,
}: RejectModalProps) => {
  const { mutateAsync: rejectNft, isLoading: rejectLoading } = useRejectRequestNFTMutation()

  return (
    <Modal bigSize open={openRejectModal} setOpen={setOpenRejectModal} noPadding={true}>
      <ModalBody>
        <ModalText>
          Are you sure you would like to reject <strong>{selectedRequest?.playerName}</strong>'s
          request for <strong>“{selectedRequest?.nftName}”</strong>?
        </ModalText>
        <Buttons>
          <SecondaryButton
            onClick={() => {
              setOpenRejectModal(false)
            }}
          >
            Cancel
          </SecondaryButton>
          <RejectStyledButton
            disabled={rejectLoading}
            onClick={async () => {
              if (selectedRequest) {
                await rejectNft({
                  id: selectedRequest.id,
                })
                setOpenRejectModal(false)
              }
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={rejectLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Reject
          </RejectStyledButton>
        </Buttons>
      </ModalBody>
    </Modal>
  )
}

const RejectStyledButton = styled(RejectButton)`
  font-size: 16px;
  padding: 10px 12px;
`

const ModalBody = styled.div`
  padding: 40px 90px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`

export default RejectModal
