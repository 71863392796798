import Modal from '../Modal'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { useCallback } from 'react'
import { useCurrency } from '../../contexts/CurrencyContext'
import { Currency, CurrencySymbol } from '../../types/currency'
import NftSection from './components/NftSection'
import EarningsSection from './components/EarningsSection'
import useProfileQuery from './queries/useProfileQuery'

interface ProfileModalProps {
  openModal: boolean
  setOpenModal: (o: boolean) => void
  userId: number | null
}

const ProfileModal = ({ openModal, setOpenModal, userId }: ProfileModalProps) => {
  const { data: userData } = useProfileQuery(userId)
  const { currency } = useCurrency()

  const getEarnedAmount = useCallback(
    (member: any) => {
      if (currency === Currency.BITCOIN) {
        return `${CurrencySymbol.BITCOIN} ${+parseFloat(
          member?.totalEarned?.btcAmount?.toFixed?.(5) || 0
        )}`
      }
      if (currency === Currency.EURO) {
        return `${CurrencySymbol.EURO} ${+parseFloat(
          member?.totalEarned?.eurAmount?.toFixed?.(2) || 0
        )}`
      }
      return `${CurrencySymbol.USD} ${+parseFloat(
        member?.totalEarned?.dollarAmount?.toFixed?.(2) || 0
      )}`
    },
    [currency]
  )

  if (userId === null || !userData) {
    return null
  }

  return (
    <Modal bigSize open={openModal} setOpen={setOpenModal} noPadding={true}>
      <ModalHead>
        <UserProfileImage
          src={userData.profilePictureUrl || '/images/avatar.svg'}
          alt={userData ? `${userData.firstName} ${userData.lastName}` : ''}
        />
        <UserInfoWrapper>
          <UserNameLabel>
            {userData.firstName} {userData.lastName}
          </UserNameLabel>
          <div>
            <AwardWrapper>
              <AwardIcon src="/images/award.svg" alt="award icon" />
              {userData.skillRating || 0}
            </AwardWrapper>
            <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
            {getEarnedAmount(userData)}
          </div>
        </UserInfoWrapper>
      </ModalHead>
      <ModalStatsBody>
        <NftSection userId={userData.id} />
        <div>
          <EarningsSection userId={userData.id} />
        </div>
      </ModalStatsBody>
    </Modal>
  )
}

const ModalStatsBody = styled.div`
  padding: 30px;
`

const UserProfileImage = styled.img`
  width: 60px;
  height: 60px;
  vertical-align: middle;
  border-radius: 50%;
`

const AwardIcon = styled.img`
  margin-right: 0;
  margin-bottom: -2px;
`

const BanknoteIcon = styled.img`
  margin-right: 5px;
  margin-top: -2px;
`

const AwardWrapper = styled.span`
  border-right: 1px solid #c9daff;
  padding-right: 5px;
  margin-right: 5px;
`

const ModalHead = styled.div`
  background: #f1f5ff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 30px;
`

const UserInfoWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 18px;
`

const UserNameLabel = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
  font-weight: 700;
  margin: 0 0 5px;
`

export default ProfileModal
