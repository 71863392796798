import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { LinkButton } from '../../../../components/Button'
import Popup from '../../../../components/Popup'
import TeamModal from '../../../../components/TeamModal'
import { useUser } from '../../../../contexts/AuthContext'
import useTeamQuery from '../../../../queries/useTeamQuery'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { TeamMember } from '../../../../types/user'
import { findTop3Earners } from '../../../../utils/team'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'

interface MyTeamSectionProps {
  editable: boolean
  mask?: boolean
}

const EarnerPopup = ({ earner }: { earner: TeamMember | undefined }) => {
  if (!earner) {
    return null
  }

  return (
    <Popup open={true}>
      <TeamMemberName>
        <MiniImage
          $player
          src={earner?.profilePictureUrl || '/images/avatar.svg'}
          alt="player photo"
        />
        {earner.firstName} {earner.lastName}
      </TeamMemberName>
      <Award>
        <MiniImage src="/images/banknote.svg" alt="banknote icon" />
        {earner.totalEarned || 0}
      </Award>
      <AwardBottom>
        <AwardMiniImage src="/images/award.svg" alt="awards icon" />
        {earner.skillRating || 0}
      </AwardBottom>
    </Popup>
  )
}

const MyTeamSection = ({ editable, mask }: MyTeamSectionProps) => {
  const [openTeamModal, setOpenTeamModal] = useState<boolean>(false)
  const { user } = useUser()
  const { data: teamData } = useTeamQuery(user.team?.id)

  const top3Earners = useMemo(() => findTop3Earners(teamData, user.id), [teamData, user.id])

  return (
    <Container>
      <MyTeamSectionContainer $editable={editable} $mask={mask}>
        <SectionTitle>My Team</SectionTitle>
        <Wrapper>
          <ImageWrapper>
            <SmallImage
              src={top3Earners?.[1]?.profilePictureUrl || '/images/avatar.svg'}
              alt="player photo"
            />
            <EarnerPopup earner={top3Earners?.[1]} />
          </ImageWrapper>
          <ImageWrapper>
            <Image
              src={top3Earners?.[0]?.profilePictureUrl || '/images/avatar.svg'}
              alt="player photo"
            />
            <EarnerPopup earner={top3Earners?.[0]} />
          </ImageWrapper>
          <ImageWrapper>
            <SmallImage
              src={top3Earners?.[2]?.profilePictureUrl || '/images/avatar.svg'}
              alt="player photo"
            />
            <EarnerPopup earner={top3Earners?.[2]} />
          </ImageWrapper>
          <TopEarnerName>
            {top3Earners?.[0]?.firstName} {top3Earners?.[0]?.lastName}
          </TopEarnerName>
          <IconsWrapper>
            <a href={user?.team?.telegram || '#'} target="_blank" rel="noreferrer">
              <Icon src="/images/telegram.svg" alt="telegram icon" />
            </a>
            <a href={user?.team?.discord || '#'} target="_blank" rel="noreferrer">
              <DiscordIcon src="/images/discord.svg" alt="discord icon" />
            </a>
          </IconsWrapper>
          <ViewAllButton onClick={() => setOpenTeamModal(true)}>VIEW ALL</ViewAllButton>
        </Wrapper>
      </MyTeamSectionContainer>
      <TeamModal openModal={openTeamModal} setOpenModal={setOpenTeamModal} />
    </Container>
  )
}

const Container = styled.div`
  width: 15%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  height: 315px;

  @media screen and (max-width: 1300px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  text-align: center;
  padding: 14px 0 5px;
`

const Image = styled.img`
  width: 70px;
  height: 70px;
  margin: 0 5px;
  vertical-align: middle;
  border-radius: 50%;
`

const SmallImage = styled.img`
  width: 32px;
  height: 32px;
  vertical-align: middle;
  border-radius: 50%;
`

const MiniImage = styled.img<{ $player?: boolean }>`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-top: -3px;
  margin-right: 4px;
  ${({ $player }) =>
    $player &&
    `
    border-radius: 50%;
  `}
`

const ViewAllButton = styled(LinkButton)`
  margin-top: 25px;
`

const MyTeamSectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  height: 100%;
`

const TopEarnerName = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.grey800};
  margin-top: 10px;
  margin-bottom: 1px;
`

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  & > div {
    display: none;
  }

  & > img:hover {
    & + div {
      display: block;
    }
  }
`

const Icon = styled.img`
  width: 30px;
`

const DiscordIcon = styled.img`
  width: 35px;
`

const TeamMemberName = styled.p`
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  color: ${colors.blue900};
  margin: 5px 0 12px;
  font-family: ${fonts.fontFamilyBold};
`

const IconsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

const Award = styled.p`
  margin: 0 0 12px;
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 12px;
  text-align: left;
`

const AwardBottom = styled(Award)`
  margin: 0 0 5px;
`

const AwardMiniImage = styled(MiniImage)`
  margin-top: 0;
  margin-left: 3px;
  margin-right: 1px;
`

export default MyTeamSection
