import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'
import { VictoryPie } from 'victory'
import { LinkButton } from '../../../components/Button'
import Popup from '../../../components/Popup'
import { colorScale } from '../../../components/ProfileModal/components/EarningsSection'
import { useCurrency } from '../../../contexts/CurrencyContext'
import useGetTeamMembersQuery from '../../../queries/useGetTeamMembersQuery'
import useTeamEarningsGameQuery from '../../../queries/useTeamEarningsGameQuery'
import useTeamEarningsPlayersQuery from '../../../queries/useTeamEarningsPlayersQuery'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { AggregatedEarning, Balance } from '../../../types/user'
import { selectEarningsStyles, selectNetworkStyles } from '../../../utils/dropdown'
import { CurrencyHTML } from '../../Profile/components/EarningsSection/components/EarningTile'
import SectionContainer from '../../Profile/components/SectionContainer'
import SectionTitle from '../../Profile/components/SectionTitle'

const selectOptions = [
  {
    value: 'day',
    label: 'Day',
  },
  {
    value: 'week',
    label: 'Week',
  },
  {
    value: 'month',
    label: 'Month',
  },
  {
    value: 'year',
    label: 'Year',
  },
]

const EarningsSection = () => {
  const { currency } = useCurrency()
  const [filter, setFilter] = useState(selectOptions[2])
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>(null)
  const { data: gameEarnings } = useTeamEarningsGameQuery(filter.value, selectedTeamMember?.id || 0)
  const { data: playersEarnings } = useTeamEarningsPlayersQuery(filter.value)
  const { data: teamMembers } = useGetTeamMembersQuery()

  const [hoveredElement, setHoveredElement] = useState<number | null>(null)
  const [openPopup, setOpenPopup] = useState<number | null>(null)
  const navigate = useNavigate()

  const sortedMembers = playersEarnings?.sort((a: any, b: any) =>
    a.dollarAmount > b.dollarAmount ? -1 : 1
  )

  const selectTeamMembers = teamMembers?.map((m: any) => {
    return {
      id: m.id,
      name: m.name,
      value: m.name,
      label: m.name,
    }
  })

  const defaultTotal = {
    dollarAmount: 0,
    eurAmount: 0,
    btcAmount: 0,
  }

  const totalEarned =
    gameEarnings?.reduce((acc: Balance, nextValue: AggregatedEarning) => {
      return {
        dollarAmount: acc.dollarAmount + nextValue.dollarAmount,
        eurAmount: acc.eurAmount + nextValue.eurAmount,
        btcAmount: acc.btcAmount + nextValue.btcAmount,
      }
    }, defaultTotal) || defaultTotal

  const chartData = useMemo(() => {
    return gameEarnings?.map((earning: any) => {
      return {
        x: () => null,
        y: earning.dollarAmount,
      }
    })
  }, [gameEarnings])

  const calculatePercentage = (amount: number) => {
    const onePerc = totalEarned.dollarAmount / 100
    const perc = amount / onePerc

    return perc.toFixed(1)
  }

  return (
    <Container>
      <EarningsContainer>
        <Header>
          <Title>Statistics</Title>
          <LinkButton
            onClick={() => {
              navigate('/analytics')
            }}
          >
            View all
          </LinkButton>
        </Header>
        <ContentWrapper>
          <MyTeamWrapper>
            <SectionHeader>
              <SectionLabel>My Team's Earnings</SectionLabel>
              <SelectWrapper>
                <Select
                  id="filter"
                  options={selectOptions}
                  value={filter}
                  styles={selectEarningsStyles}
                  onChange={e => {
                    if (e) {
                      setFilter(e)
                    }
                  }}
                />
              </SelectWrapper>
            </SectionHeader>
            <MembersWrapper>
              {sortedMembers?.slice(0, 5)?.map((m: any, index: number) => {
                return (
                  <MemberWrapper key={m.userId}>
                    <ProfileImage src={m.profilePictureUrl || '/images/avatar.svg'} alt="" />
                    <Column
                      $position={index + 1}
                      onClick={() => {
                        setOpenPopup(m.userId)
                      }}
                    >
                      {index === 0 ? <FirstPlace src="/images/first-place.svg" alt="" /> : null}
                      <Popup open={openPopup === m.userId} minWidth={150} noPadding>
                        <PopupWrapper>
                          <UserProfileImage
                            src={m.profilePictureUrl || '/images/avatar.svg'}
                            alt=""
                          />
                          <PopupName>{m.userName}</PopupName>
                          <div>
                            <AwardWrapper>
                              <AwardIcon src="/images/award.svg" alt="award icon" />
                              {m.experience || 0}
                            </AwardWrapper>
                            <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
                            <CurrencyHTML currency={currency} earning={m} round />
                          </div>
                        </PopupWrapper>
                      </Popup>
                    </Column>
                    <PriceTag>
                      <CurrencyHTML currency={currency} earning={m} />
                    </PriceTag>
                  </MemberWrapper>
                )
              })}
              {openPopup !== null ? (
                <PopupBackground
                  onClick={() => {
                    setOpenPopup(null)
                  }}
                />
              ) : null}
              {sortedMembers?.length === 0 ? (
                <NoData>No earnings data for this period!</NoData>
              ) : null}
            </MembersWrapper>
          </MyTeamWrapper>
          <EarningsWrapper>
            <HeaderChart>
              <SectionLabel>Earnings per game</SectionLabel>
              <Select
                id="filter-members"
                options={selectTeamMembers}
                value={selectedTeamMember}
                styles={selectNetworkStyles}
                placeholder="All players"
                isClearable={true}
                onChange={e => {
                  setSelectedTeamMember(e)
                }}
              />
            </HeaderChart>
            <ChartContainer>
              <ChartCell>
                {gameEarnings?.length > 0 ? (
                  <>
                    <VictoryPie
                      innerRadius={130}
                      padAngle={1}
                      data={chartData}
                      colorScale={colorScale}
                      padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
                      events={[
                        {
                          target: 'data',
                          eventHandlers: {
                            onMouseOver: (prop, data) => {
                              setHoveredElement(data.index)
                              return [
                                {
                                  target: 'data',
                                  mutation: ({ style }) => {
                                    return style.fill === `${colorScale[data.index]}99`
                                      ? null
                                      : { style: { fill: `${colorScale[data.index]}99` } }
                                  },
                                },
                              ]
                            },
                            onMouseOut: (prop, data) => {
                              setHoveredElement(null)
                              return [
                                {
                                  target: 'data',
                                  mutation: ({ style }) => {
                                    return style.fill === `${colorScale[data.index]}99`
                                      ? null
                                      : { style: { fill: `${colorScale[data.index]}99` } }
                                  },
                                },
                              ]
                            },
                          },
                        },
                      ]}
                    />
                    <AllAmount>
                      <CurrencyHTML currency={currency} earning={totalEarned} />
                    </AllAmount>
                  </>
                ) : null}
              </ChartCell>
              {gameEarnings?.length > 0 ? (
                <GamesCell>
                  {gameEarnings?.map((earning: any, index: number) => {
                    return (
                      <Cell key={earning.gameId}>
                        <InnerCell $hovered={index === hoveredElement}>
                          <GameName $hovered={index === hoveredElement}>
                            <Color $index={index} />
                            {earning.gameName}
                          </GameName>
                          <GamePerc>{calculatePercentage(earning.dollarAmount)}%</GamePerc>
                        </InnerCell>
                      </Cell>
                    )
                  })}
                </GamesCell>
              ) : null}
              {gameEarnings?.length === 0 ? (
                <NoData $games>No game data for this period!</NoData>
              ) : null}
            </ChartContainer>
          </EarningsWrapper>
        </ContentWrapper>
      </EarningsContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 65%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const EarningsContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const Title = styled(SectionTitle)`
  margin-left: 0;
  margin-bottom: 0;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const MyTeamWrapper = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 20px rgba(121, 172, 255, 0.16);
  border-radius: 12px;
  padding: 20px 25px;
  width: 50%;
  min-height: 320px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const EarningsWrapper = styled.div`
  background: ${colors.white};
  box-shadow: 0px 4px 20px rgba(121, 172, 255, 0.16);
  border-radius: 12px;
  padding: 23px 25px;
  width: 50%;
  min-height: 320px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SectionLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 10px;
`

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
`

const Column = styled.div<{ $position: number }>`
  width: 30px;
  height: 135px;
  background: #2d4a6c;
  transition: all 0.4s;
  border-radius: 12px;
  cursor: pointer;
  position: relative;

  &:hover {
    background: ${colors.blue500};
  }

  ${({ $position }) =>
    $position === 2 &&
    `
    height: 115px;

  `}

  ${({ $position }) =>
    $position === 3 &&
    `
    height: 90px;

  `}

  ${({ $position }) =>
    $position === 4 &&
    `
    height: 58px;
  `}

  ${({ $position }) =>
    $position === 5 &&
    `
    height: 35px;
  `}
`

const PriceTag = styled.div`
  color: ${colors.blue900};
  font-family: ${fonts.fontFamilyBold};
  font-size: 14px;
  line-height: 16px;
`

const FirstPlace = styled.img`
  position: absolute;
  top: 15px;
  left: 5px;
  right: 5px;
  width: 20px;
`

const SelectWrapper = styled.div`
  max-width: 100px;
`

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ChartCell = styled.div`
  position: relative;
  max-width: 150px;
`

const GamesCell = styled.div`
  padding-left: 30px;
  padding-top: 10px;
`

const Color = styled.div<{ $index: number }>`
  display: inline-block;
  width: 23px;
  height: 13px;
  border-radius: 4px;
  margin-right: 7px;
  margin-left: 5px;
  vertical-align: middle;

  ${({ $index }) => `
    background: ${colorScale[$index]};
  `}
`

const Cell = styled.div`
  margin-bottom: 7px;
`

const InnerCell = styled.div<{ $hovered?: boolean }>`
  padding-bottom: 5px;
  transition: 0.4s all;
  border-radius: 6px;
  padding-top: 5px;
  padding-right: 5px;
  border: 1px solid ${colors.white};

  ${({ $hovered }) =>
    $hovered &&
    `
    background: rgba(86, 135, 244, 0.15);
  `}
`

const AllAmount = styled.p`
  position: absolute;
  top: calc(50% - 15px);
  text-align: center;
  left: 0;
  right: 0;
  margin: 0;

  & > span {
    font-size: 14px;
    line-height: 16px;
    color: ${colors.blue900};
    font-family: ${fonts.fontFamilyBold};
  }
`

const GameName = styled.div<{ $hovered?: boolean }>`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
  margin-bottom: 2px;

  ${({ $hovered }) =>
    $hovered &&
    `
    color: ${colors.blue500};
  `}
`

const GamePerc = styled.div`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  padding-left: 35px;
`

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
`

const NoData = styled.p<{ $games?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  color: ${colors.blue900};
  text-align: center;
  width: 100%;
  margin-top: 15px;

  ${({ $games }) =>
    $games &&
    `
    margin-top: 2px;
  `}
`

const ProfileImage = styled.img`
  width: 42px;
  border-radius: 50%;
`

const UserProfileImage = styled.img`
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 50%;
`

const AwardIcon = styled.img`
  margin-right: 4px;
  margin-bottom: -2px;
  margin-left: 4px;
`

const BanknoteIcon = styled.img`
  margin-right: 9px;
  margin-top: -2px;
  margin-left: 3px;
`

const AwardWrapper = styled.div`
  margin: 10px 0;
`

const PopupWrapper = styled.div`
  padding: 12px;
  font-size: 12px;
  line-height: 12px;
`

const PopupName = styled.span`
  font-family: ${fonts.fontFamilyBold};
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
`

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
`

const HeaderChart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export default EarningsSection
