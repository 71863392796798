import Modal from '../Modal'
import styled from 'styled-components'
import fonts from '../../styles/fonts'
import colors from '../../styles/colors'
import Input from '../Input'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useUser } from '../../contexts/AuthContext'
import useTeamQuery from '../../queries/useTeamQuery'
import { useCurrency } from '../../contexts/CurrencyContext'
import { Currency, CurrencySymbol } from '../../types/currency'
import { TeamMember } from '../../types/user'
import Select from 'react-select'
import { selectFilterStyles } from '../../utils/dropdown'
import NftSection from './components/NftSection'
import EarningsSection from './components/EarningsSection'
import { getDefaultAmount } from '../../utils/user'

interface TeamModalProps {
  openModal: boolean
  setOpenModal: (o: boolean) => void
}

type Filter = {
  value: string
  label: string
}

const filters = [
  {
    value: 'name',
    label: 'Name',
  },
  {
    value: 'rating',
    label: 'Rating',
  },
  {
    value: 'earnings',
    label: 'Earnings',
  },
]

const TeamModal = ({ openModal, setOpenModal }: TeamModalProps) => {
  const [searchUser, setSearchUser] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<number | null>(null)
  const [filteredMembers, setFilteredMembers] = useState<TeamMember[]>([])
  const [filter, setFilter] = useState<Filter>(filters[0])
  const { user } = useUser()
  const { data: teamData } = useTeamQuery(user.team?.id)

  const sUser =
    typeof selectedUser === 'number'
      ? teamData?.members?.filter((m: TeamMember) => m.id === selectedUser)[0]
      : null

  const { currency } = useCurrency()

  useEffect(() => {
    if (teamData?.members) {
      if (filter.value === 'name') {
        const newMembers = teamData.members.sort((a: TeamMember, b: TeamMember) => {
          const aName = `${a.firstName} ${a.lastName}`
          const bName = `${b.firstName} ${b.lastName}`
          return aName.localeCompare(bName)
        })
        setFilteredMembers([...newMembers])
      } else if (filter.value === 'rating') {
        const newMembers = teamData.members.sort((a: TeamMember, b: TeamMember) => {
          return a.skillRating > b.skillRating ? -1 : 1
        })
        setFilteredMembers([...newMembers])
      } else if (filter.value === 'earnings') {
        const newMembers = teamData.members.sort((a: TeamMember, b: TeamMember) => {
          return a.totalEarned > b.totalEarned ? -1 : 1
        })
        setFilteredMembers([...newMembers])
      }
    }
  }, [teamData?.members, filter])

  const getEarnedAmout = useCallback(
    (member: TeamMember) => {
      if (currency === Currency.BITCOIN) {
        return `${CurrencySymbol.BITCOIN} ${
          member.totalEarnedBtc || getDefaultAmount(member.id, 0.015)
        }`
      }
      if (currency === Currency.EURO) {
        return `${CurrencySymbol.EURO} ${member.totalEarnedEur || getDefaultAmount(member.id, 15)}`
      }
      if (currency === Currency.USD) {
        return `${CurrencySymbol.USD} ${
          member.totalEarnedDollar || getDefaultAmount(member.id, 15)
        }`
      }
      return member.totalEarned
    },
    [currency]
  )

  return (
    <Modal
      bigSize
      title={!selectedUser ? 'My Team' : ''}
      open={openModal}
      setOpen={setOpenModal}
      noPadding={!!selectedUser}
    >
      {!selectedUser ? (
        <ModalBody>
          <Cell>
            <SearchContainer>
              <Input
                id="search-user"
                value={searchUser}
                placeholder="Search player"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setSearchUser(e.target.value)
                }}
                big
                icon={'/images/magnifier.svg'}
              />
            </SearchContainer>
          </Cell>
          <Cell $alignRight>
            <FilterWrapper>
              <Select
                id="country"
                options={filters}
                value={filter}
                styles={selectFilterStyles}
                isSearchable={false}
                onChange={e => {
                  setFilter(filters.filter(f => f.value === e?.value)[0])
                }}
              />
            </FilterWrapper>
          </Cell>
          <UsersWrapper>
            {filteredMembers?.map((user: any) => {
              return (
                <UserContainer key={user.id}>
                  <UserWrapper>
                    <div>
                      <UserImage
                        src={user.profilePictureUrl || '/images/avatar.svg'}
                        alt={`${user.firstName} ${user.lastName}`}
                        onClick={() => setSelectedUser(user.id)}
                      />
                    </div>
                    <UserName onClick={() => setSelectedUser(user.id)}>
                      {user.firstName} {user.lastName}
                    </UserName>
                    <div>
                      <UserInfo>
                        <AwardWrapper>
                          <AwardIcon src="/images/award.svg" alt="award icon" />
                          {user.skillRating || 0}
                        </AwardWrapper>
                        <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
                        {getEarnedAmout(user)}
                      </UserInfo>
                    </div>
                  </UserWrapper>
                </UserContainer>
              )
            })}
          </UsersWrapper>
        </ModalBody>
      ) : (
        <>
          <ModalHead>
            <ArrowBack onClick={() => setSelectedUser(null)} src="/images/arrow.svg" alt="back" />
            <UserProfileImage
              src={sUser.profilePictureUrl || '/images/avatar.svg'}
              alt={sUser ? `${sUser.firstName} ${sUser.lastName}` : ''}
            />
            <UserInfoWrapper>
              <UserNameLabel>
                {sUser.firstName} {sUser.lastName}
              </UserNameLabel>
              <div>
                <AwardWrapper>
                  <AwardIcon src="/images/award.svg" alt="award icon" />
                  {sUser.skillRating || 0}
                </AwardWrapper>
                <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
                {getEarnedAmout(sUser)}
              </div>
            </UserInfoWrapper>
          </ModalHead>
          <ModalStatsBody>
            <NftSection userId={sUser?.id} />
            <div>
              <EarningsSection userId={sUser?.id} />
            </div>
          </ModalStatsBody>
        </>
      )}
    </Modal>
  )
}

const ModalBody = styled.div``

const ModalStatsBody = styled.div`
  padding: 30px;
`

const Cell = styled.div<{ $alignRight?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;

  ${({ $alignRight }) =>
    $alignRight &&
    `
    text-align: right;
  `}
`

const UsersWrapper = styled.div`
  margin-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 70px;
`

const UserContainer = styled.div`
  display: inline-block;
  width: 33.333%;
  vertical-align: top;
  padding: 5px;
`

const UserWrapper = styled.div`
  background: rgba(142, 177, 255, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 15px 10px 10px;
`

const UserImage = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
  border-radius: 50%;
`

const UserProfileImage = styled.img`
  width: 60px;
  height: 60px;
  vertical-align: middle;
  border-radius: 50%;
`

const UserName = styled.p`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: ${colors.blue900};
  margin: 10px 0;
  font-weight: 700;
  font-family: ${fonts.fontFamilyBold};
  cursor: pointer;
`

const UserInfo = styled.span`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin-bottom: 10px;
`

const AwardIcon = styled.img`
  margin-right: 0;
  margin-bottom: -2px;
`

const BanknoteIcon = styled.img`
  margin-right: 5px;
  margin-top: -2px;
`

const AwardWrapper = styled.span`
  border-right: 1px solid #c9daff;
  padding-right: 5px;
  margin-right: 5px;
`

const SearchContainer = styled.div`
  max-width: 80%;
  padding-left: 5px;
`

const ModalHead = styled.div`
  background: #f1f5ff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 30px;
`

const ArrowBack = styled.img`
  cursor: pointer;
  vertical-align: middle;
  margin-right: 15px;
`

const FilterWrapper = styled.div`
  display: inline-block;
  width: 150px;
  text-align: left;
`

const UserInfoWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 18px;
`

const UserNameLabel = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
  font-weight: 700;
  margin: 0 0 5px;
`

export default TeamModal
