import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useViewNotificationMutation from '../../queries/useViewNotificationMutation'
import colors from '../../styles/colors'
import { NOTIFICATION_TYPES } from '../../types/notification'

interface NotificationsProps {
  openNotifications: boolean
  setOpenNotifications: (o: boolean) => void
  notifications: any
}

const Notifications = ({
  openNotifications,
  setOpenNotifications,
  notifications,
}: NotificationsProps) => {
  const { mutateAsync: viewNotification } = useViewNotificationMutation()
  const navigate = useNavigate()

  return (
    <NotificationWrapper $open={openNotifications}>
      <TitleWrapper
        onClick={() => {
          setOpenNotifications(false)
        }}
      >
        <Title>Notifications</Title>
        <Arrow src="/images/white-arrow-icon.svg" alt="arrow" />
      </TitleWrapper>
      <div>
        <ViewContainer>
          <Latest>Latest</Latest>
          <LinkBtn to="/notifications">VIEW ALL</LinkBtn>
        </ViewContainer>

        {notifications?.slice(0, 6)?.map((not: any) => {
          return (
            <NotWrapper
              key={not.id}
              onClick={async () => {
                if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST) {
                  navigate('/')
                }
                if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_ACCEPT) {
                  navigate('/')
                }
                if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_REJECT) {
                  navigate('/')
                }
                if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST) {
                  navigate('/marketplace?isLeased=false')
                }
                if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_ACCEPT) {
                  navigate('/marketplace?isLeased=false')
                }
                if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_REJECT) {
                  navigate('/marketplace?isLeased=false')
                }
                if (not.notificationType?.includes(NOTIFICATION_TYPES.PAYMENT_REQUEST)) {
                  navigate('/payments')
                }
                await viewNotification({ id: not.id })
              }}
            >
              <NotificationImageWrapper>
                <NFTImage
                  src={not.pictureUrl || '/images/avatar.svg'}
                  $isAxie={not.pictureUrl?.includes('axieinfinity')}
                />
              </NotificationImageWrapper>
              <Description>{not.description}</Description>
              <Dot className="dot" />
              <Cross
                src="/images/notification-close.svg"
                alt=""
                onClick={async () => {
                  await viewNotification({ id: not.id })
                }}
              />
            </NotWrapper>
          )
        })}
      </div>
    </NotificationWrapper>
  )
}

const NotificationWrapper = styled.div<{ $open: boolean }>`
  background: linear-gradient(180deg, rgba(51, 120, 232, 0.56) 0%, rgba(59, 130, 246, 0) 100%);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  bottom: 0;
  top: 100%;
  left: 0;
  right: 0;
  position: absolute;
  opacity: 0;
  transition: all 0.4s;
  padding: 10px 0;
  z-index: 2;

  ${({ $open }) =>
    $open &&
    `
    bottom: 0;
    top: 140px;
    opacity: 1;
  `}
`

const Title = styled.span`
  color: ${colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
`

const TitleWrapper = styled.div`
  border-bottom: 1px solid #5687f4;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const ViewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 10px;
  padding: 0 10px;
`

const Latest = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};
`

const LinkBtn = styled(Link)`
  color: ${colors.white};
  background: transparent;
  border: 0;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  padding: 0 5px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:disabled {
    opacity: 0;
  }

  &:hover {
    color: ${colors.grey400};
  }
`

const Arrow = styled.img``

const NotWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 0;
  transition: all 0.4s;

  &:hover {
    background: rgba(3, 41, 96, 0.14);

    & > .dot {
      right: 28px;
    }

    & > img {
      opacity: 1;
    }
  }
`

const NFTImage = styled.img<{ $isAxie: boolean }>`
  top: 50%;
  left: 50%;
  position: relative;
  transform: translateX(-40%) translateY(-50%);
  width: 40px;
  max-height: 40px;
  border-radius: 50%;

  ${({ $isAxie }) =>
    $isAxie &&
    `
    transform: translateX(-40%) translateY(-50%) scale(1.7);
  `}
`

const NotificationImageWrapper = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`

const Description = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 14px;
  padding-right: 32px;
  padding-left: 10px;
  color: ${colors.white};
`

const Dot = styled.span`
  transition: all 0.4s;
  background: #fa8e40;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;
`

const Cross = styled.img`
  transition: all 0.4s;
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
  opacity: 0;
`

export default Notifications
