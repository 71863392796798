import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const fetchAvailableCurrency = async () => {
  const data = await API.authGet(`lookup/currency`)

  return data
}

const useAvailableWithdrawCurrency = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.WITHDRAW_CURRENCY],
    queryFn: fetchAvailableCurrency,
  })
}

export default useAvailableWithdrawCurrency
