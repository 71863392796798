import styled from 'styled-components'
import { useUser } from '../../../contexts/AuthContext'
import { useCurrency } from '../../../contexts/CurrencyContext'
import useTeamQuery from '../../../queries/useTeamQuery'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { getDefaultAmount } from '../../../utils/user'
import { CurrencyHTML } from '../../Profile/components/EarningsSection/components/EarningTile'
import SectionContainer from '../../Profile/components/SectionContainer'

interface TeamPerformanceProps {
  selectedUser: number
  setSelectedUser: (n: number) => void
}

const TeamPerformance = ({ selectedUser, setSelectedUser }: TeamPerformanceProps) => {
  const { user } = useUser()
  const { currency } = useCurrency()
  const { data: teamData } = useTeamQuery(user.team?.id)

  return (
    <Container>
      <TeamPerformanceContainer>
        <SectionLabel>My Team's Performance</SectionLabel>
        <MembersWrapper>
          <UserContainer
            key={'all'}
            $selected={0 === selectedUser}
            onClick={() => setSelectedUser(0)}
          >
            <div>
              <TeamImage src={'/images/teams-grey.svg'} alt={`${teamData?.name}`} />
            </div>
            <UserName>{teamData?.name}</UserName>
          </UserContainer>
          {teamData?.members?.map((user: any) => {
            return (
              <UserContainer
                key={user.id}
                $selected={user.id === selectedUser}
                onClick={() => setSelectedUser(user.id)}
              >
                <div>
                  <UserImage
                    src={user.profilePictureUrl || '/images/avatar.svg'}
                    alt={`${user.firstName} ${user.lastName}`}
                  />
                </div>
                <UserName>
                  {user.firstName} {user.lastName}
                </UserName>
                <div>
                  <UserInfo>
                    <AwardWrapper>
                      <AwardIcon src="/images/award.svg" alt="award icon" />
                      {user.skillRating || 0}
                    </AwardWrapper>
                    <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
                    <CurrencyHTML
                      currency={currency}
                      earning={{
                        btcAmount: getDefaultAmount(user.id, 0.015),
                        eurAmount: getDefaultAmount(user.id, 15),
                        dollarAmount: getDefaultAmount(user.id, 15),
                      }}
                    />
                  </UserInfo>
                </div>
              </UserContainer>
            )
          })}
        </MembersWrapper>
      </TeamPerformanceContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 15px 10px;
`

const TeamPerformanceContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
`

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 11px;
`

const SectionLabel = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`

const UserContainer = styled.div<{ $selected: boolean }>`
  width: 150px;
  background: rgba(142, 177, 255, 0.1);
  border-radius: 8px;
  text-align: center;
  padding: 20px 10px 10px;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background: rgba(142, 177, 255, 0.3);
  }

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(142, 177, 255, 0.5);

    &:hover {
      background: rgba(142, 177, 255, 0.5);
    }
  `}
`

const UserImage = styled.img`
  width: 27px;
  height: 27px;
  cursor: pointer;
  border-radius: 50%;
`

const TeamImage = styled.img`
  width: 58px;
  margin-top: 8px;
  margin-bottom: 10px;
  cursor: pointer;
`

const UserName = styled.p`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: ${colors.blue900};
  margin: 13px 0;
  font-weight: 700;
  font-family: ${fonts.fontFamilyBold};
  cursor: pointer;
`

const UserInfo = styled.span`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  display: block;
  margin-bottom: 10px;
`

const AwardIcon = styled.img`
  margin-right: 0;
  margin-bottom: -2px;
`

const BanknoteIcon = styled.img`
  margin-right: 5px;
  margin-bottom: 3px;
`

const AwardWrapper = styled.span`
  border-right: 1px solid #c9daff;
  padding-right: 5px;
  margin-right: 5px;
`

export default TeamPerformance
