import { useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'
import { LinkButton, SubmitButton } from '../../../../components/Button'
import CustomValueContainer from '../../../../components/CustomValueContainer'
import colors from '../../../../styles/colors'
import { selectSearchFilterStyles } from '../../../../utils/dropdown'
import useSearchAutocompleteQuery from './queries/useSearchAutocompleteQuery'

const SearchSection = ({
  noPadding,
  marketplace,
}: {
  noPadding?: boolean
  marketplace?: boolean
}) => {
  const { data: autocompleteData } = useSearchAutocompleteQuery()
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')
  const isUnleased = searchParams.get('isLeased') === 'false'

  const [selectedFilters, setSelectedFilters] = useState<any>(
    search
      ? search.split(',').map(s => {
          return {
            value: s,
            label: s,
          }
        })
      : []
  )
  const navigate = useNavigate()
  const currentPage = marketplace ? 'marketplace' : 'games'

  const game = searchParams.get('game') || '1'

  const autocompleteWords = useMemo(() => {
    if (autocompleteData) {
      return autocompleteData.map((a: any) => {
        return {
          value: a,
          label: a,
        }
      })
    }
    return []
  }, [autocompleteData])

  return (
    <Container $noPadding={noPadding}>
      <Wrapper>
        <InnerContainer>
          <SearchWrapper>
            <Magnifier src="/images/magnifier.svg" />
            <Select
              components={{
                ValueContainer: props => <CustomValueContainer {...props} icon={true} />,
              }}
              id="search-nft"
              options={autocompleteWords}
              value={selectedFilters}
              placeholder="Search"
              styles={selectSearchFilterStyles}
              onChange={(e: any) => {
                const findFilter = selectedFilters.filter((f: any) => f.value === e.value)
                if (findFilter?.length > 0) {
                  const newFilters = selectedFilters.filter((f: any) => f.value !== e.value)
                  const searchParam = encodeURIComponent(
                    newFilters.map((n: any) => n.value).join(',')
                  )
                  const isLeased = searchParams.get('isLeased')

                  navigate(
                    `/${currentPage}?game=${game}${searchParam ? `&search=${searchParam}` : ''}${
                      isLeased && currentPage === 'marketplace' ? `&isLeased=${isLeased}` : ''
                    }`
                  )
                  setSelectedFilters(newFilters)
                } else {
                  const isLeased = searchParams.get('isLeased')
                  const newFilters = [...selectedFilters, e]
                  const searchParam = encodeURIComponent(
                    newFilters.map((n: any) => n.value).join(',')
                  )
                  navigate(
                    `/${currentPage}?game=${game}&search=${searchParam}${
                      isLeased && currentPage === 'marketplace' ? `&isLeased=${isLeased}` : ''
                    }`
                  )
                  setSelectedFilters([...selectedFilters, e])
                }
              }}
            />
          </SearchWrapper>
          {selectedFilters.map((s: any) => {
            return (
              <Filter
                key={s.value}
                onClick={() => {
                  const newFilters = selectedFilters.filter((f: any) => f.value !== s.value)
                  const searchParam = encodeURIComponent(
                    newFilters.map((n: any) => n.value).join(',')
                  )
                  const isLeased = searchParams.get('isLeased')

                  navigate(
                    `/${currentPage}?game=${game}${searchParam ? `&search=${searchParam}` : ''}${
                      isLeased && currentPage === 'marketplace' ? `&isLeased=${isLeased}` : ''
                    }`
                  )
                  setSelectedFilters(newFilters)
                }}
              >
                {s.label}
                <img src="/images/close.svg" alt="delete" />
              </Filter>
            )
          })}
          {selectedFilters.length > 0 ? (
            <LinkButton
              onClick={() => {
                const isLeased = searchParams.get('isLeased')

                navigate(
                  `/${currentPage}?game=${game}${
                    isLeased && currentPage === 'marketplace' ? `&isLeased=${isLeased}` : ''
                  }`
                )
                setSelectedFilters([])
              }}
            >
              Clear all
            </LinkButton>
          ) : null}
        </InnerContainer>
        {marketplace ? (
          <LeasedToggle>
            <Toggler
              $selected={isUnleased}
              onClick={() => {
                setSelectedFilters([])
                navigate(`/marketplace?game=${game}&isLeased=false`)
              }}
            >
              Unleased
            </Toggler>
            <Toggler
              $selected={!isUnleased}
              onClick={() => {
                setSelectedFilters([])
                navigate(`/marketplace?game=${game}&isLeased=true`)
              }}
            >
              Leased
            </Toggler>
          </LeasedToggle>
        ) : (
          <MarketplaceButton>
            <SubmitButton
              onClick={() => {
                navigate(`/marketplace?game=${game}&isLeased=false`)
              }}
            >
              Go to marketplace
            </SubmitButton>
          </MarketplaceButton>
        )}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div<{ $noPadding?: boolean }>`
  padding: 25px 22px 0;

  ${({ $noPadding }) =>
    $noPadding &&
    `
    padding: 0;
  `}
`

const InnerContainer = styled.div`
  width: 75%;
  display: inline-block;
  vertical-align: top;
`

const LeasedToggle = styled.div`
  width: 25%;
  display: inline-flex;
  vertical-align: top;
  gap: 8px;
  padding-top: 5px;
  justify-content: flex-end;
`
const MarketplaceButton = styled.div`
  width: 25%;
  display: inline-flex;
  vertical-align: top;
  justify-content: flex-end;
`

const Wrapper = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding-bottom: 18px;
`

const SearchWrapper = styled.div`
  width: 240px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 7px;
  position: relative;
`

const Magnifier = styled.img`
  position: absolute;
  top: 8px;
  left: 7px;
  width: 16px;
  z-index: 1;
`

const Filter = styled.span`
  background: rgba(86, 135, 244, 0.15);
  border-radius: 12.5px;
  letter-spacing: 0.15px;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-left: 5px;
  padding: 0 24px 2px 12px;
  position: relative;
  cursor: pointer;
  color: #4f6e8e;
  transition: all 0.4s;

  &:hover {
    background: rgba(86, 135, 244, 0.3);
  }

  & > img {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 8px;
  }
`

const Toggler = styled.span<{ $selected: boolean }>`
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  padding: 1px 10px;
  transition: all 0.4s;
  color: #4f6e8e;
  background: rgba(255, 203, 70, 0.3);
  border-radius: 22px;
  cursor: pointer;
  &:hover {
    background: rgba(229, 182, 63, 0.3);
  }

  ${({ $selected }) =>
    $selected &&
    `
    color: ${colors.blue900};
    background: #FFCB46;

    &:hover {
      background: #e5b63f;
    }
  `}
`

export default SearchSection
