import { useQueryClient } from '@tanstack/react-query'
import { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { VA_TOKEN } from '../../api'
import { useUser } from '../../contexts/AuthContext'
import useGetUnreadNotificationsQuery from '../../queries/useGetUnreadNotificationsQuery'
import ROUTES from '../../routes'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import TutorialTooltip from '../TutorialTooltip/TutorialTooltip'
import MobileNav from './MobileNav'
import Notifications from './Notifications'

const Navigation = () => {
  const { user, tutorialStep, setTutorialStep } = useUser()
  const target = useRef(null)
  const gamesTarget = useRef(null)
  const profileTarget = useRef(null)
  const [collapsedNav, setCollapsedNav] = useState<boolean>(true)
  const [openNotifications, setOpenNotifications] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { data: notifications } = useGetUnreadNotificationsQuery()

  const mobileMediaQuery = window.matchMedia('(max-width: 768px)')

  if (mobileMediaQuery?.matches) {
    return <MobileNav />
  }

  return (
    <Container
      $collapsed={collapsedNav}
      onMouseEnter={() => {
        setCollapsedNav(false)
      }}
      onMouseLeave={() => {
        setOpenNotifications(false)
        setCollapsedNav(true)
      }}
    >
      <LogoContainer>
        <LogoWrapper $collapsed={collapsedNav}>
          <Logo
            src={collapsedNav ? '/images/small-logo.svg' : '/images/white-logo.svg'}
            alt="virtue alliance"
          />
        </LogoWrapper>
      </LogoContainer>
      <ImageWrapper
        $selected={location.pathname === ROUTES.PROFILE_PAGE}
        onClick={() => {
          if (tutorialStep === 7) {
            setTutorialStep(tutorialStep + 1)
          }
          navigate('/')
        }}
      >
        <Overlay placement={'bottom'} show={tutorialStep === 7} target={profileTarget.current}>
          {props => (
            <Tooltip className="tutorial" {...props}>
              <TutorialTooltip>Step: Check your game credentials</TutorialTooltip>
            </Tooltip>
          )}
        </Overlay>
        <Image
          ref={profileTarget}
          $collapsed={collapsedNav}
          src={user.previewPhoto || '/images/avatar.svg'}
          alt="profile avatar"
        />
      </ImageWrapper>
      <LinkWrapper
        $collapsed={collapsedNav}
        $notificationsOpen={openNotifications}
        $selected={location.pathname === ROUTES.DASHBOARD_PAGE}
        onClick={() => {
          navigate('/dashboard')
        }}
      >
        <Icon $collapsed={collapsedNav} src="/images/dashboard-icon.svg" alt="dashboard icon" />
        <LinkLabel $collapsed={collapsedNav}>Dashboard</LinkLabel>
      </LinkWrapper>
      <Overlay placement={'top'} show={tutorialStep === 3} target={gamesTarget.current}>
        {props => (
          <Tooltip className="tutorial" {...props}>
            <TutorialTooltip>Step: Explore all games</TutorialTooltip>
          </Tooltip>
        )}
      </Overlay>
      <LinkWrapper
        $selected={location.pathname === ROUTES.GAMES_PAGE}
        $collapsed={collapsedNav}
        $notificationsOpen={openNotifications}
        onClick={() => {
          if (tutorialStep === 3) {
            setTutorialStep(tutorialStep + 1)
          }
          navigate('/games')
        }}
      >
        <Icon
          ref={gamesTarget}
          $collapsed={collapsedNav}
          src="/images/games-icon.svg"
          alt="games icon"
        />
        <LinkLabel $collapsed={collapsedNav}>Games</LinkLabel>
      </LinkWrapper>
      <Overlay placement={'top'} show={tutorialStep === 5} target={target.current}>
        {props => (
          <Tooltip className="tutorial" {...props}>
            <TutorialTooltip>Step: Explore our NFT marketplace</TutorialTooltip>
          </Tooltip>
        )}
      </Overlay>
      <LinkWrapper
        $collapsed={collapsedNav}
        $notificationsOpen={openNotifications}
        $selected={location.pathname === ROUTES.MARKETPLACE_PAGE}
        onClick={() => {
          if (tutorialStep === 5) {
            setTutorialStep(tutorialStep + 1)
          }
          navigate('/marketplace?isLeased=false')
        }}
      >
        <Icon
          ref={target}
          $collapsed={collapsedNav}
          src="/images/marketplace-icon.svg"
          alt="marketplace icon"
        />
        <LinkLabel $collapsed={collapsedNav}>Marketplace</LinkLabel>
      </LinkWrapper>
      <LinkWrapper
        $collapsed={collapsedNav}
        $notificationsOpen={openNotifications}
        $selected={location.pathname === ROUTES.ANALYTICS_PAGE}
        onClick={() => {
          navigate('/analytics')
        }}
      >
        <Icon $collapsed={collapsedNav} src="/images/analytics-icon.svg" alt="analytics icon" />
        <LinkLabel $collapsed={collapsedNav}>Analytics</LinkLabel>
      </LinkWrapper>
      <LinkWrapper
        $collapsed={collapsedNav}
        $notificationsOpen={openNotifications}
        $selected={location.pathname === ROUTES.PAYMENTS_PAGE}
        onClick={() => {
          navigate('/payments')
        }}
      >
        <Icon $collapsed={collapsedNav} src="/images/withdraws.svg" alt="payments icon" />
        <LinkLabel $collapsed={collapsedNav}>Payments</LinkLabel>
      </LinkWrapper>
      <LinkWrapper $disabled $collapsed={collapsedNav} $notificationsOpen={openNotifications}>
        <Icon $collapsed={collapsedNav} src="/images/tournaments-icon.svg" alt="tournaments icon" />
        <LinkLabel $collapsed={collapsedNav}>Tournaments</LinkLabel>
      </LinkWrapper>
      <LinkWrapper
        $notification={true}
        $notificationsOpen={openNotifications}
        $collapsed={collapsedNav}
        onClick={() => {
          setOpenNotifications(true)
        }}
      >
        <Icon $collapsed={collapsedNav} src="/images/bell.svg" alt="notification icon" />
        {notifications?.length > 0 ? (
          <NotificationsNumber>{notifications.length}</NotificationsNumber>
        ) : null}
        <LinkLabel $collapsed={collapsedNav}>Notifications</LinkLabel>
      </LinkWrapper>
      <LinkWrapper
        $logout={true}
        $notificationsOpen={openNotifications}
        $collapsed={collapsedNav}
        onClick={() => {
          window.localStorage.removeItem(VA_TOKEN)
          queryClient.clear()
          window.location.href = '/login'
        }}
      >
        <Icon $collapsed={collapsedNav} src="/images/logout.svg" alt="logout icon" />
        <LinkLabel $collapsed={collapsedNav}>Logout</LinkLabel>
      </LinkWrapper>
      <Notifications
        openNotifications={openNotifications}
        setOpenNotifications={setOpenNotifications}
        notifications={notifications}
      />
    </Container>
  )
}

const Container = styled.div<{ $collapsed: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background: linear-gradient(182.23deg, #122f5a 0%, #5687f4 108.39%);
  z-index: 1000;
  padding-top: 14px;
  transition: all 0.4s;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    width: 64px;
  `}

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LogoContainer = styled.div`
  text-align: center;
`

const Logo = styled.img`
  margin-bottom: 14px;
`

const LogoWrapper = styled.div<{ $collapsed: boolean }>`
  border-bottom: 1px solid rgba(86, 135, 244, 0.5);
  display: inline-block;
  width: 155px;
  margin: 0 auto;
  transition: all 0.4s;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    width: 35px;
  `}
`

const Image = styled.img<{ $collapsed: boolean }>`
  width: 57px;
  height: 57px;
  transition: all 0.4s;
  border-radius: 50%;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    width: 36px;
    height: 36px;
  `}
`

const ImageWrapper = styled.div<{ $selected?: boolean }>`
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background: rgba(3, 41, 96, 0.65);
  }

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(3, 41, 96, 0.65);
  `}
`

const LinkLabel = styled.span<{ $collapsed: boolean }>`
  color: ${colors.white};
  font-size: 18px;
  line-height: 16px;
  transition: all 0.4s;
  transition-delay: 0.1s;
  margin-left: 15px;
  vertical-align: middle;
  opacity: 1;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    transition: none;
    opacity: 0;
    line-height: 0;
    font-size: 0;
  `}
`

const LinkWrapper = styled.div<{
  $collapsed: boolean
  $logout?: boolean
  $selected?: boolean
  $notification?: boolean
  $notificationsOpen?: boolean
  $disabled?: boolean
}>`
  padding: 20px 15px;
  cursor: pointer;
  opacity: 1;
  height: auto;
  transition: all 0.4s;

  &:hover {
    background: rgba(3, 41, 96, 0.65);

    & > span {
      font-family: ${fonts.fontFamilyBold};
    }
  }

  ${({ $collapsed }) =>
    $collapsed &&
    `
    text-align: center;
    padding: 18px 0;
  `}

  ${({ $logout }) =>
    $logout &&
    `
    position: absolute;
    bottom: 14px;
    left: 0;
    right: 0;
  `}

  ${({ $notification, $collapsed }) =>
    $notification &&
    `
    position: absolute;
    bottom: ${$collapsed ? '58px' : '82px'};
    left: 0;
    right: 0;
  `}

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(3, 41, 96, 0.65);

    & > span {
      font-family: ${fonts.fontFamilyBold};
    }
  `}

  ${({ $notificationsOpen }) =>
    $notificationsOpen &&
    `
    height: 0;
    opacity: 0;
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    mix-blend-mode: soft-light;
    &:hover {
      background: transparent;
  
      & > span {
        font-family: ${fonts.fontFamilyRegular};
      }
    }
  `}
`

const Icon = styled.img<{ $collapsed: boolean }>`
  width: 23px;
  vertical-align: middle;
  transition: all 0.4s;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    margin-left: 15px;
  `}
`

const NotificationsNumber = styled.span`
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 30px;
  background: #ffce1e;
  color: ${colors.blue900};
  font-size: 14px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
`

export default Navigation
