import { useQuery } from '@tanstack/react-query'
import { useLocation, useSearchParams } from 'react-router-dom'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const getAutocompletes = async (gameId: string, search: string, isMarket: boolean) => {
  const data = await API.authGet(
    `nft/search/autocomplete?filter.GameId=${gameId}&filter.SearchValuesString=${encodeURIComponent(
      search
    )}&filter.IsMarket=${isMarket}${isMarket ? `&filter.IsLeased=false` : ''}`
  )

  return data
}

const useSearchAutocompleteQuery = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const gameQuery = searchParams.get('game')
  const search = searchParams.get('search')
  const isMarket = location.pathname === '/marketplace'

  return useQuery(
    [QUERY_KEYS.SEARCH_AUTOCOMPLETES, gameQuery || '1', search || '', isMarket],
    () => getAutocompletes(gameQuery || '1', search || '', isMarket),
    {
      staleTime: 60 * 1000 * 60,
    }
  )
}

export default useSearchAutocompleteQuery
