import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const fetchAnalyticsData = async () => {
  const data = await API.authGet(`analytics/payments`)

  return data
}

const useAnalyticsDataQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.ANALYTICS_DATA],
    queryFn: fetchAnalyticsData,
  })
}

export default useAnalyticsDataQuery
