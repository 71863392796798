import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

interface PaymentParams {
  payoutWalletId: number
  amount: number
  currencyCodeId: number
}

const addPayment = async (body: PaymentParams) => {
  const data = await API.authPost('payment/request', {
    ...body,
  })

  return data
}

const useAddPaymentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: PaymentParams) => addPayment(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENTS] })
    },
  })
}

export default useAddPaymentMutation
