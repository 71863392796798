import styled from 'styled-components'
import { PropsWithChildren } from 'react'
import colors from '../../styles/colors'

interface PopupProps {
  open: boolean
  input?: boolean
  topLeft?: boolean
  noPadding?: boolean
  minWidth?: number
  maxWidth?: number
}

const Popup = ({
  children,
  open,
  input,
  topLeft,
  noPadding,
  minWidth,
  maxWidth,
}: PopupProps & PropsWithChildren) => {
  if (!open) {
    return null
  }
  if (input) {
    return <InputPopupWrapper>{children}</InputPopupWrapper>
  }

  return (
    <PopupWrapper
      $topLeft={topLeft}
      $noPadding={noPadding}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
    >
      {children}
    </PopupWrapper>
  )
}

const PopupWrapper = styled.div<{
  $topLeft?: boolean
  $noPadding?: boolean
  $minWidth?: number
  $maxWidth?: number
}>`
  position: absolute;
  bottom: 100%;
  left: 100%;
  padding: 12px 10px;
  background: ${colors.white};
  box-shadow: 0px 2px 8px rgba(142, 177, 255, 0.42);
  border-radius: 8px;
  min-width: 150px;
  z-index: 999;
  overflow: hidden;

  ${({ $topLeft }) =>
    $topLeft &&
    `
    left: 0;
    bottom: 50%;
  `}

  ${({ $noPadding }) =>
    $noPadding &&
    `
    padding: 0;
  `}

  ${({ $minWidth }) =>
    $minWidth &&
    `
    min-width: ${$minWidth}px;
  `}
  
  ${({ $maxWidth }) =>
    $maxWidth &&
    `
    max-width: ${$maxWidth}px;
  `}
`

const InputPopupWrapper = styled.div`
  position: absolute;
  bottom: 100%;
  left: 100%;
  padding: 3px 6px 5px;
  background: ${colors.white};
  box-shadow: 0px 2px 8px rgba(142, 177, 255, 0.42);
  border-radius: 8px;
  min-width: 180px;
  z-index: 999;
`

export default Popup
