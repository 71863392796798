import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import { useUser } from '../../contexts/AuthContext'
import colors from '../../styles/colors'
import GamesSection from './components/GamesSection'
import ManagerNftsSection from './components/ManagerNftsSection'
import ManagerTeamSection from './components/ManagerTeamSection'
import MyEarningsSection from './components/MyEarningsSection'
import NFTSection from './components/NFTSection'
import TeamAchivementsSection from './components/TeamAchivementsSection'
import TeamEarningsSection from './components/TeamEarningsSection'
import TrainingsSection from './components/TrainingsSection'
import VideoSection from './components/VideoSection'
import useGetAllGamesQuery from '../../queries/useGetAllGamesQuery'
import { useState } from 'react'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'

const GamesPage = () => {
  const [searchParams] = useSearchParams()
  const [selectedPlayers, setSelectedPlayers] = useState<any>([])
  const gameQuery = searchParams.get('game')

  const { data: games } = useGetAllGamesQuery()

  const { user, isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer id="games-page">
        <AlphaBanner />
        <Wrapper>
          <MainWrapper>
            <GamesWrapper>
              <GamesSection games={games} activeGame={gameQuery || '1'} />
            </GamesWrapper>
            <ContentWrapper $isManager={user.userRoleName === 'Manager'}>
              {user.userRoleName === 'Player' ? (
                <>
                  <VideoSection activeGame={gameQuery || '1'} />
                  <TrainingsSection />
                  <NFTSection />
                </>
              ) : null}
              {user.userRoleName === 'Manager' ? <ManagerNftsSection /> : null}
            </ContentWrapper>
          </MainWrapper>
          <EarningsWrapper>
            {user.userRoleName !== 'Manager' ? (
              <MyEarningsSection />
            ) : (
              <TeamEarningsSection selectedPlayers={selectedPlayers} />
            )}
            {user.userRoleName !== 'Manager' ? (
              <TeamAchivementsSection />
            ) : (
              <ManagerTeamSection
                selectedPlayers={selectedPlayers}
                setSelectedPlayers={setSelectedPlayers}
              />
            )}
          </EarningsWrapper>
        </Wrapper>
      </PageContainer>
    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 22px;
`

const MainWrapper = styled.div`
  display: inline-block;
  width: 70%;
  vertical-align: top;
  background: ${colors.white};
  border-radius: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const EarningsWrapper = styled.div`
  display: inline-block;
  width: 30%;
  vertical-align: top;
  padding-left: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const GamesWrapper = styled.div`
  display: inline-block;
  width: 22%;
  vertical-align: top;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const ContentWrapper = styled.div<{ $isManager: boolean }>`
  display: inline-block;
  width: 78%;
  vertical-align: top;
  padding: 21px;
  border-left: 1px solid rgba(86, 135, 244, 0.23);
  margin: 20px 0;

  ${({ $isManager }) =>
    $isManager &&
    `
    padding: 17px 21px;
  `}

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

export default GamesPage
