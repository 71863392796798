import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../../../styles/colors'

interface GamesSectionProps {
  games: any
  activeGame: string
}

const GamesSection = ({ games, activeGame }: GamesSectionProps) => {
  return (
    <Container>
      <Title>Choose game</Title>
      {games?.map((game: any, index: number) => {
        return (
          <GameWrapper key={game.id} $active={activeGame === `${game.id}`}>
            <Link to={index === 0 ? `/games?game=${game.id}` : '#'}>
              <Image
                $active={activeGame === `${game.id}`}
                src={game.bannerPictureUrl}
                alt={game.name}
              />
            </Link>
          </GameWrapper>
        )
      })}
    </Container>
  )
}

const Title = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  display: inherit;
  margin-bottom: 14px;
`

const Container = styled.div`
  padding: 17px 22px;
`

const Image = styled.img<{ $active?: boolean }>`
  width: 100%;
  border-radius: 12px;
  opacity: 0.4;
  cursor: not-allowed;
  border: 2px solid transparent;

  ${({ $active }) =>
    $active &&
    `
    cursor: pointer;
    border: 2px solid ${colors.blue500};
    opacity: 1;
  `}
`

const GameWrapper = styled.div<{ $active?: boolean }>`
  margin-bottom: 15px;

  & > a {
    cursor: not-allowed;
  }

  ${({ $active }) =>
    $active &&
    `
    & > a {
      cursor: pointer;
    }
  `}
`

export default GamesSection
