import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'
import { AggregatedEarning } from '../types/user'

const getUserAggregatedPayments = async (id?: number): Promise<AggregatedEarning[]> => {
  const data = await API.authGet(`payment/aggregated?userId=${id}`)

  return data
}

const useUserEarningsAggregatedQuery = (userId?: number) => {
  return useQuery(
    [QUERY_KEYS.AGGREGATED_EARNINGS, userId],
    () => getUserAggregatedPayments(userId),
    {
      staleTime: 60 * 1000,
      enabled: !!userId,
    }
  )
}

export default useUserEarningsAggregatedQuery
