import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const beautifyNetworks = (networks: any) => {
  return networks?.map((network: any) => ({
    ...network,
    value: network.name,
    label: network.name,
  }))
}

const getNetworks = async () => {
  const data = await API.authGet('lookup/networks')

  return beautifyNetworks(data)
}

const useNetworksQuery = () => {
  return useQuery([QUERY_KEYS.NETWORKS], getNetworks, {
    staleTime: 60 * 1000,
  })
}

export default useNetworksQuery
