import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const cancelNft = async (id: number) => {
  const data = await API.authPost('nft/request/cancel', {
    id,
  })

  return data
}

interface CancelRequestNFTParams {
  id: number
}

const useCancelRequestNFTMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: CancelRequestNFTParams) => cancelNft(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NFT_PLAYER_REQUESTS] })
    },
  })
}

export default useCancelRequestNFTMutation
