import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getTeam = async (id?: number) => {
  const data = await API.authGet(`team?id=${id}`)

  return data
}

const useTeamQuery = (id?: number) => {
  return useQuery([QUERY_KEYS.TEAM], () => getTeam(id), {
    staleTime: 60 * 1000,
    enabled: !!id,
  })
}

export default useTeamQuery
