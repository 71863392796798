import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

interface WalletParams {
  name: string
  networkId: string
  publicAddress: string
}

const addWallet = async (body: WalletParams) => {
  const data = await API.authPost('wallet', {
    ...body,
  })

  return data
}

const useAddWalletMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: WalletParams) => addWallet(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] })
    },
  })
}

export default useAddWalletMutation
