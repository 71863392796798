import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { ApproveButton, SecondaryButton } from '../../../../../components/Button'
import { ErrorMsg } from '../../../../../components/ErrorMessage'
import Modal from '../../../../../components/Modal'
import colors from '../../../../../styles/colors'
import fonts from '../../../../../styles/fonts'
import useDeleteWalletMutation from '../queries/useDeleteWalletMutation'
import { useUser } from '../../../../../contexts/AuthContext'
import { useEffect, useState } from 'react'
import Wallet from '../../../../../types/wallet'

interface ConfirmDeleteModalProps {
  openConfirmDeleteModal: boolean
  setOpenConfirmDeleteModal: (o: boolean) => void
  walletId: number
}

const ConfirmDeleteModal = ({
  openConfirmDeleteModal,
  setOpenConfirmDeleteModal,
  walletId,
}: ConfirmDeleteModalProps) => {
  const { mutateAsync: deleteWallet, isError, isLoading } = useDeleteWalletMutation()
  const [closeModal, setCloseModal] = useState<boolean>(false)
  const { user } = useUser()

  useEffect(() => {
    const wallet = user?.payoutWallets?.find?.((w: Wallet) => w.id === walletId)
    if (closeModal && !wallet) {
      setOpenConfirmDeleteModal(false)
      setCloseModal(false)
    }
  }, [closeModal, user])

  return (
    <Modal
      bigSize
      open={openConfirmDeleteModal}
      setOpen={setOpenConfirmDeleteModal}
      noPadding={true}
    >
      <ModalBody>
        <ModalText>Are you sure you would like to delete your wallet?</ModalText>
        {isError ? <ErrorMsg>Something went wrong. Please try again later.</ErrorMsg> : null}
        <Buttons>
          <SecondaryButton
            onClick={() => {
              setOpenConfirmDeleteModal(false)
            }}
          >
            Cancel
          </SecondaryButton>
          <SubmitButton
            disabled={isLoading || closeModal}
            onClick={async () => {
              if (walletId) {
                await deleteWallet({
                  id: walletId,
                })
                setCloseModal(true)
              }
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={isLoading || closeModal}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Delete
          </SubmitButton>
        </Buttons>
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 40px 90px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};
  margin-bottom: 24px;

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const SubmitButton = styled(ApproveButton)`
  font-size: 16px;
  padding: 10px 12px;
`

export default ConfirmDeleteModal
