import { useMutation } from '@tanstack/react-query'
import API from '../../../../../api'

interface TrainingProgress {
  trainingId: number
}

const enroll = async (body: TrainingProgress) => {
  const data = await API.authPost('training/enroll', {
    ...body,
  })

  return data
}

const useEnrollTraining = () => {
  return useMutation((body: TrainingProgress) => enroll(body))
}

export default useEnrollTraining
