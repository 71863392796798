import { useMutation } from '@tanstack/react-query'
import API from '../../../../../api'

import { useUser } from '../../../../../contexts/AuthContext'

interface CardParams {
  firstName: string
  lastName: string
  cardNumber: string
  expirationDate: Date
  cvc: string
}

const updateCard = async (body: CardParams, digitalCards?: any) => {
  if (digitalCards && digitalCards.length > 0) {
    const cardId = digitalCards?.[0]?.id
    const data = await API.authPut('card', {
      id: cardId,
      ...body,
    })

    return data
  } else {
    const data = await API.authPost('card', {
      ...body,
    })

    return data
  }
}

const useUpdateCardDetailsMutation = () => {
  const { user } = useUser()

  return useMutation((body: CardParams) => updateCard(body, user.digitalCards))
}

export default useUpdateCardDetailsMutation
