import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getEarningsPerPlayers = async (timeFrame: string) => {
  const data = await API.authGet(`payment/aggregated/team/members?timeFrame=${timeFrame}`)

  return data
}

const useTeamEarningsPlayersQuery = (timeFrame: string) => {
  return useQuery(
    [QUERY_KEYS.TEAM_EARNINGS_PLAYERS, timeFrame],
    () => getEarningsPerPlayers(timeFrame),
    {
      staleTime: 60 * 1000,
    }
  )
}

export default useTeamEarningsPlayersQuery
