import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getUserNFT = async (id?: number) => {
  const data = await API.authGet(`nft/user?userId=${id}`)

  return data
}

const useUserNFTQuery = (userId?: number) => {
  return useQuery([QUERY_KEYS.NFT, userId], () => getUserNFT(userId), {
    staleTime: 60 * 1000,
    enabled: !!userId,
  })
}

export default useUserNFTQuery
