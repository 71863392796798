export enum Currency {
  USD = 'USD',
  EURO = 'EUR',
  BITCOIN = 'BTC',
}

export enum CurrencySymbol {
  USD = '$',
  EURO = '€',
  BITCOIN = 'BTC',
}
