import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getEarningsPerGame = async (timeFrame: string, memberId: number) => {
  const data = await API.authGet(
    `payment/aggregated/team/games?timeFrame=${timeFrame}${
      memberId !== 0 ? `&memberId=${memberId}` : ''
    }`
  )

  return data
}

const useTeamEarningsGameQuery = (timeFrame: string, memberId: number) => {
  return useQuery(
    [QUERY_KEYS.TEAM_EARNINGS_GAME, timeFrame, memberId],
    () => getEarningsPerGame(timeFrame, memberId),
    {
      staleTime: 60 * 1000,
    }
  )
}

export default useTeamEarningsGameQuery
