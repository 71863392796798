import { formatDistance } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { LinkButton } from '../../../../components/Button'
import colors from '../../../../styles/colors'
import ConfirmCancelModal from './components/ConfirmCancelModal'
import useNftRequestsQuery from './queries/useNftRequestsQuery'

interface NFTRequestsSectionProps {
  collapsed: boolean
  setCollapsed: (c: boolean) => void
}

const NFTRequestsSection = ({ collapsed, setCollapsed }: NFTRequestsSectionProps) => {
  const { data: requests, isSuccess } = useNftRequestsQuery()
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false)
  const [selectedRequest, setSelectedRequest] = useState<any>(null)

  const pendingRequests = useMemo(() => {
    return (
      requests?.filter((r: any) => {
        return r.nftRequestStatus === 'Active'
      }) || []
    )
  }, [requests])

  const historyRequests = useMemo(() => {
    return (
      requests?.filter((r: any) => {
        return r.nftRequestStatus === 'Accepted' || r.nftRequestStatus === 'Rejected'
      }) || []
    )
  }, [requests])

  useEffect(() => {
    if (isSuccess && pendingRequests?.length === 0 && historyRequests?.length === 0) {
      setCollapsed(true)
    }
  }, [pendingRequests, historyRequests])

  return (
    <>
      <Container $hide={collapsed}>
        <Header>
          <Title>My requests</Title>
          <CrossIcon
            src="/images/blue-cross-icon.svg"
            alt="cross"
            onClick={() => {
              setCollapsed(true)
            }}
          />
        </Header>
        <ScrollableWrapper>
          {pendingRequests?.length > 0 ? (
            <RequestsWrapper>
              <Title>Pending</Title>
              {pendingRequests.slice(0, 3).map((r: any) => {
                return (
                  <Cell key={r.id}>
                    <ImageWrapper>
                      <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                    </ImageWrapper>
                    <div>
                      <NFTName>{r.nftName}</NFTName>
                      <div>
                        <PendingStatus>Pending</PendingStatus>
                      </div>
                      <Time>
                        {formatDistance(new Date(r.createdOn), new Date(), { addSuffix: true })}
                      </Time>
                      <CancelBtn
                        onClick={() => {
                          setSelectedRequest(r)
                          setOpenConfirmCancelModal(true)
                        }}
                      >
                        Cancel
                      </CancelBtn>
                    </div>
                  </Cell>
                )
              })}
            </RequestsWrapper>
          ) : null}
          {historyRequests?.length > 0 ? (
            <HistoryWrapper>
              <Title>Request history</Title>
              {historyRequests.slice(0, 3).map((r: any) => {
                return (
                  <Cell key={r.id} $history>
                    <ImageWrapper>
                      <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                    </ImageWrapper>
                    <div>
                      <NFTName>{r.nftName}</NFTName>
                      <div>
                        {r.nftRequestStatus === 'Accepted' ? (
                          <ApprovedStatus>Approved</ApprovedStatus>
                        ) : null}
                        {r.nftRequestStatus === 'Rejected' ? (
                          <RejectedStatus>Rejected</RejectedStatus>
                        ) : null}
                      </div>
                      <Time>
                        {formatDistance(new Date(r.updatedOn), new Date(), { addSuffix: true })}
                      </Time>
                    </div>
                  </Cell>
                )
              })}
            </HistoryWrapper>
          ) : null}
        </ScrollableWrapper>
      </Container>
      <NFTButton
        $hide={!collapsed}
        onClick={() => {
          setCollapsed(false)
        }}
      >
        <NFTIcon src="/images/nft-icon.svg" alt="nft icon" />
        My NFT requests
      </NFTButton>
      <ConfirmCancelModal
        openConfirmCancelModal={openConfirmCancelModal}
        setOpenConfirmCancelModal={setOpenConfirmCancelModal}
        selectedRequest={selectedRequest}
      />
    </>
  )
}

const Container = styled.div<{ $hide: boolean }>`
  background: ${colors.white};
  box-shadow: 0px 4px 13px rgba(86, 135, 244, 0.15);
  border-radius: 12px;
  padding: 20px 14px;
  position: fixed;
  top: 50px;
  right: 22px;
  bottom: 50px;
  width: 245px;

  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `}
`

const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 60px);
  padding-right: 15px;
  margin-top: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const Header = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding-bottom: 10px;
`

const Title = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  display: inline-block;
  margin-bottom: 14px;
`

const RequestsWrapper = styled.div`
  border-bottom: 1px solid #dce9ff;
`

const Cell = styled.div<{ $history?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
  border-radius: 8px;
  transition: all 0.4s;
  padding: 8px 4px;

  ${({ $history }) =>
    $history &&
    `
    cursor: pointer;
    &:hover {
      background: rgba(142, 177, 255, 0.15);
    }
  `}
`

const ImageWrapper = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const NFTName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  color: ${colors.blue900};
`

const PendingStatus = styled.span`
  font-size: 12px;
  line-height: 12px;
  padding: 2px 6px;
  border-radius: 50px;
  background: #ffce1f;
  margin-top: 8px;
  margin-bottom: 6px;
  display: inline-block;
`

const ApprovedStatus = styled(PendingStatus)`
  background: #1dac56;
  color: ${colors.white};
  padding-bottom: 4px;
`

const RejectedStatus = styled(PendingStatus)`
  background: #fd7c7c;
  color: ${colors.white};
  padding-bottom: 4px;
`

const Time = styled.p`
  font-size: 11px;
  line-height: 12px;
  margin: 0;
  color: ${colors.grey800};
`

const CancelBtn = styled(LinkButton)`
  color: #fa2424;
  padding: 2px 4px;
  margin-left: -4px;

  &:hover {
    border-radius: 12px;
    background: ${colors.grey200};
  }
`

const HistoryWrapper = styled.div``

const CrossIcon = styled.img`
  position: absolute;
  top: 29px;
  right: 20px;
  width: 10px;
  cursor: pointer;
`

const NFTButton = styled.span<{ $hide: boolean }>`
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  display: inline-block;
  width: 130px;
  font-size: 12px;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }

  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `}
`
const NFTIcon = styled.img`
  width: 20px;
  margin-right: 8px;
`

export default NFTRequestsSection
