import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { useUser } from '../../contexts/AuthContext'
import colors from '../../styles/colors'

const TutorialTooltip = ({ children }: PropsWithChildren) => {
  const { setTutorialStep, tutorialStep } = useUser()
  return (
    <>
      {children}
      <FooterTooltip>
        <SkipButton
          onClick={() => {
            setTutorialStep(tutorialStep + 1)
          }}
        >
          Skip
        </SkipButton>
      </FooterTooltip>
    </>
  )
}

const FooterTooltip = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const SkipButton = styled.button`
  background: transparent;
  color: ${colors.white};
  border: 0;
  text-decoration: underline;
  padding: 0;

  &:hover {
    color: ${colors.grey100};
  }
`

export default TutorialTooltip
