import { format } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import { useCurrency } from '../../../contexts/CurrencyContext'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { Currency, CurrencySymbol } from '../../../types/currency'
import SectionContainer from '../../Profile/components/SectionContainer'
import useAnalyticsDataQuery from '../queries/useAnalyticsDataQuery'

const GAMES = [
  {
    name: 'Axie Infinity',
    color: '#ec9cff',
  },
  {
    name: 'Guild Of Guardians',
    color: '#79ACFF',
  },
  {
    name: 'Nyan Heroes',
    color: '#FA8E41',
  },
]

const COLORS = {
  1: {
    id: 1,
    color: '#ec9cff',
  },
  2: {
    id: 2,
    color: '#79ACFF',
  },
  3: {
    id: 3,
    color: '#FA8E41',
  },
}

interface GameChartProps {
  selectedUser: number
}

export const Tooltip = (props: any) => {
  return (
    <VictoryTooltip
      {...props}
      active={!!props.active}
      style={{ fontSize: 9, fontWeight: 400, fill: colors.black, letterSpacing: 1 }}
      cornerRadius={5}
      pointerLength={0}
      flyoutStyle={{
        stroke: colors.black,
        strokeWidth: 1,
        fill: colors.white,
      }}
      flyoutPadding={{
        top: 5,
        bottom: 5,
        left: 15,
        right: 15,
      }}
      centerOffset={{ x: 40, y: 2 }}
    />
  )
}

const GameChart = ({ selectedUser }: GameChartProps) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [chartWidth, setChartWidth] = useState<number>(1000)
  const [selectedGames, setSelectedGames] = useState<string[]>(GAMES.map(d => d.name))
  const { data: analyticsData } = useAnalyticsDataQuery()
  const chartData = analyticsData?.filter((u: any) => u.userId === selectedUser)?.[0]?.games
  const { currency } = useCurrency()

  const beautifyChartData = useCallback(() => {
    return chartData?.map((d: any) => {
      const payments = d.payments
        ?.map((p: any) => {
          const date = format(new Date(p.paymentDate), 'yyyy/MM')
          if (currency === Currency.BITCOIN) {
            return {
              x: date,
              y: p.btcAmount,
            }
          }
          if (currency === Currency.EURO) {
            return {
              x: date,
              y: p.eurAmount,
            }
          }
          return {
            x: date,
            y: p.dollarAmount,
          }
        })
        .reduce((acc: any, curr: any) => {
          const { x, y } = curr
          const findObj = acc.find((o: any) => o.x === x)
          if (!findObj) {
            acc.push({ x, y })
          } else {
            findObj.y = findObj.y + y
          }
          return acc
        }, [])
      return {
        payments,
        id: d.id,
        name: d.name,
      }
    })
  }, [chartData, currency])

  const getMaxValue = useCallback(() => {
    if (selectedUser === 0) {
      return beautifyChartData()?.reduce((acc: number, d: any) => {
        const summariesPayments = d.payments?.reduce((sumacc: any, curr: any) => {
          const { x, y } = curr
          const findObj = sumacc.find((o: any) => o.x === x)
          if (!findObj) {
            sumacc.push({ x, y })
          } else {
            findObj.y = findObj.y + y
          }
          return sumacc
        }, [])

        let gameMaxValue = 0
        summariesPayments.forEach((p: any) => {
          if (gameMaxValue < p.y) {
            gameMaxValue = p.y
          }
        })
        if (acc < gameMaxValue) {
          return gameMaxValue
        }
        return acc
      }, 0)
    }
    return chartData?.reduce((acc: number, d: any) => {
      let gameMaxValue = 0
      d.payments?.forEach((p: any) => {
        if (currency === Currency.BITCOIN && gameMaxValue < p.btcAmount) {
          gameMaxValue = p.btcAmount
        }
        if (currency === Currency.EURO && gameMaxValue < p.eurAmount) {
          gameMaxValue = p.eurAmount
        }
        if (currency === Currency.USD && gameMaxValue < p.dollarAmount) {
          gameMaxValue = p.dollarAmount
        }
      })
      if (acc < gameMaxValue) {
        return gameMaxValue
      }
      return acc
    }, 0)
  }, [chartData, currency, selectedUser])

  const getFormat = useCallback(
    (value: number) => {
      if (currency === Currency.BITCOIN) {
        return `BTC ${value}`
      }
      if (currency === Currency.EURO) {
        return `${CurrencySymbol.EURO} ${value}`
      }
      return `${CurrencySymbol.USD} ${value}`
    },
    [currency]
  )

  const beautyChartData = beautifyChartData()
  const maxValue = getMaxValue()

  const tickValues = Array(5)
    .fill(0)
    .map((tick, index) => {
      if (index === 4) {
        if (currency === Currency.BITCOIN) {
          return +parseFloat(maxValue.toFixed(3))
        }
        return maxValue
      }
      if (currency === Currency.BITCOIN) {
        const divider = +parseFloat((maxValue / 5).toFixed(3))
        return divider * (index + 1)
      }
      const divider = Math.ceil(maxValue / 5)
      return divider * (index + 1)
    })

  useEffect(() => {
    if (chartRef.current) {
      const width = chartRef.current.getBoundingClientRect()?.width
      setChartWidth(width)
    }
  }, [])

  if (!beautyChartData || beautyChartData?.length === 0) {
    return null
  }

  return (
    <Container>
      <GameChartContainer ref={chartRef} className="game-chart">
        <GamesWrapper>
          {beautyChartData.map((d: any) => {
            return (
              <Game
                key={d.name}
                $selected={selectedGames.includes(d.name)}
                onClick={() => {
                  if (selectedGames.includes(d.name) && selectedGames.length > 1) {
                    const newGames = selectedGames.filter(n => n !== d.name)
                    setSelectedGames(newGames)
                  } else if (!selectedGames.includes(d.name)) {
                    setSelectedGames([...selectedGames, d.name])
                  }
                }}
              >
                <Color $color={(COLORS as any)[d.id]?.color} />
                <Name>{d.name}</Name>
              </Game>
            )
          })}
        </GamesWrapper>

        <VictoryChart
          width={chartWidth}
          theme={{
            axis: {
              style: {
                grid: {
                  fill: 'none',
                  stroke: '#F2F2F2',
                  pointerEvents: 'painted',
                },
                ticks: {
                  fill: 'transparent',
                  size: 5,
                  stroke: 'transparent',
                  strokeWidth: 1,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                },
              },
            },
          }}
          animate={{
            duration: 800,
            onLoad: { duration: 1000 },
          }}
          height={300}
          containerComponent={<VictoryVoronoiContainer />}
        >
          <VictoryAxis
            dependentAxis
            tickValues={tickValues}
            tickFormat={tickValues.map(val => getFormat(val))}
            axisLabelComponent={<VictoryLabel dy={-12} />}
            style={{
              tickLabels: {
                fill: colors.blue900,
                fontSize: '10px',
              },
              axis: {
                stroke: '#F2F2F2',
              },
            }}
          />
          <VictoryAxis
            axisLabelComponent={<VictoryLabel dy={-12} />}
            style={{
              tickLabels: {
                fill: colors.blue900,
                fontSize: '10px',
              },
              axis: {
                stroke: '#F2F2F2',
              },
            }}
          />
          {beautyChartData.map((d: any) => {
            if (!selectedGames.includes(d.name)) {
              return null
            }

            return (
              <VictoryLine
                key={d.name}
                style={{
                  data: { stroke: (COLORS as any)[d.id]?.color || '#fff' },
                }}
                data={d.payments}
                labels={({ datum }) => {
                  if (currency === Currency.BITCOIN) {
                    return `${CurrencySymbol.BITCOIN} ${Math.round(datum.y)}`
                  }
                  if (currency === Currency.EURO) {
                    return `${CurrencySymbol.EURO} ${Math.round(datum.y)}`
                  }
                  return `${CurrencySymbol.USD} ${Math.round(datum.y)}`
                }}
                labelComponent={<Tooltip />}
              />
            )
          })}
          {beautyChartData.map((d: any) => {
            if (!selectedGames.includes(d.name)) {
              return null
            }

            return (
              <VictoryScatter
                key={`${d.name}-scatter`}
                style={{ data: { fill: (COLORS as any)[d.id]?.color || '#fff' } }}
                data={d.payments}
              />
            )
          })}
        </VictoryChart>
      </GameChartContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 10px 15px;
`

const GameChartContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
`

const GamesWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
`

const Game = styled.div<{ $selected?: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: row;
  padding: 15px 20px;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(142, 177, 255, 0.05);
  }

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(142, 177, 255, 0.15);
    
    &:hover {
      background: rgba(142, 177, 255, 0.15);
    }

    & > span {
      font-family: ${fonts.fontFamilyBold};
    }
  `}
`

const Color = styled.span<{ $color: string }>`
  width: 23px;
  height: 14px;
  border-radius: 4px;

  ${({ $color }) =>
    $color &&
    `
    background: ${$color};
  `}
`

const Name = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.black};
`

export default GameChart
