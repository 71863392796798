import { Fragment, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { SubmitButton } from '../../../../components/Button'
import NFTCard from '../../../../components/NFTCard'
import colors from '../../../../styles/colors'
import useSearchNFTQuery from '../../queries/useSearchNFTQuery'
import { useInView } from 'react-intersection-observer'
import SearchSection from '../../../Marketplace/components/SearchSection'

const NFTSection = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { ref, inView } = useInView()
  const gameQuery = searchParams.get('game') || '1'
  const { status, data, fetchNextPage, hasNextPage } = useSearchNFTQuery()

  useEffect(() => {
    const page = searchParams.get('page')
    if (page) {
      const search = searchParams.get('search')
      const searchParam = encodeURIComponent(search || '')
      navigate(`/games?game=${gameQuery}${searchParam ? `&search=${searchParam}` : ''}`)
    }
  }, [])

  useEffect(() => {
    if (inView) {
      if (hasNextPage) {
        ;(async () => {
          const search = searchParams.get('search')
          const searchParam = encodeURIComponent(search || '')

          const page = searchParams.get('page')
          const newPage = parseInt(page || '1') + 1

          await fetchNextPage()

          navigate(
            `/games?game=${gameQuery}${searchParam ? `&search=${searchParam}` : ''}&page=${newPage}`
          )
        })()
      }
    }
  }, [inView])

  return (
    <Container>
      <Title>My NFTs</Title>
      <SearchSection noPadding />
      <NFTContainer>
        {data?.pages?.map?.((page, index) => (
          <Fragment key={index}>
            {page.items?.length > 0 ? (
              <>
                {page.items.map((nft: any) => (
                  <Cell key={`${nft.id}-${nft.address}`}>
                    <NFTCard
                      name={nft.name}
                      imageUrl={nft.imageUrl}
                      id={nft.id}
                      availableForRequest={true}
                      metadata={nft.metadata}
                      marketUrl={nft.marketUrl}
                      isAxie={gameQuery === '1'}
                      assignedToUserName={nft.assignedToUserName}
                      selectedGameId={gameQuery ? Number(gameQuery) : 0}
                    />
                  </Cell>
                ))}
              </>
            ) : (
              <Wrapper>
                <NoNftsImage src="/images/no-nfts.svg" alt="no nfts" />
                <Description>
                  There aren't any NFTs in your list. <br />
                  You can easily do that by pressing the magic button below.
                </Description>
                <SubmitButton
                  onClick={() => {
                    navigate(`/marketplace?game=${gameQuery}&isLeased=false`)
                  }}
                >
                  REQUEST NFTS
                </SubmitButton>
              </Wrapper>
            )}
          </Fragment>
        ))}
      </NFTContainer>
      {hasNextPage ? (
        <LoadingContainer ref={ref}>
          <br />
        </LoadingContainer>
      ) : null}
      {hasNextPage || status === 'loading' ? (
        <LoadingContainer>
          <ClipLoader
            color={colors.blue900}
            loading={true}
            cssOverride={{
              verticalAlign: 'middle',
            }}
            size={80}
            aria-label="Loading Spinner"
          />
        </LoadingContainer>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  padding: 13px 5px;
`

const Title = styled.p`
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`

const NoNftsImage = styled.img`
  width: 80px;
  margin-bottom: 22px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 32px;
  color: ${colors.grey500};
`

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: 50px 0;
`

const NFTContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-top: 12px;
  flex-wrap: wrap;
`

const Cell = styled.div`
  flex-basis: calc(33.333% - 8px);
  margin-bottom: 12px;
`

const LoadingContainer = styled.div`
  text-align: center;
  padding-top: 24px;
`

export default NFTSection
