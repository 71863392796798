import { useEffect, useRef, useState } from 'react'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import colors from '../../../../styles/colors'
import useGetGameDetailsQuery from '../../queries/useGetGameDetailsQuery'
import { GreenButton } from '../TrainingCard'

export const getVideoId = (url: string) => {
  if (url) {
    const params = url.substring(url.indexOf('?') + 1)
    const paramsObj = new URLSearchParams(params)

    return paramsObj.get('v')
  }
  return null
}

const VideoSection = ({ activeGame }: { activeGame: string }) => {
  const [playVideo, setPlayVideo] = useState<boolean>(false)
  const { data: game } = useGetGameDetailsQuery()
  const videoId = getVideoId(game?.gamePlayVideoUrl)
  const youtubeVideo = useRef<any>(null)

  useEffect(() => {
    if (activeGame) {
      setPlayVideo(false)
    }
  }, [activeGame])

  if (!videoId) {
    return <Container $loading></Container>
  }
  const trainingsLeft = game?.trainings?.filter((training: any) => {
    return training.status !== 'Done'
  })

  return (
    <Container>
      <Wrapper $hide={playVideo}>
        {game?.requiredNfts ? (
          <NFTRequired>
            <NFTIcon src="/images/nft.svg" alt="nft" />
            {game?.requiredNfts} required
          </NFTRequired>
        ) : null}
        <PlayIcon
          src="/images/play-button.svg"
          alt="play button"
          onClick={() => {
            if (youtubeVideo.current) {
              youtubeVideo.current.internalPlayer.playVideo()
              setPlayVideo(true)
            }
          }}
        />
        <DescriptionWrapper>
          <Description>Watch gameplay</Description>
        </DescriptionWrapper>
        {trainingsLeft?.length > 0 ? (
          <PassTrainingsWrapper>
            <img src="/images/white-arrow-down.svg" alt="arrow" />
            <span>In order to play this game please pass the trainigs below!</span>
          </PassTrainingsWrapper>
        ) : (
          <GreenButton>
            <ButtonLink href={game.gameSiteUrl} target="_blank">
              Play now
            </ButtonLink>
          </GreenButton>
        )}
      </Wrapper>
      <YouTube
        ref={youtubeVideo}
        videoId={videoId} // defaults -> ''
        // id={string}                       // defaults -> ''
        // className={string}                // defaults -> ''
        // iframeClassName={string}          // defaults -> ''
        // style={object}                    // defaults -> {}
        // title={string}                    // defaults -> ''
        // loading={string}                  // defaults -> undefined
        // opts={obj}                        // defaults -> {}
        // onReady={func}                    // defaults -> noop
        // onPlay={func}                     // defaults -> noop
        // onPause={func}                    // defaults -> noop
        // onEnd={func}                      // defaults -> noop
        // onError={func}                    // defaults -> noop
        // onStateChange={func}              // defaults -> noop
        // onPlaybackRateChange={func}       // defaults -> noop
        // onPlaybackQualityChange={func}    // defaults -> noop
      />
    </Container>
  )
}

const Container = styled.div<{ $loading?: boolean }>`
  padding-top: 8px;
  position: relative;

  & iframe {
    border-radius: 12px;
    width: 100%;
  }

  ${({ $loading }) =>
    $loading &&
    `
    height: 360px;
  `}
`

const Wrapper = styled.div<{ $hide?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 2;
  background: linear-gradient(97.16deg, rgba(86, 135, 244, 0.8) 2.84%, rgba(3, 41, 96, 0.8) 93.15%);
  backdrop-filter: blur(7px);
  text-align: center;
  padding-top: 100px;
  transition: all 0.4s;
  opacity: 1;

  ${({ $hide }) =>
    $hide &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`

const PlayIcon = styled.img`
  cursor: pointer;
`

const DescriptionWrapper = styled.div`
  padding: 20px 0;
`

const Description = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.white};
`

const ButtonLink = styled.a`
  display: block;
`

const NFTRequired = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  color: ${colors.white};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const NFTIcon = styled.img`
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -2px;
`

const PassTrainingsWrapper = styled.div`
  display: inline-block;
  background: rgba(86, 135, 244, 0.47);
  border-radius: 6px;
  color: ${colors.white};
  padding: 5px 11px;

  & > span {
    margin-left: 8px;
    display: inline-block;
    font-size: 12px;
    margin-top: -3px;
    vertical-align: middle;
  }

  & > img {
    margin-top: -2px;
  }
`

export default VideoSection
