import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'

const ErrorMessage = styled.p`
  background: rgba(211, 47, 47, 0.7);
  color: ${colors.white};
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 8px;
  border-radius: 6px;

  & > img {
    vertical-align: middle;
    margin-right: 6px;
    margin-top: -2px;
  }
`

export const ErrorMsg = ({ children }: PropsWithChildren) => {
  return (
    <ErrorMessage>
      <img src="/images/white-exclamation.svg" alt="exclamation" />
      {children}
    </ErrorMessage>
  )
}
