import { useEffect } from 'react'
import styled from 'styled-components'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { CurrencyHTML } from '../../../Profile/components/EarningsSection/components/EarningTile'
import useGetManagerGameDetailsQuery from '../../queries/useGetManagerGameDetailsQuery'
import useGetTeamEarningsQuery from '../../queries/useGetTeamEarnings'

interface TeamEarningsSectionProps {
  selectedPlayers: any
}

const TeamEarningsSection = ({ selectedPlayers }: TeamEarningsSectionProps) => {
  const { data: game } = useGetManagerGameDetailsQuery()
  const { data: filteredTeamEarnings, refetch: refectTeamEarnings } = useGetTeamEarningsQuery({
    selectedPlayers,
  })
  const { currency } = useCurrency()
  const earning = game?.myEarnings
  const teamEarnings = game?.teamEarnings

  useEffect(() => {
    if (selectedPlayers?.length > 0) {
      refectTeamEarnings()
    }
  }, [selectedPlayers, refectTeamEarnings])

  return (
    <Container>
      <Title>
        <Icon src="/images/banknote.svg " alt="earnings" /> Earnings
      </Title>
      <div>
        <EarningWrapper>
          <EarningTitle>Team Earnings</EarningTitle>
          <Earning>
            <CurrencyHTML currency={currency} earning={filteredTeamEarnings || teamEarnings} />
          </Earning>
        </EarningWrapper>
        <EarningWrapper $right>
          <EarningTitle>My Earnings</EarningTitle>
          <Earning>
            <CurrencyHTML currency={currency} earning={earning} />
          </Earning>
        </EarningWrapper>
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: ${colors.white};
  border-radius: 12px;
  padding: 14px 24px;
`

const Title = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 24px;
  letter-spacing: 0.15px;
  color: ${colors.grey500};
`

const Icon = styled.img`
  margin-right: 5px;
  width: 20px;
  vertical-align: middle;
  margin-top: -2px;
`

const Earning = styled.p`
  font-weight: 700;
  font-size: 26px;
  margin: 40px 0;
  color: ${colors.blue900};
  font-family: ${fonts.fontFamilyBold};
`

const EarningWrapper = styled.div<{ $right?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 15px;
  margin-bottom: 24px;

  ${({ $right }) =>
    $right &&
    `
    padding-left: 15px;
    padding-right: 0;
    border-left: 1px solid #DCE9FF;
  `}
`

const EarningTitle = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${colors.blue900};
`

export default TeamEarningsSection
