import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'

interface ModalProps {
  title?: string
  open: boolean
  setOpen: (o: boolean) => void
  bigSize?: boolean
  biggestSize?: boolean
  noPadding?: boolean
}

const Modal = ({
  title,
  open,
  setOpen,
  children,
  bigSize,
  biggestSize,
  noPadding,
}: ModalProps & PropsWithChildren) => {
  const modalPlaceholder = useRef<HTMLElement | null>(null)
  const [animateOpen, setAnimateOpen] = useState<boolean>(false)
  const scrollY = useRef<number | null>(null)
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    modalPlaceholder.current = document.getElementById('modal')
  }, [])

  const close = () => {
    setAnimateOpen(false)
    setTimeout(() => {
      const scrollYStyle = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
      window.scrollTo(0, parseInt(scrollYStyle || '0') * -1)
      setOpen(false)
      scrollY.current = null

      document.removeEventListener('keydown', closeOnEscape, false)
    }, 400)
  }

  const closeOnEscape = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      close()
    }
  }

  useEffect(() => {
    if (open && scrollY.current === null) {
      scrollY.current = window.scrollY

      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY.current}px`
      document.body.style.left = `0px`
      document.body.style.right = `0px`

      setAnimateOpen(true)
      document.addEventListener('keydown', closeOnEscape, false)
    } else if (!open && animateOpen) {
      setAnimateOpen(false)
      const scrollYStyle = document.body.style.top
      document.body.style.position = ''
      document.body.style.top = ''
      document.body.style.left = ''
      document.body.style.right = ''
      window.scrollTo(0, parseInt(scrollYStyle || '0') * -1)
      scrollY.current = null

      document.removeEventListener('keydown', closeOnEscape, false)
    }
  }, [open])

  if (!open || !modalPlaceholder.current) {
    return null
  }

  return createPortal(
    <>
      <ModalBackground $animateOpen={animateOpen} onClick={close} />
      <ModalContainer $bigSize={bigSize} $biggestSize={biggestSize} $animateOpen={animateOpen}>
        <ModalWrapper ref={modalRef}>
          <CloseIcon
            onClick={close}
            src="/images/modal-cross.svg"
            alt="close modal icon"
            id="close-modal"
          />
          {title && (
            <Head>
              <Title dangerouslySetInnerHTML={{ __html: title }} />
            </Head>
          )}
          <Body $noPadding={noPadding}>{children}</Body>
        </ModalWrapper>
      </ModalContainer>
    </>,
    modalPlaceholder.current
  )
}

const ModalWrapper = styled.div`
  position: relative;
  border-radius: 12px;
  background: ${colors.white};
`

const CloseIcon = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  top: -16px;
  right: -16px;
  cursor: pointer;
`

const Title = styled.span`
  color: ${colors.blue900};
  font-size: 16px;
  line-height: 24px;
  font-family: ${fonts.fontFamilyBold};
  letter-spacing: 0.15px;
`

const Head = styled.div`
  text-align: center;
  padding: 14px 0;
  border-bottom: 1px solid ${colors.blue300};
`

const ModalContainer = styled.div<{
  $animateOpen?: boolean
  $bigSize?: boolean
  $biggestSize?: boolean
}>`
  position: fixed;
  top: 0;
  left: calc(50% - 160px);
  z-index: 99999;
  max-width: 320px;
  width: 100%;
  opacity: 0;
  box-shadow: 0px 4px 10px rgba(86, 135, 244, 0.29);
  transition: all 0.4s;
  transform: translateY(0);

  ${({ $bigSize }) =>
    $bigSize &&
    `
    max-width: 600px;
    width: 100%;
    left: calc(50% - 300px);
  `}

  ${({ $biggestSize }) =>
    $biggestSize &&
    `
    max-width: 720px;
    width: 100%;
    left: calc(50% - 360px);
  `}

  ${({ $animateOpen }) =>
    $animateOpen &&
    `
    opacity: 1;
    top: 50%;
    transform: translateY(-50%);
  `}
  ${({ $animateOpen, $bigSize }) =>
    $animateOpen &&
    $bigSize &&
    `
      top: 50%;
      transform: translateY(-50%);
  `}


  @media screen and (max-width: 768px) {
    right: 20px;
    left: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
  }
`

const ModalBackground = styled.div<{ $animateOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99998;
  background: linear-gradient(
    245.29deg,
    rgba(71, 102, 173, 0.93) 14.43%,
    rgba(3, 41, 96, 0.93) 93.14%
  );
  backdrop-filter: blur(19px);
  opacity: 0;
  transition: all 0.4s;

  ${({ $animateOpen }) =>
    $animateOpen &&
    `
    opacity: 1;
  `}
`

const Body = styled.div<{ $noPadding?: boolean }>`
  padding: 14px;

  ${({ $noPadding }) =>
    $noPadding &&
    `
    padding: 0;
  `}
`

export default Modal
