import { ChangeEvent, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { SecondaryButton, SubmitButton } from '../../../../components/Button'
import Input from '../../../../components/Input'
import Modal from '../../../../components/Modal'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'
import useAddReferralLinkMutation from './queries/useAddReferralLinkMutation'
import useDeleteReferralLinkMutation from './queries/useDeleteReferralLinkMutation'
import useManagerReferralsQuery from './queries/useManagerReferralsQuery'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Popup from '../../../../components/Popup'
import useUpdateReferralMutation from './queries/useUpdateReferralMutation'

interface ManagerReferralsProps {
  editable: boolean
  mask?: boolean
}

enum NETWORKS {
  TWITTER = 'twitter',
  DISCORD = 'discord',
  TELEGRAM = 'telegram',
}

const getNetworkId = (network: NETWORKS) => {
  if (network === NETWORKS.TWITTER) {
    return 2
  }
  if (network === NETWORKS.TELEGRAM) {
    return 1
  }
  if (network === NETWORKS.DISCORD) {
    return 4
  }
  return 5
}

const getNetworkImage = (networkId: number) => {
  if (networkId === 2) {
    return '/images/twitter-referral.svg'
  }
  if (networkId === 1) {
    return '/images/telegram-referral.svg'
  }
  if (networkId === 4) {
    return '/images/discord-referral.svg'
  }
  return ''
}

const ManagerReferralsSection = ({ editable, mask }: ManagerReferralsProps) => {
  const [copiedPopup, setCopiedPopup] = useState<number | null>(null)
  const [copiedEditPopup, setCopiedEditPopup] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [referralTitle, setReferralTitle] = useState<string>('')
  const [referralId, setReferralId] = useState<number | null>(null)
  const [title, setTitle] = useState<string>('')
  const [link, setLink] = useState<string>('')
  const [titleError, setTitleError] = useState<boolean>(false)
  const [socialNetwork, setSocialNetwork] = useState<NETWORKS>(NETWORKS.TWITTER)
  const { data: referrals } = useManagerReferralsQuery()
  const { mutateAsync: addReferral, isLoading } = useAddReferralLinkMutation()
  const { mutateAsync: deleteReferral, isLoading: isDeleteLoading } =
    useDeleteReferralLinkMutation()
  const { mutateAsync: updateReferral, isLoading: isLoadingUpdateReferral } =
    useUpdateReferralMutation()

  const handleSubmit = async () => {
    if (!title) {
      setTitleError(true)
      return
    }
    setTitleError(false)

    await addReferral({
      title,
      networkId: getNetworkId(socialNetwork),
    })
    setOpenModal(false)
    setSocialNetwork(NETWORKS.TWITTER)
    setTitle('')
  }

  const handleEdit = async () => {
    if (!title) {
      setTitleError(true)
      return
    }
    setTitleError(false)

    if (referralId !== null) {
      await updateReferral({
        id: referralId,
        name: title,
      })
    }
    setOpenEditModal(false)
    setSocialNetwork(NETWORKS.TWITTER)
    setTitle('')
    setReferralId(null)
  }

  return (
    <Container>
      <ReferralsContainer $editable={editable} $mask={mask}>
        <Header>
          <Title>Referrals</Title>
          <div>
            <Plus
              src="/images/white-plus.svg"
              alt=""
              onClick={() => {
                setOpenModal(true)
              }}
            />
          </div>
        </Header>
        <Wrapper>
          {referrals?.map((referral: any) => {
            return (
              <ReferralContainer key={referral.id}>
                <ClickableDiv
                  onClick={() => {
                    setTitle(referral.name)
                    setLink(referral.url)
                    setReferralId(referral.id)
                    setOpenEditModal(true)
                  }}
                >
                  <Image src={getNetworkImage(referral.socialNetworkId)} alt="" />
                  <NameContainer>
                    <Name>{referral.name}</Name>
                  </NameContainer>
                </ClickableDiv>
                <Buttons>
                  <ReferralIcon src="/images/referrals.svg" alt="referrals count" />
                  <Referrals>{referral.countReferred}</Referrals>
                  <ActionsWrapper>
                    <CopyToClipboard
                      text={referral.url}
                      onCopy={() => {
                        setCopiedPopup(referral.id)
                        setTimeout(() => {
                          setCopiedPopup(null)
                        }, 3000)
                      }}
                    >
                      <CopyIcon src="/images/copy.svg" alt="copy" />
                    </CopyToClipboard>
                    <DeleteIcon
                      src="/images/delete.svg"
                      alt="delete"
                      onClick={() => {
                        setReferralId(referral.id)
                        setReferralTitle(referral.name)
                        setOpenConfirmModal(true)
                      }}
                    />
                    <Popup open={copiedPopup === referral.id} minWidth={100} maxWidth={100}>
                      <span>Copied!</span>
                    </Popup>
                  </ActionsWrapper>
                </Buttons>
              </ReferralContainer>
            )
          })}
        </Wrapper>
      </ReferralsContainer>
      <Modal title={'Add Referral Link'} open={openModal} setOpen={setOpenModal}>
        <ModalBody>
          <SectionLabel>Choose Social Network</SectionLabel>
          <SocialContainer>
            <Square
              onClick={() => {
                setSocialNetwork(NETWORKS.TWITTER)
              }}
              $selected={socialNetwork === NETWORKS.TWITTER}
            >
              <img src="/images/twitter-referral.svg" alt="twitter" />
            </Square>
            <Square
              onClick={() => {
                setSocialNetwork(NETWORKS.TELEGRAM)
              }}
              $selected={socialNetwork === NETWORKS.TELEGRAM}
            >
              <img src="/images/telegram-referral.svg" alt="telegram" />
            </Square>
            <Square
              onClick={() => {
                setSocialNetwork(NETWORKS.DISCORD)
              }}
              $selected={socialNetwork === NETWORKS.DISCORD}
              $discord
            >
              <img src="/images/discord-referral.svg" alt="discord" />
            </Square>
          </SocialContainer>
          <SectionLabel>Referral Links</SectionLabel>
          <Input
            id="title"
            big
            value={title}
            label="Title"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value)
              if (e.target.value !== '' && titleError) {
                setTitleError(false)
              }
            }}
            error={titleError}
            errorMsg="Referral title cannot be empty!"
          />
          <ButtonsWrapper>
            <Cell $paddingRight>
              <SecButton
                onClick={() => {
                  document.getElementById('close-modal')?.click()
                }}
              >
                CANCEL
              </SecButton>
            </Cell>
            <Cell $paddingLeft>
              <SubButton disabled={!!title && isLoading} onClick={handleSubmit}>
                <ClipLoader
                  color={colors.white}
                  loading={isLoading}
                  cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                  size={20}
                  aria-label="Loading Spinner"
                />
                SAVE
              </SubButton>
            </Cell>
          </ButtonsWrapper>
        </ModalBody>
      </Modal>
      <Modal
        title={'Edit Referral Link'}
        open={openEditModal}
        bigSize
        setOpen={() => {
          setTitle('')
          setOpenEditModal(false)
        }}
      >
        <ModalBody>
          <Input
            id="title"
            big
            value={title}
            label="Title"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTitle(e.target.value)
              if (e.target.value !== '' && titleError) {
                setTitleError(false)
              }
            }}
            error={titleError}
            errorMsg="Referral title cannot be empty!"
          />
          <CopyWrapper>
            <InputCopyWrapper>
              <Input id="link" big value={link} label="Link" disabled onChange={() => {}} />
            </InputCopyWrapper>
            <CopyIconWrapper>
              <CopyToClipboard
                text={link}
                onCopy={() => {
                  setCopiedEditPopup(true)
                  setTimeout(() => {
                    setCopiedEditPopup(false)
                  }, 3000)
                }}
              >
                <CopyPopupIcon src="/images/copy.svg" alt="copy" />
              </CopyToClipboard>
              <Popup open={copiedEditPopup} minWidth={100} maxWidth={100}>
                <span>Copied!</span>
              </Popup>
            </CopyIconWrapper>
          </CopyWrapper>
          <ButtonsWrapper>
            <Cell $paddingRight>
              <SecButton
                onClick={() => {
                  document.getElementById('close-modal')?.click()
                }}
              >
                CANCEL
              </SecButton>
            </Cell>
            <Cell $paddingLeft>
              <SubButton disabled={!!title && isLoadingUpdateReferral} onClick={handleEdit}>
                <ClipLoader
                  color={colors.white}
                  loading={isLoadingUpdateReferral}
                  cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                  size={20}
                  aria-label="Loading Spinner"
                />
                Edit
              </SubButton>
            </Cell>
          </ButtonsWrapper>
        </ModalBody>
      </Modal>
      <Modal bigSize open={openConfirmModal} setOpen={setOpenConfirmModal} noPadding={true}>
        <ModalConfirmBody>
          <ModalText>
            Are you sure you want to delete <strong>{referralTitle}</strong> referral link?
          </ModalText>
          <ConfirmButtons>
            <SecondaryStyledButton
              onClick={() => {
                setReferralTitle('')
                setOpenConfirmModal(false)
              }}
            >
              NO
            </SecondaryStyledButton>
            <SubmitStyledButton
              disabled={isDeleteLoading}
              onClick={async () => {
                if (referralId !== null) {
                  await deleteReferral({
                    id: referralId,
                  })
                  setReferralTitle('')
                  setReferralId(null)
                  setOpenConfirmModal(false)
                }
              }}
            >
              <ClipLoader
                color={colors.white}
                loading={isDeleteLoading}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              YES
            </SubmitStyledButton>
          </ConfirmButtons>
        </ModalConfirmBody>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  max-height: 315px;

  @media screen and (max-width: 1010px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

const ReferralsContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  height: 100%;
`

const Title = styled(SectionTitle)`
  margin: 0;
`

const Wrapper = styled.div``

const ReferralContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 10px;
  background: rgba(142, 177, 255, 0.16);
  border-radius: 8px;
  margin-bottom: 3px;
`

const Image = styled.img`
  width: 24px;
  height: 24px;
  vertical-align: middle;
`

const NameContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`

const Name = styled.p`
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: ${colors.blue900};
  margin: 0;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const Plus = styled.img`
  cursor: pointer;
`

const ModalBody = styled.div`
  padding: 12px;
`

const Cell = styled.div<{ $paddingLeft?: boolean; $paddingRight?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;

  ${({ $paddingLeft }) =>
    $paddingLeft &&
    `
    padding-left: 5px;
  `}
  ${({ $paddingRight }) =>
    $paddingRight &&
    `
    padding-right: 5px;
    text-align: right;
  `}
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`

const SecButton = styled(SecondaryButton)`
  width: 100px;
`

const SubButton = styled(SubmitButton)`
  width: 100px;
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${colors.blue300};
  margin-bottom: 30px;
`

const Square = styled.div<{ $selected?: boolean; $discord?: boolean }>`
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 10px;
  transition: all 0.4s;
  position: relative;
  cursor: pointer;

  & > img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 12px;
    bottom: 12px;
    left: 12px;
    right: 12px;
    margin: auto;

    ${({ $discord }) =>
      $discord &&
      `
      width: 45px;
    `}
  }

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(59, 130, 246, 0.1);
    border: 1px solid rgba(59, 130, 246, 0.5);
  `}
`

const SectionLabel = styled.p`
  margin: 0 0 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`

const Referrals = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
  color: ${colors.black};
  margin-left: 4px;
`

const ReferralIcon = styled.img``

const CopyIcon = styled.img`
  margin-left: 20px;
  margin-right: 15px;
  cursor: pointer;
  width: 13px;
`

const CopyPopupIcon = styled.img`
  cursor: pointer;
  width: 20px;
`

const DeleteIcon = styled.img`
  cursor: pointer;
  width: 12px;
`

const ActionsWrapper = styled.div`
  border-left: 1px solid ${colors.white};
  margin-left: 20px;
  padding-right: 7px;
  position: relative;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const SecondaryStyledButton = styled(SecondaryButton)`
  width: 70px;
`

const SubmitStyledButton = styled(SubmitButton)`
  min-width: 70px;
`

const ModalConfirmBody = styled.div`
  text-align: center;
  padding: 32px 12px 12px;
`

const ConfirmButtons = styled.div`
  display: flex;
  gap: 10px;
  margin: 20px 0;
  justify-content: center;
`

const ClickableDiv = styled.div`
  cursor: pointer;
`

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
  align-items: flex-end;
`

const InputCopyWrapper = styled.div`
  width: calc(100% - 40px);
`

const CopyIconWrapper = styled.div`
  width: 40px;
  text-align: center;
  padding-bottom: 22px;
  position: relative;
`

export default ManagerReferralsSection
