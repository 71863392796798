enum QUERY_KEYS {
  USER = 'user',
  TEAM = 'team',
  TEAM_MEMBERS = 'team_members',
  MANAGERS = 'managers',
  COUNTRIES = 'countries',
  EARNINGS = 'earnings',
  TEAM_EARNINGS = 'team_earnings',
  NFT = 'nft',
  AGGREGATED_EARNINGS = 'aggregated_earnings',
  MANAGER_REQUESTS = 'manager_requests',
  REFERRAL_CODE = 'referral_code',
  NETWORKS = 'networks',
  GAMES = 'games',
  DASHBOARD_GAMES = 'dashboard_games',
  GAME_DETAILS = 'game_details',
  GAME_DETAILS_MANAGER = 'game_details_manager',
  SEARCH_NFT = 'search_nft',
  SEARCH_MARKETPLACE_NFT = 'search_marketplace_nft',
  NFT_PLAYER_REQUESTS = 'nft_player_requests',
  NFT_TEAM_REQUESTS = 'nft_team_requests',
  GAME_TEAM_EARNINGS = 'game_team_earnings',
  PROFILE = 'profile',
  SEARCH_AUTOCOMPLETES = 'search_autocompletes',
  NFT_REQUESTS_AUTOCOMPLETES = 'nft_requests_autocompletes',
  NFT_TEAM_REQUESTS_MODAL = 'nft_team_requests_modal',
  UNREAD_NOTIFICATIONS = 'unread_notifications',
  ALL_READ_NOTIFICATIONS = 'all_read_notifications',
  ALL_UNREAD_NOTIFICATIONS = 'all_unread_notifications',
  MANAGER_REFERRALS = 'manager_referrals',
  TEAM_EARNINGS_GAME = 'team_earnings_game',
  TEAM_EARNINGS_PLAYERS = 'team_earnings_players',
  ANALYTICS_DATA = 'analytics_data',
  WITHDRAW_CURRENCY = 'withdraw_currency',
  PAYMENTS = 'payments',
  TEAM_PAYMENTS = 'team_payments',
  PAYMENT_STATUSES = 'payment_statuses',
  GAME_CREDENTIALS = 'game_credentials',
}

export default QUERY_KEYS
