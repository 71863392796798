import { Children } from 'react'
import { components } from 'react-select'
import styled from 'styled-components'

const { ValueContainer, Placeholder } = components

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      {props?.selectProps?.inputValue ? (
        <></>
      ) : (
        <StyledPlaceholder {...props} isFocused={props.isFocused} $hasIcon={props.icon}>
          {props.selectProps.placeholder}
        </StyledPlaceholder>
      )}
      {Children.map(children, child => (child && child.type !== Placeholder ? child : null))}
    </ValueContainer>
  )
}

const StyledPlaceholder = styled(Placeholder)`
  font-size: 12px;

  ${({ $hasIcon }: any) =>
    $hasIcon &&
    `
    padding-left: 18px;
  `}
`

export default CustomValueContainer
