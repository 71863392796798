import moment from 'moment'
import { ChangeEvent, useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import useUpdateCardDetailsMutation from '../../pages/Profile/components/CardDetailsSection/queries/useUpdateCardDetailsMutation'
import colors from '../../styles/colors'
import { SecondaryButton, SubmitButton } from '../Button'
import Input from '../Input'
import Modal from '../Modal'
import { SingleDatePicker } from 'react-dates'
import { useUser } from '../../contexts/AuthContext'
import Card from '../../types/card'

const mapDigitalCard = (digitalCard: any) => {
  return {
    number: digitalCard.cardNumber,
    name: `${digitalCard.firstName} ${digitalCard.lastName}`,
    expDate: digitalCard.expirationDate,
    cvc: digitalCard.cvc,
  }
}

interface CardModalProps {
  openDetailsModal: boolean
  setOpenDetailsModal: (o: boolean) => void
}

const CardModal = ({ openDetailsModal, setOpenDetailsModal }: CardModalProps) => {
  const { mutateAsync: updateCardDetails, isLoading } = useUpdateCardDetailsMutation()
  const [cardDetails, setCardDetails] = useState<Card>({})
  const { user } = useUser()
  const [cardNumberError, setCardNumberError] = useState<boolean>(false)
  const [cardNameError, setCardNameError] = useState<boolean>(false)
  const [cardExpDateError, setCardExpDateError] = useState<boolean>(false)
  const [cardCVCError, setCardCVCError] = useState<boolean>(false)
  const [focus, setFocus] = useState<boolean>(false)

  useEffect(() => {
    if (user?.digitalCards?.[0]) {
      setCardDetails(mapDigitalCard(user.digitalCards[0]))
    }
  }, [user?.digitalCards])

  const handleSubmit = async () => {
    if (!cardDetails.number) {
      setCardNumberError(true)
      return
    }
    if (!cardDetails.name) {
      setCardNameError(true)
      return
    }
    if (!cardDetails.expDate) {
      setCardExpDateError(true)
      return
    }
    if (!cardDetails.cvc) {
      setCardCVCError(true)
      return
    }

    const names = cardDetails.name.split(' ')

    await updateCardDetails({
      cardNumber: cardDetails.number,
      firstName: names[0],
      lastName: names?.[1],
      expirationDate: cardDetails.expDate,
      cvc: cardDetails.cvc,
    })

    document.getElementById('close-modal')?.click()
  }

  return (
    <Modal title={'Card Details'} open={openDetailsModal} setOpen={setOpenDetailsModal}>
      <ModalBody>
        <Input
          id="card-number"
          value={cardDetails.number || ''}
          label="Card number"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCardDetails({
              ...cardDetails,
              number: e.target.value,
            })
            if (e.target.value !== '' && cardNumberError) {
              setCardNumberError(false)
            }
          }}
          error={cardNumberError}
          errorMsg="Card number cannot be empty!"
        />
        <Input
          id="card-name"
          value={cardDetails.name || ''}
          label="Name on card"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setCardDetails({
              ...cardDetails,
              name: e.target.value,
            })
            if (e.target.value !== '' && cardNameError) {
              setCardNameError(false)
            }
          }}
          error={cardNameError}
          errorMsg="Card name cannot be empty!"
        />
        <div>
          <Cell $paddingRight>
            <Label htmlFor={'card-exp-date'}>Expiration date</Label>
            <SingleDatePicker
              id="card-exp-date"
              placeholder=""
              date={cardDetails.expDate ? moment(cardDetails.expDate) : null} // momentPropTypes.momentObj or null
              isOutsideRange={day => day.isBefore(moment())}
              onDateChange={date => {
                setCardDetails({
                  ...cardDetails,
                  expDate: date?.toDate(),
                })
              }} // PropTypes.func.isRequired
              focused={focus} // PropTypes.bool
              onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
              displayFormat="YYYY-MM-DD"
              numberOfMonths={1}
              showClearDate={true}
              noBorder={true}
              small={true}
              openDirection="up"
            />
          </Cell>
          <Cell $paddingLeft>
            <Input
              id="card-cvc"
              maxWidth={50}
              value={cardDetails.cvc || ''}
              label="CVC"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCardDetails({
                  ...cardDetails,
                  cvc: e.target.value,
                })
                if (e.target.value !== '' && cardCVCError) {
                  setCardCVCError(false)
                }
              }}
              error={cardCVCError}
              errorMsg="CVC cannot be empty!"
            />
          </Cell>
        </div>
        <ButtonsWrapper>
          <Cell $paddingRight>
            <SButton
              onClick={() => {
                document.getElementById('close-modal')?.click()
              }}
            >
              CANCEL
            </SButton>
          </Cell>
          <Cell $paddingLeft>
            <SubButton disabled={isLoading} onClick={handleSubmit}>
              <ClipLoader
                color={colors.white}
                loading={isLoading}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              SAVE
            </SubButton>
          </Cell>
        </ButtonsWrapper>
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 12px;
`

const Cell = styled.div<{ $paddingLeft?: boolean; $paddingRight?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;

  ${({ $paddingLeft }) =>
    $paddingLeft &&
    `
    padding-left: 5px;
  `}
  ${({ $paddingRight }) =>
    $paddingRight &&
    `
    padding-right: 5px;
  `}
`

const Label = styled.label`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 4px;
`

const SButton = styled(SecondaryButton)`
  width: 100%;
`

const SubButton = styled(SubmitButton)`
  width: 100%;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`

export default CardModal
