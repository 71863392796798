import colors from '../../../../../styles/colors'

interface DollarSignProps {
  color: string
}

const DollarSign = ({ color }: DollarSignProps) => {
  return (
    <svg width="27" height="48" viewBox="0 0 27 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5007 0C15.1601 0 16.5007 1.34344 16.5007 3V6.345C16.6507 6.36469 16.7914 6.38625 16.9414 6.40781C17.9351 6.55594 20.8976 7.03312 22.1164 7.34156C23.6351 7.74844 24.6945 9.38437 24.282 10.9875C23.8789 12.5906 22.2476 13.5656 20.6351 13.1625C19.7632 12.8531 17.1101 12.4969 16.0601 12.3469C13.0507 11.8969 10.4726 12.1219 8.70075 12.7969C6.987 13.4531 6.27543 14.3813 6.087 15.4219C5.90512 16.425 6.04293 16.9969 6.20793 17.3531C6.38512 17.7281 6.73106 18.15 7.42012 18.6094C8.9445 19.6125 11.2695 20.2875 14.2882 21.1031L14.5507 21.1781C17.2132 21.9 20.4757 22.7906 22.8945 24.3938C24.2164 25.275 25.4632 26.4656 26.232 28.1156C27.0101 29.7844 27.1789 31.6594 26.8226 33.5719C26.1664 37.2188 23.6351 39.6 20.6632 40.8375C19.3882 41.3625 17.982 41.7 16.5007 41.8688V45C16.5007 46.6594 15.1601 48 13.5007 48C11.8414 48 10.5007 46.6594 10.5007 45V41.7281C10.4632 41.7281 10.4164 41.6344 10.3789 41.7094H10.3601C8.082 41.3531 4.31981 40.3688 1.78199 39.2438C0.267932 38.5688 -0.41363 36.7969 0.259495 35.2781C0.93262 33.7687 2.70543 33.0844 4.13512 33.7594C6.17887 34.6313 9.40387 35.4844 11.2695 35.775C14.2695 36.225 16.7351 35.9625 18.4039 35.2875C19.9882 34.6406 20.7101 33.7031 20.9164 32.5781C21.0945 31.575 20.9632 31.0031 20.7945 30.6469C20.6164 30.2719 20.2695 29.85 19.5851 29.3906C18.057 28.3875 15.732 27.7125 12.7132 26.8969L12.4507 26.8219C9.78825 26.1 6.52387 25.2094 4.10324 23.6063C2.78043 22.725 1.54106 21.5344 0.77137 19.8844C-0.00956798 18.2156 -0.179255 16.3406 0.183557 14.3438C0.847307 10.6969 3.47418 8.37094 6.5595 7.19344C7.78012 6.7275 9.10387 6.42094 10.5007 6.25594V3C10.5007 1.34344 11.8414 0 13.5007 0Z"
        fill={color || colors.white}
      />
    </svg>
  )
}

export default DollarSign
