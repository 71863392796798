import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const checkCode = async (code?: string) => {
  const data = await API.get(`referral/check?referralcode=${code}`)

  return data
}

const useCheckCodeQuery = ({ code }: { code?: string }) => {
  return useQuery([QUERY_KEYS.REFERRAL_CODE, code], () => checkCode(code), {
    staleTime: 60 * 1000,
    enabled: !!code,
  })
}

export default useCheckCodeQuery
