import styled from 'styled-components'
import SectionContainer from '../SectionContainer'
import useEarningsQuery from './queries/useEarnings'
import { useUser } from '../../../../contexts/AuthContext'
import PlayerEarnings from './components/PlayerEarnings'
import ManagerEarnings from './components/ManagerEarnings'

interface EarningsSectionProps {
  editable: boolean
  mask?: boolean
}

const EarningsSection = ({ editable, mask }: EarningsSectionProps) => {
  const { user } = useUser()
  const { data: earnings } = useEarningsQuery()

  return (
    <Container>
      <EarningsSectionContainer $editable={editable} $mask={mask}>
        {user.userRoleName === 'Manager' ? (
          <ManagerEarnings earnings={earnings} />
        ) : (
          <PlayerEarnings earnings={earnings} />
        )}
      </EarningsSectionContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 35%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  height: 315px;

  @media screen and (max-width: 1300px) {
    width: 50%;
    margin-top: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const EarningsSectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px 14px;
`

export default EarningsSection
