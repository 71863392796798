import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { LinkButton } from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import { useUser } from '../../../../contexts/AuthContext'
import colors from '../../../../styles/colors'
import SectionTitle from '../SectionTitle'
import QRCode from 'react-qr-code'
import useGameCredentials from './queries/useGameCredentials'
import { Overlay, Tooltip } from 'react-bootstrap'
import TutorialTooltip from '../../../../components/TutorialTooltip/TutorialTooltip'

const IngameDetails = () => {
  const { user, tutorialStep, setTutorialStep } = useUser()
  const target = useRef(null)
  const [selectedGame, setSelectedGame] = useState<any>(null)
  const [openCredentialsModal, setOpenCredentialsModal] = useState<boolean>(false)
  const { data: gameCredentials } = useGameCredentials(selectedGame?.id)

  useEffect(() => {
    if (selectedGame === null && user?.games) {
      setSelectedGame(user?.games?.[0])
    }
  }, [user])

  return (
    <div>
      <SectionTitle>Ingame details</SectionTitle>
      <GameContainer>
        {user?.games?.map((game: any) => {
          return (
            <GameWrapper key={game.name}>
              <GameImage
                $selected={selectedGame?.name === game.name}
                onClick={() => setSelectedGame(game)}
                src={game.bannerPictureUrl}
                alt={game.name}
              />
            </GameWrapper>
          )
        })}
      </GameContainer>
      <Overlay
        placement={'top'}
        show={tutorialStep === 8 && !!gameCredentials?.credentialsKey}
        target={target.current}
      >
        {props => (
          <Tooltip className="tutorial" {...props}>
            <TutorialTooltip>Step: Log in to the game and start playing</TutorialTooltip>
          </Tooltip>
        )}
      </Overlay>
      <InlineDiv ref={target}>
        {gameCredentials?.credentialsKey ? (
          <>
            <LinkButton
              onClick={() => {
                if (tutorialStep === 8) {
                  setTutorialStep(tutorialStep + 1)
                }
                setOpenCredentialsModal(true)
              }}
              data-test-id="show-credentials-btn"
            >
              <KeyIcon src="/images/key.svg" alt="key icon" />
              Show credentials
            </LinkButton>
          </>
        ) : null}
      </InlineDiv>
      <Modal
        title={'Verify Credentials'}
        open={openCredentialsModal}
        setOpen={setOpenCredentialsModal}
      >
        <ModalBody>
          <QRWrapper>
            <QRCode
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={gameCredentials?.credentialsKey || ''}
              viewBox={`0 0 256 256`}
            />
          </QRWrapper>
          <Description data-test-id="credential-description">
            In order to play please scan the QR code!
          </Description>
        </ModalBody>
      </Modal>
    </div>
  )
}

const GameImage = styled.img<{ $selected?: boolean }>`
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  ${({ $selected }) =>
    !$selected &&
    `
    opacity: 0.5;
  `}
`

const GameWrapper = styled.div`
  width: 33.333%;
  display: inline-block;
  padding: 3px;
`

const ModalBody = styled.div`
  text-align: center;
  padding: 15px 20px;
`

const KeyIcon = styled.img`
  vertical-align: middle;
  margin-right: 3px;
`

const Description = styled.p`
  color: ${colors.grey500};
  font-size: 16px;
  line-height: 24px;
`

const GameContainer = styled.div`
  margin-bottom: 10px;
`

const QRWrapper = styled.div`
  max-width: 200px;
  margin: 0 auto 20px;
`

const InlineDiv = styled.div`
  display: inline-block;
`

export default IngameDetails
