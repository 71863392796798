import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const fetchNftRequests = async (game: string, size: number) => {
  const data = await API.authGet(
    `nft/request/search/team?filter.GameId=${game}&filter.Page=1&filter.Size=${size}&filter.NftRequestStatusIds=1`
  )

  return data
}

const fetchClosedNftRequests = async (game: string) => {
  const data = await API.authGet(
    `nft/request/search/team?filter.GameId=${game}&filter.Page=1&filter.Size=4&filter.NftRequestStatusIds=3&filter.NftRequestStatusIds=4`
  )

  return data
}

export const useManagerActiveNftRequestsQuery = (size?: number) => {
  const { user } = useUser()
  const [searchParams] = useSearchParams()
  const game = searchParams.get('game')

  return useQuery({
    queryKey: [QUERY_KEYS.NFT_TEAM_REQUESTS, user.id, game || '1', size || 4, 'active'],
    queryFn: () => fetchNftRequests(game || '1', size || 4),
    enabled: user?.userRoleName === 'Manager',
  })
}

export const useManagerClosedNftRequestsQuery = () => {
  const { user } = useUser()
  const [searchParams] = useSearchParams()
  const game = searchParams.get('game')

  return useQuery({
    queryKey: [QUERY_KEYS.NFT_TEAM_REQUESTS, user.id, game || '1', 'closed'],
    queryFn: () => fetchClosedNftRequests(game || '1'),
    enabled: user?.userRoleName === 'Manager',
  })
}
