import { useState } from 'react'
import styled from 'styled-components'
import colors from '../../../../styles/colors'
import useGetGameDetailsQuery from '../../queries/useGetGameDetailsQuery'
import TrainingCard from '../TrainingCard'

const TrainingsSection = () => {
  const [collapsed, setCollapsed] = useState<boolean>(true)
  const { data: game, refetch } = useGetGameDetailsQuery()

  if (!game?.trainings) {
    return null
  }

  const sortedTrainings = game.trainings?.sort((a: any, b: any) => {
    return a.order - b.order
  })

  return (
    <Container $collapsed={collapsed}>
      <Title
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        My Trainings <ArrowIcon $collapsed={collapsed} src="/images/arrow-icon.svg" alt="arrow" />
      </Title>
      <CardWrapper $collapsed={collapsed}>
        {sortedTrainings?.map((training: any, index: number) => {
          return (
            <CardCell key={`${training.name}-${training.id}`}>
              <TrainingCard
                {...training}
                isAxie={game?.name === 'Axie Infinity'}
                index={index}
                refetchGame={refetch}
              />
            </CardCell>
          )
        })}
      </CardWrapper>
    </Container>
  )
}

const Container = styled.div<{ $collapsed?: boolean }>`
  margin-top: 22px;
  border-top: 1px solid #dce9ff;
  border-bottom: 1px solid #dce9ff;
  padding: 13px 5px;
`

const CardCell = styled.div`
  flex-basis: calc(33.333% - 8px);
`

const CardWrapper = styled.div<{ $collapsed?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  height: 0;
  overflow: hidden;
  transition: all 0.4s;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    padding-top: 24px;
    padding-bottom: 20px;
    height: auto;
  `}
`

const Title = styled.p`
  position: relative;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  margin: 0;
  cursor: pointer;
`

const ArrowIcon = styled.img<{ $collapsed?: boolean }>`
  width: 12px;
  position: absolute;
  right: 0;
  top: 5px;
  transition: all 0.4s;

  ${({ $collapsed }) =>
    $collapsed &&
    `
    transform: rotate(180deg);
  `}
`

export default TrainingsSection
