import { formatDistance } from 'date-fns'
import { Fragment, useState } from 'react'
import styled from 'styled-components'
import { ApproveButton, LinkButton, RejectButton } from '../../../../components/Button'
import colors from '../../../../styles/colors'
import RequestsModal from '../RequestsModal.tsx'
import ConfirmModal from './components/ConfirmModal'
import RejectModal from './components/RejectModal'
import {
  useManagerActiveNftRequestsQuery,
  useManagerClosedNftRequestsQuery,
} from './queries/useManagerNftRequestsQuery'
import ProfileModal from '../../../../components/ProfileModal'

interface ManagerNFTRequestsSectionProps {
  collapsed: boolean
  setCollapsed: (c: boolean) => void
}

const ManagerNFTRequestsSection = ({ collapsed, setCollapsed }: ManagerNFTRequestsSectionProps) => {
  const { data: closedRequests } = useManagerClosedNftRequestsQuery()
  const { data: activeRequests } = useManagerActiveNftRequestsQuery()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number | null>(null)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  const [selectedRequest, setSelectedRequest] = useState<any>(null)
  const [preselectedTab, setPreselectedTab] = useState<number>(0)

  return (
    <>
      <Container $hide={collapsed}>
        <Header>
          <Title>NFT requests</Title>
          <ViewAll
            onClick={() => {
              setPreselectedTab(0)
              setOpenModal(true)
            }}
          >
            View all
          </ViewAll>
          <CrossIcon
            src="/images/blue-cross-icon.svg"
            alt="cross"
            onClick={() => {
              setCollapsed(true)
            }}
          />
        </Header>
        <ScrollableWrapper>
          {activeRequests?.items?.length > 0 ? (
            <RequestsWrapper>
              <Title>Pending</Title>
              {activeRequests.items.length > 3 ? (
                <More
                  onClick={() => {
                    setPreselectedTab(0)
                    setOpenModal(true)
                  }}
                >
                  {activeRequests.items.length - 3} more
                </More>
              ) : null}
              {activeRequests.items.slice(0, 3)?.map?.((r: any) => {
                return (
                  <Fragment key={r.id}>
                    <Cell>
                      <ImageWrapper>
                        <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                      </ImageWrapper>
                      <div>
                        <NFTName>{r.nftName}</NFTName>
                        <Time>
                          {formatDistance(new Date(r.createdOn), new Date(), { addSuffix: true })}
                        </Time>
                        <PlayerName
                          onClick={() => {
                            setSelectedUser(r.playerId)
                            setOpenProfileModal(true)
                          }}
                        >
                          By {r.playerName}
                        </PlayerName>
                      </div>
                    </Cell>
                    <ButtonsFooter>
                      <RejectButton
                        onClick={() => {
                          setSelectedRequest(r)
                          setOpenRejectModal(true)
                        }}
                      >
                        Reject
                      </RejectButton>
                      <ApproveButton
                        onClick={() => {
                          setSelectedRequest(r)
                          setOpenConfirmModal(true)
                        }}
                      >
                        Approve
                      </ApproveButton>
                    </ButtonsFooter>
                  </Fragment>
                )
              })}
            </RequestsWrapper>
          ) : null}
          {closedRequests?.items?.length > 0 ? (
            <HistoryWrapper>
              <Title>Request history</Title>
              {closedRequests.items.length > 3 ? (
                <More
                  onClick={() => {
                    setPreselectedTab(1)
                    setOpenModal(true)
                  }}
                >
                  View all
                </More>
              ) : null}
              {closedRequests.items.slice(0, 3)?.map?.((r: any, index: number) => {
                return (
                  <Cell key={`${r.id}-${r.nftRequestStatus}-${index}`} $history>
                    <ImageWrapper>
                      <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                    </ImageWrapper>
                    <div>
                      <NFTName>{r.nftName}</NFTName>
                      {r.nftRequestStatus === 'Accepted' ? (
                        <ApprovedStatus>Approved</ApprovedStatus>
                      ) : null}
                      {r.nftRequestStatus === 'Rejected' ? (
                        <RejectedStatus>Rejected</RejectedStatus>
                      ) : null}
                      <Time>
                        {formatDistance(new Date(r.updatedOn), new Date(), { addSuffix: true })}
                      </Time>
                    </div>
                  </Cell>
                )
              })}
            </HistoryWrapper>
          ) : null}
        </ScrollableWrapper>
      </Container>
      <NFTButton
        $hide={!collapsed}
        onClick={() => {
          setCollapsed(false)
        }}
      >
        <NFTIcon src="/images/nft-icon.svg" alt="nft icon" />
        NFT requests
      </NFTButton>
      <RequestsModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        preselectedTab={preselectedTab}
      />
      <ConfirmModal
        openConfirmModal={openConfirmModal}
        setOpenConfirmModal={setOpenConfirmModal}
        selectedRequest={selectedRequest}
      />
      <RejectModal
        openRejectModal={openRejectModal}
        setOpenRejectModal={setOpenRejectModal}
        selectedRequest={selectedRequest}
      />
      <ProfileModal
        userId={selectedUser}
        openModal={openProfileModal}
        setOpenModal={() => {
          setOpenProfileModal(false)
          setSelectedUser(null)
        }}
      />
    </>
  )
}

const Header = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding-bottom: 10px;
`

const ScrollableWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 60px);
  padding-right: 15px;
  margin-top: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const Container = styled.div<{ $hide: boolean }>`
  background: ${colors.white};
  box-shadow: 0px 4px 13px rgba(86, 135, 244, 0.15);
  border-radius: 12px;
  padding: 20px 14px;
  position: fixed;
  top: 50px;
  right: 22px;
  bottom: 50px;
  width: 245px;

  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `}
`

const Title = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  display: inline-block;
  margin-bottom: 14px;
`

const RequestsWrapper = styled.div`
  border-bottom: 1px solid #dce9ff;
`

const Cell = styled.div<{ $history?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 2px;

  ${({ $history }) =>
    $history &&
    `
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      background: rgba(142, 177, 255, 0.15);
    }
  `}
`

const ImageWrapper = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const NFTName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  color: ${colors.blue900};
`

const Time = styled.p`
  font-size: 11px;
  line-height: 12px;
  margin: 2px 0;
  color: ${colors.grey800};
`

const HistoryWrapper = styled.div``

const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-bottom: 30px;
  margin-top: 7px;
`

const PlayerName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  color: ${colors.blue500};
  cursor: pointer;
`

const CrossIcon = styled.img`
  position: absolute;
  top: 29px;
  right: 20px;
  width: 10px;
  cursor: pointer;
`

const NFTButton = styled.span<{ $hide: boolean }>`
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  display: inline-block;
  width: 130px;
  font-size: 12px;
  text-align: right;

  &:hover {
    text-decoration: underline;
  }

  ${({ $hide }) =>
    $hide &&
    `
    display: none;
  `}
`
const NFTIcon = styled.img`
  width: 20px;
  margin-right: 8px;
`

const ApprovedStatus = styled.span`
  font-size: 12px;
  line-height: 12px;
  padding: 2px 6px;
  border-radius: 50px;
  margin-top: 6px;
  margin-bottom: 4px;
  display: inline-block;
  background: #1dac56;
  color: ${colors.white};
  padding-bottom: 4px;
`

const RejectedStatus = styled(ApprovedStatus)`
  background: #fd7c7c;
  color: ${colors.white};
  padding-bottom: 4px;
`

const ViewAll = styled(LinkButton)`
  float: right;
  margin-right: 27px;
  margin-top: 5px;
  text-transform: none;
`

const More = styled(ViewAll)`
  margin-right: 10px;
`

export default ManagerNFTRequestsSection
