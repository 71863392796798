import colors from '../../styles/colors'
import styled from 'styled-components'

const PageContainer = styled.div`
  background: ${colors.grey300};
  min-height: 100vh;
  width: calc(100% - 64px);
  display: inline-block;
  margin-left: 64px;

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding-bottom: 90px;
  }
`

export default PageContainer
