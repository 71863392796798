import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const getDashboardGames = async () => {
  const data = await API.authGet('game/dashboard')

  return data
}

const useGetDashboardGamesQuery = () => {
  return useQuery([QUERY_KEYS.DASHBOARD_GAMES], getDashboardGames, {
    staleTime: 60 * 1000,
  })
}

export default useGetDashboardGamesQuery
