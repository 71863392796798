import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

const fetchPayments = async (
  filters: number[] | null,
  startDate: string | null,
  endDate: string | null
) => {
  const params = filters?.map(f => `filter.PaymentRequestStatusIds=${f}`) || []
  const filterParam = params.join('&')
  const data = await API.authGet(
    `payment/request/all?${filterParam}${startDate ? `&filter.DateFrom=${startDate}` : ''}${
      endDate ? `&filter.DateTo=${endDate}` : ''
    }`
  )

  return data
}

const usePaymentsQuery = (
  filters: number[] | null,
  startDate: moment.Moment | null,
  endDate: moment.Moment | null
) => {
  const { user } = useUser()
  const sDate = startDate ? startDate.format('yyyy-MM-DDTHH:mm:ss') : null
  const eDate = endDate ? endDate.format('yyyy-MM-DDTHH:mm:ss') : null
  return useQuery({
    queryKey: [QUERY_KEYS.PAYMENTS, user?.id, filters, sDate, eDate],
    queryFn: () => fetchPayments(filters, sDate, eDate),
  })
}

export default usePaymentsQuery
