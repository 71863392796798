const TeamIcon = ({ color }: { color?: string }) => {
  return (
    <svg width="21" height="10" viewBox="0 0 21 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 5.625C11.9263 5.625 13.1862 5.95 14.21 6.375C15.155 6.775 15.75 7.675 15.75 8.65V10H5.25V8.65833C5.25 7.675 5.845 6.775 6.79 6.38333C7.81375 5.95 9.07375 5.625 10.5 5.625ZM3.5 5.83333C4.4625 5.83333 5.25 5.08333 5.25 4.16667C5.25 3.25 4.4625 2.5 3.5 2.5C2.5375 2.5 1.75 3.25 1.75 4.16667C1.75 5.08333 2.5375 5.83333 3.5 5.83333ZM4.48875 6.75C4.165 6.7 3.84125 6.66667 3.5 6.66667C2.63375 6.66667 1.81125 6.84167 1.0675 7.15C0.750498 7.27904 0.480308 7.49397 0.290516 7.76807C0.100724 8.04216 -0.000300725 8.36334 6.72432e-07 8.69167V10H3.9375V8.65833C3.9375 7.96667 4.13875 7.31667 4.48875 6.75ZM17.5 5.83333C18.4625 5.83333 19.25 5.08333 19.25 4.16667C19.25 3.25 18.4625 2.5 17.5 2.5C16.5375 2.5 15.75 3.25 15.75 4.16667C15.75 5.08333 16.5375 5.83333 17.5 5.83333ZM21 8.69167C21 8.01667 20.58 7.41667 19.9325 7.15C19.1652 6.83115 18.3371 6.66661 17.5 6.66667C17.1588 6.66667 16.835 6.7 16.5113 6.75C16.8612 7.31667 17.0625 7.96667 17.0625 8.65833V10H21V8.69167ZM10.5 0C11.9525 0 13.125 1.11667 13.125 2.5C13.125 3.88333 11.9525 5 10.5 5C9.0475 5 7.875 3.88333 7.875 2.5C7.875 1.11667 9.0475 0 10.5 0Z"
        fill={color || '#3B82F6'}
      />
    </svg>
  )
}

export default TeamIcon
