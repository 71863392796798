import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const getCredentials = async (gameId?: number) => {
  const data = await API.authGet(`game/credentials?gameId=${gameId}`)

  return data
}

const useGameCredentials = (gameId?: number) => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.GAME_CREDENTIALS, user.id, gameId], () => getCredentials(gameId), {
    staleTime: 60 * 1000,
    enabled: !!user.id && gameId !== undefined,
    retry: false,
  })
}

export default useGameCredentials
