import styled from 'styled-components'
import TeamIcon from '../../../../components/Icons/TeamIcon'
import colors from '../../../../styles/colors'
import useGetGameDetailsQuery from '../../queries/useGetGameDetailsQuery'
import Player from './components/Player'

const TeamAchivementsSection = () => {
  const { data: game } = useGetGameDetailsQuery()

  return (
    <Container>
      <Title>
        <TeamIcon color={colors.grey500} /> Players
        {game?.teamMembers ? `(${game?.teamMembers?.length})` : ''}
      </Title>
      <div>
        {game?.teamMembers?.map((member: any) => {
          return <Player key={member.userId} member={member} />
        })}
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: ${colors.white};
  border-radius: 12px;
  padding: 14px 24px;
  margin-top: 12px;
`

const Title = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 14px;
  letter-spacing: 0.15px;
  color: ${colors.grey500};

  & > svg {
    margin-right: 5px;
    margin-top: -3px;
  }
`

export default TeamAchivementsSection
