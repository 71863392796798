import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const getAllReadNotifications = async () => {
  const data = await API.authGet('notification?isUnread=false')

  return data
}

const useGetAllReadNotificationsQuery = () => {
  return useQuery([QUERY_KEYS.ALL_READ_NOTIFICATIONS], getAllReadNotifications)
}

export default useGetAllReadNotificationsQuery
