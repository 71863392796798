import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

const fetchTeamPayments = async () => {
  const data = await API.authGet(`payment/request/search/team`)

  return data
}

const useTeamPaymentsQuery = () => {
  const { user } = useUser()
  return useQuery({
    queryKey: [QUERY_KEYS.TEAM_PAYMENTS],
    queryFn: fetchTeamPayments,
    enabled: user?.userRoleName === 'Manager',
  })
}

export default useTeamPaymentsQuery
