import { Currency } from './currency'

interface Socials {
  discord?: string
  facebook?: string
  twitter?: string
}

export interface Country {
  id: number
  name: string
  continent: string
  region: string
  phoneExtension: string
}

export interface TeamMember {
  countryId: number
  discordAccount: string | null
  email: string
  facebookAccount: string | null
  firstName: string
  id: number
  lastName: string
  managerId: number | null
  phoneNumber: string | null
  platfromCurrency: Currency
  profilePictureUrl: string | null
  skillRating: number
  telegramAccount: string | null
  totalEarned: number
  totalEarnedBtc: number | null
  totalEarnedDollar: number | null
  totalEarnedEur: number | null
  twitterAccount: string | null
  userRoleId: number
}

export interface Team {
  id: number
  managerId: number
  members: TeamMember[]
  name: string
  teamDiscord: string
  teamTelegram: string
}

interface UserTeam {
  managerId?: number | null
  managerName?: string
  managerPhoto?: string
  id?: number
  telegram?: string
  discord?: string
  name?: string
}

export interface Balance {
  dollarAmount: number
  eurAmount: number
  btcAmount: number
}

export interface User {
  id?: number
  userName?: string
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  countryName?: string
  photo?: File | null
  socials?: Socials
  managerId?: number | null
  userRoleName?: string
  currency?: Currency
  team?: UserTeam
  balance?: Balance
  averageMonthlyWithdraw?: Balance
  lastMonthWithdraw?: Balance
  previewPhoto?: string
  digitalCards?: any
  payoutWallets?: any
  games?: any
  availableSLPAmount?: number
  loginCount?: number
}

export enum UserType {
  PLAYER = 'player',
  MANAGER = 'manager',
}

export interface UserServer {
  id: number
  userRoleName: string
  managerId: number | null
  loginCount: number
  email: string
  userName: string
  firstName: string
  lastName: string
  phoneNumber: string
  countryName: string
  platfromCurrency: Currency
  profilePictureUrl: string
  facebookAccount: string
  discordAccount: string
  telegramAccount: string
  twitterAccount: string
  balance: Balance
  averageMonthlyWithdraw: Balance
  lastMonthWithdraw: Balance
  managerName: string
  managerPictureUrl: string
  teamId: number | null
  teamTelegram: string
  teamDiscord: string
  teamName: string
  digitalCards: any
  payoutWallets: any
  games: any
  availableSLPAmount: number
}

export interface EditableUserServer {
  id: number
  userRoleName?: string
  email?: string
  userName?: string
  firstName: string
  lastName: string
  phoneNumber?: string
  countryId?: number
  facebookAccount?: string
  discordAccount?: string
  twitterAccount?: string
  platfromCurrency?: Currency
  photo?: File
}

export interface Earning {
  id: number
  userId: number
  paymentTypeName: string
  paymentStatusName: string
  amount: number
  currencyCode: string
  dollarAmount: number
  btcAmount: number
  eurAmount: number
  paymentDate: Date
  userFirstName?: string
  userLastName?: string
}

export interface AggregatedEarning {
  gameId: number
  gameName: string
  dollarAmount: number
  eurAmount: number
  btcAmount: number
}

export enum ROLES {
  MANAGER = 'Manager',
  PLAYER = 'Player',
}
