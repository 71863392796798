import Modal from '../Modal'
import styled from 'styled-components'
import Select from 'react-select'
import colors from '../../styles/colors'
import Input from '../Input'
import { useUser } from '../../contexts/AuthContext'
import { useEffect, useMemo, useState } from 'react'
import { selectCurrencyStyles, selectNetworkStyles } from '../../utils/dropdown'
import WalletComponent from '../../pages/Profile/components/CardDetailsSection/components/WalletComponent'
import useNetworksQuery from '../../pages/Profile/components/CardDetailsSection/queries/useNetworksQuery'
import { SecondaryButton, SubmitButton } from '../Button'
import useAvailableWithdrawCurrency from './queries/useAvailableWithdrawCurrency'
import useAddPaymentMutation from './queries/useAddPaymentMutation'
import { ClipLoader } from 'react-spinners'
import useEditPaymentMutation from './queries/useEditPaymentMutation'
import { ErrorMsg } from '../ErrorMessage'
import fonts from '../../styles/fonts'

interface WithdrawModalProps {
  openModal: boolean
  setOpenModal: (o: boolean) => void
  selectedWithdraw?: any
}

const WITHDRAW_OPTIONS = [
  // {
  //   value: 'Card',
  //   label: 'Card',
  // },
  {
    value: 'Web 3 wallet',
    label: 'Web 3 wallet',
  },
]

const MINIMUM_SLP = 50

const WithdrawModal = ({ openModal, setOpenModal, selectedWithdraw }: WithdrawModalProps) => {
  const { user } = useUser()
  const { data: networks } = useNetworksQuery()
  const { data: availableCurrencies } = useAvailableWithdrawCurrency()
  const [amount, setAmount] = useState<string>('')
  const [selectedWithdrawCurrency, setSelectedWithdrawCurrency] = useState<any>(null)
  const [selectedWalletId, setSelectedWalletId] = useState<number | null>(null)
  const [selectedWalletAddress, setSelectedWalletAddress] = useState<string | null>(null)
  const [selectedWithdrawOption, setSelectedWithdrawOption] = useState<any>(WITHDRAW_OPTIONS[0])
  const [showError, setShowError] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)

  const { mutateAsync: addPayment, isLoading } = useAddPaymentMutation()
  const { mutateAsync: editPayment, isLoading: isEditLoading } = useEditPaymentMutation()

  const handleAmountChange = (e: any) => {
    if (showError) {
      setShowError(false)
    }
    setAmount(e.target.value)
  }

  const withdrawCurrencies = useMemo(() => {
    return availableCurrencies?.map((c: any) => {
      return {
        ...c,
        label: c.name,
        value: c.id,
      }
    })
  }, [availableCurrencies])

  useEffect(() => {
    setSelectedWithdrawCurrency(withdrawCurrencies?.[0])
  }, [withdrawCurrencies])

  useEffect(() => {
    if (selectedWithdraw) {
      setAmount(selectedWithdraw.amount)
      setSelectedWithdrawCurrency(withdrawCurrencies?.[1])
      const wallet = user?.payoutWallets?.filter(
        (w: any) => w.publicAddress === selectedWithdraw.address
      )?.[0]
      setSelectedWalletId(wallet?.id)
      setSelectedWalletAddress(wallet?.publicAddress)
      const withdrawCurrency = withdrawCurrencies?.filter(
        (w: any) => w.label === selectedWithdraw.currencyCode
      )?.[0]
      setSelectedWithdrawCurrency(withdrawCurrency)
    }
  }, [selectedWithdraw])

  return (
    <>
      <Modal bigSize title={'Withdraw'} open={openModal} setOpen={setOpenModal} noPadding={true}>
        <ModalBody>
          <FirstRow>
            <div>
              <Label>Available Amount</Label>
              <AvailableAmount>SLP {user?.availableSLPAmount || 0}</AvailableAmount>
            </div>
            <div>
              <Input
                big
                label="Amount*"
                type="number"
                onChange={handleAmountChange}
                value={amount}
                noMargin
              />
            </div>
            <div>
              <Label>Receive as*</Label>
              <Select
                id="receive-option"
                options={withdrawCurrencies}
                value={selectedWithdrawCurrency}
                styles={selectCurrencyStyles}
                isSearchable={false}
                onChange={e => {
                  if (e) {
                    setSelectedWithdrawCurrency(e)
                  }
                }}
              />
            </div>
          </FirstRow>
          <SecondRow>
            <Label>Withdraw to*</Label>
            <Select
              id="withdraw-option"
              options={WITHDRAW_OPTIONS}
              value={selectedWithdrawOption}
              styles={selectNetworkStyles}
              isSearchable={false}
              onChange={e => {
                if (e) {
                  setSelectedWithdrawOption(e)
                }
              }}
            />
          </SecondRow>
          <ThirdRow>
            {selectedWithdrawOption.value === WITHDRAW_OPTIONS[0]?.value ? (
              <InnerWrapper>
                {user?.payoutWallets?.map((p: any) => {
                  const net = networks?.filter((network: any) => {
                    return network.id === p.networkId
                  })?.[0]

                  return (
                    <ClickableArea
                      key={p.id}
                      $selected={selectedWalletId === p.id}
                      $hasSelection={selectedWalletId !== null}
                      onClick={() => {
                        if (selectedWalletId === p.id) {
                          setSelectedWalletId(null)
                          setSelectedWalletAddress(null)
                        } else {
                          setSelectedWalletId(p.id)
                          setSelectedWalletAddress(p.publicAddress)
                        }
                      }}
                    >
                      <WalletComponent
                        id={p.id}
                        name={p.name}
                        publicAddress={p.publicAddress}
                        backgroundImageUrl={p.backgroundImageUrl}
                        network={net?.name || ''}
                        noDots
                        isSelected={selectedWalletId === p.id}
                      />
                    </ClickableArea>
                  )
                })}
              </InnerWrapper>
            ) : (
              <div>
                <VirtualCard>
                  <img src="/images/va-card-logo.svg" alt="va logo" />
                </VirtualCard>
              </div>
            )}
          </ThirdRow>
          {showError ? (
            <div>
              <ErrorMsg>Something went wrong, please try again later!</ErrorMsg>
            </div>
          ) : null}
          {selectedWithdrawCurrency?.label === 'SLP' ? (
            <MessageWrapper>
              <Message>Minimum amount to withdraw: SLP {MINIMUM_SLP}</Message>
            </MessageWrapper>
          ) : null}
          {amount &&
          Number(amount) >= MINIMUM_SLP &&
          Number(amount) <= (user?.availableSLPAmount || 0) ? (
            <MessageWrapper>
              <Message>You will receive: SLP {((Number(amount) * 40) / 100).toFixed(2)} </Message>
            </MessageWrapper>
          ) : null}
          <ButtonRow>
            <Reset
              onClick={() => {
                setAmount('')
                setSelectedWithdrawCurrency(withdrawCurrencies?.[0])
                setSelectedWithdrawOption(WITHDRAW_OPTIONS[0])
              }}
            >
              RESET
            </Reset>
            <SubmitButton
              disabled={
                !amount ||
                Number(amount) > (user?.availableSLPAmount || 0) ||
                selectedWalletId === null ||
                Number(amount) < MINIMUM_SLP
              }
              onClick={async () => {
                setOpenModal(false)
                setOpenConfirmModal(true)
              }}
            >
              SUBMIT
            </SubmitButton>
          </ButtonRow>
        </ModalBody>
      </Modal>

      {openConfirmModal ? (
        <Modal bigSize open={openConfirmModal} setOpen={setOpenConfirmModal} noPadding={true}>
          <ModalBody>
            <ModalText>
              Are you sure you would like to withdraw <strong>SLP {amount}</strong> to the following
              address <strong>{selectedWalletAddress}</strong>?
            </ModalText>
            <Buttons>
              <SecondaryButton
                onClick={() => {
                  setOpenConfirmModal(false)
                  setOpenModal(true)
                }}
              >
                Cancel
              </SecondaryButton>
              <SubmitButton
                disabled={isLoading || isEditLoading}
                onClick={async () => {
                  const numAmount = Number(amount)
                  if (
                    numAmount <= (user?.availableSLPAmount || 0) &&
                    selectedWalletId !== null &&
                    (!isLoading || !isEditLoading)
                  ) {
                    if (!selectedWithdraw) {
                      try {
                        await addPayment({
                          amount: numAmount,
                          payoutWalletId: selectedWalletId,
                          currencyCodeId: selectedWithdrawCurrency.id,
                        })
                        setAmount('')
                        setSelectedWithdrawCurrency(withdrawCurrencies?.[0])
                        setSelectedWithdrawOption(WITHDRAW_OPTIONS[0])
                        setOpenModal(false)
                        setOpenConfirmModal(false)
                      } catch (e) {
                        setShowError(true)
                      }
                    } else {
                      try {
                        await editPayment({
                          id: selectedWithdraw.id,
                          amount: numAmount,
                          payoutWalletId: selectedWalletId,
                          currencyCodeId: selectedWithdrawCurrency.id,
                        })
                        setAmount('')
                        setSelectedWithdrawCurrency(withdrawCurrencies?.[0])
                        setSelectedWithdrawOption(WITHDRAW_OPTIONS[0])
                        setOpenModal(false)
                        setOpenConfirmModal(false)
                      } catch (e) {
                        setShowError(true)
                      }
                    }
                  }
                }}
              >
                <ClipLoader
                  color={colors.white}
                  loading={isLoading || isEditLoading}
                  cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                  size={20}
                  aria-label="Loading Spinner"
                />
                Approve
              </SubmitButton>
            </Buttons>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  )
}

const ModalBody = styled.div`
  padding: 45px;
`

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
`

const SecondRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  padding-bottom: 50px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(163, 198, 255, 0.5);
`
const ThirdRow = styled.div`
  height: 132px;
  overflow: hidden;
`

const InnerWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const Label = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 4px;
  display: inline-block;
`

const AvailableAmount = styled.div`
  background: rgba(29, 172, 86, 0.31);
  border-radius: 36px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.black};
  text-align: center;
  height: 32px;
  padding: 8px 15px 0;
`

const VirtualCard = styled.div`
  background: linear-gradient(64.36deg, #032960 7.02%, #5687f4 93.91%);
  border-radius: 12px;
  height: 160px;
  padding-top: 10px;
  text-align: center;
  overflow: hidden;
  max-width: 320px;
  margin: 0 auto;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
`

const Reset = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  letter-spacing: 0.46px;
  text-transform: uppercase;
  cursor: pointer;
  color: #5282ec;
`

const ClickableArea = styled.div<{ $selected?: boolean; $hasSelection?: boolean }>`
  cursor: pointer;

  ${({ $hasSelection, $selected }) =>
    $hasSelection &&
    !$selected &&
    `
    opacity: 0.5;
  `}
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};
  text-align: center;

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`

const MessageWrapper = styled.div`
  padding-top: 8px;
`

const Message = styled.div`
  font-size: 12px;
  line-height: 14px;
  background: #ffcb46;
  color: ${colors.blue900};
  display: inline-block;
  padding: 4px;
  border-radius: 2px;
`

export default WithdrawModal
