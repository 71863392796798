import { useMutation } from '@tanstack/react-query'
import API from '../../../api'

export interface SignupParams {
  firstName: string
  lastName: string
  userName: string
  email: string
  password: string
  countryId: number
  phoneNumber?: string
  referralCode?: string
  registrationKey?: string
}

const signup = async (body: SignupParams) => {
  const data = await API.post('auth/register', body)

  return data
}

const useSignupMutation = () => {
  return useMutation((body: SignupParams) => signup(body))
}

export default useSignupMutation
