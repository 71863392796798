import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useUser } from '../../../../contexts/AuthContext'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import useCountriesQuery from '../../../../queries/useCountries'
import colors from '../../../../styles/colors'
import { Currency } from '../../../../types/currency'
import { EditableUserServer } from '../../../../types/user'
import { getCountryIdByName } from '../../../../utils/user'
import useEditProfile from '../ProfileDetails/queries/useEditProfile'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'
import BitcoinSign from './components/BitcoinSign'
import DollarSign from './components/DollarSign'
import EuroSign from './components/EuroSign'

interface CurrencySectionProps {
  mask?: boolean
}

const CurrencySection = ({ mask }: CurrencySectionProps) => {
  const { currency, setCurrency } = useCurrency()
  const { user } = useUser()
  const [changedCurrency, setChangedCurrency] = useState<Currency>(currency)
  const { mutateAsync: editProfile } = useEditProfile()
  const { data: countries } = useCountriesQuery()

  useEffect(() => {
    if (currency) {
      setChangedCurrency(currency)
    }
  }, [currency, setChangedCurrency])

  const handleSubmit = async (newCurrency: Currency) => {
    if (user && user.id) {
      const newUser: EditableUserServer = {
        id: user.id,
        email: user.email,
        userName: user.userName,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phoneNumber: user.phone || '',
        countryId: getCountryIdByName(countries || [], user.countryName || '') || 0,
        platfromCurrency: newCurrency,
      }

      if (user.socials?.twitter) {
        newUser.twitterAccount = user.socials?.twitter
      }
      if (user.socials?.discord) {
        newUser.discordAccount = user.socials?.discord
      }
      if (user.socials?.facebook) {
        newUser.facebookAccount = user.socials?.facebook
      }

      if (user.photo) {
        newUser.photo = user.photo
      }

      await editProfile({
        user: newUser,
      })

      setCurrency(newCurrency)
      setChangedCurrency(newCurrency)
    }
  }

  return (
    <Container>
      <CurrencySectionContainer $mask={mask}>
        <SectionTitle>Currency</SectionTitle>
        <div>
          <Cell>
            <InnerWrapper
              onClick={() => {
                if (changedCurrency !== Currency.USD) {
                  handleSubmit(Currency.USD)
                }
              }}
              $selected={changedCurrency === Currency.USD}
            >
              <DollarSign
                color={changedCurrency === Currency.USD ? colors.white : colors.blue450}
              />
              <div>
                <CurrencyLabel
                  $selected={changedCurrency === Currency.USD}
                  data-test-id={
                    changedCurrency === Currency.USD ? `selected-usd-label` : 'usd-label'
                  }
                >
                  USD
                </CurrencyLabel>
              </div>
            </InnerWrapper>
          </Cell>
          <Cell>
            <InnerWrapper
              onClick={() => {
                if (changedCurrency !== Currency.EURO) {
                  handleSubmit(Currency.EURO)
                }
              }}
              $selected={changedCurrency === Currency.EURO}
            >
              <EuroSign color={changedCurrency === Currency.EURO ? colors.white : colors.blue450} />
              <div>
                <CurrencyLabel
                  $euro
                  $selected={changedCurrency === Currency.EURO}
                  data-test-id={
                    changedCurrency === Currency.EURO ? `selected-euro-label` : 'euro-label'
                  }
                >
                  EUR
                </CurrencyLabel>
              </div>
            </InnerWrapper>
          </Cell>
          <Cell>
            <InnerWrapper
              onClick={() => {
                if (changedCurrency !== Currency.BITCOIN) {
                  handleSubmit(Currency.BITCOIN)
                }
              }}
              $selected={changedCurrency === Currency.BITCOIN}
            >
              <BitcoinSign
                color={changedCurrency === Currency.BITCOIN ? colors.white : colors.blue450}
              />
              <div>
                <CurrencyLabel
                  $selected={changedCurrency === Currency.BITCOIN}
                  data-test-id={
                    changedCurrency === Currency.BITCOIN
                      ? `selected-bitcoin-label`
                      : 'bitcoin-label'
                  }
                >
                  Bitcoin
                </CurrencyLabel>
              </div>
            </InnerWrapper>
          </Cell>
        </div>
      </CurrencySectionContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 25%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  height: 315px;

  @media screen and (max-width: 1300px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const CurrencySectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  height: 100%;
`

const Cell = styled.div`
  width: 33.333%;
  display: inline-block;
  padding: 5px;
  text-align: center;
  vertical-align: top;
  margin-top: 25px;
`

const InnerWrapper = styled.div<{ $selected: boolean }>`
  margin-bottom: 20px;
  border-radius: 8px;
  padding: 20px 0;
  height: 120px;
  transition: all 0.4s;
  cursor: pointer;
  background: ${colors.blue200}9C;

  &:hover {
    background: ${colors.blue300}9C;
  }

  ${({ $selected }) =>
    $selected &&
    `
    background: ${colors.blue500};

    &:hover {
      background: ${colors.blue500};
    }
  `}
`

const CurrencyLabel = styled.span<{ $euro?: boolean; $selected?: boolean }>`
  color: ${colors.blue450};
  font-size: 16px;
  line-height: 16px;
  margin-top: 10px;
  display: inline-block;
  transition: all 0.4s;

  ${({ $euro }) =>
    $euro &&
    `
    margin-top: 14px;
  `}

  ${({ $selected }) =>
    $selected &&
    `
    color: ${colors.white};
  `}
`

export default CurrencySection
