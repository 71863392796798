import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

interface RejectPaymentParams {
  id: number
}

const rejectPayment = async (body: RejectPaymentParams) => {
  const data = await API.authPost('payment/request/reject', {
    ...body,
  })

  return data
}

const useRejectPaymentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: RejectPaymentParams) => rejectPayment(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TEAM_PAYMENTS] })
    },
  })
}

export default useRejectPaymentMutation
