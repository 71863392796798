import { HTMLProps } from 'react'
import styled from 'styled-components'
import colors from '../../styles/colors'
import Popup from '../Popup'

interface InputProps {
  label?: string
  reactRef?: any
  big?: boolean
  error?: boolean
  noMargin?: boolean
  errorMsg?: string
  icon?: string
  labelColor?: string
  maxWidth?: number
  paddingLeft?: number
  noLabelMobile?: boolean
}

const Input = ({
  value,
  placeholder,
  label,
  labelColor,
  noLabelMobile,
  id,
  type,
  disabled,
  onChange,
  reactRef,
  error,
  errorMsg,
  big,
  icon,
  noMargin,
  maxWidth,
  paddingLeft,
  onFocus,
  onBlur,
}: InputProps & HTMLProps<HTMLInputElement>) => {
  return (
    <InputContainer $maxWidth={maxWidth}>
      {label && (
        <Label htmlFor={id} $big={big} $noLabelMobile={noLabelMobile} $labelColor={labelColor}>
          {label}
        </Label>
      )}
      <HTMLInput
        id={id}
        ref={reactRef}
        value={value}
        type={type || 'text'}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        data-test-id={id}
        $error={error}
        $noMargin={noMargin}
        $big={big}
        $icon={!!icon}
        $paddingLeft={paddingLeft}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {!!icon ? <Icon src={icon} alt={icon} /> : null}
      {error && errorMsg ? (
        <ErrorMsg>
          <ErrorIcon src="/images/error.svg" alt="error" />
          <Popup open={true} input>
            <ErrorMessage>{errorMsg}</ErrorMessage>
          </Popup>
        </ErrorMsg>
      ) : null}
    </InputContainer>
  )
}

const HTMLInput = styled.input<{
  $error?: boolean
  $big?: boolean
  $icon?: boolean
  $paddingLeft?: number
  $noMargin?: boolean
}>`
  width: 100%;
  border: 0;
  border-radius: 6px;
  padding: 3px 8px;
  font-size: 12px;
  color: ${colors.black};
  background: ${colors.blue100};
  border: 1px solid transparent;
  outline: none;
  margin-bottom: 18px;

  &:disabled {
    color: ${colors.grey800};
    background: ${colors.grey100};
  }

  &::placeholder {
    color: transparent;

    @media screen and (max-width: 768px) {
      color: ${colors.white};
    }
  }

  &:hover {
    border: 1px solid ${colors.blue500};
  }

  &:focus {
    border: 1px solid ${colors.blue500};
  }
  @media screen and (max-width: 768px) {
    background: rgba(86, 135, 244, 0.35);
    color: ${colors.white};
  }

  ${({ $noMargin }) =>
    $noMargin &&
    `
    margin-bottom: 0;
  `}
  ${({ $big }) =>
    $big &&
    `
    padding: 4px 8px;
    line-height: 22px;
    @media screen and (max-width: 768px) {
      line-height: 32px;
    }
  `}

  ${({ $icon }) =>
    $icon &&
    `
    padding-left: 30px;
  `}

  ${({ $error }) =>
    $error &&
    `
    border: 1px solid ${colors.red300};
    color: ${colors.red300};
    padding-right: 28px;
  `}

  ${({ $paddingLeft }) =>
    $paddingLeft &&
    `
    padding-left: ${$paddingLeft}px;
  `}
`

const Label = styled.label<{ $labelColor?: string; $big?: boolean; $noLabelMobile?: boolean }>`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 4px;

  ${({ $labelColor }) =>
    $labelColor &&
    `
    color: ${$labelColor};
  `}

  ${({ $big }) =>
    $big &&
    `
    font-size: 12px;
  `}

  ${({ $noLabelMobile }) =>
    $noLabelMobile &&
    `
    @media screen and (max-width: 768px) {
      display: none;
    }
  `}
`

const ErrorIcon = styled.img`
  width: 18px;
  height: 18px;
`

const ErrorMsg = styled.div`
  position: absolute;
  bottom: 30px;
  width: 18px;
  right: 5px;
  cursor: pointer;
  height: 18px;

  & > div {
    display: none;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`

const InputContainer = styled.div<{ $maxWidth?: number }>`
  position: relative;

  ${({ $maxWidth }) =>
    $maxWidth &&
    `
    max-width: ${$maxWidth}px;
  `}
`

const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${colors.red300};
`

const Icon = styled.img`
  width: 16px;
  position: absolute;
  bottom: 26px;
  left: 8px;
`

export default Input
