import styled from 'styled-components'
import colors from '../../../../styles/colors'

const SectionContainer = styled.div<{ $editable?: boolean; $mask?: boolean }>`
  background: ${colors.white};
  padding: 26px 0 16px;
  transition: all 0.4s;

  &:hover {
    box-shadow: 0px 4px 25px rgba(59, 130, 246, 0.15);
  }

  ${({ $editable }) =>
    $editable &&
    `
    box-shadow: 0px 4px 25px rgba(59, 130, 246, 0.15);
  `}

  ${({ $mask }) =>
    $mask &&
    `
    opacity: 0.35;
    mix-blend-mode: luminosity;

    &:hover {
      box-shadow: none;
    }
  `}

  @media screen and (max-width: 768px) {
    background: transparent;
  }
`

export default SectionContainer
