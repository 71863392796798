import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const getAutocompletes = async (
  gameId: string,
  search: string,
  players: string,
  startDate: string,
  endDate: string,
  status: string
) => {
  const requestsStatus =
    status === 'pending'
      ? '&filter.NftRequestStatusIds=1'
      : '&filter.NftRequestStatusIds=3&filter.NftRequestStatusIds=4'
  const data = await API.authGet(
    `nft/request/search/team/autocomplete?filter.GameId=${gameId}&filter.NftSearchValuesString=${encodeURIComponent(
      search
    )}${players}${requestsStatus}${startDate ? `&filter.DateFrom=${startDate}` : ''}${
      endDate ? `&filter.DateTo=${endDate}` : ''
    }`
  )

  return data
}

interface Params {
  game?: string
  search?: string
  players?: string
  status: string
  startDate: string | null
  endDate: string | null
}

const useNFTRequestsAutocompleteQuery = ({
  game,
  search,
  players,
  status,
  startDate,
  endDate,
}: Params) => {
  return useQuery(
    [
      QUERY_KEYS.NFT_REQUESTS_AUTOCOMPLETES,
      game || '1',
      search || '',
      players || '',
      startDate || '',
      endDate || '',
      status,
    ],
    () =>
      getAutocompletes(
        game || '1',
        search || '',
        players || '',
        startDate || '',
        endDate || '',
        status
      ),
    {
      staleTime: 60 * 1000 * 60,
    }
  )
}

export default useNFTRequestsAutocompleteQuery
