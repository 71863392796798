import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import { useUser } from '../../contexts/AuthContext'
import useGetAllGamesQuery from '../../queries/useGetAllGamesQuery'
import colors from '../../styles/colors'
import GamesSection from './components/GamesSection'
import NFTRequestsSection from './components/NFTRequestsSection'
import NFTsSection from './components/NFTsSection'
import 'bootstrap/dist/css/bootstrap.min.css'
import ManagerNFTRequestsSection from './components/ManagerNFTRequestsSection'
import { useState } from 'react'
import SearchSection from './components/SearchSection'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'

const MarketplacePage = () => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const [collapsed, setCollapsed] = useState<boolean>(false)

  const { data: games } = useGetAllGamesQuery()

  const { user, isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer>
        <AlphaBanner />
        <Wrapper>
          <GamesWrapper $expand={collapsed}>
            <GamesSection games={games} activeGame={gameQuery || '1'} />
            <SearchSection marketplace />
            <NFTsSection />
          </GamesWrapper>
          <RequestsWrapper $hide={collapsed}>
            {user.userRoleName === 'Manager' ? (
              <ManagerNFTRequestsSection collapsed={collapsed} setCollapsed={setCollapsed} />
            ) : (
              <NFTRequestsSection collapsed={collapsed} setCollapsed={setCollapsed} />
            )}
          </RequestsWrapper>
        </Wrapper>
      </PageContainer>
    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 22px;
  background: ${colors.white};
  border-radius: 12px;
  min-height: calc(100vh - 48px);
  display: flex;
  align-items: flex-start;
`

const GamesWrapper = styled.div<{ $expand: boolean }>`
  width: calc(100% - 260px);
  transition: all 0.4s;

  ${({ $expand }) =>
    $expand &&
    `
    width: 100%;
  `}

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const RequestsWrapper = styled.div<{ $hide: boolean }>`
  width: 260px;
  transition: all 0.4s;
  position: relative;

  ${({ $hide }) =>
    $hide &&
    `
    width: 0;
  `}
`

export default MarketplacePage
