import { Currency } from '../types/currency'
import { Country, User, UserServer } from '../types/user'

export const beautifyUser = (userData: UserServer): User => {
  if (userData) {
    return {
      id: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      userName: userData.userName,
      phone: userData.phoneNumber,
      countryName: userData.countryName,
      previewPhoto: userData.profilePictureUrl,
      loginCount: userData.loginCount,
      socials: {
        discord: userData.discordAccount,
        facebook: userData.facebookAccount,
        twitter: userData.twitterAccount,
      },
      userRoleName: userData.userRoleName,
      currency: userData.platfromCurrency || Currency.USD,
      team: {
        managerId: userData.managerId,
        managerName: userData.managerName,
        managerPhoto: userData.managerPictureUrl,
        id: userData.teamId || undefined,
        telegram: userData.teamTelegram,
        discord: userData.teamDiscord,
        name: userData.teamName,
      },
      balance: {
        dollarAmount: userData.balance?.dollarAmount,
        eurAmount: userData.balance?.eurAmount,
        btcAmount: userData.balance?.btcAmount,
      },
      averageMonthlyWithdraw: {
        dollarAmount: userData.averageMonthlyWithdraw?.dollarAmount,
        eurAmount: userData.averageMonthlyWithdraw?.eurAmount,
        btcAmount: userData.averageMonthlyWithdraw?.btcAmount,
      },
      lastMonthWithdraw: {
        dollarAmount: userData.lastMonthWithdraw?.dollarAmount,
        eurAmount: userData.lastMonthWithdraw?.eurAmount,
        btcAmount: userData.lastMonthWithdraw?.btcAmount,
      },
      digitalCards: userData.digitalCards,
      payoutWallets: userData.payoutWallets,
      games: userData.games,
      availableSLPAmount: userData.availableSLPAmount,
    }
  }

  return {}
}

export const getDefaultAmount = (id: number, multiplier: number): number => {
  if (window.backEndConfig.environment === 'Production') {
    return 0
  }
  return id * multiplier
}

export const getCountryIdByName = (countries: Country[], countryName: string): number | null => {
  return (
    countries.filter(country => {
      return countryName === country.name
    })?.[0]?.id || null
  )
}
