import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

interface ReferralParams {
  name: string
  id: number
}

const updateReferral = async (body: ReferralParams) => {
  const data = await API.authPut('referral', {
    ...body,
  })

  return data
}

const useUpdateReferralMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: ReferralParams) => updateReferral(body), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGER_REFERRALS])
    },
  })
}

export default useUpdateReferralMutation
