import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { OverlayTrigger, Popover, PopoverBody, PopoverHeader } from 'react-bootstrap'
import styled from 'styled-components'
import { VictoryPie } from 'victory'
import { colorScale } from '../../../../../components/ProfileModal/components/EarningsSection'
import useProfileQuery from '../../../../../components/ProfileModal/queries/useProfileQuery'
import { useCurrency } from '../../../../../contexts/CurrencyContext'
import useUserEarningsAggregatedQuery from '../../../../../queries/useUserEarningsAggregatedQuery'
import useUserNFTQuery from '../../../../../queries/useUserNFTQuery'
import colors from '../../../../../styles/colors'
import fonts from '../../../../../styles/fonts'
import { Currency, CurrencySymbol } from '../../../../../types/currency'
import { AggregatedEarning, Balance } from '../../../../../types/user'
import { CurrencyHTML } from '../../../../Profile/components/EarningsSection/components/EarningTile'

const UserInfoOverlay = ({
  children,
  userId,
}: PropsWithChildren & {
  userId: number
}) => {
  const { data: userData } = useProfileQuery(userId)
  const { data: earnings } = useUserEarningsAggregatedQuery(userId)
  const { data: nfts } = useUserNFTQuery(userId)
  const [hoveredElement, setHoveredElement] = useState<number | null>(null)

  const { currency } = useCurrency()
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const getEarnedAmout = useCallback(
    (member: any) => {
      if (currency === Currency.BITCOIN) {
        return `${CurrencySymbol.BITCOIN} ${+parseFloat(
          member?.totalEarned?.btcAmount?.toFixed?.(5)
        )}`
      }
      if (currency === Currency.EURO) {
        return `${CurrencySymbol.EURO} ${+parseFloat(member?.totalEarned?.eurAmount?.toFixed?.(2))}`
      }
      return `${CurrencySymbol.USD} ${+parseFloat(member?.totalEarned?.dollarAmount?.toFixed?.(2))}`
    },
    [currency]
  )
  const defaultTotal = {
    dollarAmount: 0,
    eurAmount: 0,
    btcAmount: 0,
  }

  const totalEarned =
    earnings?.reduce((acc: Balance, nextValue: AggregatedEarning) => {
      return {
        dollarAmount: acc.dollarAmount + nextValue.dollarAmount,
        eurAmount: acc.eurAmount + nextValue.eurAmount,
        btcAmount: acc.btcAmount + nextValue.btcAmount,
      }
    }, defaultTotal) || defaultTotal

  const calculatePercentage = (amount: number) => {
    const onePerc = totalEarned.dollarAmount / 100
    const perc = amount / onePerc

    return perc.toFixed(1)
  }
  const chartData = useMemo(() => {
    return earnings?.map(earning => {
      return {
        x: () => null,
        y: earning.dollarAmount,
      }
    })
  }, [earnings])

  const popover = (
    <Popover
      style={{
        background: colors.white,
        boxShadow: '0px 4px 10px rgba(86, 135, 244, 0.29)',
        borderRadius: '8px',
        border: 0,
        width: '230px',
        zIndex: 99999,
      }}
    >
      <PopoverHeader
        style={{
          background: '#E6EDFD',
          padding: 0,
          borderBottom: 0,
        }}
      >
        <Header>
          <UserProfileImage src={userData?.profilePictureUrl || '/images/avatar.svg'} alt="" />
          <div>
            <UserNameLabel>
              {userData?.firstName} {userData?.lastName}
            </UserNameLabel>
            <div>
              <AwardWrapper>
                <AwardIcon src="/images/award.svg" alt="award icon" />
                {userData?.skillRating || 0}
              </AwardWrapper>
              <BanknoteIcon src="/images/banknote.svg" alt="banknote icon" />
              <Amount>{getEarnedAmout(userData)}</Amount>
            </div>
          </div>
        </Header>
      </PopoverHeader>
      <PopoverBody
        style={{
          padding: 0,
        }}
      >
        <Tabs>
          <Tab
            $selected={selectedTab === 0}
            onClick={() => {
              setSelectedTab(0)
            }}
          >
            NFTS
          </Tab>
          <Tab
            $selected={selectedTab === 1}
            onClick={() => {
              setSelectedTab(1)
            }}
          >
            EARNINGS
          </Tab>
        </Tabs>
        <ContentWrapper>
          {selectedTab === 0 ? (
            <NFTS>
              {nfts?.slice(0, 8)?.map((nft: any) => {
                return (
                  <NFTCell key={nft.id}>
                    <ImageWrapper>
                      <NFTImage src={nft.imageUrl} alt={nft.name} />
                    </ImageWrapper>
                    <NFTDetails>
                      <NFTTitle>{nft.name}</NFTTitle>
                    </NFTDetails>
                  </NFTCell>
                )
              })}
            </NFTS>
          ) : null}
          {selectedTab === 1 ? (
            <>
              {earnings && earnings.length > 0 ? (
                <ChartCell>
                  <VictoryPie
                    innerRadius={130}
                    padAngle={1}
                    data={chartData}
                    colorScale={colorScale}
                    padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
                    events={[
                      {
                        target: 'data',
                        eventHandlers: {
                          onMouseOver: (prop, data) => {
                            setHoveredElement(data.index)
                            return [
                              {
                                target: 'data',
                                mutation: ({ style }) => {
                                  return style.fill === `${colorScale[data.index]}99`
                                    ? null
                                    : { style: { fill: `${colorScale[data.index]}99` } }
                                },
                              },
                            ]
                          },
                          onMouseOut: (prop, data) => {
                            setHoveredElement(null)
                            return [
                              {
                                target: 'data',
                                mutation: ({ style }) => {
                                  return style.fill === `${colorScale[data.index]}99`
                                    ? null
                                    : { style: { fill: `${colorScale[data.index]}99` } }
                                },
                              },
                            ]
                          },
                        },
                      },
                    ]}
                  />
                  <AllAmount>
                    <CurrencyHTML currency={currency} earning={totalEarned} />
                  </AllAmount>
                </ChartCell>
              ) : null}
              {earnings?.map((earning, index) => {
                return (
                  <Cell key={earning.gameId}>
                    <InnerCell $hovered={index === hoveredElement}>
                      <GameName $hovered={index === hoveredElement}>
                        <Color $index={index} />
                        {earning.gameName}
                      </GameName>
                      <GamePerc>{calculatePercentage(earning.dollarAmount)}%</GamePerc>
                    </InnerCell>
                  </Cell>
                )
              })}
            </>
          ) : null}
        </ContentWrapper>
      </PopoverBody>
    </Popover>
  )

  return (
    <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
      {children as any}
    </OverlayTrigger>
  )
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 10px;
`

const UserProfileImage = styled.img`
  width: 32px;
  height: 32px;
  vertical-align: middle;
  border-radius: 50%;
`

const UserNameLabel = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue900};
  font-weight: 700;
  margin: 0 0 2px;
  font-family: ${fonts.fontFamilyBold};
`

const AwardIcon = styled.img`
  margin-right: 0;
  margin-bottom: -1px;
`

const BanknoteIcon = styled.img`
  margin-right: 5px;
  margin-top: -1px;
`

const AwardWrapper = styled.span`
  border-right: 1px solid #c9daff;
  padding-right: 5px;
  margin-right: 5px;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.blue900};
`

const Amount = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.blue900};
`

const ContentWrapper = styled.div`
  padding: 5px;
`

const Tabs = styled.div`
  margin-bottom: 10px;
`

const Tab = styled.button<{ $selected?: boolean }>`
  background: transparent;
  border: 0;
  font-size: 12px;
  line-height: 40px;
  letter-spacing: 0.15px;
  color: ${colors.blue500};
  width: 50%;
  border-bottom: 1px solid ${colors.blue300};
  transition: all 0.4s;

  ${({ $selected }) =>
    $selected &&
    `
    font-family: ${fonts.fontFamilyBold};
    border-bottom: 2px solid ${colors.blue500};
  `}
`

const NFTS = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`

const NFTCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(50% - 4px);
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const ImageWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-right: 7px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`

const NFTDetails = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 50px);
`

const NFTTitle = styled.div`
  color: ${colors.blue900};
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
`

const Cell = styled.div`
  margin-bottom: 7px;
`

const InnerCell = styled.div<{ $hovered?: boolean }>`
  padding-bottom: 5px;
  transition: 0.4s all;
  border-radius: 6px;
  padding-top: 3px;
  border: 1px solid ${colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;

  ${({ $hovered }) =>
    $hovered &&
    `
    background: rgba(86, 135, 244, 0.15);
    border: 1px solid rgba(86, 135, 244, 0.5);
  `}
`

const Color = styled.div<{ $index: number }>`
  display: inline-block;
  width: 23px;
  height: 13px;
  border-radius: 4px;
  margin-right: 7px;
  margin-left: 5px;
  margin-top: -2px;
  vertical-align: middle;

  ${({ $index }) => `
    background: ${colorScale[$index]};
  `}
`

const GameName = styled.div<{ $hovered?: boolean }>`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};

  ${({ $hovered }) =>
    $hovered &&
    `
    color: ${colors.blue500};
  `}
`

const GamePerc = styled.div`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
`

const ChartCell = styled.div`
  position: relative;
  padding: 10px 40px 30px;
`

const AllAmount = styled.p`
  position: absolute;
  top: calc(50% - 24px);
  text-align: center;
  left: 0;
  right: 0;
  margin: 0;

  & > span {
    font-size: 12px;
    line-height: 16px;
    color: ${colors.blue900};
    font-family: ${fonts.fontFamilyBold};
  }
`

export default UserInfoOverlay
