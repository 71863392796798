import styled, { createGlobalStyle } from 'styled-components'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import ReadNotifications from './components/ReadNotifications'
import UnreadNotifications from './components/UnreadNotifications'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'
import { useUser } from '../../contexts/AuthContext'

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
  }
`

const NotificationsPage = () => {
  const { isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <GlobalStyle />
      <PageContainer>
        <AlphaBanner />
        <Wrapper>
          <OuterSection>
            <UnreadNotifications />
          </OuterSection>
          <OuterSection>
            <ReadNotifications />
          </OuterSection>
        </Wrapper>
      </PageContainer>
    </>
  )
}

const OuterSection = styled.div`
  padding: 11px;
  display: inline-block;
  width: 50%;
  height: 100%;
  vertical-align: top;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const Wrapper = styled.div`
  height: 100%;
`

export default NotificationsPage
