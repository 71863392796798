import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

const getManagerGame = async (gameId: string) => {
  const data = await API.authGet(`game/detail/manager?gameId=${gameId}`)
  return data
}

const useGetManagerGameDetailsQuery = () => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const { user } = useUser()

  return useQuery(
    [QUERY_KEYS.GAME_DETAILS_MANAGER, gameQuery || '1'],
    () => getManagerGame(gameQuery || '1'),
    {
      enabled: user.userRoleName === 'Manager',
    }
  )
}

export default useGetManagerGameDetailsQuery
