import { useMutation } from '@tanstack/react-query'
import API, { VA_TOKEN } from '../../../api'

const login = async (email: string, password: string) => {
  const data = await API.post('auth/login', {
    email,
    password,
  })

  if (data) {
    window.localStorage.setItem(VA_TOKEN, data)
  }
  return data
}

interface LoginParams {
  email: string
  password: string
}

const useLoginMutation = () => {
  return useMutation(({ email, password }: LoginParams) => login(email, password))
}

export default useLoginMutation
