import styled from 'styled-components'
import colors from '../../styles/colors'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { PropsWithChildren, useState } from 'react'
import { Link, SecondaryButton, SubmitButton } from '../Button'
import useRequestNFTMutation from './queries/useRequestNFTMutation'
import { ClipLoader } from 'react-spinners'
import { useUser } from '../../contexts/AuthContext'
import Modal from '../Modal'
import fonts from '../../styles/fonts'
import { ErrorMsg } from '../ErrorMessage'

interface NFTCardProps {
  imageUrl: string
  name: string
  id: number
  assignedToUserId?: number
  assignedToUserName?: string
  metadata?: any
  handleClick?: (id?: number) => void
  availableForRequest?: boolean
  marketUrl?: string
  isAxie?: boolean
  skipTutorial?: boolean
  selectedGameId?: number
}

const CardOverlay = ({
  imageUrl,
  name,
  isAxie,
  metadata,
  availableForRequest,
  children,
  assignedToUserName,
  marketUrl,
  isLoading,
  setOpenConfirmModal,
}: NFTCardProps &
  PropsWithChildren & {
    isLoading: boolean
    setOpenConfirmModal: (o: boolean) => void
  }) => {
  const { user } = useUser()

  if (!availableForRequest) {
    return <>{children}</>
  }

  const bodyParts = metadata?.filter((m: any) => {
    return !!m.stringValue && m.key !== 'Class'
  })

  const classData = metadata?.filter((m: any) => {
    return m.key === 'Class'
  })

  const popover = (
    <Popover
      style={{
        background: colors.white,
        boxShadow: '0px 4px 10px rgba(86, 135, 244, 0.29)',
        borderRadius: '8px',
        border: 0,
        minWidth: '420px',
      }}
    >
      <PopoverHeader>
        <HeaderCell>
          <HeaderImageWrapper>
            <HeaderImage src={imageUrl} alt="" />
          </HeaderImageWrapper>
          <span>{name}</span>
        </HeaderCell>
        <HeaderCell>
          <img src={classData?.[0]?.image} alt="" />
          <span>{classData?.[0]?.stringValue}</span>
        </HeaderCell>
      </PopoverHeader>
      {isAxie ? (
        <PopoverBody>
          <div>
            <BodyTitle>Body Parts</BodyTitle>
          </div>
          <BodyWrapper>
            {bodyParts?.map((part: any) => {
              return (
                <Cell key={part.key}>
                  <img src={part.image} alt="" />
                  <span>{part.stringValue}</span>
                </Cell>
              )
            })}
          </BodyWrapper>
        </PopoverBody>
      ) : null}
      <PopoverFooter>
        {user.userRoleName !== 'Manager' && !assignedToUserName ? (
          <SubmitButton
            disabled={isLoading}
            onClick={() => {
              setOpenConfirmModal(true)
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={isLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Request
          </SubmitButton>
        ) : null}
        <LinkMore target={'_blank'} href={marketUrl}>
          Learn more
        </LinkMore>
      </PopoverFooter>
    </Popover>
  )
  return (
    <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
      {children as any}
    </OverlayTrigger>
  )
}

const NFTCard = ({
  imageUrl,
  name,
  assignedToUserId,
  assignedToUserName,
  handleClick,
  availableForRequest,
  id,
  metadata,
  marketUrl,
  isAxie,
  skipTutorial,
  selectedGameId,
}: NFTCardProps) => {
  const { tutorialStep, setTutorialStep } = useUser()
  const { mutateAsync: requestNft, isLoading } = useRequestNFTMutation()
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const setModal = (isOpen: boolean) => {
    setError('')
    setOpenConfirmModal(isOpen)
  }

  const classData = metadata?.filter((m: any) => {
    return m.key === 'Class'
  })

  return (
    <CardOverlay
      availableForRequest={availableForRequest}
      name={name}
      imageUrl={imageUrl}
      assignedToUserName={assignedToUserName}
      metadata={metadata}
      id={id}
      isAxie={isAxie}
      marketUrl={marketUrl}
      setOpenConfirmModal={setModal}
      isLoading={isLoading}
    >
      <Container
        $clickable={availableForRequest}
        onClick={() => {
          if (skipTutorial && tutorialStep === 6) {
            setTutorialStep(tutorialStep + 1)
          }
        }}
      >
        <ImageWrapper>
          <NFTImage src={imageUrl} alt={name} $scaleImage={isAxie} />
        </ImageWrapper>
        <TextContainer>
          <NFTName>{name}</NFTName>
          {classData?.[0]?.stringValue ? <NFTType>{classData?.[0]?.stringValue}</NFTType> : null}
          {assignedToUserName ? (
            <NFTAssign>
              To:{' '}
              <span
                onClick={() => {
                  handleClick?.(assignedToUserId)
                }}
              >
                {assignedToUserName}
              </span>
            </NFTAssign>
          ) : null}
        </TextContainer>
        {openConfirmModal ? (
          <Modal bigSize open={openConfirmModal} setOpen={setModal} noPadding={true}>
            <ModalBody>
              <ModalText>
                You are about to submit a <strong>request</strong> for <strong>“{name}”</strong>.{' '}
                <br /> Do you confirm your choice?
              </ModalText>
              <Buttons>
                <SecondaryStyledButton
                  onClick={() => {
                    setModal(false)
                  }}
                >
                  NO
                </SecondaryStyledButton>
                <SubmitStyledButton
                  disabled={isLoading}
                  onClick={async () => {
                    await requestNft(
                      {
                        id,
                      },
                      {
                        onSuccess: () => {
                          setModal(false)
                        },
                        onError: (error: any) => {
                          const errorMsg = error?.response?.data?.[0]?.errorMessage
                          setError(errorMsg)
                        },
                      }
                    )
                  }}
                >
                  <ClipLoader
                    color={colors.white}
                    loading={isLoading}
                    cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                    size={20}
                    aria-label="Loading Spinner"
                  />
                  YES
                </SubmitStyledButton>
              </Buttons>
              {error ? (
                <ErrorMsgWrapper>
                  <ErrorMsg>
                    {error}{' '}
                    {error.includes('trainings') ? (
                      <LinkTag href={`/games?game=${selectedGameId}`}>Explore trainings</LinkTag>
                    ) : null}
                  </ErrorMsg>
                </ErrorMsgWrapper>
              ) : null}
            </ModalBody>
          </Modal>
        ) : null}
      </Container>
    </CardOverlay>
  )
}

const Container = styled.div<{ $clickable?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-radius: 8px;
  transition: all 0.4s;

  ${({ $clickable }) =>
    $clickable &&
    `
    cursor: pointer;

    &:hover {
      background: rgba(142, 177, 255, 0.15);
    }
  `}
`

const NFTImage = styled.img<{ $scaleImage?: boolean }>`
  min-width: 100%;
  height: 100%;
  position: relative;

  ${({ $scaleImage }) =>
    $scaleImage &&
    `
    left: 50%;
    transform: translateX(-50%);
  `}
`

const NFTName = styled.p`
  margin: 0;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
`

const NFTType = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  margin: 8px 0;
  display: inline-block;
  color: ${colors.grey500};
`

const NFTAssign = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};

  & > span {
    text-decoration: underline;
    color: ${colors.blue500};
    cursor: pointer;
  }
`

const TextContainer = styled.div`
  max-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
`

const PopoverFooter = styled.div`
  padding: 16px 22px;
  border-top: 1px solid rgba(86, 135, 244, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const PopoverHeader = styled.div`
  padding: 10px 18px;
  display: flex;
  justify-content: space-between;
  background: #eef3ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const ModalBody = styled.div`
  padding: 40px 80px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`

const SecondaryStyledButton = styled(SecondaryButton)`
  width: 70px;
`

const SubmitStyledButton = styled(SubmitButton)`
  min-width: 70px;
`

const PopoverBody = styled.div`
  padding: 12px;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  padding: 12px 0;
`

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  flex-basis: calc(50% - 12px);
  align-items: center;
`

const BodyTitle = styled.span`
  padding-left: 10px;
  color: ${colors.grey500};
  font-size: 16px;
  line-height: 16px;
`

const HeaderImageWrapper = styled.div`
  min-width: 50px;
  width: 50px;
  height: 50px;
  overflow: hidden;
`

const HeaderImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const HeaderCell = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`

const LinkMore = styled(Link)``

const ErrorMsgWrapper = styled.div`
  margin-top: 12px;
`

const LinkTag = styled(Link)`
  color: ${colors.white};
  text-decoration: underline;
  text-transform: capitalize;

  &:hover {
    color: ${colors.grey300};
  }
`

export default NFTCard
