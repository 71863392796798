enum ROUTES {
  PROFILE_PAGE = '/',
  GAMES_PAGE = '/games',
  MARKETPLACE_PAGE = '/marketplace',
  NOTIFICATIONS_PAGE = '/notifications',
  DASHBOARD_PAGE = '/dashboard',
  ANALYTICS_PAGE = '/analytics',
  PAYMENTS_PAGE = '/payments',
  SIGNUP_PAGE = 'signup',
  LOGIN_PAGE = 'login',
  VERIFY_PAGE = 'verify-email',
  CONFIRM_EMAIL_PAGE = 'confirm-email',
  NOT_FOUND_PAGE = '*',
}

export const noAuthRoutes = [
  ROUTES.SIGNUP_PAGE,
  ROUTES.LOGIN_PAGE,
  ROUTES.VERIFY_PAGE,
  ROUTES.CONFIRM_EMAIL_PAGE,
]

export default ROUTES
