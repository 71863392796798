import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { ClipLoader } from 'react-spinners'
import colors from '../../styles/colors'

interface EditableSectionProps {
  editable: boolean
  setEditable: (editable: boolean) => void
  onSubmit: () => void
  onEdit?: () => void
  onCancel?: () => void
  testId?: string
  smallSection?: boolean
  isLoading?: boolean
}

const EditableSection = ({
  children,
  editable,
  setEditable,
  onSubmit,
  onCancel,
  onEdit,
  testId,
  smallSection,
  isLoading,
}: EditableSectionProps & PropsWithChildren) => {
  return (
    <Section>
      {editable ? (
        <>
          <Cross
            data-test-id={`${testId}-cross-icon`}
            src="/images/blue-cross.svg"
            alt="close icon"
            $small={smallSection}
            onClick={() => {
              onCancel?.()
              setEditable(false)
            }}
          />
          {isLoading ? (
            <ClipLoader
              color={colors.blue900}
              loading={isLoading}
              cssOverride={{
                verticalAlign: 'middle',
                marginRight: '3px',
                position: 'absolute',
                top: 0,
                right: smallSection ? '5px' : '22px',
              }}
              size={20}
              aria-label="Loading Spinner"
            />
          ) : (
            <Tick
              data-test-id={`${testId}-tick-icon`}
              onClick={() => onSubmit()}
              src="/images/green-tick.svg"
              alt="submit icon"
              $small={smallSection}
            />
          )}
        </>
      ) : (
        <HoverWrapper
          onClick={() => {
            onEdit?.()
            setEditable(true)
          }}
          $small={smallSection}
        >
          <Pencil
            data-test-id={`${testId}-pencil-icon`}
            src="/images/pencil.svg"
            alt="editable icon"
          />
        </HoverWrapper>
      )}
      {children}
    </Section>
  )
}

const Section = styled.div`
  position: relative;
`

const Pencil = styled.img``

const Cross = styled.img<{ $small?: boolean }>`
  position: absolute;
  top: 0;
  right: 48px;
  cursor: pointer;

  ${({ $small }) =>
    $small &&
    `
    right: 31px;
  `}
`

const Tick = styled.img<{ $small?: boolean }>`
  position: absolute;
  top: 0;
  right: 22px;
  cursor: pointer;

  ${({ $small }) =>
    $small &&
    `
    right: 5px;
  `}
`

const HoverWrapper = styled.div<{ $small?: boolean }>`
  position: absolute;
  top: 0;
  right: 22px;
  cursor: pointer;
  border-radius: 50%;
  background: transparent;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: ${colors.grey200};
  }

  ${({ $small }) =>
    $small &&
    `
    right: 5px;
  `}
`

export default EditableSection
