import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'
import { Manager } from '../../../../../types/manager'

const getManagers = async (id?: number): Promise<Manager[]> => {
  const data = await API.authGet(`user/managers/open?playerId=${id}`)

  return data
}

const useManagersQuery = () => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.MANAGERS], () => getManagers(user.id), {
    staleTime: 60 * 1000,
    enabled: !!user.id,
  })
}

export default useManagersQuery
