import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'
import { Country } from '../types/user'

const beautifyCountries = (countries: Country[]) => {
  return countries?.map(country => ({
    ...country,
    value: country.name,
    label: country.name,
  }))
}

const getCountries = async (): Promise<Country[]> => {
  const data = await API.get('country')

  return beautifyCountries(data)
}

const useCountriesQuery = () => {
  return useQuery([QUERY_KEYS.COUNTRIES], getCountries, {
    staleTime: 60 * 1000 * 60, // 1 hour
  })
}

export default useCountriesQuery
