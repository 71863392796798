import { formatDistance } from 'date-fns'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ApproveButton, LinkButton, RejectButton } from '../../../components/Button'
import { useUser } from '../../../contexts/AuthContext'
import colors from '../../../styles/colors'
import ConfirmModal from '../../Marketplace/components/ManagerNFTRequestsSection/components/ConfirmModal'
import RejectModal from '../../Marketplace/components/ManagerNFTRequestsSection/components/RejectModal'
import { useManagerActiveNftRequestsQuery } from '../../Marketplace/components/ManagerNFTRequestsSection/queries/useManagerNftRequestsQuery'
import ConfirmCancelModal from '../../Marketplace/components/NFTRequestsSection/components/ConfirmCancelModal'
import useCancelRequestNFTMutation from '../../Marketplace/components/NFTRequestsSection/queries/useCancelRequestNFTMutation'
import useNftRequestsQuery from '../../Marketplace/components/NFTRequestsSection/queries/useNftRequestsQuery'
import RequestsModal from '../../Marketplace/components/RequestsModal.tsx'
import SectionContainer from '../../Profile/components/SectionContainer'
import SectionTitle from '../../Profile/components/SectionTitle'
import ProfileModal from '../../../components/ProfileModal'

const DashboardNFTRequests = () => {
  const { user } = useUser()
  const { data: activeRequests } = useManagerActiveNftRequestsQuery(6)
  const { data: requests } = useNftRequestsQuery()

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  const [selectedRequest, setSelectedRequest] = useState<any>(null)
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState<boolean>(false)
  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number | null>(null)

  const pendingRequests = useMemo(() => {
    return (
      requests?.filter((r: any) => {
        return r.nftRequestStatus === 'Active'
      }) || []
    )
  }, [requests])

  return (
    <Container>
      <DashboardNFTRequestsContainer>
        {user.userRoleName === 'Manager' ? (
          <>
            <Header>
              <Title>My Team's Requests</Title>
              <LinkButton
                onClick={() => {
                  setOpenModal(true)
                }}
              >
                View all
              </LinkButton>
            </Header>
            <RequestsWrapper>
              {activeRequests?.items?.slice(0, 4)?.map((r: any) => {
                return (
                  <Wrapper key={r.id}>
                    <Cell>
                      <ImageWrapper>
                        <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                      </ImageWrapper>
                      <div>
                        <NFTName>{r.nftName}</NFTName>
                        <Time>
                          {formatDistance(new Date(r.createdOn), new Date(), { addSuffix: true })}
                        </Time>
                        <PlayerName
                          onClick={() => {
                            setSelectedUser(r.playerId)
                            setOpenProfileModal(true)
                          }}
                        >
                          By {r.playerName}
                        </PlayerName>
                      </div>
                    </Cell>
                    <ButtonsFooter>
                      <RejButton
                        onClick={() => {
                          setSelectedRequest(r)
                          setOpenRejectModal(true)
                        }}
                      >
                        Reject
                      </RejButton>
                      <AppButton
                        onClick={() => {
                          setSelectedRequest(r)
                          setOpenConfirmModal(true)
                        }}
                      >
                        Approve
                      </AppButton>
                    </ButtonsFooter>
                  </Wrapper>
                )
              })}
            </RequestsWrapper>
          </>
        ) : null}
        {user.userRoleName === 'Player' ? (
          <>
            <Header>
              <Title>My Requests</Title>
            </Header>
            <RequestsWrapper>
              {pendingRequests?.slice(0, 4)?.map((r: any) => {
                return (
                  <Wrapper key={r.id}>
                    <Cell $player>
                      <ImageWrapper>
                        <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                      </ImageWrapper>
                      <div>
                        <NFTName>{r.nftName}</NFTName>
                        <div>
                          <PendingStatus>Pending</PendingStatus>
                        </div>
                        <Time>
                          {formatDistance(new Date(r.createdOn), new Date(), { addSuffix: true })}
                        </Time>
                        <CancelBtn
                          onClick={() => {
                            setSelectedRequest(r)
                            setOpenConfirmCancelModal(true)
                          }}
                        >
                          Cancel
                        </CancelBtn>
                      </div>
                    </Cell>
                  </Wrapper>
                )
              })}
            </RequestsWrapper>
          </>
        ) : null}
        <ConfirmModal
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
          selectedRequest={selectedRequest}
        />
        <RejectModal
          openRejectModal={openRejectModal}
          setOpenRejectModal={setOpenRejectModal}
          selectedRequest={selectedRequest}
        />
        <ConfirmCancelModal
          openConfirmCancelModal={openConfirmCancelModal}
          setOpenConfirmCancelModal={setOpenConfirmCancelModal}
          selectedRequest={selectedRequest}
        />
        <RequestsModal openModal={openModal} setOpenModal={setOpenModal} />
        <ProfileModal
          userId={selectedUser}
          openModal={openProfileModal}
          setOpenModal={() => {
            setOpenProfileModal(false)
            setSelectedUser(null)
          }}
        />
      </DashboardNFTRequestsContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 35%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`

const DashboardNFTRequestsContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  min-height: 392px;

  @media screen and (max-width: 768px) {
    background: ${colors.white};
  }
`

const Title = styled(SectionTitle)`
  margin-left: 0;
  margin-bottom: 0;
`

const Cell = styled.div<{ $player?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 2px;

  ${({ $player }) =>
    $player &&
    `
    margin-bottom: 10px;
  `}
`

const PendingStatus = styled.span`
  font-size: 12px;
  line-height: 12px;
  padding: 2px 6px;
  border-radius: 50px;
  background: #ffce1f;
  margin-top: 8px;
  margin-bottom: 6px;
  display: inline-block;
`

const ImageWrapper = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const NFTName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  color: ${colors.blue900};
`

const Time = styled.p`
  font-size: 11px;
  line-height: 12px;
  margin: 2px 0;
  color: ${colors.grey800};
`

const RequestsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
`

const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-bottom: 25px;
`

const PlayerName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  cursor: pointer;
  color: ${colors.blue500};
`

const CancelBtn = styled(LinkButton)`
  color: #fa2424;
  padding: 2px 4px;
  margin-left: -4px;

  &:hover {
    border-radius: 12px;
    background: ${colors.grey200};
  }
`

const Wrapper = styled.div`
  display: inline-block;
  width: calc(50% - 5px);
  vertical-align: top;

  @media screen and (max-width: 1210px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: calc(50% - 5px);
  }
`

const RejButton = styled(RejectButton)`
  font-size: 12px;
  padding: 6px 15px;
`

const AppButton = styled(ApproveButton)`
  font-size: 12px;
  padding: 6px 15px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`

export default DashboardNFTRequests
