import { formatDistance } from 'date-fns'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { ApproveButton, RejectButton, SecondaryButton } from '../../../components/Button'
import Modal from '../../../components/Modal'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { maskPublicAddress } from '../../../utils/wallet'
import useApprovePaymentMutation from '../queries/useApprovePaymentMutation'
import useRejectPaymentMutation from '../queries/useRejectPaymentMutation'

const PendingPayment = (props: any) => {
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  const [openApproveModal, setOpenApproveModal] = useState<boolean>(false)
  const { mutateAsync: rejectPayment, isLoading: rejectIsLoading } = useRejectPaymentMutation()
  const { mutateAsync: approvePayment, isLoading: approveIsLoading } = useApprovePaymentMutation()

  return (
    <Container>
      <ColumnCell>
        <Name>{props.playerName}</Name>
        <DateLabel>
          {formatDistance(new Date(props.createdOn), new Date(), { addSuffix: true })}
        </DateLabel>
      </ColumnCell>
      <Buttons>
        <AmountCell>
          <AmountLabel>Amount</AmountLabel>
          <Amount>
            {props.currencyCode} {props.amount}
          </Amount>
        </AmountCell>
        <RejButton
          onClick={() => {
            setOpenRejectModal(true)
          }}
        >
          Reject
        </RejButton>
        <AppButton
          onClick={() => {
            setOpenApproveModal(true)
          }}
        >
          Approve
        </AppButton>
      </Buttons>
      <Modal
        title={`Are you sure you would like to reject </br> ${props.playerName}'s withdraw request?`}
        open={openRejectModal}
        setOpen={setOpenRejectModal}
        bigSize
      >
        <Body>
          <ColumnCell>
            <AmountLabel>Player</AmountLabel>
            <Amount>{props.playerName}</Amount>
          </ColumnCell>
          <ColumnCell>
            <AmountLabel>Amount</AmountLabel>
            <Amount>
              {props.currencyCode} {props.amount}
            </Amount>
          </ColumnCell>
          <ColumnCell>
            <AmountLabel>Withdraw address</AmountLabel>
            <Amount>{maskPublicAddress(props.address)}</Amount>
          </ColumnCell>
        </Body>
        <ButtonsWrapper>
          <SecondaryButton
            onClick={() => {
              setOpenRejectModal(false)
            }}
          >
            CANCEL
          </SecondaryButton>
          <BigRejectButton
            disabled={rejectIsLoading}
            onClick={async () => {
              await rejectPayment({
                id: props.id,
              })
              setOpenRejectModal(false)
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={rejectIsLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Reject
          </BigRejectButton>
        </ButtonsWrapper>
      </Modal>
      <Modal
        title={`Are you sure you would like to approve <br /> ${props.playerName}'s withdraw request?`}
        open={openApproveModal}
        setOpen={setOpenApproveModal}
        bigSize
      >
        <Body>
          <ColumnCell>
            <AmountLabel>Player</AmountLabel>
            <Amount>{props.playerName}</Amount>
          </ColumnCell>
          <ColumnCell>
            <AmountLabel>Amount</AmountLabel>
            <Amount>
              {props.currencyCode} {props.amount}
            </Amount>
          </ColumnCell>
          <ColumnCell>
            <AmountLabel>Withdraw address</AmountLabel>
            <Amount>{maskPublicAddress(props.address)}</Amount>
          </ColumnCell>
        </Body>
        <ButtonsWrapper>
          <SecondaryButton
            onClick={() => {
              setOpenApproveModal(false)
            }}
          >
            CANCEL
          </SecondaryButton>
          <BigApproveButton
            disabled={approveIsLoading}
            onClick={async () => {
              await approvePayment({
                id: props.id,
              })
              setOpenApproveModal(false)
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={approveIsLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Approve
          </BigApproveButton>
        </ButtonsWrapper>
      </Modal>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dce9ff;
  padding: 11px 0;
`

const RejButton = styled(RejectButton)`
  font-size: 12px;
  padding: 6px 15px;
`
const BigRejectButton = styled(RejectButton)`
  font-size: 16px;
`
const BigApproveButton = styled(ApproveButton)`
  font-size: 16px;
`

const AppButton = styled(ApproveButton)`
  font-size: 12px;
  padding: 6px 15px;
`

const ColumnCell = styled.div`
  display: flex;
  flex-direction: column;
`

const AmountCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 50px;
`

const Name = styled.span`
  font-size: 14px;
  line-height: 12px;
  color: ${colors.blue900};
`

const DateLabel = styled.span`
  font-size: 12px;
  color: ${colors.grey800};
  line-height: 16px;
`

const AmountLabel = styled.span`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.grey800};
`

const Amount = styled.span`
  font-size: 16px;
  line-height: 22px;
  color: ${colors.blue800};
  font-family: ${fonts.fontFamilyBold};
`

const Buttons = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
`
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: center;
`

const Body = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
`

export default PendingPayment
