import { format } from 'date-fns'
import styled from 'styled-components'
import { useUser } from '../../../../contexts/AuthContext'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { CurrencyHTML } from '../EarningsSection/components/EarningTile'
import SectionContainer from '../SectionContainer'

interface PayoutSectionProps {
  editable: boolean
  mask?: boolean
}

const PayoutSection = ({ editable, mask }: PayoutSectionProps) => {
  const { user } = useUser()
  const { currency } = useCurrency()

  const date = new Date()
  date.setMonth(date.getMonth() - 1)

  return (
    <Container>
      <PayoutSectionContainer $editable={editable} $mask={mask}>
        <Cell>
          <div>
            <CellTitle>Average payout</CellTitle>
          </div>
          <Payout>
            <CurrencyHTML currency={currency} earning={user.averageMonthlyWithdraw} />
          </Payout>
        </Cell>
        <Cell $borders>
          <div>
            <CellTitle>Last monthly payout</CellTitle>
            <NextDate>{format(date, 'MMM yyyy')}</NextDate>
          </div>
          <Payout>
            <CurrencyHTML currency={currency} earning={user.lastMonthWithdraw} />
          </Payout>
        </Cell>
        <Cell $paddingLeft>
          <div>
            <CellTitle>Balance</CellTitle>
          </div>
          <Balance>
            <CurrencyHTML currency={currency} earning={user.balance} />
          </Balance>
        </Cell>
      </PayoutSectionContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 15px 10px;
`

const PayoutSectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;

  @media screen and (max-width: 768px) {
    background: ${colors.white};
    padding: 20px 0;
  }
`

const Cell = styled.div<{ $borders?: boolean; $paddingLeft?: boolean }>`
  display: inline-block;
  width: 33.333%;
  vertical-align: top;
  padding-right: 25px;

  ${({ $borders }) =>
    $borders &&
    `
    border-left: 1px solid rgba(86, 135, 244, 0.23);
    border-right: 1px solid rgba(86, 135, 244, 0.23);
    padding: 0 25px;
  `}

  ${({ $paddingLeft }) =>
    $paddingLeft &&
    `
    padding-left: 25px;
    padding-right: 0;
  `}

  @media screen and (max-width: 1000px) {
    border: 0;
    padding: 0 20px;
    width: 100%;

    ${({ $borders }) =>
      $borders &&
      `
      padding-top: 8px;
      border-top: 1px solid rgba(86, 135, 244, 0.23);
      border-bottom: 1px solid rgba(86, 135, 244, 0.23);
    `}
  }
`

const CellTitle = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey500};
`

const Payout = styled.p`
  text-align: center;
  color: ${colors.blue900};
  font-size: 45px;
  font-family: ${fonts.fontFamilyBold};
  margin: 25px 0;

  & > span {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Balance = styled.p`
  color: ${colors.blue400};
  text-align: center;
  font-size: 45px;
  font-family: ${fonts.fontFamilyBold};
  margin: 25px 0;
`

const NextDate = styled.span`
  font-size: 14px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
  color: ${colors.grey500};
  background: #fff0e6;
  border-radius: 15px;
  padding: 7px 12px;
  float: right;
`

export default PayoutSection
