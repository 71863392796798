import { ChangeEvent, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { LinkButton, SubmitButton } from '../../components/Button'
import Input from '../../components/Input'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import useCountriesQuery from '../../queries/useCountries'
import { selectBigStyles } from '../../utils/dropdown'
import useSignupMutation, { SignupParams } from './queries/useSignupMutation'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ErrorMsg } from '../../components/ErrorMessage'
import useCheckCodeQuery from './queries/useCheckCodeQuery'
import ClipLoader from 'react-spinners/ClipLoader'
import { BackgroundWrapper, BlueContainer } from '../../components/UI'

const SignupPage = () => {
  const [searchParams] = useSearchParams()
  const referralCode = searchParams.get('referral_code')

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false)
  const [countryName, setCountryName] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneExtPadding, setPhoneExtPadding] = useState<number>(0)
  const [serverError, setServerError] = useState<any>(null)
  const phoneExtRef = useRef<any>(null)
  const navigate = useNavigate()

  const { data: countries } = useCountriesQuery()
  const { data: referral } = useCheckCodeQuery({ code: referralCode || undefined })
  const { mutateAsync: signup, isLoading } = useSignupMutation()

  const enableSubmit =
    !!firstName &&
    !!lastName &&
    !!email &&
    !!code &&
    !!userName &&
    !!password &&
    !!countryName &&
    password === confirmPassword

  const userCountry = useMemo(() => {
    const defaultCountry = countries?.filter(country => {
      return country.name === countryName
    })?.[0]

    return defaultCountry
  }, [countryName, countries])

  const handleSubmit = async () => {
    if (!enableSubmit || !userCountry?.id) {
      return
    }
    const body: SignupParams = {
      firstName,
      lastName,
      userName,
      email,
      password,
      countryId: userCountry.id,
      registrationKey: code,
    }

    if (phone) {
      body.phoneNumber = `${userCountry?.phoneExtension}${phone}`
    }

    if (referral?.isValid && referralCode) {
      body.referralCode = referralCode
    }
    await signup(body, {
      onError: (error: any) => {
        const data = error?.response?.data
        if (data && Array.isArray(data) && data.length > 0) {
          setServerError(data[0]?.errorMessage)
        }
      },
      onSuccess: () => {
        navigate(`/verify-email?email=${email}`)
      },
    })
  }

  return (
    <>
      <BlueContainer>
        <BackgroundWrapper />
      </BlueContainer>
      <FormWrapper>
        <Head>
          <Welcome>Welcome to</Welcome>
          <Title>Virtue Alliance!</Title>
        </Head>
        <MobileHead>
          <MobileLogo src="/images/small-logo.svg" alt="virtue alliance" />
          <MobileTitle>Virtue Alliance</MobileTitle>
        </MobileHead>
        <div>
          <Cell>
            <Input
              id="first-name"
              value={firstName}
              label="First Name"
              placeholder="First Name"
              noLabelMobile
              big
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setFirstName(e.target.value)
                // if (e.target.value !== '' && firstNameError) {
                //   setFirstNameError(false)
                // }
              }}
              // error={firstNameError}
              errorMsg="First name cannot be empty!"
            />
          </Cell>
          <Cell $right>
            <Input
              id="last-name"
              value={lastName}
              big
              noLabelMobile
              placeholder="Last Name"
              label="Last Name"
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setLastName(e.target.value)
              }}
              errorMsg="Last name cannot be empty!"
            />
          </Cell>
          <Cell>
            <Input
              id="username"
              value={userName}
              label="Username"
              placeholder="Username"
              big
              noLabelMobile
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setUserName(e.target.value)
              }}
              errorMsg="First name cannot be empty!"
            />
          </Cell>
          <Cell $right>
            <Input
              id="email"
              type="email"
              value={email}
              big
              noLabelMobile
              label="Email address"
              placeholder="Email address"
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmail(e.target.value)
              }}
              errorMsg="Email cannot be empty!"
            />
          </Cell>
          <Cell>
            <Input
              id="password"
              type="password"
              value={password}
              noLabelMobile
              big
              label="Password"
              placeholder="Password"
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPassword(e.target.value)
              }}
              errorMsg="Password cannot be empty!"
            />
          </Cell>
          <Cell $right>
            <Input
              id="confirm-password"
              type="password"
              value={confirmPassword}
              big
              label="Confirm Password"
              placeholder="Confirm Password"
              labelColor={colors.white}
              noLabelMobile
              onBlur={() => {
                if (confirmPassword !== password) {
                  setConfirmPasswordError(true)
                }
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setConfirmPassword(e.target.value)
                if (confirmPasswordError && e.target.value === password) {
                  setConfirmPasswordError(false)
                }
              }}
              error={confirmPasswordError}
              errorMsg="Confirm password should be equal to the password!"
            />
          </Cell>
          <Cell>
            <CountryLabel htmlFor="country">Country</CountryLabel>
            <Select
              id="country"
              options={countries}
              placeholder="Country"
              value={userCountry}
              styles={selectBigStyles}
              onChange={e => {
                setCountryName(e?.name || '')
              }}
            />
          </Cell>
          <Cell $right>
            <Input
              id="phone"
              value={phone}
              big
              noLabelMobile
              paddingLeft={phoneExtPadding}
              label="Phone number"
              placeholder={!userCountry?.phoneExtension ? 'Phone number' : ''}
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPhone(e.target.value)
              }}
              onFocus={e => {
                setPhoneExtPadding((phoneExtRef.current?.offsetWidth || 35) + 10)
              }}
            />
            <PhoneExtension ref={phoneExtRef} $hidden={!userCountry?.phoneExtension}>
              {userCountry?.phoneExtension || '+000'}
            </PhoneExtension>
          </Cell>
          <div>
            <Input
              id="signup-code"
              type="text"
              value={code}
              noLabelMobile
              big
              label="Registration key"
              placeholder="Registration key"
              labelColor={colors.white}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCode(e.target.value)
              }}
              errorMsg="Registration key cannot be empty!"
            />
          </div>
        </div>
        {referral && referral.isValid && referral.ownerName ? (
          <ReferredMsg>
            Referred by: <span>{referral.ownerName}</span>
          </ReferredMsg>
        ) : null}
        {referral && !referral.isValid ? (
          <ReferredMsg>
            <ErrorIcon src="/images/error.svg" alt="error" />
            Referred link <span>expired</span> or it's <span>invalid</span>!
          </ReferredMsg>
        ) : null}
        {serverError ? <ErrorMsg>{serverError}</ErrorMsg> : null}
        <ButtonWrapper>
          <SButton disabled={!enableSubmit || isLoading} onClick={handleSubmit}>
            <ClipLoader
              color={colors.white}
              loading={isLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Sign up
          </SButton>
          <OrDivider>or</OrDivider>
          <LoginButton
            onClick={() => {
              navigate('/login')
            }}
          >
            Login
          </LoginButton>
        </ButtonWrapper>
      </FormWrapper>
    </>
  )
}

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - 215px);
  max-width: 430px;
  width: 100%;
  background: linear-gradient(
    180.11deg,
    rgba(93, 129, 229, 0.19) -13.71%,
    rgba(93, 129, 229, 0.19) -13.7%,
    rgba(18, 27, 52, 0.19) 107.63%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 15px 96px rgba(3, 41, 96, 0.85);
  border-radius: 18px;
  padding: 40px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    top: 0;
    left: 0;
    max-width: 100%;
    transform: translateY(0);
  }
`

const Head = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(78, 130, 204, 0.55);

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Welcome = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
`

const Title = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-family: ${fonts.fontFamilyBold};
`

const Cell = styled.div<{ $right?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 10px;
  position: relative;

  ${({ $right }) =>
    $right &&
    `
    padding-left: 10px;
    padding-right: 0;
  `}

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

const MobileHead = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const MobileLogo = styled.img`
  width: 130px;
  margin-bottom: 20px;
`

const MobileTitle = styled.h2`
  font-size: 21px;
  line-height: 30px;
  color: ${colors.white};
  font-family: ${fonts.fontFamilySemiBold};
  letter-spacing: 0.8px;
  margin: 0;
`

const ErrorIcon = styled.img`
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 5px;
`
const ButtonWrapper = styled.div`
  text-align: center;
  color: ${colors.white};
  margin-top: 20px;
`

const OrDivider = styled.p`
  margin: 15px 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LoginButton = styled(LinkButton)`
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    text-decoration: none;
    border: 1px solid ${colors.white};
    width: 100%;
    height: 38px;
    margin-top: 20px;
    border-radius: 9px;
  }
`

const CountryLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.white};
  margin-bottom: 4px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const PhoneExtension = styled.span<{ $hidden?: boolean }>`
  position: absolute;
  top: 33px;
  left: 15px;
  border-right: 1px solid rgba(86, 135, 244, 0.23);
  color: ${colors.black};
  font-size: 12px;
  line-height: 16px;
  padding-right: 5px;

  ${({ $hidden }) =>
    $hidden &&
    `
    color: ${colors.blue100};
  `}

  @media screen and (max-width: 768px) {
    top: 13px;
    left: 7px;
    color: ${colors.white};
    border-right: 1px solid ${colors.white};

    ${({ $hidden }) =>
      $hidden &&
      `
      color: transparent;
      border-right: 0;
    `}
  }
`

const ReferredMsg = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${colors.white};

  & > span {
    font-weight: 600;
  }
`

const SButton = styled(SubmitButton)`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export default SignupPage
