import styled from 'styled-components'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'

const SectionTitle = styled.h2`
  margin: 0 9px 16px;
  font-size: 14px;
  font-family: ${fonts.fontFamilyRegular};
  line-height: 16px;
  color: ${colors.grey500};
`

export default SectionTitle
