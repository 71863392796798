import { ChangeEvent, useRef } from 'react'
import styled from 'styled-components'
import Input from '../../../../components/Input'
import { useUser } from '../../../../contexts/AuthContext'
import colors from '../../../../styles/colors'
import SectionTitle from '../SectionTitle'
import DiscordIcon from './components/DiscordIcon'
import FacebookIcon from './components/FacebookIcon'
import TwitterIcon from './components/TwitterIcon'

interface SocialMediaProps {
  editable: boolean
}

const SocialMedia = ({ editable }: SocialMediaProps) => {
  const { user, setUser } = useUser()
  const discordInput = useRef<HTMLInputElement | null>(null)
  const fbInput = useRef<HTMLInputElement | null>(null)
  const twitterInput = useRef<HTMLInputElement | null>(null)

  return (
    <div>
      <SocialSectionTitle>Social media</SocialSectionTitle>
      <Row>
        <IconCell
          onClick={() => {
            discordInput.current?.focus()
          }}
        >
          <DiscordIcon color={!editable ? colors.grey350 : '#5865F2'} />
        </IconCell>
        <InputWrapper>
          <Input
            id="discord"
            reactRef={discordInput}
            value={user?.socials?.discord || ''}
            label="Discord account"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUser({
                ...user,
                socials: {
                  ...user.socials,
                  discord: e.target.value,
                },
              })
            }
            disabled={!editable}
          />
        </InputWrapper>
      </Row>
      <Row>
        <IconCell
          onClick={() => {
            fbInput.current?.focus()
          }}
        >
          <FacebookIcon color={!editable ? colors.grey350 : '#1877F2'} />
        </IconCell>
        <InputWrapper>
          <Input
            id="facebook"
            reactRef={fbInput}
            label="Facebook profile"
            value={user?.socials?.facebook || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUser({
                ...user,
                socials: {
                  ...user.socials,
                  facebook: e.target.value,
                },
              })
            }
            disabled={!editable}
          />
        </InputWrapper>
      </Row>
      <Row>
        <IconCell
          onClick={() => {
            twitterInput.current?.focus()
          }}
        >
          <TwitterIcon color={!editable ? colors.grey350 : '#1DA1F2'} />
        </IconCell>
        <InputWrapper>
          <Input
            id="twitter"
            reactRef={twitterInput}
            label="Twitter profile"
            value={user?.socials?.twitter || ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setUser({
                ...user,
                socials: {
                  ...user.socials,
                  twitter: e.target.value,
                },
              })
            }
            disabled={!editable}
          />
        </InputWrapper>
      </Row>
    </div>
  )
}

const Row = styled.div``

const SocialSectionTitle = styled(SectionTitle)`
  margin: 0 0 16px;
`

const IconCell = styled.div`
  display: inline-block;
  width: 30px;
  vertical-align: top;
  padding-top: 27px;

  & > img {
    width: 20px;
    cursor: pointer;
  }
`

const InputWrapper = styled.div`
  width: calc(100% - 30px);
  display: inline-block;
  vertical-align: middle;
`

export default SocialMedia
