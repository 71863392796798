import styled from 'styled-components'
import useUserNFTQuery from '../../../queries/useUserNFTQuery'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'

const NftSection = ({ userId }: { userId?: number }) => {
  const { data: nfts, isLoading } = useUserNFTQuery(userId)

  if (isLoading) {
    return (
      <>
        <SubTitle>NFTs</SubTitle>
        <NFTSectionWrapper></NFTSectionWrapper>
      </>
    )
  }

  return (
    <>
      <SubTitle>NFTs</SubTitle>
      <NFTSectionWrapper>
        {nfts?.length > 0 ? (
          <InnerWrapper>
            <ScrollWrapper>
              {nfts?.map((nft: any) => {
                return (
                  <NFTCell key={nft.id}>
                    <ImageWrapper>
                      <NFTImage src={nft.imageUrl} alt={nft.name} />
                    </ImageWrapper>
                    <NFTDetails>
                      <NFTTitle>{nft.name}</NFTTitle>
                    </NFTDetails>
                  </NFTCell>
                )
              })}
            </ScrollWrapper>
          </InnerWrapper>
        ) : (
          <CenterWrapper>
            <NoImage src="/images/no-nfts.svg" alt="no nfts" />
            <NoDataMessage>This player has no NFTs yet</NoDataMessage>
          </CenterWrapper>
        )}
      </NFTSectionWrapper>
    </>
  )
}

const CenterWrapper = styled.div`
  text-align: center;
`

const InnerWrapper = styled.div`
  height: 245px;
  overflow: hidden;
`

const ScrollWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const SubTitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 14px;
`

const NoImage = styled.img`
  width: 60px;
`

const NoDataMessage = styled.p`
  margin: 10px 0;
  color: ${colors.black};
  font-size: 12px;
  line-height: 16px;
`

const NFTSectionWrapper = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding-bottom: 40px;
  margin-bottom: 20px;
`

const NFTCell = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: middle;
  padding-right: 10px;
  margin-bottom: 10px;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const ImageWrapper = styled.div`
  width: 70px;
  height: 70px;
  margin-right: 10px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`

const NFTDetails = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 80px);
`

const NFTTitle = styled.div`
  color: ${colors.blue900};
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
  margin-bottom: 2px;
`

export default NftSection
