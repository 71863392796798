import { Link } from 'react-router-dom'
import styled from 'styled-components'
import colors from '../../../styles/colors'
import SectionContainer from '../../Profile/components/SectionContainer'
import SectionTitle from '../../Profile/components/SectionTitle'
import useGetDashboardGamesQuery from '../queries/useGetDashboardGamesQuery'

const Games = () => {
  const { data: games } = useGetDashboardGamesQuery()

  const playedGames = games?.filter((g: any) => {
    return g.isActive
  })

  const otherGames = games?.filter((g: any) => {
    return !g.isActive
  })

  return (
    <Container>
      <GamesContainer $editable={false}>
        <Wrapper>
          <MyGamesSection>
            <Title>My games</Title>
            <GamesWrapper>
              {playedGames?.map((game: any) => {
                return (
                  <Link key={game.id} to={`/games?game=${game.id}`}>
                    <Image src={game.bannerPictureUrl} alt={game.name} />
                  </Link>
                )
              })}
            </GamesWrapper>
          </MyGamesSection>
          <OtherGamesSection>
            <Title>Other games</Title>
            <GamesWrapper>
              {otherGames?.map((game: any) => {
                return <Image key={game.id} $disabled src={game.bannerPictureUrl} alt={game.name} />
              })}
            </GamesWrapper>
          </OtherGamesSection>
        </Wrapper>
      </GamesContainer>
    </Container>
  )
}

const Container = styled.div`
  padding: 0 10px;
`

const GamesContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 22px;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    background: ${colors.white};
  }
`

const Title = styled(SectionTitle)`
  margin-left: 0;
  margin-bottom: 0;
`

const MyGamesSection = styled.div`
  min-width: 160px;
`

const OtherGamesSection = styled.div`
  border-left: 1px solid rgba(86, 135, 244, 0.23);
  padding-left: 20px;

  @media screen and (max-width: 768px) {
    padding-left: 0;
    border-left: 0;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const Image = styled.img<{ $disabled?: boolean }>`
  border-radius: 12px;
  width: 100%;
  max-width: 160px;
  margin-top: 20px;
  border: 1px solid transparent;
  transition: all 0.4s;

  &:hover {
    border: 1px solid ${colors.blue900};
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.4;
    cursor: not-allowed;

    &:hover {
      border: 1px solid transparent;
    }
  `}
`

const GamesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow-y: scroll;
  padding-bottom: 15px;
`

export default Games
