import { Fragment, useEffect, useRef } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import NFTCard from '../../../../components/NFTCard'
import TutorialTooltip from '../../../../components/TutorialTooltip/TutorialTooltip'
import { useUser } from '../../../../contexts/AuthContext'
import colors from '../../../../styles/colors'
import useSearchAvailableNFTQuery from '../queries/useSearchAvailableNFTQuery'

const NFTsSection = () => {
  const [searchParams] = useSearchParams()
  const { status, data, fetchNextPage, hasNextPage } = useSearchAvailableNFTQuery()
  const navigate = useNavigate()
  const gameQuery = searchParams.get('game') || '1'
  const target = useRef(null)
  const { tutorialStep, setTutorialStep } = useUser()
  const { ref, inView } = useInView()

  useEffect(() => {
    const page = searchParams.get('page')
    if (page) {
      const search = searchParams.get('search')
      const isLeased = searchParams.get('isLeased')
      const searchParam = encodeURIComponent(search || '')
      navigate(
        `/marketplace?game=${gameQuery}${searchParam ? `&search=${searchParam}` : ''}${
          isLeased ? `&isLeased=${isLeased}` : ''
        }`
      )
    }
  }, [])

  useEffect(() => {
    if (inView) {
      if (hasNextPage) {
        ;(async () => {
          const search = searchParams.get('search')
          const searchParam = encodeURIComponent(search || '')
          const isLeased = searchParams.get('isLeased')

          const page = searchParams.get('page')
          const newPage = parseInt(page || '1') + 1

          await fetchNextPage()

          navigate(
            `/marketplace?game=${gameQuery}${
              searchParam ? `&search=${searchParam}` : ''
            }&page=${newPage}${isLeased ? `&isLeased=${isLeased}` : ''}`
          )
        })()
      }
    }
  }, [inView])

  const firstItem = data?.pages?.[0]?.items?.[0]

  return (
    <Container>
      {data?.pages?.map?.((page, index) => (
        <Fragment key={index}>
          {page.items?.length > 0 ? (
            <>
              {page.items.map((nft: any, i: number) => {
                return (
                  <Cell
                    key={`${nft.id}-${nft.address}`}
                    ref={firstItem?.id === nft.id ? target : null}
                  >
                    <Overlay
                      placement={'top'}
                      show={firstItem?.id === nft.id && tutorialStep === 6}
                      target={target.current}
                    >
                      {props => (
                        <Tooltip className="tutorial" {...props}>
                          <TutorialTooltip>
                            Step: View NFT details by clicking on it!
                          </TutorialTooltip>
                        </Tooltip>
                      )}
                    </Overlay>
                    <NFTCard
                      name={nft.name}
                      imageUrl={nft.imageUrl}
                      availableForRequest={true}
                      id={nft.id}
                      metadata={nft.metadata}
                      marketUrl={nft.marketUrl}
                      assignedToUserName={nft.assignedToUserName}
                      isAxie={gameQuery === '1'}
                      skipTutorial
                      selectedGameId={gameQuery ? Number(gameQuery) : 0}
                    />
                  </Cell>
                )
              })}
            </>
          ) : null}
          {page.items?.length === 0 && index === 0 ? (
            <Wrapper>
              <NoNftsImage src="/images/no-nfts.svg" alt="no nfts" />
              <Description>There aren't any NFTs</Description>
            </Wrapper>
          ) : null}
        </Fragment>
      ))}
      {hasNextPage ? (
        <LoadingContainer ref={ref}>
          <br />
        </LoadingContainer>
      ) : null}
      {hasNextPage || status === 'loading' ? (
        <LoadingContainer>
          <ClipLoader
            color={colors.blue900}
            loading={true}
            cssOverride={{
              verticalAlign: 'middle',
            }}
            size={80}
            aria-label="Loading Spinner"
          />
        </LoadingContainer>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-wrap: wrap;
  padding: 17px 22px;
`

const Cell = styled.div`
  width: calc(25% - 9px);

  @media screen and (max-width: 1210px) {
    width: calc(33.33% - 9px);
  }

  @media screen and (max-width: 1040px) {
    width: calc(50% - 9px);
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const NoNftsImage = styled.img`
  width: 80px;
  margin-bottom: 22px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 32px;
  color: ${colors.grey500};
`

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: 50px 0;
`

const LoadingContainer = styled.div`
  text-align: center;
  width: 100%;
  padding-top: 24px;
`

export default NFTsSection
