import styled from 'styled-components'
import Popup from '../../../../../components/Popup'
import { useCurrency } from '../../../../../contexts/CurrencyContext'
import { Currency, CurrencySymbol } from '../../../../../types/currency'
import { Balance, Earning } from '../../../../../types/user'
import format from 'date-fns/format'
import colors from '../../../../../styles/colors'

export const CurrencyHTML = ({
  currency,
  earning,
  round,
}: {
  currency: Currency
  earning: Earning | Balance | undefined
  round?: boolean
}) => {
  if (!earning) {
    return null
  }
  if (currency === Currency.BITCOIN) {
    return (
      <span>
        {CurrencySymbol.BITCOIN} {+parseFloat(earning.btcAmount?.toFixed(round ? 3 : 5) || '0')}
      </span>
    )
  }
  if (currency === Currency.EURO) {
    return (
      <span>
        {CurrencySymbol.EURO} {+parseFloat(earning.eurAmount?.toFixed(round ? 0 : 2) || '0')}
      </span>
    )
  }
  return (
    <span>
      {CurrencySymbol.USD} {+parseFloat(earning.dollarAmount?.toFixed(round ? 0 : 2) || '0')}
    </span>
  )
}

const EarningTile = ({ earning }: { earning: Earning }) => {
  const { currency } = useCurrency()

  return (
    <Wrapper>
      <Cell>
        <DateSpan>{format(new Date(earning.paymentDate), 'LLL d, yyyy')}</DateSpan>
      </Cell>
      <Cell $rightAlign>
        <Price>
          <CurrencyHTML currency={currency} earning={earning} />
          <Popup open={true} topLeft noPadding minWidth={100}>
            <EarningCurrency>
              {earning.currencyCode} {earning.amount}
            </EarningCurrency>
          </Popup>
        </Price>
        <GameLogo src={`/images/games/axie.png`} alt="game icon" />
      </Cell>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: rgba(93, 129, 229, 0.1);
  border-radius: 8px;
  margin-bottom: 7px;
  height: 45px;
  padding: 0 15px;
  line-height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DateSpan = styled.span`
  font-size: 14px;
  color: ${colors.blue800};
`

const Price = styled.span`
  font-size: 16px;
  color: ${colors.blue800};
  font-weight: 700;
  position: relative;
  coursor: pointer;

  & > div {
    opacity: 0;
    transition: opacity 0.4s;
  }

  & > span:hover {
    & + div {
      opacity: 1;
    }
  }
`

const Cell = styled.div<{ $rightAlign?: boolean }>``

const GameLogo = styled.img`
  width: 37px;
  margin-left: 7px;
  margin-top: -4px;
  vertical-align: middle;
  border-radius: 6px;
`

const EarningCurrency = styled.p`
  text-align: center;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
`

export default EarningTile
