import { ChangeEvent, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import EditableSection from '../../../../components/EditableSection'
import Input from '../../../../components/Input'
import ProfileImage from '../../../../components/ProfileImage'
import { useUser } from '../../../../contexts/AuthContext'
import IngameDetails from '../IngameDetails'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'
import SocialMedia from '../SocialMedia'
import useEditProfile from './queries/useEditProfile'
import Select from 'react-select'
import colors from '../../../../styles/colors'
import { getCountryIdByName } from '../../../../utils/user'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import { EditableUserServer, User } from '../../../../types/user'
import { selectStyles } from '../../../../utils/dropdown'
import useCountriesQuery from '../../../../queries/useCountries'
import MobileProfileSection from './MobileProfileSection'

interface ProfileDetailsProps {
  editable: boolean
  setEditable: () => void
  mask?: boolean
}

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ProfileDetails = ({ editable, setEditable, mask }: ProfileDetailsProps) => {
  const { user, setUser } = useUser()
  const { currency } = useCurrency()
  const userRef = useRef<User>(user)
  const [firstNameError, setFirstNameError] = useState<boolean>(false)
  const [lastNameError, setLastNameError] = useState<boolean>(false)
  const [emailError, setEmailError] = useState<boolean>(false)
  const [usernameError, setUsernameError] = useState<boolean>(false)

  const { data: countries } = useCountriesQuery()
  const { mutateAsync: editProfile, isLoading: editLoading } = useEditProfile()

  const handleSubmit = async () => {
    if (user && user.id) {
      if (user.firstName === '') {
        setFirstNameError(true)
      } else if (user.lastName === '') {
        setLastNameError(true)
      } else if (!user.email || !emailRegex.test(user.email.toLowerCase())) {
        setEmailError(true)
      } else if (user.userName === '') {
        setUsernameError(true)
      } else {
        const newUser: EditableUserServer = {
          id: user.id,
          email: user.email,
          userName: user.userName,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          phoneNumber: user.phone || '',
          countryId: getCountryIdByName(countries || [], user.countryName || '') || 0,
          platfromCurrency: currency,
        }

        if (user.socials?.twitter) {
          newUser.twitterAccount = user.socials?.twitter
        }
        if (user.socials?.discord) {
          newUser.discordAccount = user.socials?.discord
        }
        if (user.socials?.facebook) {
          newUser.facebookAccount = user.socials?.facebook
        }

        if (user.photo) {
          newUser.photo = user.photo
        }

        await editProfile({
          user: newUser,
        })
        setEditable()
      }
    }
  }
  const userCountry = useMemo(() => {
    const defaultCountry = countries?.filter(country => {
      return country.name === user.countryName
    })?.[0]

    return defaultCountry
  }, [user.countryName, countries])

  return (
    <DetailsSectionContainer $editable={editable} $mask={mask}>
      <MobileProfileSection />
      <InnerWrapper>
        <EditableSection
          editable={editable}
          setEditable={setEditable}
          onEdit={() => {
            userRef.current = user
          }}
          isLoading={editLoading}
          onCancel={() => {
            setUser(userRef.current)
            setFirstNameError(false)
            setLastNameError(false)
          }}
          onSubmit={handleSubmit}
          testId="player-details"
        >
          <BigCell>
            <ImageWrapper>
              <ProfileImage disabled={!editable} />
            </ImageWrapper>
            <DetailsWrapper>
              <SectionTitle>Profile details</SectionTitle>
              <HalfCell>
                <Input
                  id="first-name"
                  value={user.firstName || ''}
                  label="First Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setUser({
                      ...user,
                      firstName: e.target.value,
                    })
                    if (e.target.value !== '' && firstNameError) {
                      setFirstNameError(false)
                    }
                  }}
                  disabled={!editable}
                  error={firstNameError}
                  errorMsg="First name cannot be empty!"
                />
                <Input
                  id="email"
                  type="email"
                  value={user.email || ''}
                  label="Email address"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setUser({
                      ...user,
                      email: e.target.value,
                    })
                    if (emailRegex.test(e.target.value.toLowerCase()) && emailError) {
                      setEmailError(false)
                    }
                  }}
                  disabled={!editable}
                  error={emailError}
                  errorMsg="Email is not valid!"
                />
                <Input
                  id="username"
                  value={user.userName || ''}
                  label="Username"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setUser({
                      ...user,
                      userName: e.target.value,
                    })
                    if (e.target.value && usernameError) {
                      setUsernameError(false)
                    }
                  }}
                  disabled={!editable}
                  error={usernameError}
                  errorMsg="Username cannot be empty!"
                />
              </HalfCell>
              <HalfCell>
                <Input
                  id="last-name"
                  value={user.lastName || ''}
                  label="Last Name"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setUser({
                      ...user,
                      lastName: e.target.value,
                    })

                    if (e.target.value !== '' && lastNameError) {
                      setLastNameError(false)
                    }
                  }}
                  disabled={!editable}
                  error={lastNameError}
                  errorMsg="Last name cannot be empty!"
                />
                <Input
                  id="phone"
                  value={user.phone || ''}
                  label="Phone number"
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setUser({
                      ...user,
                      phone: e.target.value,
                    })
                  }
                  disabled={!editable}
                />
                <CountryLabel htmlFor="country">Country</CountryLabel>
                <Select
                  id="country"
                  options={countries}
                  value={userCountry}
                  styles={selectStyles}
                  placeholder=""
                  isDisabled={!editable}
                  onChange={e => {
                    setUser({
                      ...user,
                      countryName: e?.name,
                    })
                  }}
                />
              </HalfCell>
            </DetailsWrapper>
          </BigCell>
          <SmallCell $editable={editable}>
            <IngameDetails />
          </SmallCell>
          <Cell>
            <SocialMedia editable={editable} />
          </Cell>
        </EditableSection>
      </InnerWrapper>
    </DetailsSectionContainer>
  )
}

const DetailsSectionContainer = styled(SectionContainer)`
  padding: 26px 0 8px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const InnerWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  width: 25%;
  display: inline-block;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    width: 100%;
    background: linear-gradient(
      342.51deg,
      rgba(3, 41, 96, 0.97) -15.95%,
      rgba(93, 129, 229, 0.97) 87.37%,
      rgba(93, 129, 229, 0.97) 87.38%
    );
    mix-blend-mode: normal;
    backdrop-filter: blur(17.5px);
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 0px 0px 25px 25px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
  }
`

const DetailsWrapper = styled.div`
  width: 75%;
  display: inline-block;
  vertical-align: middle;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const BigCell = styled.div`
  width: 45%;
  display: inline-block;
  vertical-align: top;
  padding-right: 29px;

  @media screen and (max-width: 1010px) {
    width: 100%;
    padding-right: 0;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0;
  }
`

const SmallCell = styled.div<{ $editable?: boolean }>`
  width: 25%;
  display: inline-block;
  vertical-align: top;
  padding: 0 33px 20px;
  border-left: 1px solid rgba(151, 151, 151, 0.15);
  border-right: 1px solid rgba(151, 151, 151, 0.15);
  transition: all 0.4s;

  ${({ $editable }) =>
    $editable &&
    `
    border-left: 1px solid rgba(86, 135, 244, 0.23);
    border-right: 1px solid rgba(86, 135, 244, 0.23);
  `}

  @media screen and (max-width: 1010px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Cell = styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
  padding: 0 70px 0 30px;

  @media screen and (max-width: 1010px) {
    width: 50%;
    padding: 0 20px;
  }
`

const HalfCell = styled.div`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  padding: 0 9px;
`

const CountryLabel = styled.label`
  font-size: 10px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 4px;
`

export default ProfileDetails
