import colors from '../../../../../styles/colors'

interface TwitterIconProps {
  color: string
}

const TwitterIcon = ({ color }: TwitterIconProps) => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.9643 0H2.03571C0.91183 0 0 0.923828 0 2.0625V17.1875C0 18.3262 0.91183 19.25 2.03571 19.25H16.9643C18.0882 19.25 19 18.3262 19 17.1875V2.0625C19 0.923828 18.0882 0 16.9643 0ZM14.8904 6.82344C14.8989 6.94375 14.8989 7.06836 14.8989 7.18867C14.8989 10.9141 12.0998 15.2066 6.98504 15.2066C5.40737 15.2066 3.9442 14.7426 2.71429 13.9434C2.93906 13.9691 3.15536 13.9777 3.38438 13.9777C4.68638 13.9777 5.88237 13.5309 6.83661 12.7746C5.61518 12.7488 4.58884 11.9367 4.23683 10.8195C4.66518 10.884 5.05112 10.884 5.49219 10.768C4.21987 10.5059 3.26562 9.37148 3.26562 8.00078V7.96641C3.6346 8.17695 4.06719 8.30586 4.52098 8.32305C4.13947 8.06594 3.82671 7.71713 3.61062 7.30776C3.39454 6.89839 3.28184 6.44119 3.28259 5.97695C3.28259 5.45273 3.4183 4.97148 3.66004 4.55469C5.02991 6.26484 7.08683 7.38203 9.39397 7.50234C8.99955 5.59023 10.4118 4.03906 12.1083 4.03906C12.9098 4.03906 13.6308 4.37852 14.1397 4.92852C14.7674 4.8082 15.3696 4.57187 15.904 4.24961C15.6962 4.90273 15.2594 5.45273 14.6826 5.80078C15.2424 5.74062 15.7853 5.58164 16.2857 5.3625C15.9083 5.92539 15.4333 6.42383 14.8904 6.82344Z"
        fill={color || colors.grey350}
      />
    </svg>
  )
}

export default TwitterIcon
