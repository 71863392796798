import styled from 'styled-components'
import colors from '../../styles/colors'

const AlphaBanner = () => {
  return (
    <Container>
      <img src="/images/warning.svg" alt="warning" />
      This is an alpha version of the platform - not all features are available
    </Container>
  )
}

const Container = styled.div`
  padding: 8px 24px;
  background: #ffcb46;
  color: ${colors.blue900};
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;

  & > img {
    width: 16px;
    margin-bottom: -1px;
  }
`

export default AlphaBanner
