import styled from 'styled-components'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import PayoutSection from '../Profile/components/PayoutSection'
import DashboardNFTRequests from './components/DashboardNFTRequests'
import EarningsSection from './components/EarningsSection'
import Games from './components/Games'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'
import { useUser } from '../../contexts/AuthContext'

const DashboardPage = () => {
  const { isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer>
        <AlphaBanner />
        <Wrapper>
          <Games />
          <PayoutSection editable={false} />
          <EarningsSection />
          <DashboardNFTRequests />
        </Wrapper>
      </PageContainer>
    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 22px;
`

export default DashboardPage
