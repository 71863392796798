import { LinkButton } from '../Button'
import styled from 'styled-components'
import { useUser } from '../../contexts/AuthContext'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'

interface ProfileImageProps {
  disabled?: boolean
}

const ProfileImage = ({ disabled }: ProfileImageProps) => {
  const { user, setUser } = useUser()

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0]) {
        setUser({
          ...user,
          photo: acceptedFiles[0],
          previewPhoto: URL.createObjectURL(acceptedFiles[0]),
        })
      }
    },
    [setUser, user]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: 1, disabled })

  return (
    <Wrapper {...getRootProps()}>
      <Image src={user.previewPhoto || '/images/avatar.svg'} alt="default profile avatar" />
      <ButtonWrapper>
        <input {...getInputProps()} />
        <LinkButton disabled={disabled} data-test-id="change-photo-btn">
          Change photo
        </LinkButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
`

const Image = styled.img`
  width: 95px;
  height: 95px;
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    width: 72px;
    height: 72px;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 14px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export default ProfileImage
