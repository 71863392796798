import styled from 'styled-components'
import colors from '../../../../../styles/colors'
import { maskPublicAddress } from '../../../../../utils/wallet'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Popup from '../../../../../components/Popup'
import { useEffect, useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import ConfirmDeleteModal from './ConfirmDeleteModal'

interface WalletComponentProps {
  id: number
  name: string
  publicAddress: string
  network: string
  backgroundImageUrl: string
  noDots?: boolean
  paymentsPage?: boolean
  edit?: (id: number) => void
  isSelected?: boolean
}

const WalletComponent = ({
  id,
  name,
  publicAddress,
  network,
  noDots,
  edit,
  backgroundImageUrl,
  paymentsPage,
  isSelected,
}: WalletComponentProps) => {
  const [openPopup, setOpenPopup] = useState<boolean>(false)
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState<boolean>(false)
  const [openCopyTooltip, setOpenCopyTooltip] = useState<boolean>(false)
  const target = useRef(null)

  useEffect(() => {
    let timeout: any = null
    if (openCopyTooltip) {
      timeout = setTimeout(() => {
        setOpenCopyTooltip(false)
      }, 3000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [openCopyTooltip])

  return (
    <Container $backgroundImageUrl={backgroundImageUrl} $selected={isSelected}>
      <NameWrapper>
        <Name $paymentsPage={paymentsPage}>{name}</Name>
        <Network>{network}</Network>
      </NameWrapper>
      <Overlay placement={'top'} show={openCopyTooltip} target={target.current}>
        {props => (
          <Tooltip id={`tooltip-top`} {...props}>
            Copied!
          </Tooltip>
        )}
      </Overlay>
      <CopyToClipboard
        text={publicAddress}
        onCopy={e => {
          setOpenCopyTooltip(true)
        }}
      >
        <Address $paymentsPage={paymentsPage} ref={target}>
          {paymentsPage ? publicAddress : maskPublicAddress(publicAddress)}{' '}
          <Icon src="/images/copy.svg" alt="copy" />
        </Address>
      </CopyToClipboard>
      {paymentsPage ? (
        <ButtonWrapper>
          <BtnIcon
            onClick={() => {
              setOpenConfirmDeleteModal(true)
            }}
            src="/images/delete.svg"
            alt="delete"
          />
          <BtnIcon
            onClick={() => {
              edit?.(id)
            }}
            src="/images/pencil.svg"
            alt="edit"
          />
        </ButtonWrapper>
      ) : null}
      {!noDots ? (
        <>
          <MoreWrapper onClick={() => setOpenPopup(!openPopup)}>
            <More src="/images/white-more.svg" alt="more" />
          </MoreWrapper>
          <Popup open={openPopup} minWidth={80} noPadding topLeft>
            <div>
              <EditButton
                onClick={() => {
                  setOpenPopup(false)
                  edit?.(id)
                }}
              >
                <BtnIcon src="/images/pencil.svg" alt="edit" /> EDIT
              </EditButton>
            </div>
            <div>
              <DeleteButton
                onClick={() => {
                  setOpenConfirmDeleteModal(true)
                  setOpenPopup(false)
                }}
              >
                <BtnIcon src="/images/delete.svg" alt="delete" /> DELETE
              </DeleteButton>
            </div>
          </Popup>
        </>
      ) : null}
      <ConfirmDeleteModal
        openConfirmDeleteModal={openConfirmDeleteModal}
        setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
        walletId={id}
      />
    </Container>
  )
}

const Container = styled.div<{ $backgroundImageUrl: string; $selected?: boolean }>`
  border-radius: 8px;
  padding: 4px 12px;
  background-color: ${colors.blue500};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: ${colors.white};
  position: relative;

  ${({ $backgroundImageUrl }) =>
    $backgroundImageUrl
      ? `
    background-image: url(${$backgroundImageUrl});
  `
      : ``}

  ${({ $selected }) =>
    $selected &&
    `
    border: 2px solid ${colors.blue500};
  `}
`

const Network = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`

const Name = styled.span<{ $paymentsPage?: boolean }>`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  width: calc(100% - 100px);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ $paymentsPage }) =>
    $paymentsPage &&
    `
    width: 100px;
  `}
`

const Address = styled.span<{ $paymentsPage?: boolean }>`
  font-size: 12px;
  line-height: 24px;
  background: ${colors.white};
  color: ${colors.black};
  padding: 2px 4px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;

  ${({ $paymentsPage }) =>
    $paymentsPage &&
    `
    left: calc(50% - 150px);
    right: auto;
    min-width: 300px;
    text-align: center;
  `}
`

const Icon = styled.img`
  vertical-align: middle;
  margin-left: 4px;
  margin-top: -2px;
`

const More = styled.img``

const MoreWrapper = styled.div`
  width: 20px;
  display: inline-block;
  text-align: center;
  position: absolute;
  right: 0px;
  top: 8px;
  cursor: pointer;

  & + div {
    text-align: left;
    bottom: -35px;
    right: 0px;
    left: auto;
  }
`

const EditButton = styled.button`
  background: ${colors.white};
  color: ${colors.blue400};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border: 0;
  padding: 5px 7px;
  width: 100%;
  text-align: left;

  &:hover {
    background: ${colors.grey100};
  }
`

const DeleteButton = styled.button`
  background: ${colors.white};
  color: #d32f2f;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border: 0;
  padding: 5px 7px;
  width: 100%;
  text-align: left;

  &:hover {
    background: ${colors.grey100};
  }
`

const BtnIcon = styled.img`
  vertical-align: middle;
  width: 10px;
  margin-top: -2px;
  margin-right: 4px;
  cursor: pointer;
`

const ButtonWrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 12.5px;
  width: 60px;
  position: absolute;
  top: 8px;
  right: 12px;
  justify-content: center;
  display: flex;
  gap: 8px;
  height: 28px;
  padding-left: 3px;

  & > img {
    width: 12px;
  }

  & > img:last-child {
    width: 14px;
  }
`

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default WalletComponent
