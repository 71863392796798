import styled from 'styled-components'

export const BlueContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: linear-gradient(
    117.4deg,
    rgba(3, 41, 96, 0.97) 0%,
    rgba(93, 129, 229, 0.97) 143.98%,
    rgba(93, 129, 229, 0.97) 143.99%
  );
  mix-blend-mode: normal;
  backdrop-filter: blur(17.5px);
`

export const BackgroundWrapper = styled.div`
  height: 100vh;
  background-image: url('/images/login-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  opacity: 0.6;
`
