import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getUnreadNotifications = async () => {
  const data = await API.authGet('notification?isUnread=true')

  return data
}

const useGetUnreadNotificationsQuery = () => {
  return useQuery([QUERY_KEYS.UNREAD_NOTIFICATIONS], getUnreadNotifications)
}

export default useGetUnreadNotificationsQuery
