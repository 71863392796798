const fonts = {
  fontFamilyAlata: 'Alata',
  fontFamilyLight: 'Manrope Light',
  fontFamilyExtraLight: 'Manrope ExtraLight',
  fontFamilyRegular: 'Manrope Regular',
  fontFamilyMedium: 'Manrope Medium',
  fontFamilySemiBold: 'Manrope SemiBold',
  fontFamilyBold: 'Manrope Bold',
  fontFamilyExtraBold: 'Manrope ExtraBold',
}

export default fonts
