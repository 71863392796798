import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import useUserQuery from '../queries/useUserQuery'
import ROUTES, { noAuthRoutes } from '../routes'
import { User, UserType } from '../types/user'
import { beautifyUser } from '../utils/user'

const AuthContext = createContext<{
  user: User
  setUser: (user: User) => void
  type: UserType | null
  refetchUser: () => void
  tutorialStep: number
  setTutorialStep: (n: number) => void
  isUserInitialLoading: boolean
  isUserLoading: boolean
  isUserSuccess: boolean
}>({
  user: {},
  type: null,
  setUser: (user: User) => {},
  refetchUser: () => {},
  tutorialStep: 0,
  setTutorialStep: () => {},
  isUserLoading: false,
  isUserSuccess: false,
  isUserInitialLoading: false,
})

const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<User>({})
  const [tutorialStep, setTutorialStep] = useState<number>(0)
  const [type, _] = useState<UserType | null>(null)

  const {
    data: userData,
    error,
    refetch,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useUserQuery()

  useEffect(() => {
    if (userData) {
      const userJSON = beautifyUser(userData)
      setUser(userJSON)
      const tutorialJSON = window.sessionStorage.getItem('tutorial_steps')
      if ((userJSON.loginCount || 0) === 1 && (tutorialJSON === null || Number(tutorialJSON) < 8)) {
        setTutorialStep(Number(tutorialJSON || '1'))
      }
    }
  }, [userData])

  useEffect(() => {
    if (tutorialStep > 0) {
      window.sessionStorage.setItem('tutorial_steps', `${tutorialStep}`)
    }
  }, [tutorialStep])

  useEffect(() => {
    const path = window.location.pathname
    const clearPath = path.substring(1)
    if (error && !noAuthRoutes.includes(clearPath as ROUTES)) {
      window.location.href = '/login'
    }
  }, [error])

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        type,
        refetchUser: refetch,
        tutorialStep,
        setTutorialStep,
        isUserLoading,
        isUserSuccess,
        isUserInitialLoading: user?.id ? false : isUserLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(AuthContext)

  return context
}

export default AuthContextProvider
