import colors from '../../../../../styles/colors'

interface EuroSignProps {
  color: string
}

const EuroSign = ({ color }: EuroSignProps) => {
  return (
    <svg width="33" height="45" viewBox="0 0 33 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 20.9062C1.45539 20.9062 0 19.4818 0 17.7188C0 15.9557 1.45539 14.5312 3.25 14.5312H6.1232C9.13047 6.17502 17.1742 0.1875 26.8125 0.1875H29.25C31.0477 0.1875 32.5 1.6149 32.5 3.375C32.5 5.1351 31.0477 6.5625 29.25 6.5625H26.8125C20.932 6.5625 15.8234 9.78984 13.2133 14.5312H26C27.7977 14.5312 29.25 15.9557 29.25 17.7188C29.25 19.4818 27.7977 20.9062 26 20.9062H11.3953C11.3852 21.1652 11.375 21.4342 11.375 21.7031V23.2969C11.375 23.5658 11.3852 23.8348 11.3953 24.0938H26C27.7977 24.0938 29.25 25.5182 29.25 27.2812C29.25 29.0443 27.7977 30.4688 26 30.4688H13.2133C15.8234 35.2102 20.932 38.4375 26.8125 38.4375H29.25C31.0477 38.4375 32.5 39.8619 32.5 41.625C32.5 43.3881 31.0477 44.8125 29.25 44.8125H26.8125C17.1742 44.8125 9.13047 38.826 6.1232 30.4688H3.25C1.45539 30.4688 0 29.0443 0 27.2812C0 25.5182 1.45539 24.0938 3.25 24.0938H4.89023C4.88008 23.8248 4.875 23.5658 4.875 23.2969V21.7031C4.875 21.4342 4.88008 21.1752 4.89023 20.9062H3.25Z"
        fill={color || colors.white}
      />
    </svg>
  )
}

export default EuroSign
