import axios from 'axios'

// const DEV_API_URL = 'https://virtue-platform-dev-api.azurewebsites.net'

const DEV_API_URL = window.backEndConfig?.apiPrefix || ''

const API = axios.create({
  baseURL: DEV_API_URL,
})

export default API
