import { useQuery } from '@tanstack/react-query'
import API, { VA_TOKEN } from '../api'
import QUERY_KEYS from '../api/keys'

const getUser = async () => {
  const data = await API.authGet('user/profile')

  return data
}

const useUserQuery = () => {
  const token = window.localStorage.getItem(VA_TOKEN)
  return useQuery([QUERY_KEYS.USER, token], getUser, {
    staleTime: 60 * 1000,
    retry: 1,
  })
}

export default useUserQuery
