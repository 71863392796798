import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

interface WalletParams {
  id: number
}

const deleteWallet = async ({ id }: WalletParams) => {
  const data = await API.authDelete(`wallet?payoutWalletId=${id}`)

  return data
}

const useDeleteWalletMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: WalletParams) => deleteWallet(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER] })
    },
  })
}

export default useDeleteWalletMutation
