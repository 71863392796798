import { useMutation, useQueryClient } from '@tanstack/react-query'
import API, { VA_TOKEN } from '../../../../../api'
import API_INSTANCE from '../../../../../api/config'
import QUERY_KEYS from '../../../../../api/keys'
import { EditableUserServer } from '../../../../../types/user'

const editProfile = async (user: EditableUserServer) => {
  const data = await API.authPut('user/update', {
    ...user,
  })

  if (user.photo) {
    const token = window.localStorage.getItem(VA_TOKEN)

    await API_INSTANCE.post(
      'user/photo',
      {
        ProfilePicture: user.photo,
      },
      {
        headers: {
          accept: `*/*`,
          'Content-Type': `multipart/form-data`,
          Authorization: `Bearer ${token}`,
        },
      }
    )
  }

  return data
}

interface EditProfileParams {
  user: EditableUserServer
}

const useEditProfile = () => {
  const queryClient = useQueryClient()

  return useMutation(({ user }: EditProfileParams) => editProfile(user), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.USER])
      queryClient.invalidateQueries([QUERY_KEYS.TEAM])
    },
  })
}

export default useEditProfile
