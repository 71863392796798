import { format } from 'date-fns'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { SecondaryButton, SubmitButton } from '../../../components/Button'
import Modal from '../../../components/Modal'
import WithdrawModal from '../../../components/WithdrawModal'
import colors from '../../../styles/colors'
import fonts from '../../../styles/fonts'
import { maskPublicAddress } from '../../../utils/wallet'
import useCancelPaymentMutation from '../queries/useCancelPaymentMutation'

const Payment = (props: any) => {
  const { mutateAsync: cancelPayment, isLoading } = useCancelPaymentMutation()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)

  return (
    <>
      <Wrapper>
        <InfoWrapper>
          <Icon src="/images/wallet-icon.svg" alt="wallet" />
          <DateColumn>
            <DateLabel>{format(new Date(props.createdOn), 'MMM dd, yyyy')}</DateLabel>
            <Address>{maskPublicAddress(props.address)}</Address>
          </DateColumn>
        </InfoWrapper>
        <DetailsWrapper>
          <Status
            $pending={props.paymentRequestStatus === 'Pending'}
            $canceled={props.paymentRequestStatus === 'Canceled'}
            $rejected={props.paymentRequestStatus === 'Rejected'}
          >
            {props.paymentRequestStatus}
          </Status>
          <Amount>
            {props.currencyCode} {props.amount}
          </Amount>
          {props.paymentRequestStatus === 'Pending' ? (
            <ActionIcon
              className="action"
              src="/images/pencil.svg"
              alt="edit"
              onClick={() => {
                setOpenModal(true)
              }}
            />
          ) : null}
          {props.paymentRequestStatus === 'Pending' ? (
            <HideIcon
              className="action"
              src="/images/blue-cross.svg"
              alt="delete"
              onClick={() => {
                setOpenCancelModal(true)
              }}
            />
          ) : null}
        </DetailsWrapper>
      </Wrapper>
      <Modal
        bigSize
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        title="You are about to cancel the selected request.<br /> Do you confirm your choice?"
      >
        <Buttons>
          <SecondaryStyledButton
            onClick={() => {
              setOpenCancelModal(false)
            }}
          >
            NO
          </SecondaryStyledButton>
          <SubmitStyledButton
            disabled={isLoading}
            onClick={async () => {
              await cancelPayment({
                id: props.id,
              })
              setOpenCancelModal(false)
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={isLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            YES
          </SubmitStyledButton>
        </Buttons>
      </Modal>
      <WithdrawModal setOpenModal={setOpenModal} openModal={openModal} selectedWithdraw={props} />
    </>
  )
}

const Wrapper = styled.div`
  background: rgba(93, 129, 229, 0.1);
  border-radius: 8px;
  padding: 7px 16px 3px;
  display: flex;
  gap: 14px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &:hover {
    & .action {
      opacity: 1;
    }
  }
`

const InfoWrapper = styled.div`
  display: flex;
  gap: 14px;
`

const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const DateLabel = styled.span`
  color: ${colors.blue800};
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  font-family: ${fonts.fontFamilyBold};
`

const Address = styled.span`
  font-size: 12px;
  line-height: 24px;
  color: ${colors.grey500};
`

const Icon = styled.img`
  width: 24px;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-right: 95px;
  justify-content: space-between;
  min-width: 300px;
`

const Status = styled.div<{ $pending?: boolean; $canceled?: boolean; $rejected?: boolean }>`
  border-radius: 12.5px;
  font-size: 12px;
  line-height: 16px;
  padding: 3px 14px 1px;
  background: #1dac56;
  width: 90px;
  text-align: center;
  color: ${colors.white};

  ${({ $pending }) =>
    $pending &&
    `
    background: #FA8E40;
    color: ${colors.blue800};
  `}
  ${({ $canceled }) =>
    $canceled &&
    `
    background: ${colors.grey400};
    color: ${colors.blue800};
  `}
  ${({ $rejected }) =>
    $rejected &&
    `
    background: #d32f2f;
    color: ${colors.white};
  `}
`

const Amount = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: ${fonts.fontFamilyBold};
  color: ${colors.blue800};
`

const ActionIcon = styled.img`
  width: 17px;
  cursor: pointer;
  position: absolute;
  right: 57px;
  top: 17px;
  opacity: 0;
  transition: all 0.4s;
`

const HideIcon = styled.img`
  width: 17px;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 17px;
  opacity: 0;
  transition: all 0.4s;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const SecondaryStyledButton = styled(SecondaryButton)`
  width: 70px;
`

const SubmitStyledButton = styled(SubmitButton)`
  min-width: 70px;
`
export default Payment
