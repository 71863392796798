import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { VA_TOKEN } from '../../api'
import { useUser } from '../../contexts/AuthContext'
import useGetUnreadNotificationsQuery from '../../queries/useGetUnreadNotificationsQuery'
import colors from '../../styles/colors'

const MobileNav = () => {
  const { user } = useUser()
  const navigate = useNavigate()
  const [openMobileMenu, setOpenMobileMenu] = useState(false)
  const queryClient = useQueryClient()
  const { data: notifications } = useGetUnreadNotificationsQuery()

  return (
    <>
      <Container>
        <Wrapper>
          <LinkWrapper
            onClick={() => {
              navigate('/')
            }}
          >
            <Image src={user.previewPhoto || '/images/avatar.svg'} alt="profile avatar" />
          </LinkWrapper>
          <LinkWrapper
            onClick={() => {
              navigate('/dashboard')
            }}
          >
            <Icon src="/images/dashboard-icon.svg" alt="dashboard icon" />
          </LinkWrapper>
          <LinkWrapper
            onClick={() => {
              navigate('/games')
            }}
          >
            <Icon src="/images/games-icon.svg" alt="games icon" />
          </LinkWrapper>
          <LinkWrapper
            onClick={() => {
              navigate('/marketplace?isLeased=false')
            }}
          >
            <Icon src="/images/marketplace-icon.svg" alt="marketplace icon" />
          </LinkWrapper>
          <LinkWrapper
            onClick={() => {
              navigate('/notifications')
            }}
          >
            {notifications?.length > 0 ? (
              <NavNotificationsNumber>{notifications.length}</NavNotificationsNumber>
            ) : null}
            <Icon src="/images/bell.svg" alt="notifications icon" />
          </LinkWrapper>
          <LinkWrapper
            onClick={() => {
              setOpenMobileMenu(true)
            }}
          >
            <Icon src="/images/menu.svg" alt="menu icon" />
          </LinkWrapper>
        </Wrapper>
      </Container>
      {openMobileMenu ? (
        <MobileMenu>
          <Header>
            <Logo src={'/images/small-logo.svg'} alt="virtue alliance" />
            <MenuLogo
              src={user.previewPhoto || '/images/avatar.svg'}
              alt="profile avatar"
              onClick={() => {
                navigate('/')
                setOpenMobileMenu(false)
              }}
            />
            <CrossIcon
              src={'/images/white-cross-icon.svg'}
              alt="close"
              onClick={() => {
                setOpenMobileMenu(false)
              }}
            />
          </Header>
          <Content>
            <MenuItem
              onClick={() => {
                navigate('/dashboard')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/dashboard-icon.svg" alt="dashboard icon" />
              <LinkLabel>Dashboard</LinkLabel>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/games')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/games-icon.svg" alt="games icon" />
              <LinkLabel>Games</LinkLabel>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/marketplace?isLeased=false')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/marketplace-icon.svg" alt="marketplace icon" />
              <LinkLabel>Marketplace</LinkLabel>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/analytics')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/analytics-icon.svg" alt="analytics icon" />
              <LinkLabel>Analytics</LinkLabel>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/payments')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/withdraws.svg" alt="withdraws icon" />
              <LinkLabel>Payments</LinkLabel>
            </MenuItem>
            <MenuItem $disabled>
              <Icon src="/images/tournaments-icon.svg" alt="tournaments icon" />
              <LinkLabel>Tournaments</LinkLabel>
            </MenuItem>
          </Content>
          <Footer>
            <MenuItem
              onClick={() => {
                navigate('/notifications')
                setOpenMobileMenu(false)
              }}
            >
              <Icon src="/images/bell.svg" alt="notifications icon" />
              {notifications?.length > 0 ? (
                <NotificationsNumber>{notifications.length}</NotificationsNumber>
              ) : null}
              <LinkLabel>Notifications</LinkLabel>
            </MenuItem>
            <MenuItem
              onClick={() => {
                window.localStorage.removeItem(VA_TOKEN)
                queryClient.clear()
                window.location.href = '/login'
              }}
            >
              <Icon src="/images/logout.svg" alt="logout icon" />
              <LinkLabel>Logout</LinkLabel>
            </MenuItem>
          </Footer>
        </MobileMenu>
      ) : null}
    </>
  )
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 70px;
  background: ${colors.blue500};
  z-index: 99998;
`

const MobileMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    142.71deg,
    rgb(3, 41, 96) 0%,
    rgb(93, 129, 229) 99.99%,
    rgb(93, 129, 229) 100%
  );
  z-index: 99999;
  padding: 30px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
`

const Logo = styled.img`
  width: 47px;
`

const MenuLogo = styled.img`
  width: 82px;
  position: absolute;
  bottom: -41px;
  left: calc(50% - 41px);
  border-radius: 50%;
`

const CrossIcon = styled.img`
  width: 22px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 70px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding-bottom: 30px;
  margin-bottom: 30px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  gap: 12px;
  padding: 0 20px;
`

const Image = styled.img`
  width: 45px;
  border-radius: 50%;
`

const Icon = styled.img`
  width: 23px;
`

const LinkWrapper = styled.div`
  cursor: pointer;
  position: relative;
`

const MenuItem = styled.div<{ $disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  padding: 12px 0;
  position: relative;

  ${({ $disabled }) =>
    $disabled &&
    `
    cursor: not-allowed;
    mix-blend-mode: soft-light;
  `}
`

const LinkLabel = styled.div`
  color: ${colors.white};
  font-size: 18px;
  line-height: 16px;
  opacity: 1;
`

const NotificationsNumber = styled.span`
  display: inline-block;
  position: absolute;
  top: 0px;
  left: 10px;
  background: #ffce1e;
  color: ${colors.blue900};
  font-size: 14px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
`

const NavNotificationsNumber = styled.span`
  display: inline-block;
  position: absolute;
  top: -8px;
  left: 10px;
  background: #ffce1e;
  color: ${colors.blue900};
  font-size: 14px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
`

export default MobileNav
