import { createGlobalStyle } from 'styled-components'
import colors from './colors'
import fonts from './fonts'

const GlobalStyles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: ${fonts.fontFamilyRegular};
    min-height: 100vh;
    background: ${colors.grey300};
  }

  html {
    scroll-behavior: initial !important;
  }

  #root {
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }
  
  h1, h2, h3, h4, h5 {
    font-family: ${fonts.fontFamilyBold};
  }

  span {
    font-family: ${fonts.fontFamilyRegular};
  }

  p {
    font-family: ${fonts.fontFamilyRegular};
  }

  button {
    font-family: ${fonts.fontFamilyLight};
    cursor: pointer;
  }

  input {
    font-family: ${fonts.fontFamilyRegular};
  }

  label {
    font-family: ${fonts.fontFamilyRegular};
  }

  a {
    color: inherit;
    text-decoration: none;
    font-family: ${fonts.fontFamilyLight};
    cursor: pointer;
  }

  .popover-arrow:before {
    border-bottom-color: rgba(0, 0, 0, 0.05) !important;
  }

  .popover-arrow:after {
    border-bottom-color: #EEF3FF !important;
  }

  .popover[data-popper-placement="right"] .popover-arrow:before {
    border-right-color: #EEF3FF !important;
  }

  .DateInput_input__focused {
    border-bottom: 0;
  }
  .DateRangePickerInput__withBorder {
    border: 0;
  }
  .DateInput_input__small {
    font-size: 12px;
    padding: 3px 8px;
  }
  .DateRangePicker {
    background: ${colors.blue100};
  }

  .DateInput_input,
  .DateRangePickerInput__withBorder {
    background: ${colors.blue100};
    font-size: 12px;
    padding: 2px 10px;
  }

  .DateInput {
    width: 100px;
  }

  .DateRangePickerInput_clearDates {
    margin: 0;
    margin-top: -2px;
  }
  
  .DateRangePickerInput_clearDates:hover {
    background: transparent;
  }

  .DateRangePickerInput_arrow_svg {
    width: 12px;
    height: 12px;
    margin-top: -4px;
  }

  .SingleDatePickerInput_clearDate,
  .DayPickerKeyboardShortcuts_show {
    display: none;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: ${colors.blue500};
    border: 1px solid ${colors.blue500};
  }
  
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__selected_span {
    background: ${colors.blue300};
    border: 1px solid ${colors.blue500};
  }

  #tooltip-top .tooltip-inner {
    color: ${colors.blue900};
    background: ${colors.white};
    box-shadow: 0px 4px 10px rgba(86, 135, 244, 0.29);
  }

  .bs-tooltip-top .tooltip-arrow::before {
    border-top-color: ${colors.white};
  }

  #tooltip-top {
    z-index: 99999;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .tutorial.tooltip .tooltip-inner {
    background: ${colors.blue900};
    color: ${colors.white};
    box-shadow: 0px 4px 10px rgba(86, 135, 244, 0.29);
    padding: 12px;
  }

  .bs-tooltip-top.tutorial .tooltip-arrow::before {
    border-top-color: ${colors.blue900};
  }
  .bs-tooltip-bottom.tutorial .tooltip-arrow::before {
    border-bottom-color: ${colors.blue900};
  }
`

export default GlobalStyles
