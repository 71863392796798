import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const clearAllNotifications = async () => {
  const data = await API.authPost('notification/clear', {})

  return data
}

const useClearAllNotificationsMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(() => clearAllNotifications(), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.ALL_READ_NOTIFICATIONS])
    },
  })
}

export default useClearAllNotificationsMutation
