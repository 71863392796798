import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { LinkButton, SubmitButton } from '../../components/Button'

const NotFoundPage = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <p>Oops, wrong page?</p>
      <SubmitButton
        onClick={() => {
          navigate('/login')
        }}
      >
        Try to login
      </SubmitButton>
      <p>or</p>
      <LinkButton
        onClick={() => {
          navigate('/signup')
        }}
      >
        Sign up
      </LinkButton>
    </Container>
  )
}

const Container = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export default NotFoundPage
