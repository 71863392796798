import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { SecondaryButton, SubmitButton } from '../Button'
import Input from '../Input'
import Modal from '../Modal'
import Wallet from '../../types/wallet'
import useUpdateWalletMutation from '../../pages/Profile/components/CardDetailsSection/queries/useUpdateWalletMutation'
import useAddWalletMutation from '../../pages/Profile/components/CardDetailsSection/queries/useAddWalletMutation'
import useNetworksQuery from '../../pages/Profile/components/CardDetailsSection/queries/useNetworksQuery'
import { ErrorMsg } from '../ErrorMessage'
import { selectNetworkStyles } from '../../utils/dropdown'
import Select from 'react-select'
import { useUser } from '../../contexts/AuthContext'

interface WalletModalProps {
  openWeb3Modal: boolean
  setOpenWeb3Modal: (o: boolean) => void
  walletDetails: Wallet
  setWalletDetails: (w: Wallet) => void
}

const WalletModal = ({
  openWeb3Modal,
  setOpenWeb3Modal,
  walletDetails,
  setWalletDetails,
}: WalletModalProps) => {
  const { mutateAsync: addWallet, isLoading: isLoadingWallet } = useAddWalletMutation()
  const { mutateAsync: updateWallet, isLoading: isLoadingUpdateWallet } = useUpdateWalletMutation()
  const [walletTitleError, setWalletTitleError] = useState<boolean>(false)
  const [walletNetworkError, setWalletNetworkError] = useState<boolean>(false)
  const [walletPublicAddressError, setWalletPublicAddressError] = useState<boolean>(false)
  const { data: networks } = useNetworksQuery()
  const [closeModal, setCloseModal] = useState<boolean>(false)
  const [updatedWallet, setUpdatedWallet] = useState<Wallet>({})
  const { user } = useUser()

  useEffect(() => {
    const wallet = user?.payoutWallets?.find?.(
      (w: Wallet) => w.id === updatedWallet?.id && w.name === updatedWallet.name
    )

    if (closeModal && wallet) {
      setOpenWeb3Modal(false)
      setCloseModal(false)
      setWalletDetails({})
      document.getElementById('close-modal')?.click()
    }
  }, [closeModal, user, updatedWallet])

  const selectedNetwork = useMemo(() => {
    const net = networks?.filter((network: any) => {
      return network.name === walletDetails.network
    })?.[0]

    return net
  }, [walletDetails.network, networks])

  const handleWalletSubmit = async () => {
    if (!walletDetails.name) {
      setWalletTitleError(true)
      return
    }
    if (!selectedNetwork?.id) {
      setWalletNetworkError(true)
      return
    }
    if (!walletDetails.publicAddress) {
      setWalletPublicAddressError(true)
      return
    }

    if (walletDetails.id) {
      const newWallet = await updateWallet({
        name: walletDetails.name,
        id: walletDetails.id,
      })
      setUpdatedWallet(newWallet)
    } else {
      const newWallet = await addWallet({
        networkId: selectedNetwork.id,
        name: walletDetails.name,
        publicAddress: walletDetails.publicAddress,
      })
      setUpdatedWallet(newWallet)
    }
    setCloseModal(true)
  }

  return (
    <Modal
      title={'Add Web 3 Wallet'}
      open={openWeb3Modal}
      setOpen={() => {
        setWalletDetails({})
        setOpenWeb3Modal(false)
      }}
    >
      <ModalBody>
        <Input
          id="wallet-title"
          big
          value={walletDetails.name || ''}
          label="Wallet title"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setWalletDetails({
              ...walletDetails,
              name: e.target.value,
            })
            if (e.target.value !== '' && walletTitleError) {
              setWalletTitleError(false)
            }
          }}
          error={walletTitleError}
          errorMsg="Wallet title cannot be empty!"
        />
        <NetworksWrapper>
          <NetworkLabel htmlFor="network">Network</NetworkLabel>
          <Select
            id="network"
            isDisabled={!!walletDetails.id}
            options={networks}
            placeholder=""
            value={selectedNetwork}
            styles={selectNetworkStyles}
            onChange={(e: any) => {
              setWalletDetails({
                ...walletDetails,
                network: e?.value || '',
              })
              if (e?.value && walletNetworkError) {
                setWalletNetworkError(false)
              }
            }}
          />
        </NetworksWrapper>
        <Input
          id="public-address"
          big
          value={walletDetails.publicAddress || ''}
          disabled={!!walletDetails.id}
          label="Public address"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setWalletDetails({
              ...walletDetails,
              publicAddress: e.target.value,
            })
            if (e.target.value !== '' && walletPublicAddressError) {
              setWalletPublicAddressError(false)
            }
          }}
          error={walletPublicAddressError}
          errorMsg="Public address cannot be empty!"
        />
        {walletNetworkError ? <ErrorMsg>Network cannot be empty!</ErrorMsg> : null}
        <ButtonsWrapper>
          <Cell $paddingRight>
            <SButton
              onClick={() => {
                setWalletDetails({})
                document.getElementById('close-modal')?.click()
              }}
            >
              CANCEL
            </SButton>
          </Cell>
          <Cell $paddingLeft>
            <SubButton
              disabled={isLoadingWallet || isLoadingUpdateWallet || closeModal}
              onClick={handleWalletSubmit}
            >
              <ClipLoader
                color={colors.white}
                loading={isLoadingWallet || isLoadingUpdateWallet || closeModal}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              {walletDetails?.id ? 'Update' : 'Add'}
            </SubButton>
          </Cell>
        </ButtonsWrapper>
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 12px;
`

const Cell = styled.div<{ $paddingLeft?: boolean; $paddingRight?: boolean }>`
  display: inline-block;
  width: 50%;
  vertical-align: top;

  ${({ $paddingLeft }) =>
    $paddingLeft &&
    `
    padding-left: 5px;
  `}
  ${({ $paddingRight }) =>
    $paddingRight &&
    `
    padding-right: 5px;
  `}
`

const NetworksWrapper = styled.div`
  margin-bottom: 20px;
`

const SButton = styled(SecondaryButton)`
  width: 100%;
`

const SubButton = styled(SubmitButton)`
  width: 100%;
`

const ButtonsWrapper = styled.div`
  margin-top: 20px;
`

const NetworkLabel = styled.label`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 4px;
`

export default WalletModal
