import { formatDistance } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import Select from 'react-select'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import {
  ApproveButton,
  LinkButton,
  RejectButton,
  SecondaryButton,
} from '../../../../components/Button'
import CustomValueContainer from '../../../../components/CustomValueContainer'
import Modal from '../../../../components/Modal'
import { useUser } from '../../../../contexts/AuthContext'
import useGetAllGamesQuery from '../../../../queries/useGetAllGamesQuery'
import colors from '../../../../styles/colors'
import { selectPlayersFilterStyles, selectSearchFilterStyles } from '../../../../utils/dropdown'
import useGetManagerGameDetailsQuery from '../../../Games/queries/useGetManagerGameDetailsQuery'
import useApproveRequestNFTMutation from '../ManagerNFTRequestsSection/queries/useApproveRequestNFTMutation'
import useRejectRequestNFTMutation from '../ManagerNFTRequestsSection/queries/useRejectRequestNFTMutation'
import {
  useManagerActiveNftRequestsModalQuery,
  useManagerClosedNftRequestsModalQuery,
} from './queries/useManagerNftRequestsModalQuery'
import useNFTRequestsAutocompleteQuery from './queries/useNFTRequestsAutocompleteQuery'
import moment from 'moment'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import fonts from '../../../../styles/fonts'
import UserInfoOverlay from './components/UserInfoOverlay'
import { ErrorMsg } from '../../../../components/ErrorMessage'

interface RequestsModalProps {
  openModal: boolean
  setOpenModal: (o: boolean) => void
  preselectedTab?: number
  preselectedGame?: number
}

const RequestsModal = ({
  openModal,
  setOpenModal,
  preselectedTab = 0,
  preselectedGame = 1,
}: RequestsModalProps) => {
  const { user } = useUser()
  const { data: games } = useGetAllGamesQuery()

  const [selectedTab, setSelectedTab] = useState<number>(preselectedTab)
  const [selectedGame, setSelectedGame] = useState<number>(preselectedGame)
  const [isApproved, setIsApproved] = useState<boolean>(true)
  const [selectedFilters, setSelectedFilters] = useState<any>([])
  const [selectedPlayers, setSelectedPlayers] = useState<any>([])
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)

  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [openRejectModal, setOpenRejectModal] = useState<boolean>(false)
  const [selectedRequest, setSelectedRequest] = useState<any>(null)

  const { data: autocompleteData } = useNFTRequestsAutocompleteQuery({
    game: `${selectedGame}`,
    search: selectedFilters.map((f: any) => f.value).join(','),
    players: selectedPlayers.map((p: any) => `&filter.PlayerIds=${p.userId}`).join(''),
    status: selectedTab === 0 ? 'pending' : 'history',
    startDate: startDate ? startDate.format('yyyy-MM-DDTHH:mm:ss') : null,
    endDate: endDate ? endDate.format('yyyy-MM-DDTHH:mm:ss') : null,
  })

  const { data: activeRequests } = useManagerActiveNftRequestsModalQuery({
    game: `${selectedGame}`,
    search: selectedFilters.map((f: any) => f.value).join(','),
    players: selectedPlayers.map((p: any) => `&filter.PlayerIds=${p.userId}`).join(''),
    startDate: startDate ? startDate.format('yyyy-MM-DDTHH:mm:ss') : null,
    endDate: endDate ? endDate.format('yyyy-MM-DDTHH:mm:ss') : null,
    isApproved: isApproved,
  })
  const { data: closedRequests } = useManagerClosedNftRequestsModalQuery({
    game: `${selectedGame}`,
    search: selectedFilters.map((f: any) => f.value).join(','),
    players: selectedPlayers.map((p: any) => `&filter.PlayerIds=${p.userId}`).join(''),
    startDate: startDate ? startDate.format('yyyy-MM-DDTHH:mm:ss') : null,
    endDate: endDate ? endDate.format('yyyy-MM-DDTHH:mm:ss') : null,
    isApproved: isApproved,
  })

  const {
    mutateAsync: approveNft,
    isLoading: approveLoading,
    isError,
  } = useApproveRequestNFTMutation()
  const { mutateAsync: rejectNft, isLoading: rejectLoading } = useRejectRequestNFTMutation()

  const { data: game } = useGetManagerGameDetailsQuery()

  const getSelectOptions = useCallback(() => {
    if (game?.teamMembers) {
      return game.teamMembers.map((member: any) => {
        return {
          ...member,
          value: member.userId,
          label: member.name,
        }
      })
    }

    return []
  }, [game])

  const autocompleteWords = useMemo(() => {
    if (autocompleteData) {
      return autocompleteData.map((a: any) => {
        return {
          value: a,
          label: a,
        }
      })
    }
    return []
  }, [autocompleteData])

  const handleDateChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  return (
    <Modal biggestSize open={openModal} setOpen={setOpenModal} noPadding={true}>
      <TabHeader>
        <Tab
          $selected={selectedTab === 0}
          onClick={() => {
            setSelectedFilters([])
            setSelectedPlayers([])
            setStartDate(null)
            setEndDate(null)
            setSelectedTab(0)
          }}
        >
          Pending
        </Tab>
        <Tab
          $selected={selectedTab === 1}
          onClick={() => {
            setSelectedFilters([])
            setSelectedPlayers([])
            setStartDate(null)
            setEndDate(null)
            setSelectedTab(1)
          }}
        >
          History
        </Tab>
      </TabHeader>
      <Body>
        <SearchContainer>
          <SearchWrapper>
            <Magnifier src="/images/magnifier.svg" />
            <Select
              components={{
                ValueContainer: props => <CustomValueContainer {...props} icon={true} />,
              }}
              id="search-popup-nft"
              options={autocompleteWords}
              value={selectedFilters}
              placeholder="Search"
              styles={selectSearchFilterStyles}
              onChange={(e: any) => {
                const findFilter = selectedFilters.filter((f: any) => f.value === e.value)
                if (findFilter?.length > 0) {
                  const newFilters = selectedFilters.filter((f: any) => f.value !== e.value)

                  setSelectedFilters(newFilters)
                } else {
                  setSelectedFilters([...selectedFilters, e])
                }
              }}
            />
            <div>
              {selectedFilters.map((s: any) => {
                return (
                  <Filter
                    key={s.value}
                    onClick={() => {
                      const newFilters = selectedFilters.filter((f: any) => f.value !== s.value)
                      setSelectedFilters(newFilters)
                    }}
                  >
                    {s.label}
                    <img src="/images/close.svg" alt="delete" />
                  </Filter>
                )
              })}
              {selectedFilters.length > 0 ? (
                <LinkButton
                  onClick={() => {
                    setSelectedFilters([])
                  }}
                >
                  Clear all
                </LinkButton>
              ) : null}
            </div>
          </SearchWrapper>
          {user.userRoleName === 'Manager' ? (
            <FilterWrapper>
              <PlayersWrapper>
                <Select
                  components={{ ValueContainer: CustomValueContainer }}
                  id="players"
                  options={getSelectOptions()}
                  placeholder="All players"
                  value={selectedPlayers}
                  styles={selectPlayersFilterStyles}
                  onChange={(e: any) => {
                    const findPlayer = selectedPlayers.filter(
                      (p: any) => p.userId === e.userId
                    )?.[0]
                    if (findPlayer) {
                      const newPlayers = selectedPlayers.filter((p: any) => p.userId !== e.userId)

                      setSelectedPlayers([...newPlayers])
                    } else {
                      setSelectedPlayers([...selectedPlayers, e])
                    }
                  }}
                />
              </PlayersWrapper>
              <div>
                {selectedPlayers.map((s: any) => {
                  return (
                    <Filter
                      key={s.value}
                      onClick={() => {
                        const newPlayers = selectedPlayers.filter((f: any) => f.value !== s.value)
                        setSelectedPlayers(newPlayers)
                      }}
                    >
                      {s.label}
                      <img src="/images/close.svg" alt="delete" />
                    </Filter>
                  )
                })}
                {selectedPlayers.length > 0 ? (
                  <LinkButton
                    onClick={() => {
                      setSelectedPlayers([])
                    }}
                  >
                    Clear all
                  </LinkButton>
                ) : null}
              </div>
            </FilterWrapper>
          ) : null}
        </SearchContainer>
        {games?.map((game: any) => {
          return (
            <GameTab
              key={game.id}
              $selected={selectedGame === game.id}
              onClick={() => {
                setSelectedGame(game.id)
              }}
            >
              {game.name}
            </GameTab>
          )
        })}
        <NFTContent>
          <FiltersContainer>
            <DateRangePicker
              startDate={startDate}
              startDateId="start_date_id"
              endDate={endDate}
              endDateId="end_date_id"
              displayFormat="YYYY-MM-DD"
              numberOfMonths={1}
              showClearDates={true}
              isOutsideRange={day => day.isAfter(moment())}
              onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
            />
            {selectedTab === 1 && user.userRoleName === 'Manager' ? (
              <LeasedToggle>
                <Toggler
                  $selected={isApproved}
                  onClick={() => {
                    setIsApproved(true)
                  }}
                >
                  Approved
                </Toggler>
                <Toggler
                  $selected={!isApproved}
                  onClick={() => {
                    setIsApproved(false)
                  }}
                >
                  Rejected
                </Toggler>
              </LeasedToggle>
            ) : null}
          </FiltersContainer>
          <InnerContainer>
            <InnerWrapper>
              {user.userRoleName === 'Manager' && selectedTab === 0 ? (
                <>
                  {activeRequests?.items?.map?.((r: any) => {
                    return (
                      <NFTCell key={r.id}>
                        <Cell>
                          <ImageWrapper>
                            <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                          </ImageWrapper>
                          <div>
                            <NFTName>{r.nftName}</NFTName>
                            <Time>
                              {formatDistance(new Date(r.createdOn), new Date(), {
                                addSuffix: true,
                              })}
                            </Time>
                            <UserInfoOverlay userId={r.playerId}>
                              <PlayerName>By {r.playerName}</PlayerName>
                            </UserInfoOverlay>
                          </div>
                        </Cell>
                        <ButtonsFooter>
                          <RejectButton
                            onClick={() => {
                              setSelectedRequest(r)
                              setOpenRejectModal(true)
                            }}
                          >
                            Reject
                          </RejectButton>
                          <ApproveButton
                            onClick={() => {
                              setSelectedRequest(r)
                              setOpenConfirmModal(true)
                            }}
                          >
                            Approve
                          </ApproveButton>
                        </ButtonsFooter>
                      </NFTCell>
                    )
                  })}
                  {activeRequests?.items?.length === 0 ? (
                    <Wrapper>
                      <NoNftsImage src="/images/no-nfts.svg" alt="no nfts" />
                      <Description>There aren't any pending NFT requests.</Description>
                    </Wrapper>
                  ) : null}
                </>
              ) : null}
              {user.userRoleName === 'Manager' && selectedTab === 1 ? (
                <>
                  {closedRequests?.items?.map?.((r: any, index: number) => {
                    return (
                      <NFTCell key={`${r.id}-${r.nftRequestStatus}-${index}`}>
                        <Cell $history>
                          <ImageWrapper>
                            <NFTImage src={r.nftThumbnailImageUrl} alt={r.nftName} />
                          </ImageWrapper>
                          <div>
                            <NFTName>{r.nftName}</NFTName>
                            {r.nftRequestStatus === 'Accepted' ? (
                              <ApprovedStatus>Approved</ApprovedStatus>
                            ) : null}
                            {r.nftRequestStatus === 'Rejected' ? (
                              <RejectedStatus>Rejected</RejectedStatus>
                            ) : null}
                            <Time>
                              {formatDistance(new Date(r.updatedOn), new Date(), {
                                addSuffix: true,
                              })}
                            </Time>
                          </div>
                        </Cell>
                      </NFTCell>
                    )
                  })}
                  {closedRequests?.items?.length === 0 ? (
                    <Wrapper>
                      <NoNftsImage src="/images/no-nfts.svg" alt="no nfts" />
                      <Description>There aren't any closed NFT requests.</Description>
                    </Wrapper>
                  ) : null}
                </>
              ) : null}
            </InnerWrapper>
          </InnerContainer>
        </NFTContent>
      </Body>
      {openConfirmModal ? (
        <Modal bigSize open={openConfirmModal} setOpen={setOpenConfirmModal} noPadding={true}>
          <ModalBody>
            <ModalText>
              Are you sure you would like to approve <strong>{selectedRequest.playerName}</strong>'s
              request for <strong>“{selectedRequest?.nftName}”</strong>?
            </ModalText>
            {isError ? <ErrorMsg>Something went wrong. Please try again later.</ErrorMsg> : null}
            <Buttons>
              <SecondaryButton
                onClick={() => {
                  setOpenConfirmModal(false)
                }}
              >
                Cancel
              </SecondaryButton>
              <SubmitButton
                disabled={approveLoading}
                onClick={async () => {
                  if (selectedRequest) {
                    await approveNft({
                      id: selectedRequest.id,
                    })
                    setOpenConfirmModal(false)
                  }
                }}
              >
                <ClipLoader
                  color={colors.white}
                  loading={approveLoading}
                  cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                  size={20}
                  aria-label="Loading Spinner"
                />
                Approve
              </SubmitButton>
            </Buttons>
          </ModalBody>
        </Modal>
      ) : null}
      {openRejectModal ? (
        <Modal bigSize open={openRejectModal} setOpen={setOpenRejectModal} noPadding={true}>
          <ModalBody>
            <ModalText>
              Are you sure you would like to reject <strong>{selectedRequest.playerName}</strong>'s
              request for <strong>“{selectedRequest?.nftName}”</strong>?
            </ModalText>
            <Buttons>
              <SecondaryButton
                onClick={() => {
                  setOpenRejectModal(false)
                }}
              >
                Cancel
              </SecondaryButton>
              <RejectStyledButton
                disabled={rejectLoading}
                onClick={async () => {
                  if (selectedRequest) {
                    await rejectNft({
                      id: selectedRequest.id,
                    })
                    setOpenRejectModal(false)
                  }
                }}
              >
                <ClipLoader
                  color={colors.white}
                  loading={rejectLoading}
                  cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                  size={20}
                  aria-label="Loading Spinner"
                />
                Reject
              </RejectStyledButton>
            </Buttons>
          </ModalBody>
        </Modal>
      ) : null}
    </Modal>
  )
}

const Tab = styled.span<{ $selected: boolean }>`
  color: ${colors.blue500};
  text-transform: uppercase;
  letter-spacing: 0.15px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 10px;
  padding: 10px 12px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    `
    font-weight: 700;
    border-bottom: 4px solid ${colors.blue500};
  `}
`

const TabHeader = styled.div`
  margin-bottom: 10px;
  border-bottom: 1px solid ${colors.blue300};
  padding: 0 10px;
`

const GameTab = styled.div<{ $selected?: boolean }>`
  display: inline-block;
  background: rgba(82, 130, 236, 0.15);
  border-radius: 22px;
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.15px;
  padding: 2px 8px;
  margin-bottom: 5px;
  margin-right: 5px;
  transition: all 0.4s;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    `
    color: ${colors.white};
    background: #5282EC;
  `}
`

const Body = styled.div`
  padding: 20px;
`

const SearchWrapper = styled.div`
  width: 240px;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 7px;
  position: relative;
`

const SearchContainer = styled.div`
  margin-bottom: 20px;
`

const FilterWrapper = styled.div`
  width: 300px;
  display: inline-block;
  vertical-align: top;
`

const PlayersWrapper = styled.div`
  width: 150px;
  display: inline-block;
  vertical-align: top;
`

const Filter = styled.span`
  background: rgba(86, 135, 244, 0.15);
  border-radius: 12.5px;
  letter-spacing: 0.15px;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  margin-right: 5px;
  padding: 0 24px 2px 12px;
  position: relative;
  cursor: pointer;
  color: #4f6e8e;
  margin-top: 8px;
  transition: all 0.4s;

  &:hover {
    background: rgba(86, 135, 244, 0.3);
  }

  & > img {
    position: absolute;
    top: 9px;
    right: 10px;
    width: 8px;
  }
`

const NFTContent = styled.div`
  height: 400px;
  overflow: hidden;
  padding: 10px 0 40px;
  margin-top: 10px;
  border-top: 1px solid rgba(163, 198, 255, 0.56);
`

const InnerContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  margin-top: 20px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
`

const NFTCell = styled.div`
  width: calc(33.333% - 9px);

  @media screen and (max-width: 668px) {
    width: calc(50% - 9px);
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }
`

const Cell = styled.div<{ $history?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 2px;
  border-radius: 8px;
  padding: 4px;

  ${({ $history }) =>
    $history &&
    `
    cursor: pointer;
    &:hover {
      background: rgba(142, 177, 255, 0.15);
    }
  `}
`

const ImageWrapper = styled.div`
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
`

const NFTName = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  margin: 0;
  color: ${colors.blue900};
`

const Time = styled.p`
  font-size: 11px;
  line-height: 12px;
  margin: 2px 0;
  color: ${colors.grey800};
`

const ButtonsFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  margin-bottom: 7px;
  margin-top: 7px;
`

const PlayerName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  display: inline-block;
  color: ${colors.blue500};
  cursor: pointer;
`

const ApprovedStatus = styled.span`
  font-size: 12px;
  line-height: 12px;
  padding: 2px 6px;
  border-radius: 50px;
  margin-top: 6px;
  margin-bottom: 4px;
  display: inline-block;
  background: #1dac56;
  color: ${colors.white};
  padding-bottom: 4px;
`

const RejectedStatus = styled(ApprovedStatus)`
  background: #fd7c7c;
  color: ${colors.white};
  padding-bottom: 4px;
`

const Wrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: 50px 0;
`

const NoNftsImage = styled.img`
  width: 80px;
  margin-bottom: 22px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 17px;
  margin: 0 0 32px;
  color: ${colors.grey500};
`

const Toggler = styled.span<{ $selected: boolean }>`
  display: inline-block;
  font-size: 12px;
  line-height: 22px;
  padding: 0 10px 2px;
  transition: all 0.4s;
  color: #4f6e8e;
  background: rgba(255, 203, 70, 0.3);
  border-radius: 22px;
  cursor: pointer;
  height: 24px;

  ${({ $selected }) =>
    $selected &&
    `
    color: ${colors.blue900};
    background: #FFCB46;
  `}
`

const LeasedToggle = styled.div`
  display: flex;
  vertical-align: top;
  gap: 8px;
  padding-top: 5px;
`

const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

const ModalBody = styled.div`
  padding: 40px 90px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};
  margin-bottom: 24px;

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const SubmitButton = styled(ApproveButton)`
  font-size: 16px;
  padding: 10px 12px;
`

const RejectStyledButton = styled(RejectButton)`
  font-size: 16px;
  padding: 10px 12px;
`

const Magnifier = styled.img`
  position: absolute;
  top: 8px;
  left: 7px;
  width: 16px;
  z-index: 1;
`

export default RequestsModal
