import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const fetchNftRequests = async () => {
  const data = await API.authGet(`nft/request/player`)

  return data
}

const useNftRequestsQuery = () => {
  const { user } = useUser()

  return useQuery({
    queryKey: [QUERY_KEYS.NFT_PLAYER_REQUESTS, user.id],
    queryFn: fetchNftRequests,
    enabled: user?.userRoleName === 'Player',
  })
}

export default useNftRequestsQuery
