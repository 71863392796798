import { formatDistance } from 'date-fns'
import styled from 'styled-components'
import { ApproveButton, RejectButton } from '../../../../components/Button'
import colors from '../../../../styles/colors'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'
import useApproveRequestMutation from './queries/useApprovePlayerMutation'
import useManagerRequestsQuery from './queries/useManagerRequestsQuery'
import useRejectPlayerMutation from './queries/useRejectPlayerMutation'

interface ManagerRequestsProps {
  editable: boolean
  mask?: boolean
}

const ManagerRequestsSection = ({ editable, mask }: ManagerRequestsProps) => {
  const { data: managerRequests, refetch } = useManagerRequestsQuery()
  const { mutateAsync: rejectPlayer } = useRejectPlayerMutation()
  const { mutateAsync: approvePlayer } = useApproveRequestMutation()

  return (
    <Container>
      <ManagerRequestsContainer $editable={editable} $mask={mask}>
        <RequestsSectionTitle>New Player Requests</RequestsSectionTitle>
        <Wrapper>
          {managerRequests?.map(request => {
            return (
              <RequestContainer key={request.id}>
                <Image src={request.profilePictureUrl || '/images/avatar.svg'} />
                <NameContainer>
                  <Name>
                    {request.firstName} {request.lastName}
                  </Name>
                  <Time>
                    {formatDistance(new Date(request.createdOn), new Date(), { addSuffix: true })}
                  </Time>
                </NameContainer>
                <Buttons>
                  <RejectButton
                    onClick={async () => {
                      await rejectPlayer({
                        id: request.id,
                      })
                      refetch()
                    }}
                  >
                    Reject
                  </RejectButton>
                  <ApproveBtn
                    onClick={async () => {
                      await approvePlayer({
                        id: request.id,
                      })
                      refetch()
                    }}
                  >
                    Approve
                  </ApproveBtn>
                </Buttons>
              </RequestContainer>
            )
          })}
        </Wrapper>
      </ManagerRequestsContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  max-height: 315px;

  @media screen and (max-width: 1010px) {
    width: 100%;
  }
`

const ManagerRequestsContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  height: 100%;
`

const RequestsSectionTitle = styled(SectionTitle)`
  margin: 0 0 16px;
`

const Wrapper = styled.div`
  border-top: 1px solid #dce9ff;
`

const RequestContainer = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding: 11px 0;
`

const Image = styled.img`
  width: 36px;
  height: 36px;
  vertical-align: middle;
  border-radius: 50%;
`

const NameContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
`

const Name = styled.p`
  font-size: 14px;
  line-height: 12px;
  letter-spacing: 0.15px;
  color: ${colors.blue900};
  margin: 3px 0 0;
`

const Time = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey800};
`

const Buttons = styled.div`
  float: right;
  vertical-align: middle;
  padding-top: 3px;
`

const ApproveBtn = styled(ApproveButton)`
  margin-left: 5px;
`

export default ManagerRequestsSection
