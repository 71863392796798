import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

interface CancelPaymentParams {
  id: number
}

const cancelPayment = async (body: CancelPaymentParams) => {
  const data = await API.authPost('payment/request/cancel', {
    ...body,
  })

  return data
}

const useCancelPaymentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: CancelPaymentParams) => cancelPayment(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENTS] })
    },
  })
}

export default useCancelPaymentMutation
