import moment from 'moment'
import { useState } from 'react'
import { DateRangePicker, FocusedInputShape } from 'react-dates'
import styled from 'styled-components'
import { LinkButton, SubmitButton } from '../../../components/Button'
import CardModal from '../../../components/CardModal'
import WalletModal from '../../../components/WalletModal'
import WithdrawModal from '../../../components/WithdrawModal'
import { useUser } from '../../../contexts/AuthContext'
import colors from '../../../styles/colors'
import Wallet from '../../../types/wallet'
import WalletComponent from '../../Profile/components/CardDetailsSection/components/WalletComponent'
import useNetworksQuery from '../../Profile/components/CardDetailsSection/queries/useNetworksQuery'
import SectionContainer from '../../Profile/components/SectionContainer'
import SectionTitle from '../../Profile/components/SectionTitle'
import usePaymentsQuery from '../queries/usePaymentsQuery'
import usePaymentStatusesQuery from '../queries/usePaymentStatusesQuery'
import useTeamPaymentsQuery from '../queries/useTeamPaymentsQuery'
import Payment from './Payment'
import PendingPayment from './PendingPayment'

const PaymentsSection = () => {
  const { user } = useUser()
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false)
  const [openWithdrawModal, setOpenWithdrawModal] = useState<boolean>(false)
  const [selectedFilter, setSelectedFilter] = useState<number[]>([1, 2, 3, 4])
  const [openWeb3Modal, setOpenWeb3Modal] = useState<boolean>(false)
  const [walletDetails, setWalletDetails] = useState<Wallet>({})
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null)
  const [startDate, setStartDate] = useState<moment.Moment | null>(null)
  const [endDate, setEndDate] = useState<moment.Moment | null>(null)
  const { data: networks } = useNetworksQuery()
  const { data: payments } = usePaymentsQuery(selectedFilter, startDate, endDate)
  const { data: teamPayments } = useTeamPaymentsQuery()
  const { data: paymentStatuses } = usePaymentStatusesQuery()

  const handleDateChange = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleWalletEdit = (id: number) => {
    const wallet = user.payoutWallets?.filter((w: any) => {
      return w.id === id
    })?.[0]

    if (wallet) {
      const net = networks?.filter((network: any) => {
        return network.id === wallet.networkId
      })?.[0]

      setWalletDetails({
        name: wallet.name,
        network: net.name,
        publicAddress: wallet.publicAddress,
        id,
      })
      setOpenWeb3Modal(true)
    }
  }

  return (
    <Container>
      <HeaderWrapper>
        <HeaderTitle>Payments</HeaderTitle>
        <WithdrawButton
          onClick={() => {
            setOpenWithdrawModal(true)
          }}
        >
          WITHDRAW
        </WithdrawButton>
      </HeaderWrapper>
      <InnerWrapper>
        <EarningsContainer>
          <Header>
            <Title>Cards</Title>
            <LinkButton
              onClick={() => {
                setOpenDetailsModal(true)
              }}
            >
              <Plus src="/images/white-plus.svg" alt="" />
              Payment details
            </LinkButton>
          </Header>
          <Wrapper>
            <VirtualCard>
              <img src="/images/va-card-logo.svg" alt="va logo" />
            </VirtualCard>
          </Wrapper>
        </EarningsContainer>
        <EarningsContainer>
          <Header>
            <Title>Web 3 Wallets</Title>
            <LinkButton
              onClick={() => {
                setOpenWeb3Modal(true)
              }}
            >
              <Plus src="/images/white-plus.svg" alt="" />
              Add wallet
            </LinkButton>
          </Header>
          <Wrapper $height>
            <WalletsWrapper>
              {user?.payoutWallets?.map((p: any) => {
                const net = networks?.filter((network: any) => {
                  return network.id === p.networkId
                })?.[0]

                return (
                  <WalletComponent
                    key={p.id}
                    id={p.id}
                    name={p.name}
                    publicAddress={p.publicAddress}
                    backgroundImageUrl={p.backgroundImageUrl}
                    network={net?.name || ''}
                    noDots
                    edit={handleWalletEdit}
                    paymentsPage
                  />
                )
              })}
            </WalletsWrapper>
          </Wrapper>
        </EarningsContainer>
      </InnerWrapper>
      <PaymentsWrapper>
        <PaymentsContainer $isManager={user.userRoleName === 'Manager'}>
          <Header>
            <Title>My Requests ({payments?.length || 0})</Title>
          </Header>
          <FiltersContainer>
            <div>
              <DateRangePicker
                startDate={startDate}
                startDateId="start_date_id"
                endDate={endDate}
                endDateId="end_date_id"
                displayFormat="YYYY-MM-DD"
                numberOfMonths={1}
                showClearDates={true}
                isOutsideRange={day => day.isAfter(moment())}
                onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              />
            </div>
            <StatusWrapper>
              {paymentStatuses?.map((ps: any) => {
                return (
                  <Filter
                    key={ps.id}
                    $selected={selectedFilter.includes(ps.id)}
                    onClick={() => {
                      if (selectedFilter.includes(ps.id)) {
                        setSelectedFilter(selectedFilter.filter(s => s !== ps.id))
                      } else {
                        setSelectedFilter([...selectedFilter, ps.id])
                      }
                    }}
                  >
                    {ps.name}
                  </Filter>
                )
              })}
            </StatusWrapper>
          </FiltersContainer>
          <Payments>
            {payments?.map((paym: any) => {
              return <Payment key={paym.id} {...paym} />
            })}
          </Payments>
        </PaymentsContainer>
        {user.userRoleName === 'Manager' ? (
          <PaymentsContainer $isManager={true} $isRight={true}>
            <Header>
              <Title>My Team's Requests ({teamPayments?.length || 0})</Title>
            </Header>
            <div>
              {teamPayments?.map((p: any) => {
                return <PendingPayment key={p.id} {...p} />
              })}
            </div>
          </PaymentsContainer>
        ) : null}
      </PaymentsWrapper>
      <WithdrawModal openModal={openWithdrawModal} setOpenModal={setOpenWithdrawModal} />
      <WalletModal
        openWeb3Modal={openWeb3Modal}
        setOpenWeb3Modal={setOpenWeb3Modal}
        walletDetails={walletDetails}
        setWalletDetails={setWalletDetails}
      />
      <CardModal openDetailsModal={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const InnerWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  gap: 20px;

  @media screen and (max-width: 1210px) {
    flex-direction: column;
  }
`

const WithdrawButton = styled(SubmitButton)`
  font-size: 12px;
  letter-spacing: 0.46px;
`

const Payments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 15px;
`

const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
`

const WalletsWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const PaymentsWrapper = styled.div`
  padding: 0 10px;
`

const Title = styled(SectionTitle)`
  margin-bottom: 0;
  margin-left: 0;
`

const EarningsContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px 0;
  width: 50%;
  min-height: 260px;

  @media screen and (max-width: 1210px) {
    width: 100%;
    min-height: auto;
  }

  @media screen and (max-width: 768px) {
    background: ${colors.white};
  }
`
const PaymentsContainer = styled(SectionContainer)<{ $isManager?: boolean; $isRight?: boolean }>`
  border-radius: 8px;
  padding: 20px 22px;
  width: 100%;
  vertical-align: top;

  ${({ $isManager }) =>
    $isManager &&
    `
    display: inline-block;
    width: calc(50% - 10px);
    
    @media screen and (max-width: 1210px) {
      width: 100%;
      display: block;
    }
  `}

  ${({ $isRight }) =>
    $isRight &&
    `
    margin-left: 20px;


    @media screen and (max-width: 1210px) {
      margin-left: 0;
      margin-top: 20px;
    }
  `}

  @media screen and (max-width: 768px) {
    background: ${colors.white};
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 14px;
  border-bottom: 1px solid #dce9ff;
`

const Plus = styled.img`
  width: 13px;
  margin-right: 5px;
  margin-top: -2px;
`

const Wrapper = styled.div<{ $height?: boolean }>`
  padding: 20px 0 0;

  ${({ $height }) =>
    $height &&
    `
    height: 180px;
  `}
`

const HeaderTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey500};
`

const VirtualCard = styled.div`
  background: linear-gradient(64.36deg, #032960 7.02%, #5687f4 93.91%);
  border-radius: 12px;
  height: 145px;
  display: inline-block;
  padding-top: 10px;
  text-align: center;
  overflow: hidden;

  & > img {
    width: 90%;
  }
`

const Filter = styled.span<{ $selected?: boolean }>`
  letter-spacing: 0.15px;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.grey500};
  background: rgba(82, 130, 236, 0.15);
  border-radius: 22px;
  padding: 1px 10px;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    `
    background: rgba(82, 130, 236, 1);
    color: ${colors.white}; 
  `}
`

const StatusWrapper = styled.div`
  display: flex;
  gap: 4px;
`

export default PaymentsSection
