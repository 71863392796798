import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const addReferralLink = async (networkId: number, title: string) => {
  const data = await API.authPost('referral', {
    socialNetworkId: networkId,
    name: title,
  })

  return data
}

interface AddReferralLink {
  networkId: number
  title: string
}

const useAddReferralLinkMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ networkId, title }: AddReferralLink) => addReferralLink(networkId, title), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGER_REFERRALS])
    },
  })
}

export default useAddReferralLinkMutation
