import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AuthContextProvider from './contexts/AuthContext'
import CurrencyContextProvider from './contexts/CurrencyContext'
import ProfilePage from './pages/Profile'
import LoginPage from './pages/Login'
import SignupPage from './pages/Signup'
import VerifyEmailPage from './pages/VerifyEmail'
import ConfirmedEmailPage from './pages/ConfirmedEmail'
import NotFoundPage from './pages/NotFound'
import ROUTES from './routes'
import GamesPage from './pages/Games'
import MarketplacePage from './pages/Marketplace'
import NotificationsPage from './pages/Notifications'
import DashboardPage from './pages/Dashboard'
import AnalyticsPage from './pages/Analytics'
import PaymentsPage from './pages/Payments'

const queryClient = new QueryClient()

const Router = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <CurrencyContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.PROFILE_PAGE} element={<ProfilePage />} />
              <Route path={ROUTES.GAMES_PAGE} element={<GamesPage />} />
              <Route path={ROUTES.MARKETPLACE_PAGE} element={<MarketplacePage />} />
              <Route path={ROUTES.NOTIFICATIONS_PAGE} element={<NotificationsPage />} />
              <Route path={ROUTES.DASHBOARD_PAGE} element={<DashboardPage />} />
              <Route path={ROUTES.ANALYTICS_PAGE} element={<AnalyticsPage />} />
              <Route path={ROUTES.PAYMENTS_PAGE} element={<PaymentsPage />} />
              <Route path={ROUTES.SIGNUP_PAGE} element={<SignupPage />} />
              <Route path={ROUTES.LOGIN_PAGE} element={<LoginPage />} />
              <Route path={ROUTES.VERIFY_PAGE} element={<VerifyEmailPage />} />
              <Route path={ROUTES.CONFIRM_EMAIL_PAGE} element={<ConfirmedEmailPage />} />
              <Route path={ROUTES.NOT_FOUND_PAGE} element={<NotFoundPage />} />
            </Routes>
            <div id="modal" />
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} position="top-right" />
        </CurrencyContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  )
}

export default Router
