import colors from '../styles/colors'

export const selectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? colors.grey100 : colors.blue100,
    minHeight: '27px',
    height: '27px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
      marginTop: '-2px',
      ':before': {
        background: `url(https://flagcdn.com/20x15/${state.data.isoCode.toLowerCase()}.png)`,
        content: '" "',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 8,
        marginTop: -2,
        height: 15,
        width: 20,
      },
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '27px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
      ':before': {
        background: `url(https://flagcdn.com/20x15/${state.data.isoCode.toLowerCase()}.png)`,
        content: '" "',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 8,
        marginTop: -2,
        height: 15,
        width: 20,
      },
    }
  },
}

export const selectBigStyles = {
  control: (provided: any, state: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        border: 0,
        background: 'rgba(86, 135, 244, 0.35)',
        color: colors.white,
        minHeight: '42px',
        height: '42px',
        borderRadius: '6px',
        marginBottom: '18px',
      }
    }
    return {
      ...provided,
      background: state.isDisabled ? colors.grey100 : colors.blue100,
      minHeight: '32px',
      height: '32px',
      borderRadius: '6px',
      marginBottom: '18px',
      border: `1px solid transparent`,
      '&:hover': {
        border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
      },
    }
  },
  singleValue: (provided: any, state: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        fontSize: '12px',
        color: colors.white,
        ':before': {
          background: `url(https://flagcdn.com/20x15/${state.data.isoCode.toLowerCase()}.png)`,
          content: '" "',
          display: 'inline-block',
          verticalAlign: 'middle',
          marginRight: 8,
          height: 15,
          marginTop: '-3px',
          width: 20,
        },
      }
    }

    return {
      ...provided,
      fontSize: '12px',
      marginTop: '-2px',
      ':before': {
        background: `url(https://flagcdn.com/20x15/${state.data.isoCode.toLowerCase()}.png)`,
        content: '" "',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 8,
        height: 15,
        width: 20,
      },
    }
  },
  input: (provided: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        margin: '0px',
        fontSize: '12px',
        color: colors.white,
      }
    }
    return {
      ...provided,
      margin: '0px',
      fontSize: '12px',
    }
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        fontSize: 13,
        color: colors.white,
      }
    }

    return {
      ...provided,
      fontSize: 13,
    }
  },
  indicatorsContainer: (provided: any, state: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        height: '42px',
        display: state.isDisabled ? 'none' : 'flex',
      }
    }
    return {
      ...provided,
      height: '32px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    const mediaQuery = window.matchMedia('(max-width: 768px)')
    if (mediaQuery.matches) {
      return {
        ...provided,
        color: colors.white,
        transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
        transition: 'all .4s',
        '&:hover': {
          color: colors.blue800,
        },
      }
    }
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
      ':before': {
        background: `url(https://flagcdn.com/20x15/${state.data.isoCode.toLowerCase()}.png)`,
        content: '" "',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 8,
        height: 15,
        width: 20,
      },
    }
  },
}

export const selectNetworkStyles = {
  container: (provided: any) => {
    return {
      ...provided,
      minWidth: '150px',
    }
  },
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? colors.grey100 : colors.blue100,
    minHeight: '32px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '8px 0',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '32px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}
export const selectEarningsStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? colors.grey100 : colors.blue100,
    minHeight: '32px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '8px 0',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '32px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}

export const selectCurrencyStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: state.isDisabled ? colors.grey100 : colors.blue100,
    minHeight: '32px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  container: (provided: any) => {
    return {
      ...provided,
      width: '120px',
      display: 'block',
    }
  },
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: '8px 0',
    cursor: 'pointer',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '32px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}

export const selectFilterStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: colors.blue100,
    minHeight: '27px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
      ':before': {
        marginTop: 4,
        background: `url(/images/filter.svg) no-repeat`,
        content: '" "',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 8,
        height: 15,
        width: 20,
      },
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '27px',
      display: 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      marginTop: '5px',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}

export const selectPlayersFilterStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: colors.blue100,
    minHeight: '27px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      display: 'none',
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '27px',
      display: 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      marginTop: '5px',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}

export const selectSearchFilterStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: colors.blue100,
    minHeight: '27px',
    height: '32px',
    borderRadius: '6px',
    border: `1px solid transparent`,
    '&:hover': {
      border: state.isDisabled ? `1px solid transparent` : `1px solid ${colors.blue500}`,
    },
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      display: 'none',
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
    paddingLeft: '18px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '27px',
      display: 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      display: 'none',
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '16px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}

export const selectTabStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: 'transparent',
    boxShadow: 'none',
    minHeight: '16px',
    height: '16px',
    borderRadius: '6px',
    border: 0,
  }),
  container: (provided: any) => {
    return {
      ...provided,
      marginBottom: 15,
      display: 'inline-block',
    }
  },
  menu: (provided: any) => {
    return {
      ...provided,
      minWidth: 125,
    }
  },
  valueContainer: (provided: any) => {
    return {
      ...provided,
      padding: 0,
    }
  },
  singleValue: (provided: any) => {
    return {
      ...provided,
      fontSize: '14px',
      fontFamily: 'Manrope Regular',
      lineHeight: '16px',
      color: colors.grey500,
    }
  },
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    fontSize: '12px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      height: '27px',
      display: state.isDisabled ? 'none' : 'flex',
    }
  },
  dropdownIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      color: state.isFocused ? colors.blue500 : colors.blue800,
      transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0)',
      transition: 'all .4s',
      '&:hover': {
        color: colors.blue800,
      },
    }
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '12px',
      cursor: 'pointer',
      lineHeight: '14px',
      fontWeight: state.isSelected ? 700 : 400,
      backgroundColor: state.isSelected
        ? 'rgba(86, 135, 244, 0.2)'
        : state.isFocused
        ? 'rgba(93, 129, 229, 0.1)'
        : colors.white,
      color: state.isSelected ? '#5D81E5' : state.isFocused ? '#5D81E5' : colors.blue800,
    }
  },
}
