import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { ApproveButton, SecondaryButton } from '../../../../../components/Button'
import { ErrorMsg } from '../../../../../components/ErrorMessage'
import Modal from '../../../../../components/Modal'
import colors from '../../../../../styles/colors'
import fonts from '../../../../../styles/fonts'
import useCancelRequestNFTMutation from '../queries/useCancelRequestNFTMutation'

interface ConfirmCancelModalProps {
  openConfirmCancelModal: boolean
  setOpenConfirmCancelModal: (o: boolean) => void
  selectedRequest: any
}

const ConfirmCancelModal = ({
  openConfirmCancelModal,
  setOpenConfirmCancelModal,
  selectedRequest,
}: ConfirmCancelModalProps) => {
  const { mutateAsync: cancelNft, isError, isLoading } = useCancelRequestNFTMutation()

  return (
    <Modal
      bigSize
      open={openConfirmCancelModal}
      setOpen={setOpenConfirmCancelModal}
      noPadding={true}
    >
      <ModalBody>
        <ModalText>
          Are you sure you would like to cancel your request for{' '}
          <strong>“{selectedRequest?.nftName}”</strong>?
        </ModalText>
        {isError ? <ErrorMsg>Something went wrong. Please try again later.</ErrorMsg> : null}
        <Buttons>
          <SecondaryButton
            onClick={() => {
              setOpenConfirmCancelModal(false)
            }}
          >
            No
          </SecondaryButton>
          <SubmitButton
            disabled={isLoading}
            onClick={async () => {
              if (selectedRequest) {
                await cancelNft({
                  id: selectedRequest.id,
                })
                setOpenConfirmCancelModal(false)
              }
            }}
          >
            <ClipLoader
              color={colors.white}
              loading={isLoading}
              cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
              size={20}
              aria-label="Loading Spinner"
            />
            Yes
          </SubmitButton>
        </Buttons>
      </ModalBody>
    </Modal>
  )
}

const ModalBody = styled.div`
  padding: 40px 90px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};
  margin-bottom: 24px;

  & > strong {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`

const SubmitButton = styled(ApproveButton)`
  font-size: 16px;
  padding: 10px 12px;
`

export default ConfirmCancelModal
