import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const requestNft = async (id: number) => {
  const data = await API.authPost('nft/request', {
    nftId: id,
  })

  return data
}

interface RequestNFTParams {
  id: number
}

const useRequestNFTMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: RequestNFTParams) => requestNft(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NFT_PLAYER_REQUESTS] })
    },
  })
}

export default useRequestNFTMutation
