import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

const getTeamEarnings = async (gameId: string, ids: string) => {
  const data = await API.authGet(`team/earnings?GameId=${gameId}${ids}`)
  return data
}

const useGetTeamEarningsQuery = ({ selectedPlayers }: any) => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const { user } = useUser()
  const ids = selectedPlayers.reduce((acc: string, currValue: any) => {
    if (currValue) {
      return `${acc}&PlayerIds=${currValue.userId}`
    }
    return ''
  }, '')

  return useQuery(
    [QUERY_KEYS.GAME_TEAM_EARNINGS, gameQuery || '1', ids],
    () => getTeamEarnings(gameQuery || '1', ids),
    {
      enabled: user.userRoleName === 'Manager' && !!ids,
      staleTime: 60 * 1000,
    }
  )
}

export default useGetTeamEarningsQuery
