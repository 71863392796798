import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import { LinkButton } from '../../components/Button'
import colors from '../../styles/colors'
import useSendAgainMutation from './queries/useSendAgainMutation'
import { ErrorMsg } from '../../components/ErrorMessage'
import ClipLoader from 'react-spinners/ClipLoader'
import fonts from '../../styles/fonts'
import { BackgroundWrapper, BlueContainer } from '../../components/UI'

const VerifyEmailPage = () => {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const navigate = useNavigate()
  const { mutateAsync: sendAgain, isLoading } = useSendAgainMutation()
  const [serverError, setServerError] = useState<boolean>(false)

  useEffect(() => {
    if (!email) {
      navigate('/signup')
    }
  }, [email, navigate])

  return (
    <>
      <BlueContainer>
        <BackgroundWrapper />
      </BlueContainer>
      <FormWrapper>
        <Head>
          <LogoImage src="/images/small-logo.svg" alt="logo" />
        </Head>
        <MobileHead>
          <MobileLogo src="/images/small-logo.svg" alt="virtue alliance" />
          <MobileTitle>Virtue Alliance</MobileTitle>
        </MobileHead>
        <div>
          <Icon src="/images/email-tick.svg" alt="email" />
        </div>
        <div>
          <Title>Verify Your Email</Title>
          <Description>
            Confirmation <strong>link</strong> has been sent to <strong>{email}</strong>
          </Description>
          {serverError ? (
            <ErrorMsg>
              The email is invalid or it's already verified!{' '}
              <LoginLabel
                onClick={() => {
                  navigate('/login')
                }}
              >
                Log in
              </LoginLabel>
            </ErrorMsg>
          ) : null}

          <Note>Didn't get an email?</Note>
          <div>
            <SendAgain
              onClick={async () => {
                if (email && !isLoading) {
                  await sendAgain(
                    { email },
                    {
                      onError: () => {
                        setServerError(true)
                      },
                    }
                  )
                  window.location.reload()
                }
              }}
            >
              <ClipLoader
                color={colors.white}
                loading={isLoading}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              Send again
            </SendAgain>
          </div>
        </div>
      </FormWrapper>
    </>
  )
}

const Title = styled.h1`
  font-size: 24px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    margin: 30px 0;
  }
`

const Description = styled.p`
  font-size: 16px;
  line-height: 23px;
  padding: 0 15px;
  margin: 25px 0 35px;

  & > strong {
    font-weight: 600;
  }
`

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - 215px);
  max-width: 430px;
  width: 100%;
  background: linear-gradient(
    180.11deg,
    rgba(93, 129, 229, 0.19) -13.71%,
    rgba(93, 129, 229, 0.19) -13.7%,
    rgba(18, 27, 52, 0.19) 107.63%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 15px 96px rgba(3, 41, 96, 0.85);
  border-radius: 18px;
  padding: 40px;
  text-align: center;
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    left: 0;
    max-width: 100%;
  }
`

const Head = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 30px;
  margin-bottom: 15px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const LogoImage = styled.img`
  width: 57px;
`

const Icon = styled.img`
  width: 130px;
  margin-right: -30px;
`

const SendAgain = styled(LinkButton)`
  color: ${colors.white};
`

const Note = styled.span`
  font-size: 12px;
  line-height: 20px;
`

const LoginLabel = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const MobileHead = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const MobileLogo = styled.img`
  width: 130px;
  margin-bottom: 20px;
`

const MobileTitle = styled.h2`
  font-size: 21px;
  line-height: 30px;
  color: ${colors.white};
  font-family: ${fonts.fontFamilySemiBold};
  letter-spacing: 0.8px;
  margin: 0;
`

export default VerifyEmailPage
