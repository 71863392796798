import { formatDistance } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { LinkButton, SecondaryButton, SubmitButton } from '../../../../components/Button'
import Modal from '../../../../components/Modal'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { NOTIFICATION_TYPES } from '../../../../types/notification'
import useClearAllNotificationsMutation from '../../queries/useClearAllNotificationsMutation'
import useGetAllReadNotificationsQuery from '../../queries/useGetAllReadNotificationsQuery'
import { Filters } from '../../types'
import {
  Content,
  FiltersContainer,
  FilterTag,
  SectionHeader,
  Section,
  Title,
  NotificationWrapper,
  NoData,
} from '../UI'

const ReadNotifications = () => {
  const [filters, setFilters] = useState<Filters[]>([Filters.NFT, Filters.TEAM, Filters.UPDATES])
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [readNotifications, setReadNotifications] = useState<any>(undefined)
  const { data: notifications } = useGetAllReadNotificationsQuery()
  const { mutateAsync: clearAll, isLoading } = useClearAllNotificationsMutation()
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null)

  useEffect(() => {
    setReadNotifications(notifications)
  }, [notifications])

  const updateNotifications = useCallback(
    (newFilters: Filters[]) => {
      const newNotifications = notifications.filter((not: any) => {
        if (
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST ||
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_ACCEPT ||
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_REJECT
        ) {
          return newFilters.includes(Filters.NFT)
        }
        if (
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST ||
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_ACCEPT ||
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_REJECT
        ) {
          return newFilters.includes(Filters.TEAM)
        }
        if (
          not.notificationType === NOTIFICATION_TYPES.SYSTEM ||
          not.notificationType === NOTIFICATION_TYPES.GAME
        ) {
          return newFilters.includes(Filters.UPDATES)
        }
        return true
      })
      setReadNotifications(newNotifications)
    },
    [notifications, setReadNotifications]
  )

  useEffect(() => {
    if (sectionRef?.current) {
      const height = sectionRef.current.getBoundingClientRect()?.height
      setWrapperHeight(height - 160)
    }
  }, [sectionRef])

  return (
    <Section ref={sectionRef}>
      <SectionHeader>
        <Title>Read ({notifications?.length || 0})</Title>
        {readNotifications?.length > 0 ? (
          <ClearButton
            onClick={() => {
              setOpenConfirmModal(true)
            }}
          >
            Clear all
          </ClearButton>
        ) : null}
      </SectionHeader>
      <Content>
        <FiltersContainer $read>
          <FilterTag
            $selected={filters.includes(Filters.NFT)}
            onClick={() => {
              if (filters.includes(Filters.NFT)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.NFT)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.NFT]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            NFT
          </FilterTag>
          <FilterTag
            $selected={filters.includes(Filters.TEAM)}
            onClick={() => {
              if (filters.includes(Filters.TEAM)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.TEAM)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.TEAM]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            Team
          </FilterTag>
          <FilterTag
            $selected={filters.includes(Filters.UPDATES)}
            onClick={() => {
              if (filters.includes(Filters.UPDATES)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.UPDATES)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.UPDATES]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            Updates
          </FilterTag>
        </FiltersContainer>
        <NotificationWrapper style={wrapperHeight ? { height: `${wrapperHeight}px` } : {}}>
          {readNotifications?.map((not: any) => {
            return (
              <Nofitication key={not.id}>
                <NotificationImageWrapper>
                  <NFTImage src={not.pictureUrl || '/images/avatar.svg'} />
                </NotificationImageWrapper>
                <DescriptionWrapper>
                  <Description>{not.description}</Description>
                  {not.createdOn ? (
                    <Time>
                      {formatDistance(new Date(not.createdOn), new Date(), { addSuffix: true })}
                    </Time>
                  ) : null}
                </DescriptionWrapper>
              </Nofitication>
            )
          })}
          {readNotifications?.length === 0 ? (
            <div>
              <NoData>No notifications!</NoData>
            </div>
          ) : null}
        </NotificationWrapper>
      </Content>
      <Modal bigSize open={openConfirmModal} setOpen={setOpenConfirmModal} noPadding={true}>
        <ModalBody>
          <ModalText>Are you sure you would like to clear all read notifications?</ModalText>
          <Buttons>
            <SecondaryStyledButton
              onClick={() => {
                setOpenConfirmModal(false)
              }}
            >
              NO
            </SecondaryStyledButton>
            <SubmitStyledButton
              disabled={isLoading}
              onClick={async () => {
                await clearAll()
                setOpenConfirmModal(false)
              }}
            >
              <ClipLoader
                color={colors.white}
                loading={isLoading}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              YES
            </SubmitStyledButton>
          </Buttons>
        </ModalBody>
      </Modal>
    </Section>
  )
}

const ClearButton = styled(LinkButton)`
  color: #d32f2f;
`

const Nofitication = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  border-bottom: 1px solid #dce9ff;
  padding: 8px 20px 8px 0;
  gap: 8px;
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border-radius: 50%;
`

const NotificationImageWrapper = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.grey500};
  font-family: ${fonts.fontFamilyBold};
`

const Time = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.grey800};
  margin-top: 2px;
`

const ModalBody = styled.div`
  padding: 40px 90px;
  text-align: center;
`

const ModalText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.blue900};
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`

const SecondaryStyledButton = styled(SecondaryButton)`
  width: 70px;
`

const SubmitStyledButton = styled(SubmitButton)`
  min-width: 70px;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export default ReadNotifications
