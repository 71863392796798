import colors from '../../../../../styles/colors'

interface BitcoinSignProps {
  color: string
}

const BitcoinSign = ({ color }: BitcoinSignProps) => {
  return (
    <svg width="35" height="50" viewBox="0 0 35 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.25 3.125C5.25 1.39941 6.81734 0 8.75 0C10.6827 0 12.25 1.39941 12.25 3.125V6.25H15.75V3.125C15.75 1.39941 17.3141 0 19.25 0C21.1859 0 22.75 1.39941 22.75 3.125V6.25C22.75 6.40039 22.7391 6.54883 22.7172 6.69336C27.7922 8.02832 31.5 12.2168 31.5 17.1875C31.5 19.5508 30.6578 21.7383 29.2359 23.5254C32.6922 25.459 35 28.8965 35 32.8125C35 38.8574 29.5203 43.75 22.75 43.75V46.875C22.75 48.6035 21.1859 50 19.25 50C17.3141 50 15.75 48.6035 15.75 46.875V43.75H12.25V46.875C12.25 48.6035 10.6827 50 8.75 50C6.81734 50 5.25 48.6035 5.25 46.875V43.75H4.56531C2.04422 43.75 0 41.9238 0 39.6777V9.92188C0 7.89258 1.83969 6.25 4.10922 6.25H5.25V3.125ZM19.25 21.875C22.1484 21.875 24.5 19.7754 24.5 17.1875C24.5 14.5996 22.1484 12.5 19.25 12.5H7V21.875H19.25ZM7 28.125V37.5H22.75C25.6484 37.5 28 35.4004 28 32.8125C28 30.2246 25.6484 28.125 22.75 28.125H7Z"
        fill={color || colors.white}
      />
    </svg>
  )
}

export default BitcoinSign
