import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const fetchPaymentStatuses = async () => {
  const data = await API.authGet(`lookup/paymentrequeststatues`)

  return data
}

const usePaymentStatusesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.PAYMENT_STATUSES],
    queryFn: fetchPaymentStatuses,
  })
}

export default usePaymentStatusesQuery
