import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const sendRequests = async (teamIds: number[], userId?: number) => {
  const data = await API.authPost('team/apply', {
    teamIds,
    playerId: userId,
  })

  return data
}

interface SendRequestParams {
  teamIds: number[]
}

const useSendRequestMutation = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  return useMutation(({ teamIds }: SendRequestParams) => sendRequests(teamIds, user.id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGERS])
    },
  })
}

export default useSendRequestMutation
