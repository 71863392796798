import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const approveRequest = async (id: number) => {
  const data = await API.authPost('team/accept', {
    id,
  })

  return data
}

interface ApproveRequestParams {
  id: number
}

const useApproveRequestMutation = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  return useMutation(({ id }: ApproveRequestParams) => approveRequest(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGER_REQUESTS, user.id])
      queryClient.invalidateQueries([QUERY_KEYS.TEAM])
      queryClient.invalidateQueries([QUERY_KEYS.TEAM_EARNINGS])
    },
  })
}

export default useApproveRequestMutation
