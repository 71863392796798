import { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'
import { LinkButton } from '../../../../components/Button'
import TeamIcon from '../../../../components/Icons/TeamIcon'
import Popup from '../../../../components/Popup'
import TeamModal from '../../../../components/TeamModal'
import TutorialTooltip from '../../../../components/TutorialTooltip/TutorialTooltip'
import { useUser } from '../../../../contexts/AuthContext'
import useTeamQuery from '../../../../queries/useTeamQuery'
import colors from '../../../../styles/colors'
import SectionContainer from '../SectionContainer'
import SectionTitle from '../SectionTitle'
import ChooseManagerModal from './components/ChooseManagerModal.tsx'

interface MyManagerSectionProps {
  editable: boolean
  mask?: boolean
}

const MyManagerSection = ({ editable, mask }: MyManagerSectionProps) => {
  const [openTeamModal, setOpenTeamModal] = useState<boolean>(false)
  const [openChooseManagerModal, setOpenChooseManagerModal] = useState<boolean>(false)
  const { user, setTutorialStep, tutorialStep } = useUser()
  const target = useRef(null)
  const { data: teamData } = useTeamQuery(user.team?.id)

  return (
    <Container>
      <MyManagerSectionContainer $editable={editable} $mask={mask}>
        <SectionTitle>My manager</SectionTitle>
        <Wrapper>
          {user?.team?.managerName ? (
            <>
              <Image src={user.team?.managerPhoto || '/images/manager.png'} alt="manager photo" />
              <ManagerName>{user?.team?.managerName}</ManagerName>
              <TeamName onClick={() => setOpenTeamModal(true)}>
                <TeamIcon />
                <span>{user?.team?.name}</span>
                {teamData ? (
                  <Popup open={true}>
                    {teamData?.members?.map((member: any) => {
                      return (
                        <TeamMember key={member.id}>
                          {member.firstName} {member.lastName}
                        </TeamMember>
                      )
                    })}
                  </Popup>
                ) : null}
              </TeamName>
              <IconsWrapper>
                <a href={user?.team?.telegram || '#'} target="_blank" rel="noreferrer">
                  <Icon src="/images/telegram.svg" alt="telegram icon" />
                </a>
                <a href={user?.team?.discord || '#'} target="_blank" rel="noreferrer">
                  <DiscordIcon src="/images/discord.svg" alt="discord icon" />
                </a>
              </IconsWrapper>
            </>
          ) : (
            <>
              <EmptyManagerImage src="/images/manager-avatar.svg" alt="manager photo" />
              <Overlay placement={'top'} show={tutorialStep === 2} target={target.current}>
                {props => (
                  <Tooltip className="tutorial" {...props}>
                    <TutorialTooltip>Step: Choose your manager and team</TutorialTooltip>
                  </Tooltip>
                )}
              </Overlay>
              <ManagerButton
                ref={target}
                onClick={() => {
                  setTutorialStep(tutorialStep + 1)
                  setOpenChooseManagerModal(true)
                }}
              >
                CHOOSE MANAGER
              </ManagerButton>
            </>
          )}
        </Wrapper>
      </MyManagerSectionContainer>
      <TeamModal openModal={openTeamModal} setOpenModal={setOpenTeamModal} />
      {user.id && !user.team?.id ? (
        <ChooseManagerModal isOpen={openChooseManagerModal} setIsOpen={setOpenChooseManagerModal} />
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  width: 15%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  height: 315px;

  @media screen and (max-width: 1300px) {
    width: 50%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  text-align: center;
  padding: 14px 0 5px;
`

const Image = styled.img`
  width: 85px;
  height: 85px;
  border-radius: 50%;
`

const EmptyManagerImage = styled(Image)`
  margin-top: 20px;
`

const ManagerButton = styled(LinkButton)`
  margin-top: 10px;
  width: 100%;
`

const MyManagerSectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 20px 22px;
  height: 100%;
`

const ManagerName = styled.p`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue800};
  margin-top: 10px;
  margin-bottom: 1px;
`

const TeamName = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${colors.blue400};
  position: relative;
  display: inline-block;
  cursor: pointer;

  & > svg {
    margin-right: 3px;
    margin-top: -2px;
  }

  & > div {
    display: none;
  }

  &:hover {
    & > div {
      display: block;
    }
  }
`

const Icon = styled.img`
  width: 30px;
`

const DiscordIcon = styled.img`
  width: 35px;
`

const TeamMember = styled.p`
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
  margin: 0 0 12px;
`

const IconsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

export default MyManagerSection
