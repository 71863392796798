import { useQueryClient } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { LinkButton, SubmitButton } from '../../components/Button'
import { ErrorMsg } from '../../components/ErrorMessage'
import Input from '../../components/Input'
import { BackgroundWrapper, BlueContainer } from '../../components/UI'
import { useUser } from '../../contexts/AuthContext'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import useLoginMutation from './queries/useLoginMutation'
import useSendAgainMutation from '../VerifyEmail/queries/useSendAgainMutation'

const LoginPage = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [serverError, setServerError] = useState<string | null>(null)
  const navigate = useNavigate()
  const { refetchUser } = useUser()
  const queryClient = useQueryClient()
  const { mutateAsync: sendAgain, isLoading: sendAgainLoading } = useSendAgainMutation()
  const { mutateAsync: login, isLoading } = useLoginMutation()

  const enableSubmit = !!email && !!password

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (!enableSubmit) {
      return
    }
    await login(
      {
        email,
        password,
      },
      {
        onError: async (error: any) => {
          const data = error?.response?.data
          if (data === 'Email not confirmed.') {
            await sendAgain({
              email,
            })
            navigate(`/verify-email?email=${email}`)
          }
          setServerError(data)
        },
        onSuccess: () => {
          queryClient.clear()
          refetchUser()
          navigate('/')
        },
      }
    )
  }

  return (
    <>
      <BlueContainer>
        <BackgroundWrapper />
      </BlueContainer>
      <FormWrapper>
        <Head>
          <Welcome>Welcome to</Welcome>
          <Title>Virtue Alliance!</Title>
        </Head>
        <MobileHead>
          <MobileLogo src="/images/small-logo.svg" alt="virtue alliance" />
          <MobileTitle>Virtue Alliance</MobileTitle>
        </MobileHead>
        <form onSubmit={handleSubmit}>
          <InputsWrapper>
            <div>
              <Input
                id="email"
                type="email"
                value={email}
                big
                noLabelMobile
                placeholder="Email address"
                label="Email address"
                labelColor={colors.white}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value)
                }}
                errorMsg="Email cannot be empty!"
              />
            </div>
            <div>
              <Input
                id="password"
                type="password"
                value={password}
                big
                noLabelMobile
                label="Password"
                placeholder="Password"
                labelColor={colors.white}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.target.value)
                }}
                errorMsg="Password cannot be empty!"
              />
            </div>
            {serverError ? <ErrorMsg>{serverError}</ErrorMsg> : null}
          </InputsWrapper>
          <ButtonWrapper>
            <SButton disabled={!enableSubmit || isLoading || sendAgainLoading} type="submit">
              <ClipLoader
                color={colors.white}
                loading={isLoading || sendAgainLoading}
                cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
                size={20}
                aria-label="Loading Spinner"
              />
              Login
            </SButton>
            <OrDivider>or</OrDivider>
            <SignupButton
              onClick={e => {
                e.preventDefault()
                navigate('/signup')
              }}
            >
              Sign up
            </SignupButton>
          </ButtonWrapper>
        </form>
      </FormWrapper>
    </>
  )
}

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - 215px);
  max-width: 430px;
  width: 100%;
  background: linear-gradient(
    180.11deg,
    rgba(93, 129, 229, 0.19) -13.71%,
    rgba(93, 129, 229, 0.19) -13.7%,
    rgba(18, 27, 52, 0.19) 107.63%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 15px 96px rgba(3, 41, 96, 0.85);
  border-radius: 18px;
  padding: 40px;

  @media screen and (max-width: 768px) {
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    left: 0;
    max-width: 100%;
  }
`

const Head = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 30px;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(78, 130, 204, 0.55);

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const MobileHead = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const Welcome = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
`

const Title = styled.p`
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-family: ${fonts.fontFamilyBold};
`

const ButtonWrapper = styled.div`
  text-align: center;
  color: ${colors.white};
  margin-top: 40px;
`

const OrDivider = styled.p`
  margin: 15px 0;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const SignupButton = styled(LinkButton)`
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    text-decoration: none;
    border: 1px solid ${colors.white};
    width: 100%;
    height: 38px;
    margin-top: 20px;
    border-radius: 9px;
  }
`

const InputsWrapper = styled.div`
  padding: 0 35px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`

const SButton = styled(SubmitButton)`
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const MobileLogo = styled.img`
  width: 130px;
  margin-bottom: 20px;
`

const MobileTitle = styled.h2`
  font-size: 21px;
  line-height: 30px;
  color: ${colors.white};
  font-family: ${fonts.fontFamilySemiBold};
  letter-spacing: 0.8px;
  margin: 0;
`

export default LoginPage
