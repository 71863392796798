import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const approveNft = async (id: number) => {
  const data = await API.authPost('nft/request/accept', {
    id,
  })

  return data
}

interface ApproveRequestNFTParams {
  id: number
}

const useApproveRequestNFTMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: ApproveRequestNFTParams) => approveNft(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NFT_TEAM_REQUESTS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NFT_TEAM_REQUESTS_MODAL] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SEARCH_NFT] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.SEARCH_MARKETPLACE_NFT] })
    },
  })
}

export default useApproveRequestNFTMutation
