import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useCurrency } from '../../../contexts/CurrencyContext'
import { CurrencyHTML } from '../../../pages/Profile/components/EarningsSection/components/EarningTile'
import useUserEarningsAggregatedQuery from '../../../queries/useUserEarningsAggregatedQuery'
import colors from '../../../styles/colors'
import { AggregatedEarning, Balance } from '../../../types/user'
import { VictoryPie } from 'victory'
import fonts from '../../../styles/fonts'
import { Currency, CurrencySymbol } from '../../../types/currency'

const colorScale = ['#FD98FF', '#79ACFF', '#FA8E41', '#FFCE1F', '#47B88F']

const getAmount = (currency: Currency, dollar: number, euro: number, btc: number) => {
  if (currency === Currency.BITCOIN) {
    return `${CurrencySymbol.BITCOIN} ${+parseFloat(btc?.toFixed(5))}`
  }
  if (currency === Currency.EURO) {
    return `${CurrencySymbol.EURO} ${+parseFloat(euro?.toFixed(2))}`
  }

  return `${CurrencySymbol.USD} ${+parseFloat(dollar?.toFixed(2))}`
}

const EarningsSection = ({ userId }: { userId?: number }) => {
  const { data: earnings, isLoading } = useUserEarningsAggregatedQuery(userId)
  const [hoveredElement, setHoveredElement] = useState<number | null>(null)
  const { currency } = useCurrency()

  const defaultTotal = {
    dollarAmount: 0,
    eurAmount: 0,
    btcAmount: 0,
  }
  const totalEarned =
    earnings?.reduce((acc: Balance, nextValue: AggregatedEarning) => {
      return {
        dollarAmount: acc.dollarAmount + nextValue.dollarAmount,
        eurAmount: acc.eurAmount + nextValue.eurAmount,
        btcAmount: acc.btcAmount + nextValue.btcAmount,
      }
    }, defaultTotal) || defaultTotal

  const chartData = useMemo(() => {
    return earnings?.map(earning => {
      return {
        x: () => null,
        y: earning.dollarAmount,
      }
    })
  }, [earnings])

  const calculatePercentage = (amount: number) => {
    const onePerc = totalEarned.dollarAmount / 100
    const perc = amount / onePerc

    return perc.toFixed(1)
  }

  if (isLoading) {
    return (
      <>
        <SubTitle>Earnings per game</SubTitle>
        <SectionWrapper></SectionWrapper>
      </>
    )
  }

  return (
    <>
      <SubTitle>Earnings per game</SubTitle>
      <SectionWrapper>
        {earnings && earnings.length > 0 ? (
          <>
            <ChartCell>
              <VictoryPie
                innerRadius={130}
                padAngle={1}
                data={chartData}
                colorScale={colorScale}
                padding={{ top: 0, left: 0, right: 0, bottom: 0 }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onMouseOver: (prop, data) => {
                        setHoveredElement(data.index)
                        return [
                          {
                            target: 'data',
                            mutation: ({ style }) => {
                              return style.fill === `${colorScale[data.index]}99`
                                ? null
                                : { style: { fill: `${colorScale[data.index]}99` } }
                            },
                          },
                        ]
                      },
                      onMouseOut: (prop, data) => {
                        setHoveredElement(null)
                        return [
                          {
                            target: 'data',
                            mutation: ({ style }) => {
                              return style.fill === `${colorScale[data.index]}99`
                                ? null
                                : { style: { fill: `${colorScale[data.index]}99` } }
                            },
                          },
                        ]
                      },
                    },
                  },
                ]}
              />
              <AllAmount>
                <CurrencyHTML currency={currency} earning={totalEarned} />
              </AllAmount>
            </ChartCell>
            <GamesCell>
              {earnings?.map((earning, index) => {
                return (
                  <Cell key={earning.gameId}>
                    <InnerCell $hovered={index === hoveredElement}>
                      <GameName $hovered={index === hoveredElement}>
                        <Color $index={index} />
                        {earning.gameName}
                      </GameName>
                      <GamePerc>
                        {calculatePercentage(earning.dollarAmount)}% /{' '}
                        <strong>
                          {getAmount(
                            currency,
                            earning.dollarAmount,
                            earning.eurAmount,
                            earning.btcAmount
                          )}
                        </strong>
                      </GamePerc>
                    </InnerCell>
                  </Cell>
                )
              })}
            </GamesCell>
          </>
        ) : (
          <CenterWrapper>
            <NoImage src="/images/no-earnings.svg" alt="no earnings" />
            <NoDataMessage>No earning statistics</NoDataMessage>
          </CenterWrapper>
        )}
      </SectionWrapper>
    </>
  )
}

const CenterWrapper = styled.div`
  text-align: center;
`

const AllAmount = styled.p`
  position: absolute;
  top: calc(50% - 15px);
  text-align: center;
  left: 0;
  right: 0;
  margin: 0;

  & > span {
    font-size: 14px;
    line-height: 16px;
    color: ${colors.blue900};
    font-family: ${fonts.fontFamilyBold};
  }
`

const GameName = styled.div<{ $hovered?: boolean }>`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  font-family: ${fonts.fontFamilyBold};
  margin-bottom: 2px;

  ${({ $hovered }) =>
    $hovered &&
    `
    color: ${colors.blue500};
  `}
`

const GamePerc = styled.div`
  color: ${colors.grey500};
  font-size: 12px;
  line-height: 16px;
  padding-left: 30px;

  & > strong {
    font-weight: 600;
    font-family: ${fonts.fontFamilySemiBold};
  }
`

const SubTitle = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
  margin-bottom: 30px;
`

const NoImage = styled.img`
  width: 60px;
`

const NoDataMessage = styled.p`
  margin: 10px 0;
  color: ${colors.black};
  font-size: 12px;
  line-height: 16px;
`

const SectionWrapper = styled.div`
  margin-bottom: 20px;
`

const Cell = styled.div`
  display: inline-block;
  width: 50%;
  vertical-align: top;
  padding-right: 10px;
  margin-bottom: 7px;
`

const InnerCell = styled.div<{ $hovered?: boolean }>`
  padding-bottom: 5px;
  transition: 0.4s all;
  border-radius: 6px;
  padding-top: 5px;
  border: 1px solid ${colors.white};

  ${({ $hovered }) =>
    $hovered &&
    `
    background: rgba(86, 135, 244, 0.15);
  `}
`

const ChartCell = styled.div`
  display: inline-block;
  width: 30%;
  vertical-align: top;
  position: relative;
`

const GamesCell = styled.div`
  display: inline-block;
  width: 70%;
  vertical-align: top;
  padding-left: 30px;
  padding-top: 10px;
`

const Color = styled.div<{ $index: number }>`
  display: inline-block;
  width: 23px;
  height: 13px;
  border-radius: 4px;
  margin-right: 7px;
  margin-left: 5px;
  vertical-align: middle;

  ${({ $index }) => `
    background: ${colorScale[$index]};
  `}
`

export default EarningsSection
