import { useState } from 'react'
import styled from 'styled-components'
import { useUser } from '../../../../../contexts/AuthContext'
import colors from '../../../../../styles/colors'
import { Earning } from '../../../../../types/user'
import useTeamEarningsQuery from '../queries/useTeamEarnings'
import EarningTile from './EarningTile'
import ManagerEarningTile from './ManagerEarningTile'

const ManagerEarnings = ({ earnings }: { earnings?: Earning[] }) => {
  const { data: teamEarnings } = useTeamEarningsQuery()
  const [selectedTab, setSelectedTab] = useState<number>(1)
  const { user } = useUser()

  return (
    <>
      <Tabs>
        <Tab $selected={selectedTab === 1} onClick={() => setSelectedTab(1)}>
          My Team's Earnings
        </Tab>
        <Tab $selected={selectedTab === 2} onClick={() => setSelectedTab(2)} $rightTab>
          My Earnings
        </Tab>
      </Tabs>
      <EarningsContainer>
        <InnerWrapper>
          {selectedTab === 1 ? (
            <>
              {teamEarnings
                ?.filter(tE => tE.userId !== user.id)
                .map(earning => {
                  return <ManagerEarningTile earning={earning} key={earning.id} />
                })}
            </>
          ) : (
            <>
              {earnings?.map(earning => {
                return <EarningTile earning={earning} key={earning.id} />
              })}
            </>
          )}
        </InnerWrapper>
      </EarningsContainer>
    </>
  )
}

const EarningsContainer = styled.div`
  height: 245px;
  overflow: hidden;
`

const Tabs = styled.div`
  border-bottom: 1px solid rgba(163, 198, 255, 0.4);
  margin-top: -5px;
  padding-bottom: 8px;
  margin-bottom: 7px;
`

const Tab = styled.span<{ $selected?: boolean; $rightTab?: boolean }>`
  text-transform: uppercase;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${colors.blue500};
  cursor: pointer;
  padding-bottom: 10px;
  transition: all 0.4s;

  ${({ $selected }) =>
    $selected &&
    `
    font-weight: 700;
    border-bottom: 3px solid ${colors.blue500}; 
  `}

  ${({ $rightTab }) =>
    $rightTab &&
    `
    margin-left: 20px;
  `}
`

const InnerWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-right: 15px;
  padding-top: 7px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

export default ManagerEarnings
