import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const getReferrals = async () => {
  const data = await API.authGet(`referral/links`)

  return data
}

const useManagerReferralsQuery = () => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.MANAGER_REFERRALS, user.id], () => getReferrals(), {
    staleTime: 60 * 1000,
    enabled: !!user.id,
  })
}

export default useManagerReferralsQuery
