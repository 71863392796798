import styled from 'styled-components'
import colors from '../../styles/colors'

export const LinkButton = styled.button`
  background: transparent;
  color: ${colors.blue400};
  border: 0;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  padding: 0 5px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:disabled {
    opacity: 0;
  }
`

export const SubmitButton = styled.button`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.white};
  background: ${colors.blue550};
  border: 1px solid ${colors.blue550};
  padding: 10px;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:hover {
    background: ${colors.blue650};
  }

  &:disabled {
    background: #cccccc;
    border: 1px solid #cccccc;
    cursor: not-allowed;
    mix-blend-mode: normal;
    color: #979797;
  }
`

export const SecondaryButton = styled.button`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue500};
  background: ${colors.white};
  border: 1px solid ${colors.blue500};
  padding: 10px;
  border-radius: 9px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:hover {
    background: rgba(180, 200, 255, 0.3);
  }

  &:disabled {
    background: rgba(204, 204, 204, 0.5);
    border: 1px solid rgba(204, 204, 204, 0.5);
    color: #979797;
  }
`

export const RejectButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};
  background: #d32f2f;
  border: 1px solid #d32f2f;
  padding: 6px 12px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:hover {
    background: #ff3f3f;
  }
`

export const ApproveButton = styled.button`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.white};
  background: #1dac56;
  border: 1px solid #1dac56;
  padding: 6px 12px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:hover {
    background: #24cd31;
  }
`

export const Link = styled.a`
  background: transparent;
  color: ${colors.blue400};
  border: 0;
  text-decoration: underline;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  outline: none;
  padding: 0 5px;
  cursor: pointer;
  opacity: 1;
  transition: all 0.4s;

  &:disabled {
    opacity: 0;
  }
`
