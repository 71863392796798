import styled from 'styled-components'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { CurrencyHTML } from '../../../Profile/components/EarningsSection/components/EarningTile'
import useGetGameDetailsQuery from '../../queries/useGetGameDetailsQuery'

const MyEarningsSection = () => {
  const { data: game } = useGetGameDetailsQuery()
  const { currency } = useCurrency()
  const earning = game?.myEarnings

  return (
    <Container>
      <Title>
        <Icon src="/images/banknote.svg " alt="earnings" /> My Earnings
      </Title>
      <div>
        <Earning>
          <CurrencyHTML currency={currency} earning={earning} />
        </Earning>
      </div>
    </Container>
  )
}

const Container = styled.div`
  background: ${colors.white};
  border-radius: 12px;
  padding: 14px 24px;
`

const Title = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  letter-spacing: 0.15px;
  color: ${colors.grey500};
`

const Icon = styled.img`
  margin-right: 5px;
  width: 20px;
  vertical-align: middle;
  margin-top: -2px;
`

const Earning = styled.p`
  font-weight: 700;
  font-size: 34px;
  margin: 50px 0;
  color: ${colors.blue900};
  text-align: center;
  font-family: ${fonts.fontFamilyBold};
`

export default MyEarningsSection
