import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getTeamMembers = async () => {
  const data = await API.authGet('lookup/team')

  return data
}

const useGetTeamMembersQuery = () => {
  return useQuery([QUERY_KEYS.TEAM_MEMBERS], getTeamMembers, {
    staleTime: 60 * 1000,
  })
}

export default useGetTeamMembersQuery
