import { useRef, useState } from 'react'
import styled from 'styled-components'
import { LinkButton } from '../../../../components/Button'
import SectionContainer from '../SectionContainer'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import colors from '../../../../styles/colors'
import { useUser } from '../../../../contexts/AuthContext'
import Wallet from '../../../../types/wallet'
import useNetworksQuery from './queries/useNetworksQuery'
import { selectTabStyles } from '../../../../utils/dropdown'
import Select from 'react-select'
import WalletComponent from './components/WalletComponent'
import CardModal from '../../../../components/CardModal'
import WalletModal from '../../../../components/WalletModal'
import { Overlay, Tooltip } from 'react-bootstrap'
import TutorialTooltip from '../../../../components/TutorialTooltip/TutorialTooltip'

interface CardDetailsSectionProps {
  editable: boolean
  mask?: boolean
}

const OPTIONS = [
  {
    value: 'Web 3 Wallet',
    label: 'Web 3 Wallet',
  },
  {
    value: 'Card',
    label: 'Card',
  },
]

const CardDetailsSection = ({ editable, mask }: CardDetailsSectionProps) => {
  const { user, tutorialStep, setTutorialStep } = useUser()
  const target = useRef(null)
  const [selectedOption, setSelectedOption] = useState<any>(OPTIONS[0])
  const [openDetailsModal, setOpenDetailsModal] = useState<boolean>(false)
  const [openWeb3Modal, setOpenWeb3Modal] = useState<boolean>(false)
  const [walletDetails, setWalletDetails] = useState<Wallet>({})
  const { data: networks } = useNetworksQuery()

  const handleEdit = (id: number) => {
    const wallet = user.payoutWallets?.filter((w: any) => {
      return w.id === id
    })?.[0]

    if (wallet) {
      const net = networks?.filter((network: any) => {
        return network.id === wallet.networkId
      })?.[0]

      setWalletDetails({
        name: wallet.name,
        network: net.name,
        publicAddress: wallet.publicAddress,
        id,
      })
      setOpenWeb3Modal(true)
    }
  }

  return (
    <Container>
      <CardSectionContainer $editable={editable} $mask={mask}>
        <Header>
          <Select
            id="card-wallet"
            options={OPTIONS}
            isSearchable={false}
            value={selectedOption}
            styles={selectTabStyles}
            onChange={e => {
              setSelectedOption(e)
            }}
          />
        </Header>
        {selectedOption.value === OPTIONS[1].value ? (
          <>
            <VirtualCard>
              <img src="/images/va-card-logo.svg" alt="va logo" />
            </VirtualCard>
            <Central>
              <LinkButton onClick={() => setOpenDetailsModal(true)}>Payment Details</LinkButton>
            </Central>
          </>
        ) : (
          <>
            <div>
              <OuterWrapper>
                <InnerWrapper>
                  {user?.payoutWallets?.map((p: any) => {
                    const net = networks?.filter((network: any) => {
                      return network.id === p.networkId
                    })?.[0]

                    return (
                      <WalletComponent
                        key={p.id}
                        id={p.id}
                        name={p.name}
                        publicAddress={p.publicAddress}
                        backgroundImageUrl={p.backgroundImageUrl}
                        network={net?.name || ''}
                        edit={handleEdit}
                      />
                    )
                  })}
                </InnerWrapper>
              </OuterWrapper>
              <Overlay placement={'top'} show={tutorialStep === 1} target={target.current}>
                {props => (
                  <Tooltip className="tutorial" {...props}>
                    <TutorialTooltip>Step: Add web3 wallet</TutorialTooltip>
                  </Tooltip>
                )}
              </Overlay>
              <LinkButton
                ref={target}
                onClick={() => {
                  if (tutorialStep === 1) {
                    setTutorialStep(tutorialStep + 1)
                  }
                  setOpenWeb3Modal(true)
                }}
              >
                <PlusIcon src="/images/plus-icon.svg" alt="plus" />
                Add wallet
              </LinkButton>
            </div>
          </>
        )}
      </CardSectionContainer>

      <WalletModal
        openWeb3Modal={openWeb3Modal}
        setOpenWeb3Modal={setOpenWeb3Modal}
        walletDetails={walletDetails}
        setWalletDetails={setWalletDetails}
      />
      <CardModal openDetailsModal={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} />
    </Container>
  )
}

const Container = styled.div`
  width: 25%;
  display: inline-block;
  padding: 0 10px;
  vertical-align: top;
  height: 315px;

  @media screen and (max-width: 1300px) {
    width: 50%;
    margin-top: 10px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
`

const CardSectionContainer = styled(SectionContainer)`
  border-radius: 8px;
  padding: 12px 22px 20px;
  height: 100%;
`

const VirtualCard = styled.div`
  background: linear-gradient(64.36deg, #032960 7.02%, #5687f4 93.91%);
  border-radius: 12px;
  height: 160px;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 22px;
  overflow: hidden;
`

const Central = styled.div`
  text-align: center;
`

const PlusIcon = styled.img`
  margin-right: 5px;
  margin-top: -2px;
  vertical-align: middle;
`

const OuterWrapper = styled.div`
  height: 215px;
  overflow: hidden;
`

const InnerWrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  padding-right: 15px;
  display: flex;
  gap: 12px;
  flex-direction: column;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
`

export default CardDetailsSection
