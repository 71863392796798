import { useCallback, useMemo, useState } from 'react'
import Select from 'react-select'
import styled from 'styled-components'
import CustomValueContainer from '../../../../components/CustomValueContainer'
import TeamIcon from '../../../../components/Icons/TeamIcon'
import ProfileModal from '../../../../components/ProfileModal'
import colors from '../../../../styles/colors'
import { selectPlayersFilterStyles } from '../../../../utils/dropdown'
import useGetManagerGameDetailsQuery from '../../queries/useGetManagerGameDetailsQuery'
import Player from './components/Player'

interface ManagerTeamSectionProps {
  selectedPlayers: any
  setSelectedPlayers: (p: any) => void
}

const ManagerTeamSection = ({ selectedPlayers, setSelectedPlayers }: ManagerTeamSectionProps) => {
  const { data: game } = useGetManagerGameDetailsQuery()
  const [openPlayerModal, setOpenPlayerModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<number | null>(null)

  const getSelectOptions = useCallback(() => {
    if (game?.teamMembers) {
      return game.teamMembers.map((member: any) => {
        return {
          ...member,
          value: member.userId,
          label: member.name,
        }
      })
    }

    return []
  }, [game])

  const filteredPlayers = useMemo(() => {
    if (!game?.teamMembers) {
      return []
    }
    if (selectedPlayers.length > 0) {
      return game.teamMembers?.filter((m: any) => {
        const isSelected = selectedPlayers.filter((s: any) => s.userId === m.userId)

        if (isSelected && isSelected.length > 0) {
          return true
        }
        return false
      })
    } else {
      return game.teamMembers
    }
  }, [game, selectedPlayers])

  return (
    <Container>
      <Title>
        <TeamIcon color={colors.grey500} /> Managed Players
        {game?.teamMembers ? `(${game?.teamMembers?.length})` : ''}
      </Title>
      <SearchWrapper>
        <Filter>
          <Select
            components={{ ValueContainer: CustomValueContainer }}
            id="players"
            options={getSelectOptions()}
            placeholder="All players"
            value={selectedPlayers}
            styles={selectPlayersFilterStyles}
            onChange={(e: any) => {
              const findPlayer = selectedPlayers.filter((p: any) => p.userId === e.userId)?.[0]
              if (findPlayer) {
                const newPlayers = selectedPlayers.filter((p: any) => p.userId !== e.userId)

                setSelectedPlayers([...newPlayers])
              } else {
                setSelectedPlayers([...selectedPlayers, e])
              }
            }}
          />
        </Filter>
        <Counter>
          {selectedPlayers.length > 0 ? (
            <Label
              onClick={() => {
                setSelectedPlayers([])
              }}
            >
              {selectedPlayers.length} selected <img src="/images/close.svg" alt="delete" />
            </Label>
          ) : null}
        </Counter>
      </SearchWrapper>
      {selectedPlayers.length > 0 ? (
        <TagsWrapper>
          {selectedPlayers.map((player: any) => {
            return (
              <Tag key={player.userId}>
                <Label
                  onClick={() => {
                    const newPlayers = selectedPlayers.filter(
                      (p: any) => p.userId !== player.userId
                    )
                    setSelectedPlayers([...newPlayers])
                  }}
                >
                  {player.name} <img src="/images/close.svg" alt="delete" />
                </Label>
              </Tag>
            )
          })}
        </TagsWrapper>
      ) : null}
      <div>
        {filteredPlayers?.map((member: any) => {
          return (
            <Player
              key={member.userId}
              member={member}
              handleClick={() => {
                setSelectedUser(member.userId)
                setOpenPlayerModal(true)
              }}
            />
          )
        })}
      </div>
      <ProfileModal
        userId={selectedUser}
        openModal={openPlayerModal}
        setOpenModal={() => {
          setOpenPlayerModal(false)
          setSelectedUser(null)
        }}
      />
    </Container>
  )
}

const Container = styled.div`
  background: ${colors.white};
  border-radius: 12px;
  padding: 14px 24px;
  margin-top: 12px;
`

const Title = styled.p`
  font-size: 12px;
  line-height: 24px;
  margin: 0 0 14px;
  letter-spacing: 0.15px;
  color: ${colors.grey500};

  & > svg {
    margin-right: 5px;
    margin-top: -3px;
  }
`

const SearchWrapper = styled.div`
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px solid #dce9ff;
`

const Filter = styled.div`
  width: 65%;
  display: inline-block;
  vertical-align: middle;
`

const Counter = styled.div`
  width: 35%;
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
  text-align: right;
`

const Label = styled.span`
  background: rgba(255, 203, 70, 0.3);
  border-radius: 22px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding-left: 10px;
  padding-right: 20px;
  display: inline-block;
  color: ${colors.blue900};
  position: relative;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background: rgba(255, 203, 70, 0.5);
  }

  & > img {
    position: absolute;
    top: 9px;
    right: 7px;
    width: 8px;
  }
`

const TagsWrapper = styled.div`
  margin-bottom: 13px;
`

const Tag = styled.div`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
`

export default ManagerTeamSection
