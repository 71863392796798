import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

const getGame = async (gameId: string) => {
  const data = await API.authGet(`game/detail?gameId=${gameId}`)
  return data
}

const useGetGameDetailsQuery = () => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const { user } = useUser()

  return useQuery([QUERY_KEYS.GAME_DETAILS, gameQuery || '1'], () => getGame(gameQuery || '1'), {
    enabled: user.userRoleName === 'Player',
  })
}

export default useGetGameDetailsQuery
