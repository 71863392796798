import colors from '../../../../../styles/colors'

interface DiscordIconProps {
  color: string
}

const DiscordIcon = ({ color }: DiscordIconProps) => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5858 12.5009C14.4201 12.2025 14.2103 12.227 13.958 12.3372C10.9375 13.6563 7.91411 13.6196 4.89512 12.3265C4.72055 12.2515 4.54745 12.201 4.4477 12.3938C4.31567 12.6478 4.57239 12.6601 4.69708 12.7473C4.73669 12.7764 4.7807 12.7993 4.82177 12.8269C5.14157 13.0488 5.76062 13.0763 5.77089 13.4237C5.78556 13.9317 5.3308 14.3587 5.05795 14.8086C4.88192 15.0978 4.64574 14.9938 4.40956 14.9065C3.02035 14.397 1.72063 13.7053 0.500122 12.8483C0.212599 12.6463 0.0747056 12.406 0.0483004 12.0388C-0.231888 8.18546 0.740703 4.68261 2.73723 1.4598C2.88246 1.22566 3.09663 1.12925 3.31961 1.02519C4.23792 0.598238 5.20318 0.331966 6.17137 0.0656936C6.60706 -0.0552001 6.94739 -0.0123517 7.08968 0.497238C7.20997 0.927253 7.47549 0.999177 7.87157 0.948677C8.94098 0.81095 10.0177 0.809419 11.0871 0.947146C11.5184 1.00224 11.7942 0.910419 11.9189 0.45439C12.0377 0.0213149 12.3267 -0.0781546 12.714 0.0549815C12.7609 0.0702845 12.8079 0.0825269 12.8548 0.0962996C16.4958 1.14456 16.0997 0.749738 17.7368 4.40409C18.7124 6.58171 19.0146 8.92766 18.997 11.3287C18.9911 12.2071 18.7182 12.7886 17.9613 13.2247C16.9124 13.8307 15.8503 14.3862 14.7369 14.8515C14.2132 15.0718 13.9169 14.9525 13.6866 14.452C13.581 14.224 13.4475 14.0083 13.311 13.7971C13.1027 13.4772 13.1086 13.2752 13.512 13.1222C13.892 12.9768 14.288 12.8223 14.5844 12.4994L14.5858 12.5009ZM11.3086 9.43113L11.5111 9.68516C12.1668 10.3126 12.8269 10.4243 13.4827 10.0172C14.134 9.61324 14.4919 8.62925 14.3115 7.73862C14.1516 6.94898 13.5325 6.34605 12.8196 6.28177C12.1507 6.22209 11.6138 6.47765 11.2749 7.076C10.8334 7.85492 10.7864 8.65374 11.3086 9.4296V9.43113ZM8.04027 8.25586C8.05054 7.12497 7.3244 6.27106 6.35034 6.268C5.40415 6.26647 4.66481 7.08824 4.64427 8.16863C4.62227 9.32707 5.37335 10.2468 6.343 10.2498C7.29652 10.2529 8.03147 9.38981 8.04174 8.25433L8.04027 8.25586Z"
        fill={color || colors.grey350}
      />
    </svg>
  )
}

export default DiscordIcon
