import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

interface PaymentParams {
  id: number
  payoutWalletId: number
  amount: number
  currencyCodeId: number
}

const editPayment = async (body: PaymentParams) => {
  const data = await API.authPost('payment/request/edit', {
    ...body,
  })

  return data
}

const useEditPaymentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: PaymentParams) => editPayment(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PAYMENTS] })
    },
  })
}

export default useEditPaymentMutation
