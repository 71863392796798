import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react'
import { Currency } from '../types/currency'
import { useUser } from './AuthContext'

const CurrencyContext = createContext<{
  currency: Currency
  setCurrency: (currency: Currency) => void
}>({
  currency: Currency.USD,
  setCurrency: () => {},
})

const CurrencyContextProvider = ({ children }: PropsWithChildren) => {
  const [currency, setCurrency] = useState<Currency>(Currency.USD)

  const { user } = useUser()

  useEffect(() => {
    if (user.currency) {
      setCurrency(user.currency)
    }
  }, [user.currency, setCurrency])

  return (
    <CurrencyContext.Provider
      value={{
        currency,
        setCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  )
}

export const useCurrency = () => {
  const context = useContext(CurrencyContext)

  return context
}

export default CurrencyContextProvider
