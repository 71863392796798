import { VA_TOKEN } from '../api'
import ROUTES, { noAuthRoutes } from '../routes'

export const redirectIfNotAuth = () => {
  const path = window.location.pathname
  const clearPath = path.substring(1)
  const token = window.localStorage.getItem(VA_TOKEN)

  if (!token && !noAuthRoutes.includes(clearPath as ROUTES)) {
    window.location.href = '/login'
    return
  }
  // if (href) {

  // }
}
