import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'

const fetchNftRequests = async (
  game: string,
  search: string,
  players: string,
  startDate: string,
  endDate: string
) => {
  const data = await API.authGet(
    `nft/request/search/team?filter.GameId=${game}&filter.NftSearchValuesString=${encodeURIComponent(
      search
    )}${players}&filter.Page=1&filter.Size=100&filter.NftRequestStatusIds=1${
      startDate ? `&filter.DateFrom=${startDate}` : ''
    }${endDate ? `&filter.DateTo=${endDate}` : ''}`
  )

  return data
}

const fetchClosedNftRequests = async (
  game: string,
  search: string,
  players: string,
  startDate: string,
  endDate: string,
  isApproved: boolean
) => {
  const data = await API.authGet(
    `nft/request/search/team?filter.GameId=${game}&filter.NftSearchValuesString=${encodeURIComponent(
      search
    )}${players}&filter.Page=1&filter.Size=100&filter.NftRequestStatusIds=${isApproved ? 4 : 3}${
      startDate ? `&filter.DateFrom=${startDate}` : ''
    }${endDate ? `&filter.DateTo=${endDate}` : ''}`
  )

  return data
}

interface Params {
  game?: string
  search?: string
  players?: string
  startDate: string | null
  endDate: string | null
  isApproved?: boolean
}

export const useManagerActiveNftRequestsModalQuery = ({
  game,
  search,
  players,
  startDate,
  endDate,
}: Params) => {
  const { user } = useUser()

  return useQuery({
    queryKey: [
      QUERY_KEYS.NFT_TEAM_REQUESTS_MODAL,
      user.id,
      game || '1',
      search || '',
      players || '',
      startDate || '',
      endDate || '',
      'active',
    ],
    queryFn: () =>
      fetchNftRequests(game || '1', search || '', players || '', startDate || '', endDate || ''),
    enabled: user?.userRoleName === 'Manager',
  })
}

export const useManagerClosedNftRequestsModalQuery = ({
  game,
  search,
  players,
  startDate,
  endDate,
  isApproved,
}: Params) => {
  const { user } = useUser()

  return useQuery({
    queryKey: [
      QUERY_KEYS.NFT_TEAM_REQUESTS_MODAL,
      user.id,
      game || '1',
      search || '',
      players || '',
      startDate || '',
      endDate || '',
      isApproved ? 'approved' : 'rejected',
      'closed',
    ],
    queryFn: () =>
      fetchClosedNftRequests(
        game || '1',
        search || '',
        players || '',
        startDate || '',
        endDate || '',
        !!isApproved
      ),
    enabled: user?.userRoleName === 'Manager',
  })
}
