import { useMutation } from '@tanstack/react-query'
import API from '../../../api'

const confirmEmail = async (code: string) => {
  const data = await API.post(`email/confirm`, {
    confirmationCode: code,
  })

  return data
}

interface ConfirmEmailParams {
  code: string
}

const useConfirmEmailMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void
  onError: () => void
}) => {
  return useMutation(({ code }: ConfirmEmailParams) => confirmEmail(code), {
    onSuccess: () => {
      onSuccess?.()
    },
    onError: () => {
      onError?.()
    },
  })
}

export default useConfirmEmailMutation
