import { useMutation } from '@tanstack/react-query'
import API from '../../../api'

const sendAgain = async (email: string) => {
  const data = await API.post('email/resendconfirmation', {
    email,
  })

  return data
}

interface SendAgainParams {
  email: string
}

const useSendAgainMutation = () => {
  return useMutation(({ email }: SendAgainParams) => sendAgain(email))
}

export default useSendAgainMutation
