import styled from 'styled-components'
import colors from '../../../../../styles/colors'

const Player = ({ member }: { member: any }) => {
  return (
    <Container>
      <NameWrapper>
        <Name>{member.name}</Name>
      </NameWrapper>
      <IconsWrapper>
        <Icon src="/images/award.svg" alt="award" />
        <Amount>{member.experience}</Amount>
        <WinIcon src="/images/wins.svg" alt="wins" />
        <Amount>{member.wins}</Amount>
        <WinIcon src="/images/sad-face.svg" alt="losses" />
        <Amount>{member.losses}</Amount>
      </IconsWrapper>
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 13px 18px;
  background: #f1f5ff;
  border-radius: 8px;
  gap: 8px;
`

const Name = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey500};
`

const NameWrapper = styled.div``

const IconsWrapper = styled.div``

const Amount = styled.span`
  vertical-align: middle;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey500};
`

const Icon = styled.img`
  width: 15px;
  vertical-align: middle;
  margin-top: 3px;
  margin-left: 12px;
`

const WinIcon = styled(Icon)`
  margin-right: 4px;
  width: 13px;
  margin-top: 1px;
`

export default Player
