import { Team, TeamMember } from '../types/user'

export const findTop3Earners = (team: Team, userId: number | undefined): TeamMember[] | null => {
  if (!team || !userId) {
    return null
  }

  const members = team.members
  const sortedMembers = members.sort((a: TeamMember, b: TeamMember) =>
    a.totalEarned > b.totalEarned ? -1 : 1
  )
  const top4members = sortedMembers.slice(0, 4)

  const filterManagerFromTop4 = top4members.filter((member: TeamMember) => {
    return member.id !== userId
  })

  return filterManagerFromTop4
}
