import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { SubmitButton } from '../../components/Button'
import { ErrorMsg } from '../../components/ErrorMessage'
import { BackgroundWrapper, BlueContainer } from '../../components/UI'
import colors from '../../styles/colors'
import fonts from '../../styles/fonts'
import useConfirmEmailMutation from './queries/useConfirmEmailMutation'

const ConfirmedEmailPage = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const navigate = useNavigate()
  const firstMount = useRef<boolean>(true)
  const [isError, setIsError] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const { mutate: confirmEmail } = useConfirmEmailMutation({
    onSuccess: () => {
      setIsSuccess(true)
    },
    onError: () => {
      setIsError(true)
    },
  })

  useEffect(() => {
    if (!code) {
      navigate('/login')
    }
  }, [code, navigate])

  useEffect(() => {
    if (firstMount.current && code) {
      firstMount.current = false
      confirmEmail({ code })
    }
  }, [code, confirmEmail])

  return (
    <>
      <BlueContainer>
        <BackgroundWrapper />
      </BlueContainer>
      <FormWrapper>
        <Head>
          <LogoImage src="/images/small-logo.svg" alt="logo" />
        </Head>
        <MobileHead>
          <MobileLogo src="/images/small-logo.svg" alt="virtue alliance" />
          <MobileTitle>Virtue Alliance</MobileTitle>
        </MobileHead>
        <div>
          <Icon
            src="/images/big-tick.svg"
            alt="tick"
            onClick={() => {
              if (code) {
                confirmEmail({ code })
              }
            }}
          />
        </div>
        <div>
          {isError ? (
            <ErrorMsg>The code is invalid or the email is already confirmed!</ErrorMsg>
          ) : null}
          {isSuccess ? <Title>Email Verification Successful!</Title> : null}
          <ClipLoader
            color={colors.white}
            loading={!isError && !isSuccess}
            cssOverride={{ verticalAlign: 'middle', marginTop: '20px', marginBottom: '20px' }}
            size={60}
            aria-label="Loading Spinner"
          />
          <div>
            <SubmitButton onClick={() => navigate('/login')}>Proceed to login</SubmitButton>
          </div>
        </div>
      </FormWrapper>
    </>
  )
}

const Title = styled.p`
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 60px;
  margin-top: 34px;
`

const FormWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - 215px);
  max-width: 430px;
  width: 100%;
  background: linear-gradient(
    180.11deg,
    rgba(93, 129, 229, 0.19) -13.71%,
    rgba(93, 129, 229, 0.19) -13.7%,
    rgba(18, 27, 52, 0.19) 107.63%
  );
  mix-blend-mode: normal;
  box-shadow: 0px 15px 96px rgba(3, 41, 96, 0.85);
  border-radius: 18px;
  padding: 40px;
  text-align: center;
  color: ${colors.white};

  @media screen and (max-width: 768px) {
    box-shadow: none;
    left: 0;
    border-radius: 0;
    background: transparent;
    max-width: 100%;
  }
`

const Head = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 30px;
  margin-bottom: 15px;
`

const LogoImage = styled.img`
  width: 57px;
`

const Icon = styled.img`
  width: 120px;
`

const MobileLogo = styled.img`
  width: 130px;
  margin-bottom: 20px;
`

const MobileTitle = styled.h2`
  font-size: 21px;
  line-height: 30px;
  color: ${colors.white};
  font-family: ${fonts.fontFamilySemiBold};
  letter-spacing: 0.8px;
  margin: 0;
`

const MobileHead = styled.div`
  text-align: center;
  color: ${colors.white};
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

export default ConfirmedEmailPage
