import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'
import { Earning } from '../../../../../types/user'

const getTeamEarnings = async (): Promise<Earning[]> => {
  const data = await API.authGet('payment/manager')

  return data
}

const useTeamEarningsQuery = () => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.TEAM_EARNINGS], getTeamEarnings, {
    staleTime: 60 * 1000,
    enabled: !!user.id && user.userRoleName === 'Manager',
  })
}

export default useTeamEarningsQuery
