const colors = {
  black: '#000',

  grey: '#EBEBEB',
  grey100: '#F8F8F8',
  grey200: '#F2F2F2',
  grey300: '#F1F4F9',
  grey350: '#B3B3B3',
  grey400: '#B9B9B9',
  grey500: '#64748B',
  grey800: '#999999',

  blue100: '#F1F7FF',
  blue200: '#E5EDFF',
  blue300: '#A3C6FF',
  blue400: '#3B82F6',
  blue450: '#4E82CC',
  blue500: '#5687F4',
  blue550: '#5D81E5',
  blue650: '#315fde',
  blue800: '#121B34',
  blue900: '#032960',

  red300: '#FA2424',

  white: '#fff',
}

export default colors
