import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'

const deleteReferralLink = async (id: number) => {
  const data = await API.authDelete(`referral?referralLinkId=${id}`)

  return data
}

interface DeleteReferralLink {
  id: number
}

const useDeleteReferralLinkMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: DeleteReferralLink) => deleteReferralLink(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.MANAGER_REFERRALS])
    },
  })
}

export default useDeleteReferralLinkMutation
