import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const viewNotification = async (id: number) => {
  const data = await API.authPost(`notification`, {
    notificationId: id,
  })

  return data
}

interface ViewNotificationParams {
  id: number
}

const useViewNotificationMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(({ id }: ViewNotificationParams) => viewNotification(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.UNREAD_NOTIFICATIONS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ALL_READ_NOTIFICATIONS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ALL_UNREAD_NOTIFICATIONS] })
    },
  })
}

export default useViewNotificationMutation
