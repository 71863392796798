import styled from 'styled-components'
import AlphaBanner from '../../components/AlphaBanner/AlphaBanner'
import Navigation from '../../components/Navigation'
import PageContainer from '../../components/PageContainer'
import PaymentsSection from './components/PaymentsSection'
import GlobalLoader from '../../components/GlobalLoader/GlobalLoader'
import { useUser } from '../../contexts/AuthContext'

const PaymentsPage = () => {
  const { isUserInitialLoading } = useUser()

  if (isUserInitialLoading) {
    return <GlobalLoader isUserInitialLoading={isUserInitialLoading} />
  }

  return (
    <>
      <Navigation />
      <PageContainer>
        <AlphaBanner />
        <Wrapper>
          <PaymentsSection />
        </Wrapper>
      </PageContainer>
    </>
  )
}

const Wrapper = styled.div`
  padding: 10px 22px;
`

export default PaymentsPage
