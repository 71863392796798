import { ClipLoader } from 'react-spinners'
import colors from '../../styles/colors'
import styled from 'styled-components'

const GlobalLoader = ({ isUserInitialLoading }: { isUserInitialLoading: boolean }) => {
  return (
    <Wrapper>
      <ClipLoader
        color={colors.blue900}
        loading={isUserInitialLoading}
        cssOverride={{ verticalAlign: 'middle', marginRight: '3px' }}
        size={60}
        aria-label="Loading Spinner"
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export default GlobalLoader
