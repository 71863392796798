import styled from 'styled-components'
import ProfileImage from '../../../../components/ProfileImage'
import { useUser } from '../../../../contexts/AuthContext'
import { useCurrency } from '../../../../contexts/CurrencyContext'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { Currency } from '../../../../types/currency'
import { CurrencyHTML } from '../EarningsSection/components/EarningTile'
import TeamIcon from '../../../../components/Icons/TeamIcon'

const MobileProfileSection = () => {
  const { user } = useUser()
  const { currency } = useCurrency()

  return (
    <MobileProfileWrapper>
      <ImageWrapper>
        <ProfileImage />
        <div>
          <MobileName>
            {user.firstName} {user.lastName}
          </MobileName>
        </div>
      </ImageWrapper>
      <MobileContainer>
        <MobileAmountWrapper>
          <Cell>
            <Label>Average</Label>
            <Payout>
              <CurrencyHTML currency={currency} earning={user.averageMonthlyWithdraw} />
            </Payout>
          </Cell>
          <Cell $border>
            <Label>Last monthly</Label>
            <Payout>
              <CurrencyHTML currency={currency} earning={user.lastMonthWithdraw} />
            </Payout>
          </Cell>
          <Cell>
            <Label>Balance</Label>
            <Payout>
              <CurrencyHTML currency={currency} earning={user.balance} />
            </Payout>
          </Cell>
        </MobileAmountWrapper>
        <TeamWrapper>
          <RowWrapper>
            <TeamImage src="/images/manager.png" alt="manager photo" />
            <ColumnWrapper>
              <ManagerLabel>My team</ManagerLabel>
              <div>
                <TeamIcon />
                <TeamName>{user?.team?.name}</TeamName>
              </div>
            </ColumnWrapper>
          </RowWrapper>

          <IconsWrapper>
            <a href={user?.team?.telegram || '#'} target="_blank" rel="noreferrer">
              <TelegramIcon src="/images/telegram.svg" alt="telegram icon" />
            </a>
            <a href={user?.team?.discord || '#'} target="_blank" rel="noreferrer">
              <DiscordIcon src="/images/discord.svg" alt="discord icon" />
            </a>
          </IconsWrapper>
        </TeamWrapper>
        <ButtonsWrapper>
          <ButtonCell>
            <Icon src="/images/account-icon.svg" alt="account" />
            <ButtonLabel>Account</ButtonLabel>
          </ButtonCell>
          <ButtonCell>
            <IngameIcon src="/images/ingames-icon.svg" alt="ingame details" />
            <ButtonLabel>Ingame Details</ButtonLabel>
          </ButtonCell>
          <ButtonCell>
            <WalletIcon src="/images/wallets-icon.svg" alt="wallets" />
            <ButtonLabel>Wallets</ButtonLabel>
          </ButtonCell>
          <ButtonCell>
            <EarningsIcon src="/images/earnings-icon.svg" alt="earnings" />
            <ButtonLabel>Earnings</ButtonLabel>
          </ButtonCell>
        </ButtonsWrapper>
        <TeamWrapper>
          <CurrencyLabel>Currency</CurrencyLabel>
          <div>
            <CurrencyValue>
              {currency === Currency.USD ? 'USD' : null}
              {currency === Currency.EURO ? 'EUR' : null}
              {currency === Currency.BITCOIN ? 'BTC' : null}
            </CurrencyValue>
            <Pencil
              data-test-id={`currency-pencil-icon`}
              src="/images/pencil.svg"
              alt="editable icon"
            />
          </div>
        </TeamWrapper>
      </MobileContainer>
    </MobileProfileWrapper>
  )
}

const MobileContainer = styled.div`
  padding: 0 20px;
  margin-top: -20px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
`

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  background: ${colors.white};
  padding: 20px;
  border-radius: 12px;
  align-items: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`

const ButtonCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(50% - 8px);
  padding: 18px;
  border-radius: 14px;
  background: ${colors.white};
  align-items: center;
  justify-content: flex-end;
`

const ButtonLabel = styled.span`
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.46px;
  color: ${colors.blue500};
`

const Label = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey800};
`

const ImageWrapper = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    background: linear-gradient(
      342.51deg,
      rgba(3, 41, 96, 0.97) -15.95%,
      rgba(93, 129, 229, 0.97) 87.37%,
      rgba(93, 129, 229, 0.97) 87.38%
    );
    mix-blend-mode: normal;
    backdrop-filter: blur(17.5px);
    padding-top: 50px;
    padding-bottom: 50px;
    border-radius: 0px 0px 25px 25px;
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
`

const Payout = styled.span`
  text-align: center;
  color: ${colors.blue900};
  font-size: 20px;
  line-height: 20px;
  font-family: ${fonts.fontFamilyBold};

  & > span {
    font-family: ${fonts.fontFamilyBold};
  }
`

const Cell = styled.div<{ $border?: boolean }>`
  flex-basis: 33.333%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  ${({ $border }) =>
    $border &&
    `
    border-left: 1px solid rgba(86, 135, 244, 0.1);
    border-right: 1px solid rgba(86, 135, 244, 0.1);
  `}
`

const MobileProfileWrapper = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const MobileAmountWrapper = styled.div`
  border-radius: 12px;
  background: ${colors.white};
  display: flex;
  flex-direction: row;
  padding: 11px 0;
`

const MobileName = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 16px;
  color: ${colors.white};
`

const CurrencyLabel = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.grey500};
`

const CurrencyValue = styled.span`
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
  background: rgba(3, 41, 96, 0.15);
  border-radius: 24px;
  display: inline-block;
  padding: 7px 14px;
`

const Pencil = styled.img`
  margin-left: 12px;
  margin-top: -2px;
`

const Icon = styled.img`
  width: 28px;
`

const IngameIcon = styled.img`
  width: 44px;
`

const WalletIcon = styled.img`
  width: 28px;
`

const EarningsIcon = styled.img`
  width: 34px;
`

const TeamImage = styled.img`
  width: 43px;
`

const TelegramIcon = styled.img`
  width: 32px;
`

const DiscordIcon = styled.img`
  width: 40px;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  padding-top: 8px;
`

const ManagerLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.blue900};
  font-family: ${fonts.fontFamilyBold};
`

const TeamName = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${colors.blue400};
  margin-left: 4px;
`

const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`

export default MobileProfileSection
