import { useQuery } from '@tanstack/react-query'
import API from '../../../../../api'
import QUERY_KEYS from '../../../../../api/keys'
import { useUser } from '../../../../../contexts/AuthContext'
import { Earning } from '../../../../../types/user'

const getEarnings = async (): Promise<Earning[]> => {
  const data = await API.authGet('payment')

  return data
}

const useEarningsQuery = () => {
  const { user } = useUser()

  return useQuery([QUERY_KEYS.EARNINGS], getEarnings, {
    staleTime: 60 * 1000,
    enabled: !!user.id,
  })
}

export default useEarningsQuery
