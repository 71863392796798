import { useInfiniteQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'
import { useUser } from '../../../contexts/AuthContext'

export const PAGE_SIZE = 15
export const MANAGER_PAGE_SIZE = 30

const searchNfts = async (gameId: string, search: string, page: string, size: number) => {
  const data = await API.authGet(
    `nft/search?filter.GameId=${gameId}${
      search ? `&filter.SearchValuesString=${encodeURIComponent(search)}` : ''
    }&filter.Page=${page}&filter.Size=${size}&filter.IsMarket=false`
  )

  return data
}

const useSearchNFTQuery = () => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const search = searchParams.get('search')
  const page = searchParams.get('page')
  const { user } = useUser()
  const size = user.userRoleName === 'Manager' ? MANAGER_PAGE_SIZE : PAGE_SIZE

  return useInfiniteQuery(
    [QUERY_KEYS.SEARCH_NFT, user.id, gameQuery || '1', search || ''],
    ({ pageParam = 1 }) => searchNfts(gameQuery || '1', search || '', pageParam, size),
    {
      getPreviousPageParam: firstPage => firstPage.previousId ?? undefined,
      getNextPageParam: lastPage => {
        const nextPage = parseInt(page || '1') + 1

        if (nextPage * size > lastPage.totalItemCount + size) {
          return undefined
        }
        return nextPage
      },
      refetchOnWindowFocus: false,
    }
  )
}

export default useSearchNFTQuery
