import { useQuery } from '@tanstack/react-query'
import API from '../api'
import QUERY_KEYS from '../api/keys'

const getAllGames = async () => {
  const data = await API.authGet('game/all')

  return data
}

const useGetAllGamesQuery = () => {
  return useQuery([QUERY_KEYS.GAMES], getAllGames, {
    staleTime: 60 * 1000,
  })
}

export default useGetAllGamesQuery
