import styled from 'styled-components'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'

export const Section = styled.div`
  border-radius: 12px;
  height: 100%;
  background: ${colors.white};
  overflow: hidden;
`

export const NoData = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.blue900};
  margin-top: 60px;
`

export const NotificationWrapper = styled.div`
  overflow-y: scroll;
  height: 600px;
  padding-right: 15px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

export const SectionHeader = styled.div`
  border-bottom: 1px solid #dce9ff;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const Title = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${colors.grey500};
`

export const Content = styled.div`
  padding: 18px 20px;
`

export const FiltersContainer = styled.div<{ $read?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding-bottom: 18px;

  ${({ $read }) =>
    $read &&
    `
    border-bottom: 1px solid #DCE9FF;
    margin-right: 15px;
  `}
`

export const FilterTag = styled.span<{ $selected?: boolean }>`
  border-radius: 22px;
  display: inline-block;
  color: ${colors.grey500};
  background: rgba(82, 130, 236, 0.15);
  transition: all 0.4s;
  padding: 2px 15px;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    `
    color: ${colors.white};
    background: #5282EC;
    font-family: ${fonts.fontFamilyBold};
  `}
`
