import { useQuery } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

const getProfile = async (id: number | null) => {
  const data = await API.authGet(`user/profile/public?userId=${id}`)

  return data
}

const useProfileQuery = (id: number | null) => {
  return useQuery([QUERY_KEYS.PROFILE, id], () => getProfile(id), {
    staleTime: 60 * 1000,
    enabled: !!id,
  })
}

export default useProfileQuery
