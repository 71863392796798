import { formatDistance } from 'date-fns'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useViewNotificationMutation from '../../../../queries/useViewNotificationMutation'
import colors from '../../../../styles/colors'
import fonts from '../../../../styles/fonts'
import { NOTIFICATION_TYPES } from '../../../../types/notification'
import useGetAllUnreadNotificationsQuery from '../../queries/useGetAllUnreadNotificationsQuery'
import { Filters } from '../../types'
import {
  Content,
  FiltersContainer,
  FilterTag,
  SectionHeader,
  Section,
  Title,
  NotificationWrapper,
  NoData,
} from '../UI'

const UnreadNotifications = () => {
  const [filters, setFilters] = useState<Filters[]>([Filters.NFT, Filters.TEAM, Filters.UPDATES])
  const { data: notifications } = useGetAllUnreadNotificationsQuery()
  const { mutateAsync: viewNotification } = useViewNotificationMutation()
  const navigate = useNavigate()
  const [unreadNotifications, setUnreadNotifications] = useState<any>(undefined)
  const sectionRef = useRef<HTMLDivElement | null>(null)
  const [wrapperHeight, setWrapperHeight] = useState<number | null>(null)

  useEffect(() => {
    setUnreadNotifications(notifications)
  }, [notifications])

  const updateNotifications = useCallback(
    (newFilters: Filters[]) => {
      const newNotifications = notifications.filter((not: any) => {
        if (
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST ||
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_ACCEPT ||
          not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_REJECT
        ) {
          return newFilters.includes(Filters.NFT)
        }
        if (
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST ||
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_ACCEPT ||
          not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_REJECT
        ) {
          return newFilters.includes(Filters.TEAM)
        }
        if (
          not.notificationType === NOTIFICATION_TYPES.SYSTEM ||
          not.notificationType === NOTIFICATION_TYPES.GAME
        ) {
          return newFilters.includes(Filters.UPDATES)
        }
        return true
      })
      setUnreadNotifications(newNotifications)
    },
    [notifications, setUnreadNotifications]
  )

  useEffect(() => {
    if (sectionRef?.current) {
      const height = sectionRef.current.getBoundingClientRect()?.height
      setWrapperHeight(height - 160)
    }
  }, [sectionRef])

  return (
    <Section ref={sectionRef}>
      <SectionHeader>
        <Title>Unread ({notifications?.length || 0})</Title>
      </SectionHeader>
      <Content>
        <FiltersContainer>
          <FilterTag
            $selected={filters.includes(Filters.NFT)}
            onClick={() => {
              if (filters.includes(Filters.NFT)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.NFT)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.NFT]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            NFT
          </FilterTag>
          <FilterTag
            $selected={filters.includes(Filters.TEAM)}
            onClick={() => {
              if (filters.includes(Filters.TEAM)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.TEAM)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.TEAM]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            Team
          </FilterTag>
          <FilterTag
            $selected={filters.includes(Filters.UPDATES)}
            onClick={() => {
              if (filters.includes(Filters.UPDATES)) {
                const newFilters = filters.filter((f: Filters) => f !== Filters.UPDATES)
                updateNotifications(newFilters)
                setFilters([...newFilters])
              } else {
                const newFilters = [...filters, Filters.UPDATES]
                updateNotifications(newFilters)
                setFilters(newFilters)
              }
            }}
          >
            Updates
          </FilterTag>
        </FiltersContainer>
        <NotificationWrapper style={wrapperHeight ? { height: `${wrapperHeight}px` } : {}}>
          {unreadNotifications?.map((not: any) => {
            return (
              <Nofitication
                key={not.id}
                onClick={async () => {
                  if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST) {
                    navigate('/')
                  }
                  if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_ACCEPT) {
                    navigate('/')
                  }
                  if (not.notificationType === NOTIFICATION_TYPES.TEAM_REQUEST_REJECT) {
                    navigate('/')
                  }
                  if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST) {
                    navigate('/marketplace?isLeased=false')
                  }
                  if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_ACCEPT) {
                    navigate('/marketplace?isLeased=false')
                  }
                  if (not.notificationType === NOTIFICATION_TYPES.NFT_REQUEST_REJECT) {
                    navigate('/marketplace?isLeased=false')
                  }
                  await viewNotification({ id: not.id })
                }}
              >
                <NotificationImageWrapper>
                  <NFTImage src={not.pictureUrl || '/images/avatar.svg'} />
                </NotificationImageWrapper>
                <DescriptionWrapper>
                  <Description>{not.description}</Description>
                  {not.createdOn ? (
                    <Time>
                      {formatDistance(new Date(not.createdOn), new Date(), { addSuffix: true })}
                    </Time>
                  ) : null}
                </DescriptionWrapper>
                <Dot className="dot" />
                <Cross
                  src="/images/notification-close.svg"
                  alt=""
                  onClick={async e => {
                    e.stopPropagation()
                    await viewNotification({ id: not.id })
                  }}
                />
              </Nofitication>
            )
          })}
          {unreadNotifications?.length === 0 ? (
            <div>
              <NoData>All notifications are read!</NoData>
            </div>
          ) : null}
        </NotificationWrapper>
      </Content>
    </Section>
  )
}

const Nofitication = styled.div`
  background: rgba(59, 130, 246, 0.07);
  border-radius: 5px;
  display: flex;
  position: relative;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background: rgba(3, 41, 96, 0.14);

    & > .dot {
      right: 28px;
    }

    & > img {
      opacity: 1;
    }
  }
`

const NFTImage = styled.img`
  min-width: 100%;
  height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  border-radius: 50%;
`

const NotificationImageWrapper = styled.div`
  width: 50px;
  min-width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
`

const Description = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 14px;
  color: ${colors.blue900};
  font-family: ${fonts.fontFamilyBold};
`

const Dot = styled.span`
  transition: all 0.4s;
  background: #fa8e40;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 4px);
  right: 10px;
`

const Cross = styled.img`
  transition: all 0.4s;
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 6px);
  right: 10px;
  opacity: 0;
`

const Time = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.blue900};
  margin-top: 2px;
`

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-right: 28px;
`

export default UnreadNotifications
