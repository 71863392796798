import { useInfiniteQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import API from '../../../../api'
import QUERY_KEYS from '../../../../api/keys'
import { useUser } from '../../../../contexts/AuthContext'

const searchMarketplaceNfts = async (
  gameId: string,
  search: string,
  page: string,
  isUnleased: boolean
) => {
  const data = await API.authGet(
    `nft/search?filter.GameId=${gameId}&filter.SearchValuesString=${encodeURIComponent(
      search
    )}&filter.IsMarket=true&filter.Page=${page}&filter.Size=20&filter.IsLeased=${
      isUnleased ? 'false' : 'true'
    }`
  )

  return data
}

const useSearchMarketplaceNFTQuery = () => {
  const [searchParams] = useSearchParams()
  const gameQuery = searchParams.get('game')
  const search = searchParams.get('search')
  const page = searchParams.get('page')
  const isUnleased = searchParams.get('isLeased') === 'false'
  const { user } = useUser()

  return useInfiniteQuery(
    [QUERY_KEYS.SEARCH_MARKETPLACE_NFT, user.id, gameQuery || '1', search || '', isUnleased],
    ({ pageParam = 1 }) =>
      searchMarketplaceNfts(gameQuery || '1', search || '', pageParam, isUnleased),
    {
      getPreviousPageParam: firstPage => firstPage.previousId ?? undefined,
      getNextPageParam: lastPage => {
        const nextPage = parseInt(page || '1') + 1

        if (nextPage * 20 > lastPage.totalItemCount + 20) {
          return undefined
        }
        return nextPage
      },
      refetchOnWindowFocus: false,
    }
  )
}

export default useSearchMarketplaceNFTQuery
