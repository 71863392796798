import { useEffect, useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import Modal from '../../../../components/Modal'
import TutorialTooltip from '../../../../components/TutorialTooltip/TutorialTooltip'
import { useUser } from '../../../../contexts/AuthContext'
import colors from '../../../../styles/colors'
import { getVideoId } from '../VideoSection'
import useAddTrainingProgress from './queries/useAddTrainingProgress'
import useEnrollTraining from './queries/useEnrollTraining'

const TrainingCard = (props: any) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [playVideo, setPlayVideo] = useState<boolean>(false)
  const youtubeVideo = useRef<any>(null)
  const videoId = getVideoId(props.videoUrl)
  const { mutateAsync: addProgress } = useAddTrainingProgress()
  const { mutateAsync: enrollTraining } = useEnrollTraining()
  const { tutorialStep, setTutorialStep } = useUser()
  const target = useRef(null)
  const [showTutorialStep, setShowTutorialStep] = useState<boolean>(false)

  const startTraining = async () => {
    if (tutorialStep === 4) {
      setTutorialStep(tutorialStep + 1)
    }
    setOpenModal(true)
    if (props.status !== 'Done') {
      await enrollTraining({ trainingId: props.id })
    }
  }

  const onClose = async () => {
    if (youtubeVideo.current && props.status !== 'Done') {
      const currentTime = await youtubeVideo.current.internalPlayer.getCurrentTime()
      const duration = await youtubeVideo.current.internalPlayer.getDuration()
      const duration5perc = duration - (duration * 95) / 100

      await youtubeVideo.current?.internalPlayer?.pauseVideo?.()
      if (currentTime + duration5perc >= duration) {
        await addProgress({
          trainingId: props.id,
          remainingMinutes: 0,
          progress: 100,
        })
        await props.refetchGame()
      } else {
        await addProgress({
          trainingId: props.id,
          remainingMinutes: duration - currentTime,
          progress: (currentTime / duration) * 100,
        })
        await props.refetchGame()
      }
    }
    setOpenModal(false)
  }

  useEffect(() => {
    if (props?.isAxie && props?.index === 0 && tutorialStep === 4) {
      setTimeout(() => {
        setShowTutorialStep(true)
      }, 2000)
    }
  }, [])

  return (
    <>
      <Overlay
        placement={'top'}
        show={tutorialStep === 4 && showTutorialStep}
        target={target.current}
      >
        {props => (
          <Tooltip className="tutorial" {...props}>
            <TutorialTooltip>
              Step: Complete the trainings and unlock the requesting NFTs.
            </TutorialTooltip>
          </Tooltip>
        )}
      </Overlay>
      <Container ref={target}>
        <Label $orange={props.status === 'Ongoing'} $green={props.status === 'Done'}>
          {props.status || 'To Do'}
        </Label>
        <Icon src="/images/training.svg" alt="training" />
        <Name onClick={startTraining}>{props.name}</Name>
        <Duration>
          <TimeIcon src="/images/time.svg" alt="remaining time" />
          {props.remainingMinutes || 2} mins
        </Duration>
        {props.status === 'Done' ? (
          <>
            <ProgressBar />
            <Percentage>100%</Percentage>
          </>
        ) : (
          <GreenButton onClick={startTraining}>
            {props.status === 'Ongoing' ? 'Continue Training' : 'Start Training'}
          </GreenButton>
        )}
        <Modal bigSize title={props.name} open={openModal} setOpen={onClose}>
          <ModalBody>
            <ModalDescription>{props.description}</ModalDescription>
            {videoId ? (
              <VideoContainer>
                <Wrapper $hide={playVideo}>
                  <PlayIcon
                    src="/images/play-button.svg"
                    alt="play button"
                    onClick={() => {
                      if (youtubeVideo.current) {
                        youtubeVideo.current.internalPlayer.playVideo()
                        setPlayVideo(true)
                      }
                    }}
                  />
                  <DescriptionWrapper>
                    <Description>Watch video</Description>
                  </DescriptionWrapper>
                </Wrapper>
                <YouTube
                  ref={youtubeVideo}
                  videoId={videoId} // defaults -> ''
                  // id={string}                       // defaults -> ''
                  // className={string}                // defaults -> ''
                  // iframeClassName={string}          // defaults -> ''
                  // style={object}                    // defaults -> {}
                  // title={string}                    // defaults -> ''
                  // loading={string}                  // defaults -> undefined
                  // opts={obj}                        // defaults -> {}
                  // onReady={func}                    // defaults -> noop
                  // onPlay={func}                     // defaults -> noop
                  // onPause={func}                    // defaults -> noop
                  // onEnd={func}                      // defaults -> noop
                  // onError={func}                    // defaults -> noop
                  // onStateChange={func}              // defaults -> noop
                  // onPlaybackRateChange={func}       // defaults -> noop
                  // onPlaybackQualityChange={func}    // defaults -> noop
                />
              </VideoContainer>
            ) : null}
          </ModalBody>
        </Modal>
      </Container>
    </>
  )
}

const Container = styled.div`
  background: ${colors.white};
  box-shadow: 0px 8px 18px rgba(224, 234, 255, 0.45);
  border-radius: 8px;
  padding: 19px 15px;
  text-align: center;
  position: relative;
`

const Icon = styled.img`
  width: 55px;
  margin-right: -11px;
  margin-top: 15px;
  margin-bottom: 12px;
`

const Name = styled.p`
  color: ${colors.blue900};
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 16px;
  cursor: pointer;
`

export const GreenButton = styled.button`
  color: ${colors.white};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  padding: 7px 10px;
  border-radius: 8px;
  background: #1dac56;
  border: 0;
  transition: all 0.4s;

  &:hover {
    background: #1dac56aa;

    & > a {
      color: ${colors.white};
    }
  }
`

const Label = styled.span<{ $orange?: boolean; $green?: boolean }>`
  background: #fd7c7c;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.white};
  letter-spacing: 0.15px;
  position: absolute;
  top: 12px;
  right: 10px;
  border-radius: 50px;
  padding: 0 6px 1px;

  ${({ $green }) =>
    $green &&
    `
    background: #1dac56;
  `}

  ${({ $orange }) =>
    $orange &&
    `
    background: #FF981F;
  `}
`

const Duration = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.grey500};
  letter-spacing: 0.15px;
  text-align: center;
  margin: 0 0 12px;
`

const TimeIcon = styled.img`
  width: 15px;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 4px;
`

const ModalBody = styled.div`
  text-align: center;
  padding: 12px 32px 32px;
`

const ModalDescription = styled.p`
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: ${colors.blue500};
  margin: 0 0 24px;
`

const ProgressBar = styled.div`
  margin: 0;
  display: inline-block;
  background: #1dac56;
  border-radius: 18px;
  height: 10px;
  width: 100px;
  vertical-align: middle;
`

const Percentage = styled.span`
  color: ${colors.blue900};
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  vertical-align: middle;
  margin-left: 5px;
`

const VideoContainer = styled.div`
  position: relative;

  & iframe {
    width: 100%;
    border-radius: 12px;
  }
`

const Wrapper = styled.div<{ $hide?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  z-index: 2;
  background: linear-gradient(97.16deg, rgba(86, 135, 244, 0.5) 2.84%, rgba(3, 41, 96, 0.5) 93.15%);
  backdrop-filter: blur(7px);
  text-align: center;
  padding-top: 140px;
  transition: all 0.4s;
  opacity: 1;

  ${({ $hide }) =>
    $hide &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`

const PlayIcon = styled.img`
  cursor: pointer;
`

const DescriptionWrapper = styled.div`
  padding: 20px 0 24px;
`

const Description = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${colors.white};
`

export default TrainingCard
