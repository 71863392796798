import { useMutation } from '@tanstack/react-query'
import API from '../../../../../api'

interface TrainingProgress {
  trainingId: number
  progress: number
  remainingMinutes: number
}

const addProgress = async (body: TrainingProgress) => {
  const data = await API.authPost('training/progress', {
    ...body,
  })

  return data
}

const useAddTrainingProgress = () => {
  return useMutation((body: TrainingProgress) => addProgress(body))
}

export default useAddTrainingProgress
