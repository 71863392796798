import styled from 'styled-components'
import colors from '../../../../../styles/colors'
import { Earning } from '../../../../../types/user'
import SectionTitle from '../../SectionTitle'
import EarningTile from './EarningTile'

const PlayerEarnings = ({ earnings }: { earnings?: Earning[] }) => {
  return (
    <>
      <SectionTitle>My Earnings</SectionTitle>
      <EarningsContainer>
        <InnerWrapper>
          {earnings?.map(earning => {
            return <EarningTile earning={earning} key={earning.id} />
          })}
        </InnerWrapper>
      </EarningsContainer>
    </>
  )
}

const EarningsContainer = styled.div`
  height: 250px;
  overflow: hidden;
`

const InnerWrapper = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  padding-right: 15px;

  /* width */
  &::-webkit-scrollbar {
    width: 3px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${colors.grey};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${colors.blue400};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.blue450};
  }
`

export default PlayerEarnings
