import { useMutation, useQueryClient } from '@tanstack/react-query'
import API from '../../../api'
import QUERY_KEYS from '../../../api/keys'

interface ApprovePaymentParams {
  id: number
}

const approvePayment = async (body: ApprovePaymentParams) => {
  const data = await API.authPost('payment/request/accept', {
    ...body,
  })

  return data
}

const useApprovePaymentMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((body: ApprovePaymentParams) => approvePayment(body), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TEAM_PAYMENTS] })
    },
  })
}

export default useApprovePaymentMutation
